import { Injectable, Pipe } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, take } from 'rxjs';
import {
  ArchiveGame,
  CityGuess,
  Country,
  dailyStats,
  FactGuess,
  FlowerGuess,
  Game,
  GameProgressStat,
  LandmarkGuess,
  NicknameGuess,
  Round,
  Settings,
  Stats,
  StatsToUpdate,
  WeatherGuess,
} from './../interfaces/interfaces';
import {
  archiveGame,
  initialData,
  settings,
} from './../data/data';
import { CountriesService } from './countries.service';
import { ImageService } from './image.service';
import { randomPerms } from '../data/perms';
import { DialogsService } from './dialogs.service';
import { DatabaseService } from './database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { countries } from '../data/countries';
import { AdsService } from './ads.service';
import { weatherByState } from '../data/weather';
import { PlatformService } from './platform.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private state = new BehaviorSubject(JSON.parse(JSON.stringify(initialData)));

  private settings = new BehaviorSubject(JSON.parse(JSON.stringify(settings)));
  private archiveGame = new BehaviorSubject(JSON.parse(JSON.stringify(archiveGame)));

  constructor(
    private countriesService: CountriesService,
    private imgService: ImageService,
    private image: ImageService,
    private dialog: DialogsService,
    private db: DatabaseService,
    private router: Router,
    private route: ActivatedRoute,
    private ads: AdsService,
    private platform: PlatformService,
    private user: UserService
  ) { }

  getState(): Observable<Game> {
    return this.state;
  }

  getStateObj(): Game {
    return this.state.value;
  }

  getSettings(): Observable<Settings> {
    return this.settings;
  }

  getIsArchive(): Observable<ArchiveGame> {
    return this.archiveGame;
  }

  resetArchiveGame() {
    let aGame: ArchiveGame = this.archiveGame.value;
    aGame.isArchiveGame = false;
    this.archiveGame.next(aGame)
    this.createGame();
  }

  setArchiveGame(gameId: string) {
    countries.forEach((country, i) => {
      country.game.forEach(game => {
        if (game.uniqueId === gameId) {
          let aGame: ArchiveGame = this.archiveGame.value;
          aGame.isArchiveGame = true;
          aGame.countryIndex = i;
          aGame.gameNumber = game.number;
          aGame.uniqueId = game.uniqueId;
          this.archiveGame.next(aGame);
        }
      });
    });
  }

  loadStats() {
    if (!this.platform.isInBrowser()) return;
    //Get stats and put into behavior subject
    if (localStorage.getItem('us-stats') !== null) {
      let localStats = localStorage.getItem('us-stats');
      if (localStats !== null) {
        let statistics: Stats = JSON.parse(localStats);
        if (
          !this.isYesterday(statistics.lastWinDate) &&
          !this.isToday(statistics.lastWinDate)
        ) {
          statistics.currentStreak = 0;
        }
        this.user.getStatsBS().next(statistics);
      }
    }
  }

  loadDist() {
    if (!this.platform.isInBrowser()) return;
    let state = this.state.value;

    // Choosing miles or kilometers
    if (localStorage.getItem('distUnit') !== null) {
      state.distUnit = localStorage.getItem('distUnit');
    } else {
      state.distUnit = 'mi';
    }
    this.state.next(state);
  }

  loadDifficulty() {
    if (!this.platform.isInBrowser()) return;
    let settings = this.settings.value;
    if (localStorage.getItem('us-difficulty') !== null) {
      settings.difficulty = localStorage.getItem('us-difficulty');
    } else {
      settings.difficulty = 'normal';
    }
    this.settings.next(settings);
  }


  createGame() {
    let state: Game = this.state.value;
    let archive: ArchiveGame = this.archiveGame.value;
    if (!this.platform.isInBrowser()) return;

    if (!archive.isArchiveGame) {
      //Does player have Daily game in Local Storage?
      let localStorageGame = localStorage.getItem('us-game');
      if (localStorageGame !== null) {
        let localGame: Game = JSON.parse(localStorageGame);

        // Load in preferred units of distance
        this.loadDist();
        const gameNumber = this.image.getGameNumber();
        if (localGame.gameNumber === gameNumber && localGame.newGame) {
          this.state.next(localGame);
          return;
        }
      }
    } else {
      //Does player have archive game in Local Storage?
      let localStorageGame = localStorage.getItem('us-archive-game');
      if (localStorageGame !== null) {
        let localGame: Game = JSON.parse(localStorageGame);
        this.loadDist();
        let gamePopulated = false;
        this.route.queryParams.pipe(take(1)).subscribe(val => {
          if (val['game'] === localGame.uniqueId || !val['game']) {
            this.state.next(localGame);
            gamePopulated = true;
          }
        });
        if (gamePopulated) {
          return;
        }
      }
    }

    // Create a new game
    this.imgService.setLoading();
    this.state.next(JSON.parse(JSON.stringify(initialData)));
    state = this.state.value;

    // Check if supports webp
    state.data.supportsWebP = this.image.supportsWebP();
    if (state.data.supportsWebP) {
      state.data.imgExtension = 'webp';
    } else state.data.imgExtension = 'jpg';

    // Load in difficulty to current game
    let diff: string = this.settings.value.difficulty;
    state.difficulty = diff;

    this.loadDist();
    state = this.state.value;

    const countries = this.countriesService.getCountries();


    const gameNumber = this.image.getGameNumber();
    let countryName = randomPerms[gameNumber].state;
    let photoCode = randomPerms[gameNumber].photoCode;

    if (archive.isArchiveGame) {
      countryName = countries[archive.countryIndex].name;
      photoCode = archive.gameNumber;
    }

    const country: Country | undefined = countries.find(obj => { return obj.name === countryName });

    if (country !== null && country !== undefined) {

      let game!: Round;
      let gameIndex = 0;
      country.game.forEach((g, i) => {
        if (g.number === photoCode) {
          game = g;
          gameIndex = i;
        }
      });
      state.imgSrc = `./assets/states/${country.code.toLowerCase()}/main${photoCode}.${state.data.imgExtension}`;
      state.uniqueId = game.uniqueId;
      state.photoCode = photoCode;
      state.imgSrcLink = game.mainImage.imageLink;
      state.imgName = game.mainImage.name;
      state.wikiLink = game.mainImage.wikiLink;
      state.landmarks.imgSrc = game.landmark.imageLink;
      state.landmarks.wikiLink = game.landmark.wikiLink;
      if (game.city.imageLink === "" || !game.city.imageLink) {
        state.cityGuess.noImage = true;
        state.showLinks = true;
        state.cityGuess.shownConfetti = true;
      }
      state.cityGuess.imgSrcLink = game.city.imageLink;
      state.cityGuess.wikiLink = game.city.wikiLink;

      if (state.country === country.name) {
        this.imgService.setLoaded();
      }

      state.country = country.name;
      state.capital = country.capital;
      state.capitalCity.answer = country.capital;
      state.countryCode = country.code.toLowerCase();
      state.lat = country.lat;
      state.long = country.long;

      const answerSrc = `./assets/states/${country.code.toLowerCase()}/landmark${photoCode}.${state.data.imgExtension}`;
      state.landmarks.answerSrc = answerSrc;
      state.landmarks.answer = game.landmark.name;
      state.landmarks.locations.push({
        id: 1,
        name: game.landmark.name,
        country: country.name,
        isCorrect: true,
        isGuessed: false,
        imgSrc: answerSrc,
        shake: false,
        zoom: false
      });

      game.landmarksRound.forEach(landmark => {
        state.landmarks.locations.push({
          id: 1,
          isCorrect: false,
          isGuessed: false,
          imgSrc: `./assets/states/${landmark.code.toLowerCase()}/landmark${landmark.number}.${state.data.imgExtension}`,
          shake: false,
          zoom: false
        });
      });
      state.landmarks.locations = this.shuffle(state.landmarks.locations);
      let id = 1;
      state.landmarks.locations.forEach(el => {
        el.id = id;
        id++;
      });

      // Adding in landmarks for part two of bonus round 1
      state.landmarksGuess.landmarks.push({
        value: state.landmarks.answer,
        isGuessed: false,
        isCorrect: true,
        shake: false
      });
      game.landmarkPlaces.forEach(place => {
        state.landmarksGuess.landmarks.push({
          value: place,
          isGuessed: false,
          isCorrect: false,
          shake: false
        });
      });
      state.landmarksGuess.landmarks = this.shuffle(state.landmarksGuess.landmarks);

      // Adding cities to game
      state.cityGuess.imgSrc = `./assets/states/${country.code.toLowerCase()}/city${photoCode}.${state.data.imgExtension}`;
      state.cityGuess.answer = game.city.name;
      state.cityGuess.cities.push({
        value: game.city.name,
        isGuessed: false,
        isCorrect: true,
        shake: false
      });
      country.cities.forEach(place => {
        if (place !== game.city.name) {
          state.cityGuess.cities.push({
            value: place,
            isGuessed: false,
            isCorrect: false,
            shake: false
          });
        }
      });
      state.cityGuess.cities = this.shuffle(state.cityGuess.cities);

      // Adding flower options to game
      state.flowerGuess.answer = country.flower.name;
      state.flowerGuess.flowers.push({
        value: country.flower.name,
        isGuessed: false,
        isCorrect: true,
        shake: false
      });

      game.flowerOptions.forEach(flower => {
        state.flowerGuess.flowers.push({
          value: flower,
          isGuessed: false,
          isCorrect: false,
          shake: false
        })
      });
      state.flowerGuess.flowers = this.shuffle(state.flowerGuess.flowers);
      if (gameIndex % 2 === 0) {
        state.flowerGuess.imgSrc = `./assets/states/${country.code.toLowerCase()}/flower2.${state.data.imgExtension}`;
        state.flowerGuess.imgSrcLink = country.flower.imgLink2;
      } else {
        state.flowerGuess.imgSrc = `./assets/states/${country.code.toLowerCase()}/flower1.${state.data.imgExtension}`;
        state.flowerGuess.imgSrcLink = country.flower.imgLink;
      }
      state.flowerGuess.wikiLink = country.flower.wikiLink;

      // Adding weather options to game
      const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
      state.weatherGuess.month = this.titleCase(game.weatherMonth);
      const weatherState = weatherByState.find(state => state.state === country.name);

      if (weatherState) {
        const temp = weatherState.weather[months.indexOf(game.weatherMonth)];
        state.weatherGuess.temp = temp;
        state.weatherGuess.tempC = this.fToC(temp);
        const tempFloor = Math.floor(temp / 10) * 10;

        let random = Math.floor(Math.random() * 4);
        if (temp > 80) {
          random = 2;
        } else if (temp > 70) {
          random = 3;
        }

        for (let i = random; i >= 1; i--) {
          state.weatherGuess.weatherOptions.push({
            value: `${tempFloor - (i * 10)} - ${tempFloor - (i * 10) + 9}°F`,
            valueC: `${this.fToC(tempFloor - (i * 10))} - ${this.fToC(tempFloor - (i * 10) + 9)}°C`,
            isGuessed: false,
            isCorrect: false,
            shake: false
          })
        }
        state.weatherGuess.weatherOptions.push({
          value: `${tempFloor} - ${tempFloor + 9}°F`,
          valueC: `${this.fToC(tempFloor)} - ${this.fToC(tempFloor + 9)}°C`,
          isGuessed: false,
          isCorrect: true,
          shake: false
        })

        for (let i = 1; i < 3 - random + 1; i++) {
          state.weatherGuess.weatherOptions.push({
            value: `${tempFloor + (i * 10)} - ${tempFloor + (i * 10) + 9}°F`,
            valueC: `${this.fToC(tempFloor + (i * 10))} - ${this.fToC(tempFloor + (i * 10) + 9)}°C`,
            isGuessed: false,
            isCorrect: false,
            shake: false
          })
        }
      }

      // Adding Nickname options to game
      state.nicknameGuess.answer = country.nickname;
      state.nicknameGuess.nicknameOptions.push({
        value: country.nickname,
        isGuessed: false,
        isCorrect: true,
        shake: false
      });
      game.nicknameOptions.forEach(nickname => {
        state.nicknameGuess.nicknameOptions.push({
          value: nickname,
          isGuessed: false,
          isCorrect: false,
          shake: false
        })
      });
      state.nicknameGuess.nicknameOptions = this.shuffle(state.nicknameGuess.nicknameOptions);

      // Adding random fact options to game
      if (game.facts.trueFalse) {
        state.randomFactGuess.title = `Which is true about ${country.name}?`;
        state.randomFactGuess.factOptions.push({
          value: game.facts.trueFact,
          isGuessed: false,
          isCorrect: true,
          shake: false
        });
        state.randomFactGuess.factOptions.push({
          value: game.facts.falseFact,
          isGuessed: false,
          isCorrect: false,
          shake: false
        });
      }
      else {
        state.randomFactGuess.title = game.facts.fillQuestion;
        state.randomFactGuess.factOptions.push({
          value: game.facts.fillAnswer,
          isGuessed: false,
          isCorrect: true,
          shake: false
        });
        game.facts.fillAnswers.forEach(option => {
          if (option !== game.facts.fillAnswer) {
            state.randomFactGuess.factOptions.push({
              value: option,
              isGuessed: false,
              isCorrect: false,
              shake: false
            })
          }
        });
      }
      state.randomFactGuess.factOptions = this.shuffle(state.randomFactGuess.factOptions);
    }

    state.gameNumber = this.image.getGameNumber();
    state.guesses = JSON.parse(JSON.stringify(initialData.guesses));
    state.guessNumber = 0;
    state.showShare = false;
    state.showAnswer = false;
    this.state.next(state);
    this.saveGame();
  }

  updatePreviousPage(route: string) {
    let state: Game = this.state.value;
    state.previousPage = route;
    this.state.next(state);
    this.saveGame();
  }

  navigateToPreviousPage() {
    const state: Game = this.state.value;
    this.router.navigate([state.previousPage]);
  }

  shuffle(array: any) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }


  getShareMsg(): string {

    const state: Game = this.state.value;
    const gameNumber = state.gameNumber;
    const numGuesses = state.guessNumber;
    let msg = "";

    if (!state.showAnswer) {
      msg = `📷 #WhereTaken🇺🇸 #${gameNumber} ${numGuesses}/6`;
    } else {
      msg = `📷 #WhereTaken🇺🇸  #${gameNumber}, X/6`;
    }
    if (state.difficulty === 'hard') {
      msg += '#HardMode';
    } else if (state.difficulty === 'veryhard') {
      msg += '#ExpertMode';
    }
    msg += '\n';

    for (let i = 0; i < state.guessNumber; i++) {
      state.guesses[i].squares.forEach((square) => {
        if (state.difficulty == 'normal') {
          if (square == 'green') {
            msg += '🟦';
          } else if (square == 'yellow') {
            msg += '🟥';
          } else {
            msg += '⬜';
          }
        } else {
          msg += '⬜';
        }
      });
      if (state.difficulty !== 'veryhard') {
        msg += state.guesses[i].direction + '\n';
      } else {
        if (i < state.guessNumber - 1 || state.showAnswer == true) {
          msg += '❌' + '\n';
        } else {
          msg += '✅' + '\n';
        }
      }
    }
    const facts = [state.nicknameGuess.guessed, state.randomFactGuess.guessed, state.flowerGuess.guessed, state.weatherGuess.guessed];
    if (state.guessed) msg += "⭐";
    if (this.checkTrueValues(facts) >= 3) msg += "⭐";
    if (state.landmarks.guessed && state.landmarksGuess.guessed) msg += "⭐";
    if (state.map.guessed && state.capitalCity.guessed) msg += "⭐";
    if (state.cityGuess.guessed) msg += "⭐";

    msg += '\nwheretakenusa.teuteuf.fr';
    return msg;
  }

  updateInputValue(val: string) {
    let currentState = this.state.value;
    currentState.guessValue = val;
    this.state.next(currentState);
  }


  updateInputValueCity(val: string) {
    let currentState = this.state.value;
    currentState.capitalCity.guessValue = val;
    this.state.next(currentState);
  }

  updateStats() {
    if (!this.platform.isInBrowser()) return;
    let state: Game = this.state.value;
    let stats: Stats = this.user.getStatsObj();

    stats.played++;
    stats.gameNumber = state.gameNumber;

    // Just won the game
    if (state.showShare && !state.showAnswer) {
      stats.win++;
      //Check if on a streak, if so update it
      if (this.isYesterday(stats.lastWinDate) || this.isToday(stats.lastWinDate)) {
        stats.currentStreak++;
      } else {
        stats.currentStreak = 1;
      }

      if (stats.maxStreak < stats.currentStreak) {
        stats.maxStreak = stats.currentStreak;
      }
      stats.lastWinDate = new Date().toISOString();

      stats.guessDist[state.guessNumber]++;
    }
    // Just lost the game
    else if (state.showShare && state.showAnswer) {
      stats.currentStreak = 0;
    }

    stats.winPercent = Math.round((stats.win / stats.played) * 1000) / 10;
    this.user.getStatsBS().next(stats);
    localStorage.setItem('us-stats', JSON.stringify(stats));
    this.user.saveStatsToDB();
  }


  updateStatsManually(stats: StatsToUpdate) {
    if (!this.platform.isInBrowser()) return;
    const current: Stats = this.user.getStatsObj();
    let total = 0;

    stats.stats.forEach(stat => {
      total += stat;
    });

    current.currentStreak = stats.currentStreak;
    current.maxStreak = stats.maxStreak;
    current.played = stats.totalGames;
    current.lastWinDate = new Date().toISOString();
    current.guessDist = stats.stats;
    current.win = total;
    current.winPercent = Math.round((total / stats.totalGames) * 1000) / 10;

    this.user.getStatsBS().next(current);
    localStorage.setItem('us-stats', JSON.stringify(current));
  }


  //Check if is yesterday
  isYesterday(date: string): boolean {
    const cYesterday = new Date(date);

    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (
      cYesterday.getFullYear() === yesterday.getFullYear() &&
      cYesterday.getMonth() === yesterday.getMonth() &&
      cYesterday.getDate() === yesterday.getDate()
    ) {
      return true;
    }
    return false;
  }

  isToday(date: string): boolean {
    const cToday = new Date(date);

    let today = new Date();

    if (
      cToday.getFullYear() === today.getFullYear() &&
      cToday.getMonth() === today.getMonth() &&
      cToday.getDate() === today.getDate()
    ) {
      return true;
    }
    return false;
  }

  saveGame() {
    if (!this.platform.isInBrowser()) return;
    const archive: ArchiveGame = this.archiveGame.value;
    const state: Game = this.state.value;

    if (!archive.isArchiveGame) {
      localStorage.setItem('us-game', JSON.stringify(state));
    } else {
      localStorage.setItem('us-archive-game', JSON.stringify(state));
    }
  }

  guess(val: string): void {
    // Find country
    const archive: ArchiveGame = this.archiveGame.value;

    let country: Country | null = this.countriesService.findCountry(val);
    if (country === null || country === undefined) {
      this.triggerAlert();
      return;
    }

    // Update state
    let state: Game = this.state.value;
    let currentGuess = state.guesses[state.guessNumber];
    state.guessValue = '';
    currentGuess.isBlank = false;
    currentGuess.distance = this.countriesService.calcDistance(
      country.lat,
      country.long,
      state.lat,
      state.long
    );

    // Convert to miles
    currentGuess.distanceMI = Math.round(currentGuess.distance * 0.621371);
    currentGuess.percent = this.countriesService.calcPercentage(
      currentGuess.distance
    );

    currentGuess.nextTo = this.countriesService.checkIfBorderState(state.country, country.name);

    currentGuess.squares = this.countriesService.calcSquares(
      currentGuess.percent
    );
    const bearing = this.countriesService.calcBearing(
      state.lat,
      state.long,
      country.lat,
      country.long
    );
    currentGuess.direction = this.countriesService.getArrow(
      bearing,
      currentGuess.distance
    );
    currentGuess.isLoading = true;

    this.state.next(state);

    setTimeout(() => {
      currentGuess.country = val;
      if (state.guessNumber > 4 || currentGuess.distance === 0) {
        state.showShare = true;
        this.state.next(state);
        this.saveGame();
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }

        this.preloadLandmarkImages();
        this.preloadFlowerImages();
        if (state.guessNumber > 4 && currentGuess.distance !== 0) {
          state.showAnswer = true;
        }
        if (currentGuess.distance === 0) {
          this.updateProgressBar();
        }

        if (!archive.isArchiveGame) this.updateStats();

        if (this.canTrack()) {
          const gameStatus = currentGuess.distance === 0 ? "completed" : "failed";
          const data: GameProgressStat = {
            puzzle: state.gameNumber,
            message: "wheretaken",
            guesses: state.guesses.map((c) => c.country!),
            language: navigator.language,
            status: gameStatus
          }
          this.db.logGameProgress(data);
        }

      }
      if (currentGuess.distance === 0) {
        state.guessed = true;
        state.stars.number++;
      }
      currentGuess.isGuessed = true;
      currentGuess.isLoading = false;

      state.guessNumber++;
      if (state.guessNumber === 3 && !state.showShare) {
        this.ads.startAuction('sidebar_left', 'desktop');
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }
      }
      this.state.next(state);
      this.saveGame();
    }, 1800);
  }

  canTrack() {
    const d = new Date();
    const h = d.getUTCHours();
    const pt: number[] = [16, 22];
    return pt.indexOf(h) !== -1 || window.origin.indexOf('staging') !== -1;
  }

  preloadImagesRoundOne() {
    let args: string[] = [];
    args.push("/assets/star-filled.svg");
    args.push("/assets/share.svg");
    this.preload(...args);
  }

  updateProgressBar() {
    let state: Game = this.state.value;
    let percent = 20

    this.state.next(state);
    this.saveGame();
    let offset = 0;

    for (let i = state.stars.percent; i <= state.stars.percent + percent; i++) {
      offset++;
      setTimeout(() => {
        state.stars.percent = i;
        if (state.stars.percent == 20) {
          state.stars.stars[0].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[0].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 40) {
          state.stars.stars[1].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[1].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 60) {
          state.stars.stars[2].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[2].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 80) {
          state.stars.stars[3].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[3].shownAnimation = true;
          }, 500);
        }
        if (state.stars.percent == 100) {
          state.stars.stars[4].isGuessed = true;
          setTimeout(() => {
            state.stars.stars[4].shownAnimation = true;
          }, 500);
        }
        this.state.next(state);
      }, offset * 20)
    }

    setTimeout(() => {
      this.saveGame();
    }, percent * 20 + 1000);
    this.state.next(state);
  }

  triggerAlert() {
    let state = this.state.value;
    state.showAlert = true;
    this.state.next(state);
    setTimeout(() => {
      state.showAlert = false;
      this.state.next(state);
    }, 2000);
    return;
  }

  updateDistUnit(units: string) {
    if (!this.platform.isInBrowser()) return;
    let state = this.state.value;
    state.distUnit = units;
    localStorage.setItem('distUnit', units);
    this.state.next(state);
    this.saveGame();
  }

  updateConfetti() {
    let state: Game = this.state.value;
    state.shownConfetti = true;
    this.state.next(state);
  }

  updateDifficulty(difficulty: string) {
    if (!this.platform.isInBrowser()) return;
    let settings: Settings = this.settings.value;
    settings.difficulty = difficulty;
    localStorage.setItem('us-difficulty', difficulty);
    this.settings.next(settings);
  }

  preload(...args: any[]): void {
    if (!this.platform.isInBrowser()) return;
    let imgs = new Array();
    for (var i = 0; i < args.length; i++) {
      imgs[i] = new Image();
      imgs[i].src = args[i];
    }
  }

  // Landmark page
  landmarkGuess(landmark: any) {
    let state: Game = this.state.value;
    if (state.landmarks.roundOver || landmark.isGuessed) {
      return;
    }
    state.landmarks.guessesRemaining--;
    for (let i = 0; i < state.landmarks.buttons.length; i++) {
      state.landmarks.buttons[i] = false;
    }

    if (landmark.isCorrect) {
      state.landmarks.guessed = true;
      state.landmarks.guesses++;
      state.landmarks.locations.forEach((landmark, i) => {
        landmark.isGuessed = true;
      });
      state.landmarks.roundOver = true;
      this.state.next(state);
      state.landmarks.shownConfetti = true;
      this.state.next(state);
      this.preLoadMap();
      this.saveGame();
      setTimeout(() => {
        state.landmarksGuess.showRound = true;
        this.state.next(state);
        this.saveGame();
      }, 1000);
    } else {
      state.landmarks.locations[landmark.id - 1].isGuessed = true;
      state.landmarks.locations[landmark.id - 1].shake = true;
      state.landmarks.guesses++;
      setTimeout(() => {
        state.landmarks.locations[landmark.id - 1].shake = false;
        if (state.landmarks.guesses >= 2) {
          state.landmarks.roundOver = true;
          this.preLoadMap();
          state.landmarks.locations.forEach(location => {
            location.isGuessed = true;
          });
          setTimeout(() => {
            state.landmarksGuess.showRound = true;
            this.state.next(state);
            this.saveGame();
          }, 300);
        }
        this.saveGame();
      }, 300);

    }

    this.state.next(state);
    this.saveGame();
  }

  preloadLandmarkImages() {
    const state: Game = this.state.value;
    let args: string[] = [];


    state.landmarks.locations.forEach(location => {
      args.push(location.imgSrc);
    });

    args.push("/assets/increase.png");
    args.push("/assets/decrease.png");
    args.push("/assets/star-filled.svg");
    args.push("/assets/close.svg");

    this.preload(...args);
  }

  updateLandmarkZoom(id: number) {
    let state: Game = this.state.value;
    state.landmarks.zoom = !state.landmarks.zoom;

    state.landmarks.locations.forEach(landmark => {
      if (landmark.id === id) {
        landmark.zoom = !landmark.zoom
        state.landmarks.zoomImage = landmark.imgSrc;
      }
    });
    this.state.next(state);
    this.saveGame();
  }

  // LANDMARK NAME GUESS
  landmarkNameGuess(landmark: LandmarkGuess, index: number) {
    let state: Game = this.state.value;
    if (state.landmarksGuess.roundOver)
      return;

    state.landmarksGuess.landmarks[index].isGuessed = true;

    if (landmark.isCorrect) {
      state.landmarksGuess.guessed = true;
      state.landmarksGuess.roundOver = true;
      this.state.next(state);
      state.landmarksGuess.shownConfetti = true;
      this.state.next(state);
      // Only give star if landmark image correct
      if (state.landmarks.guessed) {
        state.stars.number++;
        this.updateProgressBar();
      }
      if (state.topAdOpen) {
        this.ads.startAuction('top_banner', 'mobile');
      }
    } else {
      state.landmarksGuess.guessesRemaining--;
      if (state.landmarksGuess.guessesRemaining <= 0) {
        state.landmarksGuess.roundOver = true;
        state.landmarksGuess.landmarks.forEach(landmark => {
          if (landmark.isCorrect) {
            landmark.isGuessed = true;
          }
        });
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }
      }
      state.landmarksGuess.landmarks[index].shake = true;
      setTimeout(() => {
        state.landmarksGuess.landmarks[index].shake = false;
      }, 4000);
    }

    this.state.next(state);
    this.saveGame();
  }

  showButtons(index: number) {
    let state: Game = this.state.value;

    if (state.landmarks.roundOver || state.landmarks.locations[index].isGuessed) {
      return;
    }

    for (let i = 0; i < state.landmarks.buttons.length; i++) {
      state.landmarks.buttons[i] = false;
    }
    state.landmarks.buttons[index] = true;
    this.state.next(state);
    this.saveGame();
  }

  hideButtons(index: number) {
    let state: Game = this.state.value;
    state.landmarks.buttons[index] = false;
    this.state.next(state);
    this.saveGame();
  }


  // MAP PAGE

  cityConfetti() {
    let state: Game = this.state.value;
    state.capitalCity.shownConfetti = true;
    this.state.next(state);
  }

  // CITY PAGE
  capitalCityGuess(guess: string, cityNames: string[]) {
    // Find country
    const found = cityNames.find(city => city.toLowerCase() === guess?.toLowerCase());

    if (found === null || found === undefined) {
      this.triggerAlert();
      return;
    }

    // Update state
    let state: Game = this.state.value;
    let currentGuess = state.capitalCity.guesses[state.capitalCity.guessNumber];
    state.capitalCity.guessNumber++;
    currentGuess.city = this.titleCase(guess);
    currentGuess.isBlank = false;
    currentGuess.isLoading = true;

    this.state.next(state);
    setTimeout(() => {
      // Is correct
      if (guess.toLowerCase() === state.capitalCity.answer.toLowerCase()) {
        currentGuess.isCorrect = true;
        state.capitalCity.showConfetti = true;
        state.capitalCity.roundOver = true;
        state.capitalCity.guessed = true;
      }
      currentGuess.isLoading = false;
      currentGuess.isGuessed = true;
      if (state.capitalCity.guessNumber >= 3) {
        state.capitalCity.roundOver = true;
        state.capitalCity.showAnswer = true;
      }

      if (state.capitalCity.roundOver) {

        setTimeout(() => {
          state.map.showRound = true;
          this.state.next(state);
          this.saveGame();
        }, 1200)
      }
      this.state.next(state);
      this.saveGame();
    }, 1800);
  }

  mapGuess(guess: string) {
    let state: Game = this.state.value;
    if (state.map.guessed || state.map.roundOver) {
      return;
    }
    state.map.guessesRemaining--;

    if (guess === 'correct') {
      state.map.guessed = true;
      state.map.roundOver = true;
      if (state.capitalCity.guessed) {
        state.stars.number++;
        this.updateProgressBar();
      }
      this.state.next(state);
      this.saveGame();
      if (state.topAdOpen) {
        this.ads.startAuction('top_banner', 'mobile');
      }
    } else {
      state.map.incorrectGuesses.push(guess);
      state.map.guesses++;
      state.map.shake = true;
      if (state.map.guesses >= 2) {
        state.map.roundOver = true;
        if (state.topAdOpen) {
          this.ads.startAuction('top_banner', 'mobile');
        }

      }
      if (state.map.guesses >= 2) {
        state.map.roundOver = true;
      }
      this.state.next(state);
      setTimeout(() => {
        state.map.shake = false;
        this.state.next(state);
        this.saveGame();
      }, 300)
    }
    if (state.map.roundOver) {
      this.preLoadCityImage();
    }
  }

  preLoadMap() {
    const state: Game = this.state.value;
    let args: string[] = [];

    args.push(`./assets/states/${state.countryCode.toLowerCase()}/map.svg`);

    this.preload(...args);
  }

  //CITY PAGE
  cityGuess(city: CityGuess, index: number) {
    let state: Game = this.state.value;
    if (state.cityGuess.roundOver)
      return;

    state.cityGuess.cities[index].isGuessed = true;

    if (city.isCorrect) {
      state.cityGuess.guessed = true;
      state.cityGuess.roundOver = true;
      this.state.next(state);
      state.cityGuess.shownConfetti = true;
      this.state.next(state);
      state.stars.number++;
      this.updateProgressBar();
      this.preloadTurtle();
      this.populateTurtleSrc();
      setTimeout(() => {
        state.showLinks = true;
        this.state.next(state);
        this.saveGame();
        this.dialog.openEndDialog();
      }, 1000);
    } else {
      state.cityGuess.guessesRemaining--;
      if (state.cityGuess.guessesRemaining <= 0) {
        state.cityGuess.roundOver = true;
        state.cityGuess.cities.forEach(landmark => {
          if (landmark.isCorrect) {
            landmark.isGuessed = true;
          }
        });
        this.preloadTurtle();
        this.populateTurtleSrc();
        setTimeout(() => {
          state.showLinks = true;
          this.state.next(state);
          this.saveGame();

          this.dialog.openEndDialog();
        }, 600);
      }
      state.cityGuess.cities[index].shake = true;
      setTimeout(() => {
        state.cityGuess.cities[index].shake = false;
      }, 4000);
    }

    this.state.next(state);
    this.saveGame();
  }

  preLoadCityImage() {
    const state: Game = this.state.value;
    if (state.cityGuess?.noImage) {
      return;
    }

    let args: string[] = [];

    args.push(`./assets/states/${state.countryCode.toLowerCase()}/city${state.photoCode}.${state.data.imgExtension}`);

    this.preload(...args);
  }

  populateTurtleSrc() {
    let state: Game = this.state.value;
    state.turtleSrc = `./assets/turtles/turtle${state.stars.number}.${state.data.imgExtension}`;
    this.state.next(state);
    this.saveGame();
  }

  checkIfNoImage() {
    let state: Game = this.state.value;
    if (state.cityGuess?.noImage && !state.cityGuess.roundOver) {
      state.cityGuess.roundOver = true;
      state.cityGuess.guessed = true;
      state.stars.number++;
      this.state.next(state);
      this.populateTurtleSrc()
      setTimeout(() => {
        this.updateProgressBar();
      }, 300);
      setTimeout(() => {
        this.dialog.openEndDialog();

      }, 2000)
    }
    this.state.next(state);
    this.saveGame();
  }

  checkIfNoMap() {
    let state: Game = this.state.value;
    if (state.capital === 'None' && !state.map.roundOver) {
      state.map.roundOver = true;
      state.map.guessed = true;
      state.stars.number++;
      this.state.next(state);
      setTimeout(() => {
        this.updateProgressBar();
      }, 300);
    }
    this.state.next(state);
    this.saveGame();
  }

  // Flower guess functions
  flowerGuess(flower: FlowerGuess, index: number) {
    let state: Game = this.state.value;
    if (state.flowerGuess.roundOver)
      return;

    state.flowerGuess.flowers[index].isGuessed = true;

    if (flower.isCorrect) {
      state.flowerGuess.guessed = true;
      state.flowerGuess.roundOver = true;
      this.state.next(state);
      state.flowerGuess.shownConfetti = true;
      this.state.next(state);
    } else {
      state.flowerGuess.guessesRemaining--;
      if (state.flowerGuess.guessesRemaining <= 0) {
        state.flowerGuess.roundOver = true;
        state.flowerGuess.flowers.forEach(f => {
          if (f.isCorrect) {
            f.isGuessed = true;
          }
        });
      }
      state.flowerGuess.flowers[index].shake = true;
      setTimeout(() => {
        state.flowerGuess.flowers[index].shake = false;
      }, 4000);
    }

    if (state.flowerGuess.roundOver) {
      setTimeout(() => {
        state.weatherGuess.showRound = true;
        this.state.next(state);
        this.saveGame();
      }, 1000)
    }

    this.state.next(state);
    this.saveGame();
  }

  preloadFlowerImages() {
    const state: Game = this.state.value;
    let args: string[] = [];

    args.push(state.flowerGuess.imgSrc);

    this.preload(...args);
  }

  // Weather guess functions
  weatherGuess(weather: WeatherGuess, index: number) {
    let state: Game = this.state.value;
    if (state.weatherGuess.roundOver)
      return;

    state.weatherGuess.weatherOptions[index].isGuessed = true;

    if (weather.isCorrect) {
      state.weatherGuess.guessed = true;
      state.weatherGuess.roundOver = true;
      this.state.next(state);
      state.weatherGuess.shownConfetti = true;
      this.state.next(state);
    } else {
      state.weatherGuess.guessesRemaining--;
      if (state.weatherGuess.guessesRemaining <= 0) {
        state.weatherGuess.roundOver = true;
        state.weatherGuess.weatherOptions.forEach(w => {
          if (w.isCorrect) {
            w.isGuessed = true;
          }
        });
      }
      state.weatherGuess.weatherOptions[index].shake = true;
      setTimeout(() => {
        state.weatherGuess.weatherOptions[index].shake = false;
      }, 4000);
    }

    if (state.weatherGuess.roundOver) {
      const result = [state.nicknameGuess.guessed, state.randomFactGuess.guessed, state.flowerGuess.guessed, state.weatherGuess.guessed];
      if (this.checkTrueValues(result) >= 3) {
        state.stars.number++;
        this.state.next(state);
        setTimeout(() => {
          this.updateProgressBar();
        }, 300);
      }
    }

    this.state.next(state);
    this.saveGame();
  }

  // Nickname guess functions
  nicknameGuess(nickname: NicknameGuess, index: number) {
    let state: Game = this.state.value;
    if (state.nicknameGuess.roundOver)
      return;

    state.nicknameGuess.nicknameOptions[index].isGuessed = true;

    if (nickname.isCorrect) {
      state.nicknameGuess.guessed = true;
      state.nicknameGuess.roundOver = true;
      this.state.next(state);
      state.nicknameGuess.shownConfetti = true;
      this.state.next(state);
    } else {
      state.nicknameGuess.guessesRemaining--;
      if (state.nicknameGuess.guessesRemaining <= 0) {
        state.nicknameGuess.roundOver = true;
        state.nicknameGuess.nicknameOptions.forEach(w => {
          if (w.isCorrect) {
            w.isGuessed = true;
          }
        });
      }
      state.nicknameGuess.nicknameOptions[index].shake = true;
      setTimeout(() => {
        state.nicknameGuess.nicknameOptions[index].shake = false;
      }, 4000);
    }

    if (state.nicknameGuess.roundOver) {
      setTimeout(() => {
        state.randomFactGuess.showRound = true;
        this.state.next(state);
        this.saveGame();
      }, 1000);
    }

    this.state.next(state);
    this.saveGame();
  }

  // Nickname guess functions
  randomFactGuess(fact: FactGuess, index: number) {
    let state: Game = this.state.value;
    if (state.randomFactGuess.roundOver)
      return;

    state.randomFactGuess.factOptions[index].isGuessed = true;

    if (fact.isCorrect) {
      state.randomFactGuess.guessed = true;
      state.randomFactGuess.roundOver = true;
      this.state.next(state);
      state.randomFactGuess.shownConfetti = true;
      this.state.next(state);
    } else {
      state.randomFactGuess.guessesRemaining--;
      if (state.randomFactGuess.guessesRemaining <= 0 || state.randomFactGuess.factOptions.length <= 2) {
        state.randomFactGuess.roundOver = true;
        state.randomFactGuess.factOptions.forEach(w => {
          if (w.isCorrect) {
            w.isGuessed = true;
          }
        });
      }
      state.randomFactGuess.factOptions[index].shake = true;
      setTimeout(() => {
        state.randomFactGuess.factOptions[index].shake = false;
      }, 4000);
    }
    if (state.randomFactGuess.roundOver) {
      setTimeout(() => {
        state.flowerGuess.showRound = true;
        this.state.next(state);
        this.saveGame();
      }, 1000);
    }

    this.state.next(state);
    this.saveGame();
  }


  toggleTopAdOpen() {
    let state: Game = this.state.value;
    state.topAdOpen = !state.topAdOpen;
    this.state.next(state);
    this.saveGame();
  }

  titleCase(str: string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  preloadTurtle() {
    const state: Game = this.state.value;

    let args: string[] = [];
    args.push(`./assets/turtles/turtle${state.stars.number}.${state.data.imgExtension}`);
    this.preload(...args);
  }

  // Convert farenheit to celsius
  fToC(f: number) {
    return Math.round((f - 32) * 5 / 9);
  }

  // Check how many true values there are in an array
  checkTrueValues(arr: boolean[]) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) count++;
    }
    return count;
  }
}
