import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ArchiveGame, Game, Stats } from './interfaces/interfaces';
import { DataService } from './services/data.service';
import { PlatformService } from './services/platform.service';
import { UserService } from './services/user.service';
import { UserDetails } from './interfaces/UserDetails';
import { Data } from './interfaces/Data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  state$: Observable<Game>;
  stats$: Observable<Stats>;
  archive$: Observable<ArchiveGame>;
  user$: Observable<UserDetails>;

  data$: Observable<Data>;

  constructor(
    private user: UserService,
    private data: DataService,
    private router: Router,
    private platform: PlatformService,
  ) {
    this.state$ = this.data.getState();
    this.stats$ = this.user.getStats();
    this.archive$ = this.data.getIsArchive();
    this.user$ = this.user.getUserDetails();
    this.data$ = this.user.getData();
  }

  ngOnInit(): void {
    this.data.loadStats();
    this.data.loadDist();
    this.data.loadDifficulty();
    this.data.preloadImagesRoundOne();
    this.user.loadUserAndRefreshTokens();

  }

  ngAfterViewInit() {
    if (!this.platform.isInBrowser()) return;
    if (localStorage.getItem("dark-mode") === "true") {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  returnToToday() {
    this.data.resetArchiveGame();
    this.user.setArchiveGame(false);
    this.router.navigate(['/']);
  }
}
