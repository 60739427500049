<div class="flex flex-col gap-6 items-center mb-6 mt-10 text-black dark:!text-white">
  <div>
    <span
      ><b
        >❤️ WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span>?</b
      ></span
    >
    <span> - </span>
    <a
      class="underline"
      href="https://semantle.com/junior"
      target="_blank"
      >Try Semantle!</a
    >
  </div>
</div>
