<div *ngIf="user$ | async as user">
  <div *ngIf="state$ | async as state" class="mt-3 text-black dark:!text-white relative">
    <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <div *ngIf="user.loggedIn && user?.subscription?.isPremium; else notPremiumUser">
      <div>
        <div>
          <app-nickname></app-nickname>
        </div>
        <div #secondQuestion class="mt-12" *ngIf="state.randomFactGuess.showRound">
          <app-random-fact></app-random-fact>
      </div>
        <div #thirdQuestion class="mt-12" *ngIf="state.flowerGuess.showRound">
          <app-flower></app-flower>
        </div>
        <div #fourthQuestion class="mt-12" *ngIf="state.weatherGuess.showRound">
          <app-weather></app-weather>
        </div>
        <div [ngClass]="{
          'last-question' : state.nicknameGuess.roundOver && state.randomFactGuess.roundOver && state.flowerGuess.roundOver && state.weatherGuess.roundOver
        }" class="confetti" #confettiContainer></div>
        <app-progress></app-progress>
        <div #bonusRound *ngIf="state.weatherGuess.roundOver" class="w-full">
          <div>
            <p class="text-center mt-4 font-bold">{{ message }}</p>
          </div>
          <div
            class="p-2 mt-4 bg-gray-300 dark:bg-slate-700 rounded w-full text-center flex flex-col justify-center items-center gap-1">
            <p class="font-bold text-black dark:!text-white">
              <span class="text-orange-700">★</span> Bonus Round ~ 2/4 - Landmarks <span
                class="text-orange-700">★</span>
            </p>
            <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" routerLink="/landmarks">
              <b>🎁 Play the bonus round</b>
            </button>
          </div>
          <app-share-buttons></app-share-buttons>
        </div>
        <app-end-mobile-ad></app-end-mobile-ad>
      </div>
    </div>
    <ng-template #notPremiumUser>
      <div class="mt-6 mb-6 flex flex-col items-center justify-center text-center rounded-md">
        <h4 class="font-bold mb-2">This is a Teuteuf Premium only round</h4>
        <p class="mb-3">But you can still play the other bonus rounds</p>

        <app-become-premium-member></app-become-premium-member>


        <p class="mt-4">You're missing out on the nickname, random fact, flower and temperature questions.</p>
        <div
          class="p-2 mt-4 bg-gray-300 dark:bg-slate-700 rounded w-full text-center flex flex-col justify-center items-center gap-1">
          <p class="font-bold text-black dark:!text-white">
            <span class="text-orange-700">★</span> Bonus Round ~ 2/4 - Landmarks <span class="text-orange-700">★</span>
          </p>
          <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" routerLink="/landmarks">
            <b>🎁 Play the bonus round</b>
          </button>
        </div>
      </div>
      <app-progress></app-progress>
    </ng-template>

  </div>
</div>
