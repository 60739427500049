
<div class="wrapper">
    <img
      #mainImage
      (click)="imageClicked()"
      (load)="imageLoaded()"
      [ngClass]="{
        'bg-slate-300 animate-pulse' : !loaded
      }"
      [src]="imgSrc"
      width="992"
      height="720"
      alt="A photo in a country or territory to guess"
      class="zoom shadow-md md:cursor-zoom-in"
    />
</div>
