import { Country } from '../interfaces/interfaces';

export const countries: Country[] = [
  {
      "name": "Alaska",
      "code": "AK",
      "lat": 63.588753,
      "long": -154.493062,
      "cities": [
          "Juneau",
          "Anchorage",
          "Sitka",
          "Fairbanks",
          "Ketchikan",
          "Charleston"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Mount Alpenglow",
                  "photographer": "The Alaska Landmine",
                  "imageLink": "https://en.wikipedia.org/wiki/Mount_Alpenglow",
                  "wikiLink": "https://en.wikipedia.org/wiki/Alyeska_Resort"
              },
              "landmark": {
                  "name": "Mendenhall Glacier",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/mendenhall-glacier-alaska-ice-1620946/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mendenhall_Glacier",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Juneau",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://en.wikipedia.org/wiki/Juneau,_Alaska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Juneau,_Alaska"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "MS",
                      "number": 1
                  },
                  {
                      "code": "ME",
                      "number": 4
                  }
              ],
              "uniqueId": "Rm8N8DrD",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "Centennial State",
                  "America’s Paradise",
                  "North Star State/Gopher State",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state contains around 3 million lakes.",
                  "falseFact": "This state has less than a dozen islands.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Magnolia",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Old State Capitol",
                  "Minneapolis Sculpture Garden",
                  "Paul Revere House",
                  "Battleship Memorial Park",
                  "Sandia Peak Tramway",
                  "Maquoketa Caves",
                  "Arlington National Cemetery"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Denali National Park",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/denali-national-park-alaska-sky-1733313/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Denali_National_Park_and_Preserve"
              },
              "landmark": {
                  "name": "St. Michael the Archangel Orthodox Cathedral",
                  "photographer": "Barek",
                  "imageLink": "https://en.wikipedia.org/wiki/St._Michael%27s_Cathedral_(Sitka,_Alaska)#/media/File:St_Michaels_Cathedral_-_Sitka_-_back.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._Michael%27s_Cathedral_(Sitka,_Alaska)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Anchorage",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Anchorage,_Alaska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Anchorage,_Alaska"
              },
              "landmarksRound": [
                  {
                      "code": "NH",
                      "number": 2
                  },
                  {
                      "code": "ID",
                      "number": 4
                  },
                  {
                      "code": "NC",
                      "number": 5
                  }
              ],
              "uniqueId": "l8RmTXwX",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Granite State",
                  "Pelican State/Creole State/Sugar State",
                  "Grand Canyon State",
                  "Sooner State",
                  "Cotton State/Yellowhammer State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The biggest city in this state has around _____ residents.",
                  "fillAnswer": "250,000",
                  "fillAnswers": [
                      "30,000",
                      "250,000",
                      "1,500,000",
                      "4,000,000"
                  ]
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Coast Rhododendron",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Houmas House",
                  "Dinosaur Park",
                  "Mark Twain Boyhood Home",
                  "Akaka Falls",
                  "Gillette Castle",
                  "Arlington National Cemetery",
                  "Chihuly Garden and Glass"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Totem Bight State Historical Park",
                  "photographer": "Roger W",
                  "imageLink": "https://www.flickr.com/photos/24736216@N07/3845045525",
                  "wikiLink": "https://en.wikipedia.org/wiki/Totem_Bight_State_Historical_Park"
              },
              "landmark": {
                  "name": "Denail National Park",
                  "photographer": "sab_k",
                  "imageLink": "https://pixabay.com/photos/alaska-denali-national-park-3335260/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Denali_National_Park_and_Preserve",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Sitka",
                  "photographer": "dawnschuler",
                  "imageLink": "https://en.wikipedia.org/wiki/Sitka,_Alaska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sitka,_Alaska"
              },
              "landmarksRound": [
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 3
                  },
                  {
                      "code": "ND",
                      "number": 1
                  }
              ],
              "uniqueId": "Gw57sLC9",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Mountain State",
                  "Cornhusker State/Beef State",
                  "Treasure State/Big Sky Country",
                  "Garden State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state's official state sport is____",
                  "fillAnswer": "Dog mushing",
                  "fillAnswers": [
                      "Dog mushing",
                      "Basketball",
                      "Skiing",
                      "Beach volleyball"
                  ]
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Pink And White Lady's Slipper",
                  "Magnolia"
              ],
              "landmarkPlaces": [
                  "Rocky Statue",
                  "Pictograph Cave State Park",
                  "Mammoth Cave",
                  "Myriad Botanical Gardens",
                  "Three Rivers",
                  "John Dickinson Plantation",
                  "Diamond Head"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Tracy Arm Fjord",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/tracy-arm-fjord-alaska-juneau-369634/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tracy_Arm"
              },
              "landmark": {
                  "name": "Klondike Gold Rush National Historic Park",
                  "photographer": "National Park Service",
                  "imageLink": "https://en.wikipedia.org/wiki/Klondike_Gold_Rush_National_Historical_Park#/media/File:WhitePassandYukonRailwayDepot.jpeg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Klondike_Gold_Rush_National_Historical_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fairbanks",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Fairbanks,_Alaska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fairbanks,_Alaska"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 2
                  },
                  {
                      "code": "CA",
                      "number": 5
                  }
              ],
              "uniqueId": "SXL7SPb6",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "Volunteer State",
                  "Silver State/Sagebrush State",
                  "Grand Canyon State",
                  "Green Mountain State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to highest mountain in North America.",
                  "falseFact": "This state was the last state to join the United States.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Apple Blossom",
                  "Wild Prairie Rose",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Hearst Castle",
                  "Blanchard Springs Cavern",
                  "USS Cairo",
                  "Timberline Lodge",
                  "Fort Larned",
                  "Houmas House",
                  "Theodore Roosevelt National Park"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Juneau",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/juneau-alaska-town-village-363789/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Juneau,_Alaska"
              },
              "landmark": {
                  "name": "Harding Icefield",
                  "photographer": "Romandial",
                  "imageLink": "https://en.wikipedia.org/wiki/Harding_Icefield#/media/File:HardingTustumena.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Harding_Icefield",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Ketchikan",
                  "photographer": "OneOldCop",
                  "imageLink": "https://en.wikipedia.org/wiki/Ketchikan,_Alaska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ketchikan,_Alaska"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 4
                  },
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "KdgTX5TF",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Keystone State",
                  "Wolverine State/Great Lake State",
                  "Tar Heel State",
                  "Natural State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was owned by Russia before the territory was purchased by the United States in the 1800s.",
                  "falseFact": "This state rarely experiences temperatures above fifty degrees in the summer.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Indian Paintbrush",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Sandia Peak Tramway",
                  "Grand Teton",
                  "San Antonio River Walk",
                  "Minneapolis Sculpture Garden",
                  "Red Rocks Ampitheatre",
                  "Buffalo Bill Ranch",
                  "Rocky Statue"
              ]
          }
      ],
      "capital": "Juneau",
      "flower": {
          "name": "Forget-me-not",
          "imgLink": "https://pixabay.com/photos/bee-insects-pollinate-fly-257176/",
          "imgLink2": "https://pixabay.com/photos/forget-me-nots-flowers-blue-flowers-1365857/",
          "wikiLink": "https://en.wikipedia.org/wiki/Myosotis_alpestris"
      },
      "nickname": "The Last Frontier"
  },
  {
      "name": "Alabama",
      "code": "AL",
      "lat": 32.318231,
      "long": -86.902298,
      "cities": [
          "Montgomery",
          "Mobile",
          "Auburn",
          "Huntsville",
          "Tuscaloosa",
          "Laramie"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Choctaw Bluff",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/choctaw-bluff-alabama-general-store-305932/",
                  "wikiLink": "https://en.wikipedia.org/w/index.php?search=Clarke+County%2C+Alabama&title=Special:Search&profile=advanced&fulltext=1&ns0=1"
              },
              "landmark": {
                  "name": "Vulcan Statue",
                  "photographer": "RJA 1988",
                  "imageLink": "https://pixabay.com/photos/statue-god-cast-iron-vulcan-6513398/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vulcan_statue",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Montgomery",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Montgomery%2C_Alabama",
                  "wikiLink": "https://en.wikipedia.org/wiki/Montgomery%2C_Alabama"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "NE",
                      "number": 3
                  },
                  {
                      "code": "LA",
                      "number": 1
                  }
              ],
              "uniqueId": "YbPrJd8R",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Badger State/America's Dairyland",
                  "Natural State",
                  "Lone Star State",
                  "The Last Frontier",
                  "Bay State/Old Colony State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "A resident of this state is called an Alabamian or Alabaman.",
                  "falseFact": "The Rocky Mountains run through the northern territory of this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Colorado Blue Columbine",
                  "Yellow Elder"
              ],
              "landmarkPlaces": [
                  "Field of Dreams",
                  "Taliesin East",
                  "Sawtooth National Forest",
                  "Akaka Falls",
                  "Iolani Palace",
                  "Delicate Arch",
                  "Fort Christian"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Bellingrath Garden",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/bellingrath-gardens-alabama-1612728/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bellingrath_Gardens_and_Home"
              },
              "landmark": {
                  "name": "Little River Canyon National Preserve",
                  "photographer": "Sam Dellaporta",
                  "imageLink": "https://unsplash.com/photos/wsndiZw_2hs",
                  "wikiLink": "https://en.wikipedia.org/wiki/Little_River_Canyon_National_Preserve",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Mobile",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Mobile,_Alabama",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mobile,_Alabama"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "NY",
                      "number": 4
                  }
              ],
              "uniqueId": "BmWs6GnT",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Hoosier State",
                  "Palmetto State",
                  "Beaver State",
                  "Keystone State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is named after the Native American tribe who originally lived in the area.",
                  "falseFact": "The oldest city in this state is Birmingham.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Sego Lily",
                  "Magnolia"
              ],
              "landmarkPlaces": [
                  "Dinosaur Park",
                  "Taliesin East",
                  "Manitou Cliff Dwellings",
                  "Fort Frederick",
                  "Sandy Hook Lighthouse",
                  "Myrtle Beach",
                  "Minidoka National Historic Site"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Birmingham",
                  "photographer": "Clark Tibbs",
                  "imageLink": "https://unsplash.com/photos/Dbpr3t_grhU",
                  "wikiLink": "https://en.wikipedia.org/wiki/Birmingham,_Alabama"
              },
              "landmark": {
                  "name": "Battleship Memorial Park",
                  "photographer": "Ben Jacobson",
                  "imageLink": "https://en.wikipedia.org/wiki/Battleship_Memorial_Park#/media/File:USS_Alabama_Mobile,_Alabama_002.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Battleship_Memorial_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Auburn",
                  "photographer": "Josh Hallett",
                  "imageLink": "https://en.wikipedia.org/wiki/Auburn,_Alabama",
                  "wikiLink": "https://en.wikipedia.org/wiki/Auburn,_Alabama"
              },
              "landmarksRound": [
                  {
                      "code": "PA",
                      "number": 2
                  },
                  {
                      "code": "NC",
                      "number": 3
                  },
                  {
                      "code": "NV",
                      "number": 1
                  }
              ],
              "uniqueId": "bplkLPkk",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Empire State",
                  "Natural State",
                  "Beehive State",
                  "Golden State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state joined the United States in 1918.",
                  "falseFact": "The Mississippi River ends in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Cherokee Rose",
                  "Scarlet Carnation"
              ],
              "landmarkPlaces": [
                  "Oriole Park",
                  "Ben & Jerry's",
                  "Fort Larned",
                  "Minneapolis Sculpture Garden",
                  "Ford's Theatre",
                  "Meteor Crater",
                  "Bunker Hill Monument"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Dauphin Island",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/dauphin-island-alabama-1606262/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dauphin_Island,_Alabama"
              },
              "landmark": {
                  "name": "Cathedral Caverns State Park",
                  "photographer": "Diegoandrade",
                  "imageLink": "https://en.wikipedia.org/wiki/Cathedral_Caverns_State_Park#/media/File:Cathedral_Cavern_2019.png",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cathedral_Caverns_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Huntsville",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Huntsville,_Alabama",
                  "wikiLink": "https://en.wikipedia.org/wiki/Huntsville,_Alabama"
              },
              "landmarksRound": [
                  {
                      "code": "WI",
                      "number": 4
                  },
                  {
                      "code": "NH",
                      "number": 3
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "jVWc8LGm",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Centennial State",
                  "Grand Canyon State",
                  "Buckeye State",
                  "Lone Star State",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Alabama was the ____ state to join the Union.",
                  "fillAnswer": "22nd",
                  "fillAnswers": [
                      "12th",
                      "22nd",
                      "4th",
                      "37th"
                  ]
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Sunflower",
                  "Coast Rhododendron"
              ],
              "landmarkPlaces": [
                  "Old Idaho Penitentiary",
                  "Shrine of the Grotto of the Redemption",
                  "St George Utah Temple",
                  "The Witch House at Salem",
                  "Pittock Mansion",
                  "Mount Hood",
                  "Museum of the Rockies"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Mobile",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/mobile-alabama-city-cities-urban-1606283/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mobile,_Alabama"
              },
              "landmark": {
                  "name": "Cheaha Mountain",
                  "photographer": "Skye Marthaler",
                  "imageLink": "https://en.wikipedia.org/wiki/Cheaha_Mountain#/media/File:Cheaha_Mountain,_Alabama.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cheaha_Mountain",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tuscaloosa",
                  "photographer": "Nilson Cain",
                  "imageLink": "https://en.wikipedia.org/wiki/Tuscaloosa,_Alabama",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tuscaloosa,_Alabama"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 1
                  },
                  {
                      "code": "KY",
                      "number": 3
                  },
                  {
                      "code": "NE",
                      "number": 3
                  }
              ],
              "uniqueId": "sByvgghf",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "The Old Dominion/Mother of Presidents",
                  "America’s Paradise",
                  "Flickertail State/Sioux State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Alabama's land is about 70% covered in _____.",
                  "fillAnswer": "Forests",
                  "fillAnswers": [
                      "Cities",
                      "Lakes",
                      "Forests",
                      "Meadows"
                  ]
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Saguaro Cactus Blossom",
                  "Sego Lily"
              ],
              "landmarkPlaces": [
                  "Colonial Williamsburg",
                  "Diamond Head",
                  "Falls Park on the Reedy",
                  "Sleeping Bear Dunes",
                  "Puerta de San Juan",
                  "Myriad Botanical Gardens",
                  "Olbrich Botanical Gardens"
              ]
          }
      ],
      "capital": "Montgomery",
      "flower": {
          "name": "Camellia",
          "imgLink": "https://pixabay.com/photos/camellia-close-up-pink-spring-1303226/",
          "imgLink2": "https://pixabay.com/photos/camellia-red-bloom-petals-4061048/",
          "wikiLink": "https://en.wikipedia.org/wiki/Camellia_japonica"
      },
      "nickname": "Cotton State/Yellowhammer State"
  },
  {
      "name": "Arkansas",
      "code": "AR",
      "lat": 35.20105,
      "long": -91.831833,
      "cities": [
          "Hot Springs",
          "Fayetteville",
          "Forth Smith",
          "Little Rock",
          "Bentonville",
          "Des Moines"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Christ of the Ozarks",
                  "photographer": "jaystout",
                  "imageLink": "https://www.flickr.com/photos/jay_stout_photography/50189131531",
                  "wikiLink": "https://en.wikipedia.org/wiki/Christ_of_the_Ozarks"
              },
              "landmark": {
                  "name": "Hot Springs National Park",
                  "photographer": "Chris Light",
                  "imageLink": "https://en.wikipedia.org/wiki/Hot_Springs_National_Park#/media/File:Hot_Springs_NP_Hot_Spring.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hot_Springs_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hot Springs",
                  "photographer": "Samuel Grant",
                  "imageLink": "https://en.wikipedia.org/wiki/Hot_Springs,_Arkansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hot_Springs,_Arkansas"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 4
                  }
              ],
              "uniqueId": "ZRPctPbP",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Peace State/Empire State of the South",
                  "Evergreen State/Chinhook State",
                  "Show Me State",
                  "Tar Heel State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to music superstar Johnny Cash.",
                  "falseFact": "This state receives only about 50 days of sunlight per year.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Peony",
                  "Magnolia",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Everglades",
                  "Blue Whale of Catoosa",
                  "Wynwood Walls",
                  "Taliesin West",
                  "Little River Canyon National Preserve",
                  "Tryon Palace",
                  "Castillo de San Marcos"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Little Rock",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/lake-number-two-lake-water-trees-3910367/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Little_Rock,_Arkansas"
              },
              "landmark": {
                  "name": "Thorncrown Chapel",
                  "photographer": "Bobak",
                  "imageLink": "https://en.wikipedia.org/wiki/Thorncrown_Chapel#/media/File:09-02-06-ThorncrownChapel1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Thorncrown_Chapel",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fayetteville",
                  "photographer": "Brandonrush",
                  "imageLink": "https://en.wikipedia.org/wiki/Fayetteville,_Arkansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fayetteville,_Arkansas"
              },
              "landmarksRound": [
                  {
                      "code": "UT",
                      "number": 3
                  },
                  {
                      "code": "IN",
                      "number": 5
                  },
                  {
                      "code": "HI",
                      "number": 4
                  }
              ],
              "uniqueId": "wdS6Q7Qw",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Sooner State",
                  "Pine Tree State",
                  "Ocean State",
                  "Prairie State/Land of Lincoln"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state flag has 25 stars because it is the 25th state.",
                  "falseFact": "The state bird of Arkansas is the chickadee.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Flor De Maga",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "USS Albacore",
                  "Mark Twain Boyhood Home",
                  "Beale Street",
                  "Gateway Arch",
                  "Cheaha Mountain",
                  "Timberline Lodge",
                  "Akaka Falls"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Hot Springs Mountain Tower",
                  "photographer": "daveynin",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Hot_Springs_Mountain_Tower.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hot_Springs_Mountain_Tower"
              },
              "landmark": {
                  "name": "Fort Smith National Historic Site",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/historic-fort-smith-court-and-jail-3565063/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Smith_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Forth Smith",
                  "photographer": "Brandonrush",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Smith,_Arkansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Smith,_Arkansas"
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 1
                  },
                  {
                      "code": "CA",
                      "number": 3
                  },
                  {
                      "code": "MT",
                      "number": 3
                  }
              ],
              "uniqueId": "6gqHwhNh",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Show Me State",
                  "Empire State",
                  "Tar Heel State",
                  "Keystone State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Major national chain ___ started out in this state.",
                  "fillAnswer": "Walmart",
                  "fillAnswers": [
                      "Target",
                      "Home Depot",
                      "Publix",
                      "Walmart"
                  ]
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Purple Lilac",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "Haystack Rock",
                  "Fremont Troll",
                  "Fort Adams State Park",
                  "Sleeping Bear Dunes",
                  "Gateway Arch",
                  "St George Utah Temple",
                  "Red Rocks Ampitheatre"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Ozarks",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/winter-in-the-ozarks-ozarks-arkansas-3773712/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ozarks"
              },
              "landmark": {
                  "name": "Blanchard Springs Cavern",
                  "photographer": "DaveBunnell",
                  "imageLink": "https://en.wikipedia.org/wiki/Blanchard_Springs_Caverns#/media/File:Blanchard_Springs_Giant_Flowstone.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Blanchard_Springs_Caverns",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Little Rock",
                  "photographer": "TheShiv76",
                  "imageLink": "https://en.wikipedia.org/wiki/Little_Rock,_Arkansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Little_Rock,_Arkansas"
              },
              "landmarksRound": [
                  {
                      "code": "TX",
                      "number": 4
                  },
                  {
                      "code": "MI",
                      "number": 5
                  },
                  {
                      "code": "CO",
                      "number": 1
                  }
              ],
              "uniqueId": "85RhpVNS",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Buckeye State",
                  "Granite State",
                  "Silver State/Sagebrush State",
                  "Mountain State",
                  "Prairie State/Land of Lincoln"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is completely landlocked.",
                  "falseFact": "This state actually dips below sea level in several areas.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Rose",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "The Parthenon",
                  "Vermont State House",
                  "Devil's Tower National Monument",
                  "Churchill Downs",
                  "Sawtooth National Forest",
                  "Multnomah Falls",
                  "Gateway Arch"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Garvan Woodland Gardens",
                  "photographer": "Samuel Grant",
                  "imageLink": "https://en.wikipedia.org/wiki/Garvan_Woodland_Gardens#/media/File:Lake_Hamilton.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Garvan_Woodland_Gardens"
              },
              "landmark": {
                  "name": "Little Rock Central High School",
                  "photographer": "Wolfgang Beckers",
                  "imageLink": "https://en.wikipedia.org/wiki/Little_Rock_Central_High_School#/media/File:Central_High_in_Little_Rock_11.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Little_Rock_Central_High_School",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bentonville",
                  "photographer": "Alfredo Carillo",
                  "imageLink": "https://en.wikipedia.org/wiki/Bentonville,_Arkansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bentonville,_Arkansas"
              },
              "landmarksRound": [
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "WV",
                      "number": 4
                  },
                  {
                      "code": "MO",
                      "number": 5
                  }
              ],
              "uniqueId": "F5YCPxMm",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "Cornhusker State/Beef State",
                  "Green Mountain State",
                  "Bay State/Old Colony State",
                  "Flickertail State/Sioux State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The _____ State Park can be found in this state.",
                  "fillAnswer": "Crater of Diamonds",
                  "fillAnswers": [
                      "Crater of Diamonds",
                      "Yosemite",
                      "Grand Canyon",
                      "Yellowstone"
                  ]
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "Yucca Flower",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "San Xavier del Bac Mission",
                  "Battleship Memorial Park",
                  "Grand Teton",
                  "Wynwood Walls",
                  "Cloud Gate",
                  "Ford's Theatre",
                  "Waimea Canyon"
              ]
          }
      ],
      "capital": "Little Rock",
      "flower": {
          "name": "Apple Blossom",
          "imgLink": "https://pixabay.com/photos/apple-blossom-flowers-tree-1368187/",
          "imgLink2": "https://pixabay.com/photos/flower-apple-blossoms-branch-petals-7859865/",
          "wikiLink": "https://en.wikipedia.org/wiki/Malus"
      },
      "nickname": "Natural State"
  },
  {
      "name": "Arizona",
      "code": "AZ",
      "lat": 34.048928,
      "long": -111.093731,
      "cities": [
          "Tucson",
          "Phoenix",
          "Mesa",
          "Flagstaff",
          "Scottsdale",
          "Salem"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Grand Canyon",
                  "photographer": "KeYang",
                  "imageLink": "https://pixabay.com/photos/horseshoe-bend-grand-canyon-1908283/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Canyon"
              },
              "landmark": {
                  "name": "Hoover Dam",
                  "photographer": "egorshitikov",
                  "imageLink": "https://pixabay.com/photos/hoover-dam-nevada-hoover-dam-power-2107964/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hoover_Dam",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tucson",
                  "photographer": "Celtenator",
                  "imageLink": "https://en.wikipedia.org/wiki/Tucson,_Arizona",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tucson,_Arizona"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 2
                  }
              ],
              "uniqueId": "jT24x6DV",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "Nutmeg State/Constitution State",
                  "Volunteer State",
                  "Badger State/America's Dairyland",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Arizona produces more copper than any other U.S. state.",
                  "falseFact": "This state is home to the famous Yellowstone National Park.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Oklahoma Rose",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Klondike Gold Rush National Historic Park",
                  "The Keeper of the Plains",
                  "Fort McHenry",
                  "Castillo San Felipe Del Morro",
                  "Garden of the Gods",
                  "Waimea Canyon",
                  "St George Utah Temple"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Saguaro National Park",
                  "photographer": "picsbyjameslee",
                  "imageLink": "https://pixabay.com/photos/nature-outdoor-wallpaper-hd-5223016/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Saguaro_National_Park"
              },
              "landmark": {
                  "name": "San Xavier del Bac Mission",
                  "photographer": "Keyany",
                  "imageLink": "https://en.wikipedia.org/wiki/Mission_San_Xavier_del_Bac#/media/File:San_Xavier_del_Bac_in_overcast_weather.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mission_San_Xavier_del_Bac",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Phoenix",
                  "photographer": "tmsims15",
                  "imageLink": "https://en.wikipedia.org/wiki/Phoenix,_Arizona",
                  "wikiLink": "https://en.wikipedia.org/wiki/Phoenix,_Arizona"
              },
              "landmarksRound": [
                  {
                      "code": "FL",
                      "number": 3
                  },
                  {
                      "code": "MD",
                      "number": 2
                  },
                  {
                      "code": "IL",
                      "number": 5
                  }
              ],
              "uniqueId": "8pB3yHt3",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "First State/Diamond State",
                  "Centennial State",
                  "Keystone State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Arizona is one of only two states in the U.S. that does not observe _____.",
                  "fillAnswer": "Daylight savings",
                  "fillAnswers": [
                      "President's Day",
                      "Valentine's Day",
                      "Daylight savings",
                      "Thanksgiving"
                  ]
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Peony",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Milwaukee Art Museum",
                  "National Cowboy & Western Heritage Museum",
                  "Sandy Hook Lighthouse",
                  "George Washington Bridge",
                  "Taos Pueblo",
                  "Napoleon House",
                  "Grand Teton"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Monument Valley",
                  "photographer": "Hans",
                  "imageLink": "https://pixabay.com/photos/monument-valley-kayenta-arizona-usa-4092/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Monument_Valley"
              },
              "landmark": {
                  "name": "Meteor Crater",
                  "photographer": "JessicaFender",
                  "imageLink": "https://pixabay.com/photos/crater-meteor-astronomy-meteorite-408063/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Meteor_Crater",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Mesa",
                  "photographer": "lxnayonthetimmay",
                  "imageLink": "https://en.wikipedia.org/wiki/Mesa,_Arizona",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mesa,_Arizona"
              },
              "landmarksRound": [
                  {
                      "code": "WY",
                      "number": 4
                  },
                  {
                      "code": "NE",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 1
                  }
              ],
              "uniqueId": "64CBwHsq",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Treasure State/Big Sky Country",
                  "Volunteer State",
                  "Golden State",
                  "Beehive State",
                  "Bay State/Old Colony State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The third most-spoken language in this state, behind English and Spanish, is the Native American language Navajo.",
                  "falseFact": "This state is the only place in North America that has no scorpions.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Apple Blossom",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Valley of Fire State Park",
                  "Cheaha Mountain",
                  "Fort McHenry",
                  "Old State House",
                  "Sandy Hook Lighthouse",
                  "Louisville Water Tower",
                  "Bunker Hill Monument"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Sedona",
                  "photographer": "Sunfellow",
                  "imageLink": "https://pixabay.com/photos/loy-butte-sedona-arizona-hiking-672889/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sedona,_Arizona"
              },
              "landmark": {
                  "name": "Petrified Forest National Park",
                  "photographer": "ArtTower",
                  "imageLink": "https://pixabay.com/photos/petrified-petrified-wood-opal-wood-55678/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Petrified_Forest_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Flagstaff",
                  "photographer": "KCB1805",
                  "imageLink": "https://en.wikipedia.org/wiki/Flagstaff,_Arizona",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flagstaff,_Arizona"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "VI",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "rcQflF57",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Palmetto State",
                  "Prairie State/Land of Lincoln",
                  "Lone Star State",
                  "Land of Enchantment"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Some 22 Native America tribes live in Arizona.",
                  "falseFact": "This state was the first Western state to join the Union.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Wood Violet",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Three Rivers",
                  "Lucy the Elephant",
                  "Pikes Peak State Park",
                  "Minneapolis Sculpture Garden",
                  "Diamond Head",
                  "The Breakers",
                  "Mendenhall Glacier"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Grand Canyon",
                  "photographer": "Linneae_Mallette",
                  "imageLink": "https://pixabay.com/photos/grand-canyon-arizona-gorge-winter-1079937/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Canyon"
              },
              "landmark": {
                  "name": "Taliesin West",
                  "photographer": "netdesignate",
                  "imageLink": "https://pixabay.com/photos/frank-lloyd-wright-taliesin-west-713969/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Taliesin_West",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Scottsdale",
                  "photographer": "Parsonsvillas",
                  "imageLink": "https://en.wikipedia.org/wiki/Scottsdale,_Arizona",
                  "wikiLink": "https://en.wikipedia.org/wiki/Scottsdale,_Arizona"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 2
                  },
                  {
                      "code": "NH",
                      "number": 2
                  },
                  {
                      "code": "NJ",
                      "number": 5
                  }
              ],
              "uniqueId": "nBFS2ZY5",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Badger State/America's Dairyland",
                  "Beaver State",
                  "The Old Dominion/Mother of Presidents",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Around ___% of this state's land is dedicated national forests, national parks, wilderness areas, wildlife preserves, and Indian reservations. ",
                  "fillAnswer": "85",
                  "fillAnswers": [
                      "15",
                      "37",
                      "62",
                      "85"
                  ]
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Rose",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Devil's Tower National Monument",
                  "Old Mississippi State Capitol",
                  "Valley of Fire State Park",
                  "Stonington Harbour Light",
                  "Hocking Hills State Park",
                  "Bellagio Fountain",
                  "Lincoln Boyhood National Memorial"
              ]
          }
      ],
      "capital": "Phoenix",
      "flower": {
          "name": "Saguaro Cactus Blossom",
          "imgLink": "https://pixabay.com/photos/saguaro-cactus-flower-blossom-bee-2291846/",
          "imgLink2": "https://pixabay.com/photos/flower-cactus-saguaro-america-3496490/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saguaro"
      },
      "nickname": "Grand Canyon State"
  },
  {
      "name": "California",
      "code": "CA",
      "lat": 36.778261,
      "long": -119.417932,
      "cities": [
          "San Diego",
          "Sacramento",
          "San Fransisco",
          "Los Angeles",
          "Oakland",
          "Portland"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Los Angeles",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/los-angeles-california-2340323/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Los_Angeles"
              },
              "landmark": {
                  "name": "TransAmerica Pyramid",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/transamerica-pyramid-skyline-city-1633203/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Transamerica_Pyramid",
                  "hasLandmark": true
              },
              "city": {
                  "name": "San Diego",
                  "photographer": "PDPhotos",
                  "imageLink": "https://en.wikipedia.org/wiki/San_Diego",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Diego"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 4
                  },
                  {
                      "code": "HI",
                      "number": 4
                  },
                  {
                      "code": "IA",
                      "number": 1
                  }
              ],
              "uniqueId": "hP4zGZJQ",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Empire State",
                  "Grand Canyon State",
                  "Silver State/Sagebrush State",
                  "The District",
                  "Centennial State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has the most national parks of any state.",
                  "falseFact": "This state usually votes Republican in the presidential election.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wood Violet",
                  "Forget-me-not",
                  "Hawthorn"
              ],
              "landmarkPlaces": [
                  "Monument Rocks",
                  "Tupelo National Battlefield",
                  "Elvis Presley Birthplace",
                  "Brooklyn Bridge",
                  "Hanauma Bay",
                  "USS Cairo",
                  "Everglades"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Redwood National Park",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/trees-fog-street-road-lane-lush-1587301/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Redwood_National_and_State_Parks"
              },
              "landmark": {
                  "name": "Golden Gate Bridge",
                  "photographer": "crispy-fotografie",
                  "imageLink": "https://pixabay.com/photos/golden-gate-bridge-san-francisco-4271364/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Golden_Gate_Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Sacramento",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Sacramento,_California",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sacramento,_California"
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 5
                  },
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "TX",
                      "number": 4
                  }
              ],
              "uniqueId": "shDq27CW",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "Hawkeye State/Corn State",
                  "The District",
                  "Pine Tree State",
                  "Pelican State/Creole State/Sugar State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is known for its staggering redwood trees, including the tallest tree in the world, The Hyperion.",
                  "falseFact": "This state is home to landmark the Sleeping Bear Dunes.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Camellia",
                  "Hawaiian Hibiscus",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "USS Cairo",
                  "Santa Fe Plaza",
                  "Scotts Bluff",
                  "Craters of the Moon",
                  "The Greenbrier",
                  "Carhenge",
                  "The Parthenon"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Malibu",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/beach-sunset-rocks-rocky-coast-2080075/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Malibu,_California"
              },
              "landmark": {
                  "name": "Alcatraz Island",
                  "photographer": "BKD",
                  "imageLink": "https://pixabay.com/photos/alcatraz-san-francisco-island-1630477/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Alcatraz_Island",
                  "hasLandmark": true
              },
              "city": {
                  "name": "San Fransisco",
                  "photographer": "misterJang",
                  "imageLink": "https://en.wikipedia.org/wiki/San_Francisco",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Francisco"
              },
              "landmarksRound": [
                  {
                      "code": "IN",
                      "number": 3
                  },
                  {
                      "code": "NC",
                      "number": 1
                  },
                  {
                      "code": "TX",
                      "number": 2
                  }
              ],
              "uniqueId": "t7XWkmxf",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Palmetto State",
                  "Sooner State",
                  "Island of Enchantment",
                  "Garden State",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Avocado Capital of the World can be found in this state.",
                  "falseFact": "This state is one of the least populous in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Mock Orange",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "Fort Laramie",
                  "USS Constitution",
                  "Pictured Rocks",
                  "Bennington Battle Monument",
                  "Blanchard Springs Cavern",
                  "Milwaukee Art Museum",
                  "Old State House"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Santa Catalina Island",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/catalina-island-california-landscape-1588654/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Santa_Catalina_Island_(California)"
              },
              "landmark": {
                  "name": "Joshua Tree National Park",
                  "photographer": "nightowl",
                  "imageLink": "https://pixabay.com/photos/joshua-tree-national-park-74399/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Joshua_Tree_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Los Angeles",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Los_Angeles",
                  "wikiLink": "https://en.wikipedia.org/wiki/Los_Angeles"
              },
              "landmarksRound": [
                  {
                      "code": "VA",
                      "number": 4
                  },
                  {
                      "code": "WY",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 3
                  }
              ],
              "uniqueId": "CNStH3Gm",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "The District",
                  "Prairie State/Land of Lincoln",
                  "Granite State",
                  "Sooner State",
                  "Sunshine State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Many people immigrated to California during the _____.",
                  "fillAnswer": "Gold Rush",
                  "fillAnswers": [
                      "Revolutionary War",
                      "Civil War",
                      "Gold Rush",
                      "Trail of Tears"
                  ]
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Bitterroot",
                  "Pink And White Lady's Slipper"
              ],
              "landmarkPlaces": [
                  "Myrtle Beach",
                  "Coral Castle",
                  "Thunder Hole",
                  "Houmas House",
                  "Bryce Canyon",
                  "Mount Vernon",
                  "Minidoka National Historic Site"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Fisherman's Wharf",
                  "photographer": "Fabio Achilli",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Pier_39,_Fisherman%E2%80%99s_Wharf_,_San_Francisco_%2829067636674%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fisherman%27s_Wharf,_San_Francisco"
              },
              "landmark": {
                  "name": "Hearst Castle",
                  "photographer": "sarangib",
                  "imageLink": "https://pixabay.com/photos/hearst-castle-castle-san-simeon-1043075/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hearst_Castle",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Oakland",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Oakland,_California",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oakland,_California"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 4
                  }
              ],
              "uniqueId": "CkVrjZxx",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Show Me State",
                  "The District",
                  "Grand Canyon State",
                  "Silver State/Sagebrush State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "California is home to many famous burger chains, including _____.",
                  "fillAnswer": "In-N-Out",
                  "fillAnswers": [
                      "Burger King",
                      "In-N-Out",
                      "Hapeville",
                      "Halo Burger"
                  ]
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "Sunflower",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Basilica of the Sacred Heart",
                  "Delicate Arch",
                  "Dignity Statue",
                  "Washington Monument",
                  "Vermont State House",
                  "Elephant Rocks State Park",
                  "Sawtooth National Forest"
              ]
          }
      ],
      "capital": "Sacramento",
      "flower": {
          "name": "California Poppy",
          "imgLink": "https://pixabay.com/photos/eschscholtzie-gold-poppy-california-4301885/",
          "imgLink2": "https://pixabay.com/photos/california-poppy-papaver-poppy-1089788/",
          "wikiLink": "https://en.wikipedia.org/wiki/Eschscholzia_californica"
      },
      "nickname": "Golden State"
  },
  {
      "name": "Colorado",
      "code": "CO",
      "lat": 39.550051,
      "long": -105.782067,
      "cities": [
          "Boulder",
          "Aurora",
          "Denver",
          "Colorado Springs",
          "Aspen",
          "Ogden"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Aspen",
                  "photographer": "Abhard Photo",
                  "imageLink": "https://pixabay.com/photos/colorado-aspen-usa-scenic-5156229/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Aspen%2C_Colorado"
              },
              "landmark": {
                  "name": "Garden of the Gods",
                  "photographer": "SK_Zurcher",
                  "imageLink": "https://pixabay.com/photos/garden-of-the-gods-colorado-4905649/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Garden_of_the_Gods",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Boulder",
                  "photographer": "Lee Coursey",
                  "imageLink": "https://en.wikipedia.org/wiki/Boulder,_Colorado",
                  "wikiLink": "https://en.wikipedia.org/wiki/Boulder,_Colorado"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 1
                  },
                  {
                      "code": "SD",
                      "number": 3
                  },
                  {
                      "code": "NV",
                      "number": 4
                  }
              ],
              "uniqueId": "DBffw2Py",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "Flickertail State/Sioux State",
                  "Cotton State/Yellowhammer State",
                  "Hoosier State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state is well-known for being a great place to practice the fun winter sport of _____.",
                  "fillAnswer": "Skiing",
                  "fillAnswers": [
                      "Curling",
                      "Ice hockey",
                      "Broomball",
                      "Skiing"
                  ]
              },
              "flowerOptions": [
                  "Peach Blossom",
                  "Oregon Grape",
                  "Scarlet Carnation"
              ],
              "landmarkPlaces": [
                  "International Peace Garden",
                  "Hearst Castle",
                  "Carhenge",
                  "The Keeper of the Plains",
                  "Ravenel Bridge",
                  "Graceland",
                  "The Parthenon"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Pikes Peak",
                  "photographer": "RescueWarrior",
                  "imageLink": "https://pixabay.com/photos/pikes-peak-mountain-1273566/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pikes_Peak"
              },
              "landmark": {
                  "name": "Red Rocks Ampitheatre",
                  "photographer": "Dough4872",
                  "imageLink": "https://en.wikipedia.org/wiki/Red_Rocks_Amphitheatre#/media/File:Red_Rocks_Amphitheatre_from_top_of_amphitheatre.jpeg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_Rocks_Amphitheatre",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Aurora",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/Aurora,_Colorado",
                  "wikiLink": "https://en.wikipedia.org/wiki/Aurora,_Colorado"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "CA",
                      "number": 5
                  },
                  {
                      "code": "MO",
                      "number": 1
                  }
              ],
              "uniqueId": "qSYB9t2y",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Flickertail State/Sioux State",
                  "Volunteer State",
                  "Hawkeye State/Corn State",
                  "Beaver State",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Around a third of the land in this state is owned by the federal government.",
                  "falseFact": "This state is the fifth least-populated in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Bitterroot",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "Blanchard Springs Cavern",
                  "Eastern State Penitentiary",
                  "Elvis Presley Birthplace",
                  "Mount Hood",
                  "USS Constitution",
                  "Myrtle Beach",
                  "Grand Staircase"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Great Sand Dunes National Park",
                  "photographer": "RJA1988",
                  "imageLink": "https://pixabay.com/photos/colorado-mountains-great-sand-dunes-6857317/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Great_Sand_Dunes_National_Park_and_Preserve"
              },
              "landmark": {
                  "name": "Black Canyon of the Gunnison",
                  "photographer": "mudnwaffles",
                  "imageLink": "https://pixabay.com/photos/colorado-mountain-rocky-mountains-1145750/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Black_Canyon_of_the_Gunnison_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Denver",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Denver",
                  "wikiLink": "https://en.wikipedia.org/wiki/Denver"
              },
              "landmarksRound": [
                  {
                      "code": "SD",
                      "number": 4
                  },
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "SC",
                      "number": 1
                  }
              ],
              "uniqueId": "R3mZHdK9",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Island of Enchantment",
                  "Hawkeye State/Corn State",
                  "Bluegrass State",
                  "Beehive State",
                  "The Old Dominion/Mother of Presidents"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is part of the 'four corners' monument, where you can stand in fourt states at once.",
                  "falseFact": "This state is home to Jacksonville, the tomato capital of the world.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Apple Blossom",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Akaka Falls",
                  "Ocean City Boardwalk",
                  "Lincoln Boyhood National Memorial",
                  "Museum of the Rockies",
                  "Falls Park on the Reedy",
                  "Vulcan Statue",
                  "Red River Gorge"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Denver Art Museum",
                  "photographer": "Stilfehler",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Denver_Art_Museum.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Denver_Art_Museum"
              },
              "landmark": {
                  "name": "Dinosaur National Monument",
                  "photographer": "Rob Glover",
                  "imageLink": "https://en.wikipedia.org/wiki/Dinosaur_National_Monument#/media/File:Dinosaur_National_Monument_Camarasaurus.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dinosaur_National_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Colorado Springs",
                  "photographer": "Postoak",
                  "imageLink": "https://en.wikipedia.org/wiki/Colorado_Springs,_Colorado",
                  "wikiLink": "https://en.wikipedia.org/wiki/Colorado_Springs,_Colorado"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "PA",
                      "number": 2
                  },
                  {
                      "code": "AL",
                      "number": 4
                  }
              ],
              "uniqueId": "mcfJBm4Y",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Buckeye State",
                  "Evergreen State/Chinhook State",
                  "Beehive State",
                  "Lone Star State",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The highest paved road in the country can be found in this state.",
                  "falseFact": "The lowest point in the United States can be found in Colorado.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Pasque Flower",
                  "Forget-me-not"
              ],
              "landmarkPlaces": [
                  "Bob Kerrey Pedestrian Bridge",
                  "Mount Rushmore",
                  "USS Cairo",
                  "Napoleon House",
                  "Busch Stadium",
                  "The Elms",
                  "Elvis Presley Birthplace"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Vail",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/vail-colorado-foliage-1710862/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vail,_Colorado"
              },
              "landmark": {
                  "name": "Manitou Cliff Dwellings",
                  "photographer": "Obiwan23",
                  "imageLink": "https://en.wikipedia.org/wiki/Manitou_Cliff_Dwellings#/media/File:Manitou_Cliff_Dwelling2.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Manitou_Cliff_Dwellings",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Aspen",
                  "photographer": "pixeratti_art",
                  "imageLink": "https://en.wikipedia.org/wiki/Aspen,_Colorado",
                  "wikiLink": "https://en.wikipedia.org/wiki/Aspen,_Colorado"
              },
              "landmarksRound": [
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "fyp3nbt5",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Hoosier State",
                  "Gem State",
                  "Empire State",
                  "Evergreen State/Chinhook State",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The first ___ took place in Colorado.",
                  "fillAnswer": "Rodeo",
                  "fillAnswers": [
                      "Pride parade",
                      "Rodeo",
                      "Carnival",
                      "Oktoberfest"
                  ]
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Sunflower",
                  "Sagebrush"
              ],
              "landmarkPlaces": [
                  "Reed Gold Mine",
                  "Dover Motor Speedway",
                  "Santa Fe Plaza",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Mackinaw Island",
                  "Cathedral Caverns State Park",
                  "Devil's Tower National Monument"
              ]
          }
      ],
      "capital": "Denver",
      "flower": {
          "name": "Colorado Blue Columbine",
          "imgLink": "https://pixabay.com/photos/colorado-blue-columbine-flowers-6565629/",
          "imgLink2": "https://pixabay.com/photos/columbine-blue-bloom-flower-nature-2578719/",
          "wikiLink": "https://en.wikipedia.org/wiki/Aquilegia_coerulea"
      },
      "nickname": "Centennial State"
  },
  {
      "name": "Connecticut",
      "code": "CT",
      "lat": 41.603221,
      "long": -73.087749,
      "cities": [
          "New Haven",
          "Hartford",
          "Bridgeport",
          "Danbury",
          "Stamford",
          "Red Hook"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Squantz Pond",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/squantz-pond-connecticut-landscape-209864/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Squantz_Pond_State_Park"
              },
              "landmark": {
                  "name": "Yale University",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/yale-university-landscape-1604158/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Yale_University",
                  "hasLandmark": true
              },
              "city": {
                  "name": "New Haven",
                  "photographer": "Sage Ross",
                  "imageLink": "https://en.wikipedia.org/wiki/New_Haven,_Connecticut",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Haven,_Connecticut"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "TN",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 4
                  }
              ],
              "uniqueId": "nwYFMzd2",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Aloha State",
                  "Badger State/America's Dairyland",
                  "Centennial State",
                  "The District",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was the fifth offical state, achieving statehood in 1788.",
                  "falseFact": "The very first woman to ever be governor led this state in 1909 for three days.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Iris",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "Waimea Canyon",
                  "Houmas House",
                  "The Venetian",
                  "Bob Kerrey Pedestrian Bridge",
                  "Knife River Indian Villages",
                  "Devil's Tower National Monument",
                  "Mall of America"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "New Haven",
                  "photographer": "Iracaz",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:New_Haven_Green_looking_NE.png",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Haven%2C_Connecticut"
              },
              "landmark": {
                  "name": "Gillette Castle",
                  "photographer": "RachelBostwick",
                  "imageLink": "https://pixabay.com/photos/castle-gillette-castle-connecticut-1558206/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Gillette_Castle_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hartford",
                  "photographer": "jimarojfm",
                  "imageLink": "https://en.wikipedia.org/wiki/Hartford,_Connecticut",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hartford,_Connecticut"
              },
              "landmarksRound": [
                  {
                      "code": "TN",
                      "number": 4
                  },
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "AK",
                      "number": 5
                  }
              ],
              "uniqueId": "YXNvsWFy",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Aloha State",
                  "Empire State",
                  "Cornhusker State/Beef State",
                  "Palmetto State",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The first settlers to arrive in this state from Europe were from ______.",
                  "fillAnswer": "The Netherlands",
                  "fillAnswers": [
                      "The Netherlands",
                      "England",
                      "France",
                      "Spain"
                  ]
              },
              "flowerOptions": [
                  "Iris",
                  "Rhododendron",
                  "White Pine Cone And Tassel"
              ],
              "landmarkPlaces": [
                  "Fort Frederick",
                  "Valley of Fire State Park",
                  "Cadillac Ranch",
                  "Natural Bridge Caverns",
                  "Forsynth Park",
                  "Luray Caverns",
                  "Elvis Presley Birthplace"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Yale University",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/yale-university-landscape-1604156/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Yale_University"
              },
              "landmark": {
                  "name": "The Mark Twain House",
                  "photographer": "MrsBrown",
                  "imageLink": "https://pixabay.com/photos/mark-twin-house-mark-twain-writer-1535286/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mark_Twain_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bridgeport",
                  "photographer": "MrFib",
                  "imageLink": "https://en.wikipedia.org/wiki/Bridgeport,_Connecticut",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bridgeport,_Connecticut"
              },
              "landmarksRound": [
                  {
                      "code": "NH",
                      "number": 2
                  },
                  {
                      "code": "WY",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 5
                  }
              ],
              "uniqueId": "WCbnF8HB",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Palmetto State",
                  "Mountain State",
                  "Gem State",
                  "Magnolia State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Connecticut's state animal is the ______.",
                  "fillAnswer": "Sperm whale",
                  "fillAnswers": [
                      "White-tailed deer",
                      "Seagull",
                      "Sperm whale",
                      "Racoon"
                  ]
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Rose",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "St George Utah Temple",
                  "Mount Washington Cog",
                  "The Keeper of the Plains",
                  "Taos Pueblo",
                  "Garden of the Gods",
                  "Ellis Island",
                  "Iolani Palace"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Kent",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/kent-connecticut-station-train-114312/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kent,_Connecticut"
              },
              "landmark": {
                  "name": "Fort Griswold",
                  "photographer": "ItsOnlyMakeBelieve",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Griswold#/media/File:Fort_Griswold.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Griswold",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Danbury",
                  "photographer": "Daniel Case",
                  "imageLink": "https://en.wikipedia.org/wiki/Danbury,_Connecticut",
                  "wikiLink": "https://en.wikipedia.org/wiki/Danbury,_Connecticut"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 1
                  },
                  {
                      "code": "LA",
                      "number": 2
                  },
                  {
                      "code": "AL",
                      "number": 1
                  }
              ],
              "uniqueId": "jm5vP8SK",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Garden State",
                  "Granite State",
                  "Bay State/Old Colony State",
                  "Free State/Old Line State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state contains just over 5,000 square miles of land.",
                  "falseFact": "This state is known for its large almond industry.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Forget-me-not",
                  "Sunflower",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Willis Tower",
                  "Federal Hill Park",
                  "Elvis Presley Birthplace",
                  "The Witch House at Salem",
                  "Pemaquid Point Lighthouse",
                  "Sawtooth National Forest",
                  "Fort Union Trading Post"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "University of Connecticut",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/university-of-connecticut-buildings-1604152/",
                  "wikiLink": "https://en.wikipedia.org/wiki/University_of_Connecticut"
              },
              "landmark": {
                  "name": "Stonington Harbour Light",
                  "photographer": "Kenneth C. Zirkel",
                  "imageLink": "https://en.wikipedia.org/wiki/Stonington_Harbor_Light#/media/File:Stonington_Harbor_Lighthouse_2007.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Stonington_Harbor_Light",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Stamford",
                  "photographer": "John9474",
                  "imageLink": "https://en.wikipedia.org/wiki/Stamford,_Connecticut",
                  "wikiLink": "https://en.wikipedia.org/wiki/Stamford,_Connecticut"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "AK",
                      "number": 1
                  },
                  {
                      "code": "FL",
                      "number": 1
                  }
              ],
              "uniqueId": "JFFSxFbV",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "The Last Frontier",
                  "Granite State",
                  "Palmetto State",
                  "Sunflower State/Jayhawker State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Connecticut is home to the world's first nuclear submarine.",
                  "falseFact": "The chocolate chip cookie was invented in Connecticut.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sunflower",
                  "Black-eyed Susan",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "Thunder Hole",
                  "Bennington Battle Monument",
                  "A Christmas Story House",
                  "Annaberg Sugar Plantation",
                  "Pike Place Market",
                  "Temple Square",
                  "Navy Pier"
              ]
          }
      ],
      "capital": "Hartford",
      "flower": {
          "name": "Mountain Laurel",
          "imgLink": "https://pixabay.com/photos/mountain-laurel-laurel-rose-blossoms-5278114/",
          "imgLink2": "https://pixabay.com/photos/kalmia-latifolia-elf-flower-white-2352599/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kalmia_latifolia"
      },
      "nickname": "Nutmeg State/Constitution State"
  },
  {
      "name": "Washington D.C",
      "code": "DC",
      "lat": 38.905985,
      "long": -77.033418,
      "cities": [
          "Champaign"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Smithsonian Castle",
                  "photographer": "Geiserich77",
                  "imageLink": "https://en.wikipedia.org/wiki/Smithsonian_Institution_Building#/media/File:12072012_Smithsonian_Building_02a.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Smithsonian_Institution_Building"
              },
              "landmark": {
                  "name": "Lincoln Memorial",
                  "photographer": "dcandau",
                  "imageLink": "https://pixabay.com/photos/lincoln-memorial-washington-dc-826986/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lincoln_Memorial",
                  "hasLandmark": true
              },
              "city": {
                  "name": "",
                  "photographer": "",
                  "imageLink": "",
                  "wikiLink": ""
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 5
                  },
                  {
                      "code": "WI",
                      "number": 5
                  },
                  {
                      "code": "KS",
                      "number": 2
                  }
              ],
              "uniqueId": "4h5mwjqf",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Granite State",
                  "Show Me State",
                  "Pine Tree State",
                  "Hoosier State",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Washington D.C. is known for the ________ that bloom each spring.",
                  "fillAnswer": "Cherry blossom trees",
                  "fillAnswers": [
                      "Rose bushes",
                      "Tulip fields",
                      "Cherry blossom trees",
                      "Daffodils"
                  ]
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Wild Prairie Rose",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "Elephant Rocks State Park",
                  "Appalachian Trail",
                  "Minidoka National Historic Site",
                  "Sandia Peak Tramway",
                  "Taliesin East",
                  "Grave Creek Mound",
                  "Drayton Hall"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "United States Capitol",
                  "photographer": "forcal35",
                  "imageLink": "https://pixabay.com/photos/united-states-washington-dc-2361405/",
                  "wikiLink": "https://en.wikipedia.org/wiki/United_States_Capitol"
              },
              "landmark": {
                  "name": "Washington Monument",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/washington-monument-sunset-twilight-1628558/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Washington_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "",
                  "photographer": "",
                  "imageLink": "",
                  "wikiLink": ""
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 2
                  },
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 1
                  }
              ],
              "uniqueId": "RqFtKHvD",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Equality State",
                  "Show Me State",
                  "Green Mountain State",
                  "Tar Heel State",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The largest library in the world, the Library of Congress, can be found in D.C.",
                  "falseFact": "John Quincy Adams was the first U.S. president to live in the White House.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Yucca Flower",
                  "Peony",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "The Mark Twain House",
                  "Old State Capitol",
                  "Joshua Tree National Park",
                  "Maquoketa Caves",
                  "Centennial Land Run Monument",
                  "Devil's Tower National Monument",
                  "Fort Abraham Lincoln State Park"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Logan Circle",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/washington-dc-c-city-cities-urban-1606276/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Logan_Circle_(Washington,_D.C.)"
              },
              "landmark": {
                  "name": "Martin Luther King Jr. Memorial",
                  "photographer": "ParentRap",
                  "imageLink": "https://pixabay.com/photos/martin-luther-king-jr-1933334/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr._Memorial",
                  "hasLandmark": true
              },
              "city": {
                  "name": "",
                  "photographer": "",
                  "imageLink": "",
                  "wikiLink": ""
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "WV",
                      "number": 1
                  },
                  {
                      "code": "NY",
                      "number": 1
                  }
              ],
              "uniqueId": "HnbddKm2",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "America’s Paradise",
                  "First State/Diamond State",
                  "Natural State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Washington D.C. is home to the International Spy Museum.",
                  "falseFact": "The United States Capitol Building was not constructed until after the Civil War; for the first seventy years of the US's existence, Congress operated out of a wing in the White House.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wood Violet",
                  "Goldenrod",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Sandia Peak Tramway",
                  "Sawtooth National Forest",
                  "West Virginia Penitentiary",
                  "Luray Caverns",
                  "Avalanche Lake",
                  "The World's Only Corn Palace",
                  "Devil's Tower National Monument"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Lafayette Square",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/lafayette-park-washington-dc-c-fall-1604151/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lafayette_Square,_Washington,_D.C."
              },
              "landmark": {
                  "name": "Ford's Theatre",
                  "photographer": "Tim Evanson",
                  "imageLink": "https://www.flickr.com/photos/timevanson/7647482376",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ford%27s_Theatre",
                  "hasLandmark": true
              },
              "city": {
                  "name": "",
                  "photographer": "",
                  "imageLink": "",
                  "wikiLink": ""
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 1
                  },
                  {
                      "code": "WY",
                      "number": 1
                  },
                  {
                      "code": "KS",
                      "number": 1
                  }
              ],
              "uniqueId": "mP6rVRhq",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Sooner State",
                  "Cotton State/Yellowhammer State",
                  "Wolverine State/Great Lake State",
                  "Grand Canyon State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Out of all the U.S. states, residents of D.C. drink the most ____ per capita.",
                  "fillAnswer": "Wine",
                  "fillAnswers": [
                      "Wine",
                      "Beer",
                      "Lemonade",
                      "Sweet tea"
                  ]
              },
              "flowerOptions": [
                  "Hawaiian Hibiscus",
                  "Wild Prairie Rose",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Cuyahoga Valley",
                  "Victoria Mansion",
                  "John Dickinson Plantation",
                  "Grand Staircase",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Golden Driller Statue",
                  "Colonial Williamsburg"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Jefferson Memorial",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/washington-dc-c-city-cities-urban-1622642/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jefferson_Memorial"
              },
              "landmark": {
                  "name": "Washington National Cathedral",
                  "photographer": "cbhagman",
                  "imageLink": "https://pixabay.com/photos/national-cathedral-architecture-1697664/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Washington_National_Cathedral",
                  "hasLandmark": true
              },
              "city": {
                  "name": "",
                  "photographer": "",
                  "imageLink": "",
                  "wikiLink": ""
              },
              "landmarksRound": [
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 5
                  },
                  {
                      "code": "WY",
                      "number": 4
                  }
              ],
              "uniqueId": "nyV4rDyr",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "Pine Tree State",
                  "Wolverine State/Great Lake State",
                  "The Old Dominion/Mother of Presidents",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Washington Monument, created in honor of George Washington, is the largest obelisk in the world.",
                  "falseFact": "Washington D.C. is the second most densely populated major city in the U.S., behind only New York City.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Goldenrod",
                  "Wild Prairie Rose"
              ],
              "landmarkPlaces": [
                  "Pikes Peak State Park",
                  "Valley of Fire State Park",
                  "The Breakers",
                  "Cuyahoga Valley",
                  "The Venetian",
                  "Cathedral Caverns State Park",
                  "Cadillac Ranch"
              ]
          }
      ],
      "capital": "None",
      "flower": {
          "name": "American Beauty Rose",
          "imgLink": "https://pixabay.com/photos/all-american-beauty-rose-rose-2341166/",
          "imgLink2": "https://en.wikipedia.org/wiki/Rosa_%27American_Beauty%27#/media/File:Rosa_'Climbing_American_Beauty'_kz03.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosa_%27American_Beauty%27"
      },
      "nickname": "The District"
  },
  {
      "name": "Delaware",
      "code": "DE",
      "lat": 38.910832,
      "long": -75.52767,
      "cities": [
          "Wilmington",
          "Dover",
          "Newark",
          "Middletown",
          "Newport",
          "Boston"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Newark",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/newark-delaware-town-street-248044/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Newark,_Delaware"
              },
              "landmark": {
                  "name": "Nemours Estate",
                  "photographer": "A. Rasey",
                  "imageLink": "https://en.wikipedia.org/wiki/Nemours_Estate#/media/File:Nemours_Mansion.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nemours_Estate",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Wilmington",
                  "photographer": "Tim Kiser",
                  "imageLink": "https://en.wikipedia.org/wiki/Wilmington,_Delaware",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wilmington,_Delaware"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  }
              ],
              "uniqueId": "Qrh2YY4T",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Evergreen State/Chinhook State",
                  "The Old Dominion/Mother of Presidents",
                  "Ocean State",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state has one of the only flags that features a ___ .",
                  "fillAnswer": "Date",
                  "fillAnswers": [
                      "Date",
                      "Bear",
                      "Flower",
                      "Word"
                  ]
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Colorado Blue Columbine",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Manitou Cliff Dwellings",
                  "Isle Royale",
                  "Colonial Williamsburg",
                  "Cave of the Mounds",
                  "Myrtle Beach",
                  "Charging Bull",
                  "Centennial Land Run Monument"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Delaware City",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/delaware-city-river-canal-water-138953/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Delaware_City,_Delaware"
              },
              "landmark": {
                  "name": "John Dickinson Plantation",
                  "photographer": "Max.vanbalgooy",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:John_Dickinson_Plantation,_Dover.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/John_Dickinson_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Dover",
                  "photographer": "Tim Kiser",
                  "imageLink": "https://en.wikipedia.org/wiki/Dover,_Delaware",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dover,_Delaware"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "VT",
                      "number": 3
                  },
                  {
                      "code": "KS",
                      "number": 1
                  }
              ],
              "uniqueId": "pCwc6XnC",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Equality State",
                  "Land of Enchantment",
                  "Sunshine State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The only U.S. President who lived and served in Delaware for most of his career is ____.",
                  "fillAnswer": "Joe Biden",
                  "fillAnswers": [
                      "Thomas Jefferson",
                      "Ulysses Grant",
                      "Barack Obama",
                      "Joe Biden"
                  ]
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Mayflower",
                  "Sunflower"
              ],
              "landmarkPlaces": [
                  "Vulcan Statue",
                  "Statue of Liberty",
                  "Mall of America",
                  "Lucy the Elephant",
                  "Garden of the Gods",
                  "Bob Kerrey Pedestrian Bridge",
                  "Craters of the Moon"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Cape Henlopen",
                  "photographer": "Engel9",
                  "imageLink": "https://pixabay.com/photos/beach-dunes-sand-landscape-coast-5272741/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cape_Henlopen"
              },
              "landmark": {
                  "name": "Dover Motor Speedway",
                  "photographer": "The White House",
                  "imageLink": "https://en.wikipedia.org/wiki/Dover_Motor_Speedway#/media/File:President_Trump's_First_100_Days-_13_(34252546461).jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dover_Motor_Speedway",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Newark",
                  "photographer": "Paul Sableman",
                  "imageLink": "https://en.wikipedia.org/wiki/Newark,_Delaware",
                  "wikiLink": "https://en.wikipedia.org/wiki/Newark,_Delaware"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 5
                  },
                  {
                      "code": "HI",
                      "number": 4
                  },
                  {
                      "code": "CA",
                      "number": 1
                  }
              ],
              "uniqueId": "FD2PbtQw",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Cornhusker State/Beef State",
                  "Pine Tree State",
                  "Grand Canyon State",
                  "Show Me State",
                  "Nutmeg State/Constitution State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is ranked as the 3rd-most bike-friendly in the country.",
                  "falseFact": "Hurricane Katrina greatly devastated this state in 2005.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "Sagebrush",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "West Virginia Penitentiary",
                  "Blanchard Springs Cavern",
                  "Denail National Park",
                  "Cathedral Caverns State Park",
                  "Carlsbad Caverns",
                  "Diamond Head",
                  "Brooklyn Bridge"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Rehoboth Beach",
                  "photographer": "Dough4872",
                  "imageLink": "https://en.wikipedia.org/wiki/Delaware#/media/File:Rehoboth_Beach_looking_north_at_Delaware_Avenue_August_2021.jpeg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Delaware"
              },
              "landmark": {
                  "name": "Fenwick Island Lighthouse",
                  "photographer": "Delfi4110",
                  "imageLink": "https://en.wikipedia.org/wiki/Fenwick_Island_Light#/media/File:Fenwick_Island_Lighthouse.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fenwick_Island_Light",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Middletown",
                  "photographer": "Ncpanthers24",
                  "imageLink": "https://en.wikipedia.org/wiki/Middletown,_Delaware",
                  "wikiLink": "https://en.wikipedia.org/wiki/Middletown,_Delaware"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 5
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 1
                  }
              ],
              "uniqueId": "kTJd6tzk",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Cotton State/Yellowhammer State",
                  "Buckeye State",
                  "Natural State",
                  "Aloha State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was the first ever official state.",
                  "falseFact": "Delaware is almost entirely covered in saltwater marshes, making it a poor place to grow crops.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Rose",
                  "Purple Lilac",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Churchill Downs",
                  "Sandy Hook Lighthouse",
                  "Meteor Crater",
                  "Washington National Cathedral",
                  "The Parthenon",
                  "Scotts Bluff",
                  "Cadillac Ranch"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Cape Henlopen",
                  "photographer": "Engel9",
                  "imageLink": "https://pixabay.com/photos/beach-dunes-sand-landscape-coast-5272728/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cape_Henlopen"
              },
              "landmark": {
                  "name": "Fort Delaware",
                  "photographer": "Engel9",
                  "imageLink": "https://pixabay.com/photos/fort-delaware-moat-war-rampart-5250708/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Delaware",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Wilmington",
                  "photographer": "Tim Kiser",
                  "imageLink": "https://en.wikipedia.org/wiki/Wilmington,_Delaware",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wilmington,_Delaware"
              },
              "landmarksRound": [
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 5
                  },
                  {
                      "code": "MS",
                      "number": 1
                  }
              ],
              "uniqueId": "NM6mvpJs",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "Magnolia State",
                  "Land of Enchantment",
                  "Prairie State/Land of Lincoln",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was named after a governor of an existing territory, Lord De La Warr.",
                  "falseFact": "This state is the least populated US state. ",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Apple Blossom",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Brown University",
                  "Pittock Mansion",
                  "Biltmore",
                  "Seven Magic Mountains",
                  "Cathedral Caverns State Park",
                  "Multnomah Falls",
                  "Graceland"
              ]
          }
      ],
      "capital": "Dover",
      "flower": {
          "name": "Peach Blossom",
          "imgLink": "https://pixabay.com/photos/flower-peach-blossom-pink-flower-5974552/",
          "imgLink2": "https://pixabay.com/photos/peach-blossom-flowering-branch-802388/",
          "wikiLink": "https://en.wikipedia.org/wiki/Peach"
      },
      "nickname": "First State/Diamond State"
  },
  {
      "name": "Florida",
      "code": "FL",
      "lat": 27.664827,
      "long": -81.515754,
      "cities": [
          "Tallahassee",
          "Tampa",
          "Miami",
          "Orlando",
          "Jacksonville",
          "San Juan"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Bok Tower Gardens",
                  "photographer": "Neil Thompson",
                  "imageLink": "https://www.flickr.com/photos/williamsdb/5625167345",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bok_Tower_Gardens"
              },
              "landmark": {
                  "name": "Castillo de San Marcos",
                  "photographer": "paulbr75",
                  "imageLink": "https://pixabay.com/photos/castillo-de-san-marcos-castle-1701004/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Castillo_de_San_Marcos",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tallahassee",
                  "photographer": "Urbantallahassee",
                  "imageLink": "https://en.wikipedia.org/wiki/Tallahassee,_Florida",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tallahassee,_Florida"
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 1
                  },
                  {
                      "code": "TN",
                      "number": 4
                  }
              ],
              "uniqueId": "6vKl52tx",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Tar Heel State",
                  "Show Me State",
                  "Grand Canyon State",
                  "Cornhusker State/Beef State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Florida's official state beverage is orange juice.",
                  "falseFact": "Florida used to be known for its panthers, but they went extinct in the late 1800s.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Violet",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "Cheaha Mountain",
                  "Eastern State Penitentiary",
                  "Sleeping Bear Dunes",
                  "Sandy Hook Lighthouse",
                  "Arlington National Cemetery",
                  "International Peace Garden",
                  "The Mammoth Site"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Hemingway Home",
                  "photographer": "Andreas Lamecker",
                  "imageLink": "https://en.wikipedia.org/wiki/Ernest_Hemingway_House#/media/File:Hemingwayhouse.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ernest_Hemingway_House"
              },
              "landmark": {
                  "name": "Wynwood Walls",
                  "photographer": "Daniel di Palma",
                  "imageLink": "https://en.wikipedia.org/wiki/Wynwood#/media/File:Miami_-_Wynwood_Art_District_-_Wynwood_Walls_General_View_of_Courtyard.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wynwood",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tampa",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://en.wikipedia.org/wiki/Tampa,_Florida",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tampa,_Florida"
              },
              "landmarksRound": [
                  {
                      "code": "WV",
                      "number": 3
                  },
                  {
                      "code": "AL",
                      "number": 5
                  },
                  {
                      "code": "NE",
                      "number": 2
                  }
              ],
              "uniqueId": "mfrhzz9R",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Equality State",
                  "First State/Diamond State",
                  "Mountain State",
                  "Pine Tree State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has a longer coastline than any other U.S. state.",
                  "falseFact": "Florida means \"hot land.\"",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mock Orange",
                  "Mountain Laurel",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Mississippi Petrified Forest",
                  "Martin Luther King Jr. Childhood Home",
                  "The Alamo",
                  "Washington Monument",
                  "Dover Motor Speedway",
                  "Mall of America",
                  "The Parthenon"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Miami Beach",
                  "photographer": "yanivmatza",
                  "imageLink": "https://pixabay.com/photos/miami-beach-ocean-bridge-sea-674068/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Miami_Beach,_Florida"
              },
              "landmark": {
                  "name": "Coral Castle",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/coral-castle-homestead-south-florida-884521/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Coral_Castle",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Miami",
                  "photographer": "MustangJoe",
                  "imageLink": "https://en.wikipedia.org/wiki/Miami",
                  "wikiLink": "https://en.wikipedia.org/wiki/Miami"
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 4
                  },
                  {
                      "code": "RI",
                      "number": 5
                  },
                  {
                      "code": "HI",
                      "number": 1
                  }
              ],
              "uniqueId": "Ff7ptN3Y",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Magnolia State",
                  "Buckeye State",
                  "Bluegrass State",
                  "The Old Dominion/Mother of Presidents",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "A national park in this state, ____, is the only place where alligators and crocodiles live together.",
                  "fillAnswer": "The Everglades",
                  "fillAnswers": [
                      "Acadia National Park",
                      "Hot Springs National Park",
                      "The Everglades",
                      "Great Smoky Mountains National Park"
                  ]
              },
              "flowerOptions": [
                  "Pasque Flower",
                  "Peony",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "Fort Abraham Lincoln State Park",
                  "Baltimore Basilica",
                  "Field of Dreams",
                  "Lincoln Memorial",
                  "La Fortaleza",
                  "John Dickinson Plantation",
                  "Pictured Rocks"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Key West",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/key-west-florida-hurricane-dennis-81664/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Key_West"
              },
              "landmark": {
                  "name": "Disney World",
                  "photographer": "stinne24",
                  "imageLink": "https://pixabay.com/photos/walt-disney-world-disney-world-239144/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Walt_Disney_World",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Orlando",
                  "photographer": "BradyP",
                  "imageLink": "https://en.wikipedia.org/wiki/Orlando,_Florida",
                  "wikiLink": "https://en.wikipedia.org/wiki/Orlando,_Florida"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 4
                  },
                  {
                      "code": "AZ",
                      "number": 3
                  },
                  {
                      "code": "NH",
                      "number": 3
                  }
              ],
              "uniqueId": "F8jXMgQx",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "America’s Paradise",
                  "Aloha State",
                  "Badger State/America's Dairyland",
                  "Cotton State/Yellowhammer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Florida is home to the oldest inhabited city in the United States.",
                  "falseFact": "Despite its close proximity to water, Florida is almost entirely devoid of lakes.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Yucca Flower",
                  "Goldenrod",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Golden Gate Bridge",
                  "Blanchard Springs Cavern",
                  "High Roller",
                  "Mall of America",
                  "Houmas House",
                  "Gillette Castle",
                  "Klondike Gold Rush National Historic Park"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "South Beach",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/south-beach-miami-florida-sunset-891749/",
                  "wikiLink": "https://en.wikipedia.org/wiki/South_Beach"
              },
              "landmark": {
                  "name": "Everglades",
                  "photographer": "nicktember",
                  "imageLink": "https://pixabay.com/photos/marsh-wetlands-swamp-trees-5979515/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Everglades",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Jacksonville",
                  "photographer": "--",
                  "imageLink": "https://en.wikipedia.org/wiki/Jacksonville,_Florida",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jacksonville,_Florida"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 2
                  },
                  {
                      "code": "GA",
                      "number": 3
                  },
                  {
                      "code": "IL",
                      "number": 4
                  }
              ],
              "uniqueId": "q44jc7ln",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Ocean State",
                  "Wolverine State/Great Lake State",
                  "Aloha State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state is home to famous amusement park _____.",
                  "fillAnswer": "Disney World",
                  "fillAnswers": [
                      "Dollywood",
                      "Disney World",
                      "Disneyland",
                      "Cedar Point"
                  ]
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Hawthorn",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "Haystack Rock",
                  "Blanchard Springs Cavern",
                  "USS Cairo",
                  "Busch Stadium",
                  "Pittock Mansion",
                  "Charging Bull",
                  "Mount Washington"
              ]
          }
      ],
      "capital": "Tallahassee",
      "flower": {
          "name": "Orange Blossom",
          "imgLink": "https://pixabay.com/photos/orange-tree-flower-orange-tree-1645816/",
          "imgLink2": "https://pixabay.com/photos/orange-blossom-orange-tree-blossom-1117411/",
          "wikiLink": "https://en.wikipedia.org/wiki/Orange_blossom"
      },
      "nickname": "Sunshine State"
  },
  {
      "name": "Georgia",
      "code": "GA",
      "lat": 32.157435,
      "long": -82.907123,
      "cities": [
          "Savannah",
          "Atlanta",
          "Columbus",
          "Augusta",
          "Athens",
          "Colorado Springs"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Appalachian Mountains",
                  "photographer": "paulbr75",
                  "imageLink": "https://pixabay.com/photos/appalachian-mountains-dahlonega-2680454/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Appalachian_Mountains"
              },
              "landmark": {
                  "name": "Amicalola Falls",
                  "photographer": "TCR-III",
                  "imageLink": "https://en.wikipedia.org/wiki/Amicalola_Falls#/media/File:Amicalola-Falls_Main_Dawsonville-GA_02.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Amicalola_Falls",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Savannah",
                  "photographer": "paulbr75",
                  "imageLink": "https://en.wikipedia.org/wiki/Savannah,_Georgia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Savannah,_Georgia"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 5
                  },
                  {
                      "code": "MN",
                      "number": 4
                  },
                  {
                      "code": "UT",
                      "number": 3
                  }
              ],
              "uniqueId": "HsDVnK73",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Wolverine State/Great Lake State",
                  "Cornhusker State/Beef State",
                  "North Star State/Gopher State",
                  "Flickertail State/Sioux State",
                  "The Old Dominion/Mother of Presidents"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Georgia was home to its own gold rush in the 1820s.",
                  "falseFact": "The tangelo fruit, a cross between an orange and a grapefruit, was first bred in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Beauty Rose",
                  "Yucca Flower",
                  "Hawaiian Hibiscus"
              ],
              "landmarkPlaces": [
                  "Warner Theatre",
                  "Castillo de San Marcos",
                  "Brooklyn Bridge",
                  "Grave Creek Mound",
                  "Craters of the Moon",
                  "Alcatraz Island",
                  "Hearst Castle"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Savannah Historic District",
                  "photographer": "Bruce Tuten",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Staybridge_Suites_Savannah_Historic_District,_301_East_Bay_Street_%281851%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Savannah_Historic_District_(Savannah,_Georgia)"
              },
              "landmark": {
                  "name": "World of Coca Cola",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/world-of-coca-cola-atlanta-georgia-4030584/",
                  "wikiLink": "https://en.wikipedia.org/wiki/World_of_Coca-Cola",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Atlanta",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Atlanta",
                  "wikiLink": "https://en.wikipedia.org/wiki/Atlanta"
              },
              "landmarksRound": [
                  {
                      "code": "WA",
                      "number": 2
                  },
                  {
                      "code": "IA",
                      "number": 4
                  },
                  {
                      "code": "KY",
                      "number": 5
                  }
              ],
              "uniqueId": "z92pMywB",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Magnolia State",
                  "First State/Diamond State",
                  "Pine Tree State",
                  "Equality State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Famous inventor Eli Whitney created the ____ when living in Georgia.",
                  "fillAnswer": "Cotton gin",
                  "fillAnswers": [
                      "Cotton gin",
                      "Laundry machine",
                      "Tractor",
                      "Automobile"
                  ]
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Orange Blossom",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "Arch Rock",
                  "Old State House",
                  "Biltmore",
                  "Starved Rock State Park",
                  "Castillo San Felipe Del Morro",
                  "Little River Canyon National Preserve",
                  "Fenwick Island Lighthouse"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Stone Mountain Park",
                  "photographer": "paulbr75",
                  "imageLink": "https://pixabay.com/photos/stone-mountain-georgia-park-2350971/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Stone_Mountain"
              },
              "landmark": {
                  "name": "Forsynth Park",
                  "photographer": "Seasider53",
                  "imageLink": "https://en.wikipedia.org/wiki/Forsyth_Park#/media/File:Forsyth_fountain_2019.jpeg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Forsyth_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Columbus",
                  "photographer": "PghphxNfx",
                  "imageLink": "https://en.wikipedia.org/wiki/Columbus,_Georgia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Columbus,_Georgia"
              },
              "landmarksRound": [
                  {
                      "code": "VT",
                      "number": 1
                  },
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "KS",
                      "number": 1
                  }
              ],
              "uniqueId": "lTndzLQy",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "Wolverine State/Great Lake State",
                  "America’s Paradise",
                  "Sunflower State/Jayhawker State",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state shares a name with an eastern European country.",
                  "falseFact": "Famous landmark Glacier Lake National Park can be found in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Magnolia",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Dollywood",
                  "Joshua Tree National Park",
                  "Black Canyon of the Gunnison",
                  "Mount Washington",
                  "Flume Gorge",
                  "St George Utah Temple",
                  "The Keeper of the Plains"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Atlanta",
                  "photographer": "--",
                  "imageLink": "https://pixabay.com/photos/atlanta-georgia-skyline-city-70847/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Atlanta"
              },
              "landmark": {
                  "name": "Martin Luther King Jr. Childhood Home",
                  "photographer": "Carsonmc",
                  "imageLink": "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr._National_Historical_Park#/media/File:MLK's_Boyhood_home.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Martin_Luther_King_Jr._National_Historical_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Augusta",
                  "photographer": "Thomson200",
                  "imageLink": "https://en.wikipedia.org/wiki/Augusta,_Georgia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Augusta,_Georgia"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "ME",
                      "number": 1
                  }
              ],
              "uniqueId": "GVwdrfJZ",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Lone Star State",
                  "Silver State/Sagebrush State",
                  "Tar Heel State",
                  "Prairie State/Land of Lincoln",
                  "Cotton State/Yellowhammer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The oldest state park in the country can be found in Georgia.",
                  "falseFact": "This state has a population of around 3 million.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Goldenrod",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Pictured Rocks",
                  "Fort Smith National Historic Site",
                  "Avalanche Lake",
                  "Mackinaw Island",
                  "La Fortaleza",
                  "Minneapolis Sculpture Garden",
                  "Baltimore Basilica"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Savannah",
                  "photographer": "stanbalik",
                  "imageLink": "https://pixabay.com/photos/savannah-georgia-river-street-4507170/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Savannah,_Georgia"
              },
              "landmark": {
                  "name": "Rock City Gardens",
                  "photographer": "JamesPFisherIII",
                  "imageLink": "https://en.wikipedia.org/wiki/Rock_City_(attraction)#/media/File:High_Falls_at_Rock_City.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rock_City_(attraction)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Athens",
                  "photographer": "paulbr75",
                  "imageLink": "https://en.wikipedia.org/wiki/Athens,_Georgia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Athens,_Georgia"
              },
              "landmarksRound": [
                  {
                      "code": "WA",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 5
                  },
                  {
                      "code": "KY",
                      "number": 2
                  }
              ],
              "uniqueId": "43hgHsGK",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Treasure State/Big Sky Country",
                  "Land of Enchantment",
                  "Palmetto State",
                  "Show Me State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state is home to ____, which is often called the most haunted city in the country.",
                  "fillAnswer": "Savannah",
                  "fillAnswers": [
                      "Augusta",
                      "Atlanta",
                      "Savannah",
                      "Athens"
                  ]
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "Apple Blossom",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Marble House",
                  "Isle Royale",
                  "Akaka Falls",
                  "Appalachian Trail",
                  "Iolani Palace",
                  "Vulcan Statue",
                  "Field of Dreams"
              ]
          }
      ],
      "capital": "Atlanta",
      "flower": {
          "name": "Cherokee Rose",
          "imgLink": "https://pixabay.com/photos/cherokee-rose-flower-plant-6244378/",
          "imgLink2": "https://pixabay.com/photos/flower-rose-leaves-petals-plant-7236625/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosa_laevigata"
      },
      "nickname": "Peace State/Empire State of the South"
  },
  {
      "name": "Hawaii",
      "code": "HI",
      "lat": 19.898682,
      "long": -155.665857,
      "cities": [
          "Hilo",
          "Hawi",
          "Honolulu",
          "Kahului",
          "Lahaina",
          "Nampa"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Waimea Canyon",
                  "photographer": "schimiggy",
                  "imageLink": "https://pixabay.com/photos/waimea-canyon-canyon-travel-kauai-4081299/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Waimea_Canyon_State_Park"
              },
              "landmark": {
                  "name": "Iolani Palace",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/honolulu-hawaii-iolani-palace-1632072/",
                  "wikiLink": "https://en.wikipedia.org/wiki/%CA%BBIolani_Palace",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hilo",
                  "photographer": "prayitnophotography",
                  "imageLink": "https://en.wikipedia.org/wiki/Hilo,_Hawaii",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hilo,_Hawaii"
              },
              "landmarksRound": [
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "WA",
                      "number": 1
                  },
                  {
                      "code": "AL",
                      "number": 1
                  }
              ],
              "uniqueId": "hFXZz9V7",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Magnolia State",
                  "Grand Canyon State",
                  "Cotton State/Yellowhammer State",
                  "Green Mountain State",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state was the ____ to join the United States.",
                  "fillAnswer": "50th",
                  "fillAnswers": [
                      "13th",
                      "32nd",
                      "48th",
                      "50th"
                  ]
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Cherokee Rose",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Red River Gorge",
                  "Cuyahoga Valley",
                  "Battleship Memorial Park",
                  "Arch Rock",
                  "Basilica of the Sacred Heart",
                  "Mount Washington",
                  "Aerial Lift Bridge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Arizona Memorial",
                  "photographer": "MonicaVolpin",
                  "imageLink": "https://pixabay.com/photos/memorial-wwii-pearl-harbour-5105823/",
                  "wikiLink": "https://en.wikipedia.org/wiki/USS_Arizona_Memorial"
              },
              "landmark": {
                  "name": "Waimea Canyon",
                  "photographer": "MonicaVolpin",
                  "imageLink": "https://pixabay.com/photos/canyon-waimea-canyon-kauai-hawaii-5313900/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Waimea_Canyon_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hawi",
                  "photographer": "KanoaWithington",
                  "imageLink": "https://en.wikipedia.org/wiki/Hawi,_Hawaii",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hawi,_Hawaii"
              },
              "landmarksRound": [
                  {
                      "code": "SD",
                      "number": 3
                  },
                  {
                      "code": "WY",
                      "number": 5
                  },
                  {
                      "code": "KY",
                      "number": 1
                  }
              ],
              "uniqueId": "9zBrlYvb",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Keystone State",
                  "Land of Enchantment",
                  "Gem State",
                  "The Last Frontier",
                  "Hawkeye State/Corn State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state is comprised of atolls, islets, and ____ major islands.",
                  "fillAnswer": "8",
                  "fillAnswers": [
                      "2",
                      "4",
                      "8",
                      "16"
                  ]
              },
              "flowerOptions": [
                  "Peony",
                  "Mayflower",
                  "Pink And White Lady's Slipper"
              ],
              "landmarkPlaces": [
                  "Mount Hood",
                  "Fort Worden",
                  "Thunder Hole",
                  "High Roller",
                  "Victoria Mansion",
                  "Liberty Bell",
                  "Cloud Gate"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Haleakala",
                  "photographer": "Pexels",
                  "imageLink": "https://pixabay.com/photos/craters-volcanos-hawaii-1846775/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Haleakal%C4%81"
              },
              "landmark": {
                  "name": "Hanauma Bay",
                  "photographer": "photosforyou",
                  "imageLink": "https://pixabay.com/photos/hanauma-bay-bay-hawaii-sea-beach-240144/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hanauma_Bay",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Honolulu",
                  "photographer": "hhach",
                  "imageLink": "https://en.wikipedia.org/wiki/Honolulu",
                  "wikiLink": "https://en.wikipedia.org/wiki/Honolulu"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "ME",
                      "number": 3
                  },
                  {
                      "code": "MD",
                      "number": 1
                  }
              ],
              "uniqueId": "jrpc5yrH",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "The Mount Rushmore State",
                  "Beehive State",
                  "Garden State",
                  "Pelican State/Creole State/Sugar State",
                  "Bluegrass State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The only royal building in the United States can be found in this state.",
                  "falseFact": "Hawaii is an extremely dry state, being the third dries in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Sunflower",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Santa Fe Plaza",
                  "Grand Teton",
                  "Carlsbad Caverns",
                  "World's Largest Ball of Twine",
                  "Fort Laramie",
                  "St George Utah Temple",
                  "Bryce Canyon"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Maui",
                  "photographer": "hmmunoz512",
                  "imageLink": "https://pixabay.com/photos/maui-sunset-hawaii-2729958/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Maui"
              },
              "landmark": {
                  "name": "Akaka Falls",
                  "photographer": "xiSerge",
                  "imageLink": "https://pixabay.com/photos/akaka-falls-akaka-falls-state-park-7368999/",
                  "wikiLink": "https://en.wikipedia.org/wiki/%CA%BBAkaka_Falls_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Kahului",
                  "photographer": "ideatrendz",
                  "imageLink": "https://en.wikipedia.org/wiki/Kahului,_Hawaii",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kahului,_Hawaii"
              },
              "landmarksRound": [
                  {
                      "code": "CO",
                      "number": 5
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "ID",
                      "number": 1
                  }
              ],
              "uniqueId": "rxLjJDj7",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Island of Enchantment",
                  "Beaver State",
                  "Empire State",
                  "Green Mountain State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has been occupied by native peoples since around 1,500 years ago.",
                  "falseFact": "The Hawaii flag features a large yellow flower.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wild Prairie Rose",
                  "Rhododendron",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Milwaukee Art Museum",
                  "Red River Gorge",
                  "Harding Icefield",
                  "Lincoln Boyhood National Memorial",
                  "Garden of the Gods",
                  "Cheaha Mountain",
                  "West Virginia Penitentiary"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "The Byodo-In Temple",
                  "photographer": "hasanerekat",
                  "imageLink": "https://pixabay.com/photos/byodo-in-temple-hawaii-6607593/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Byodo-In_(Hawaii)"
              },
              "landmark": {
                  "name": "Diamond Head",
                  "photographer": "vergaratampe",
                  "imageLink": "https://pixabay.com/photos/diamond-head-hawaii-honolulu-4415195/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Diamond_Head,_Hawaii",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lahaina",
                  "photographer": "Calbear22",
                  "imageLink": "https://en.wikipedia.org/wiki/Lahaina,_Hawaii",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lahaina,_Hawaii"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 2
                  },
                  {
                      "code": "ME",
                      "number": 1
                  }
              ],
              "uniqueId": "2Z6Rm8jp",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Wolverine State/Great Lake State",
                  "Land of Enchantment",
                  "First State/Diamond State",
                  "Green Mountain State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state grows by around 40 acres each year due to volcanic activity.",
                  "falseFact": "Oranges are native to this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Magnolia",
                  "Orange Blossom",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "Dinosaur National Monument",
                  "The Witch House at Salem",
                  "Church Street Marketplace",
                  "Gateway Arch",
                  "Tupelo National Battlefield",
                  "Sandia Peak Tramway",
                  "Fort Worden"
              ]
          }
      ],
      "capital": "Honolulu",
      "flower": {
          "name": "Hawaiian Hibiscus",
          "imgLink": "https://pixabay.com/photos/hibiscus-flower-pink-blossom-1799318/",
          "imgLink2": "https://pixabay.com/photos/hibiscus-hawaiian-flower-pink-1117457/",
          "wikiLink": "https://en.wikipedia.org/wiki/Hawaiian_hibiscus"
      },
      "nickname": "Aloha State"
  },
  {
      "name": "Iowa",
      "code": "IA",
      "lat": 41.878003,
      "long": -93.097702,
      "cities": [
          "Des Moines",
          "Iowa City",
          "Cedar Rapids",
          "Davenport",
          "Dubuque",
          "Champaign"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Old Capitol Building",
                  "photographer": "marchinileio0",
                  "imageLink": "https://pixabay.com/photos/iowa-city-old-capitol-iowa-building-1142298/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Iowa_Old_Capitol_Building"
              },
              "landmark": {
                  "name": "Shrine of the Grotto of the Redemption",
                  "photographer": "Alejandro Pulido",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:The_Grotto_of_the_Redemption_Pic5.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grotto_of_the_Redemption",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Des Moines",
                  "photographer": "RJA1988",
                  "imageLink": "https://en.wikipedia.org/wiki/Des_Moines,_Iowa",
                  "wikiLink": "https://en.wikipedia.org/wiki/Des_Moines,_Iowa"
              },
              "landmarksRound": [
                  {
                      "code": "WA",
                      "number": 5
                  },
                  {
                      "code": "ID",
                      "number": 4
                  },
                  {
                      "code": "MN",
                      "number": 1
                  }
              ],
              "uniqueId": "XfYh5NG5",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Golden State",
                  "Beaver State",
                  "Nutmeg State/Constitution State",
                  "Bay State/Old Colony State",
                  "Aloha State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Before it was part of the United States, another European colonial power, _____, claimed the land.",
                  "fillAnswer": "France",
                  "fillAnswers": [
                      "France",
                      "Netherlands",
                      "Portugal",
                      "Italy"
                  ]
              },
              "flowerOptions": [
                  "Wood Violet",
                  "Camellia",
                  "Coast Rhododendron"
              ],
              "landmarkPlaces": [
                  "Federal Hill Park",
                  "Timberline Lodge",
                  "The Mark Twain House",
                  "Monticello",
                  "George Washington Bridge",
                  "Red Rocks Ampitheatre",
                  "Ravenel Bridge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Sergeant Floyd Monument",
                  "photographer": "Chris Light",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Sgt_Floyd_Monument_PA140783.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sergeant_Floyd_Monument"
              },
              "landmark": {
                  "name": "Amana Colonies",
                  "photographer": "Chanilim714",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:The_Chocolate_Haus_Amana_IA_-_panoramio.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Amana_Colonies",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Iowa City",
                  "photographer": "Stevat20",
                  "imageLink": "https://en.wikipedia.org/wiki/Iowa_City,_Iowa",
                  "wikiLink": "https://en.wikipedia.org/wiki/Iowa_City,_Iowa"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "WI",
                      "number": 2
                  },
                  {
                      "code": "MO",
                      "number": 2
                  }
              ],
              "uniqueId": "RvGF7hyP",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Equality State",
                  "Sunshine State",
                  "Cornhusker State/Beef State",
                  "Nutmeg State/Constitution State",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Iowa's a big state for meat production: they top the charts of U.S. states for both beef and pork production.",
                  "falseFact": "Iowa is about the same size, in terms of acreage, as France.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Purple Lilac",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "Federal Hill Park",
                  "Blanchard Springs Cavern",
                  "Pittock Mansion",
                  "Knife River Indian Villages",
                  "World of Coca Cola",
                  "Museum of the Rockies",
                  "Everglades"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Maquoketa Caves",
                  "photographer": "Phil Roeder",
                  "imageLink": "https://www.flickr.com/photos/tabor-roeder/29460848343",
                  "wikiLink": "https://en.wikipedia.org/wiki/Maquoketa_Caves_State_Park"
              },
              "landmark": {
                  "name": "Pikes Peak State Park",
                  "photographer": "Yinan Chen",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Gfp-iowa-pikes-peak-state-park-mississippi-river.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pikes_Peak_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cedar Rapids",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Cedar_Rapids,_Iowa",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cedar_Rapids,_Iowa"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 2
                  },
                  {
                      "code": "AK",
                      "number": 1
                  }
              ],
              "uniqueId": "W9cmcNCY",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Grand Canyon State",
                  "Sunshine State",
                  "The Old Dominion/Mother of Presidents",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Iowa City is the U.S. City of Literature.",
                  "falseFact": "There are no waterfalls in the state of Iowa.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Mock Orange",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "Vulcan Statue",
                  "Independence Rock",
                  "Theodore Roosevelt National Park",
                  "San Xavier del Bac Mission",
                  "Cave of the Mounds",
                  "The Greenbrier",
                  "Blue Whale of Catoosa"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Des Moines",
                  "photographer": "2564368",
                  "imageLink": "https://pixabay.com/photos/des-moines-iowa-downtown-1390472/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Des_Moines,_Iowa"
              },
              "landmark": {
                  "name": "Field of Dreams",
                  "photographer": "H. Michael Miley",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:MLB_at_Field_of_Dreams_%28aerial_view%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Field_of_Dreams_(Dubuque_County,_Iowa)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Davenport",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Davenport,_Iowa",
                  "wikiLink": "https://en.wikipedia.org/wiki/Davenport,_Iowa"
              },
              "landmarksRound": [
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "NE",
                      "number": 1
                  }
              ],
              "uniqueId": "7s5YQzKj",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Island of Enchantment",
                  "First State/Diamond State",
                  "Ocean State",
                  "Bluegrass State",
                  "Granite State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Around 92% of this state's land is dedicated to farming.",
                  "falseFact": "This state's biggest industry is tourism.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Yellow Jessamine",
                  "Rose",
                  "Sunflower"
              ],
              "landmarkPlaces": [
                  "Grand Teton",
                  "Paw Paw Tunnel",
                  "Natural Bridge Caverns",
                  "Chihuly Garden and Glass",
                  "Cloud Gate",
                  "Space Needle",
                  "Cadillac Ranch"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Dubuque",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/dubuque-iowa-clock-tower-landmark-1731522/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dubuque,_Iowa"
              },
              "landmark": {
                  "name": "Maquoketa Caves",
                  "photographer": "Gardner.glen",
                  "imageLink": "https://en.wikipedia.org/wiki/Maquoketa_Caves_State_Park#/media/File:Maquoketa_Caves_02.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Maquoketa_Caves_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Dubuque",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Dubuque,_Iowa",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dubuque,_Iowa"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 2
                  },
                  {
                      "code": "MN",
                      "number": 2
                  },
                  {
                      "code": "KY",
                      "number": 1
                  }
              ],
              "uniqueId": "Z5wf7KV3",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Hoosier State",
                  "Grand Canyon State",
                  "Sooner State",
                  "Wolverine State/Great Lake State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Iowa is taking up the cause of clean energy, being the number two U.S. producer of ______.",
                  "fillAnswer": "Wind energy",
                  "fillAnswers": [
                      "Solar energy",
                      "Nuclear energy",
                      "Wind energy",
                      "Coal power"
                  ]
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Oregon Grape",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "Busch Stadium",
                  "Fort Snelling",
                  "Appalachian Trail",
                  "Mendenhall Glacier",
                  "Tryon Palace",
                  "Grave Creek Mound",
                  "Mississippi Petrified Forest"
              ]
          }
      ],
      "capital": "Des Moines",
      "flower": {
          "name": "Wild Rose",
          "imgLink": "https://pixabay.com/photos/wild-rose-hedge-blossom-bloom-rose-4277285/",
          "imgLink2": "https://pixabay.com/photos/flowers-wildflowers-wild-roses-526543/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosa_arkansana"
      },
      "nickname": "Hawkeye State/Corn State"
  },
  {
      "name": "Idaho",
      "code": "ID",
      "lat": 44.068202,
      "long": -114.742041,
      "cities": [
          "Coeur d'Alene",
          "Idaho Falls",
          "Boise",
          "Nampa",
          "Moscow",
          "Oregon City"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "City of Rocks National Reserve",
                  "photographer": "wilson44691",
                  "imageLink": "https://en.wikipedia.org/wiki/City_of_Rocks_National_Reserve#/media/File:City_of_Rocks_NR_view_072617.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/City_of_Rocks_National_Reserve"
              },
              "landmark": {
                  "name": "Shoshone Falls",
                  "photographer": "josephhill",
                  "imageLink": "https://pixabay.com/photos/shoshone-falls-waterfall-twin-falls-7486137/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Shoshone_Falls",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Coeur d'Alene",
                  "photographer": "Antony-22",
                  "imageLink": "https://en.wikipedia.org/wiki/Coeur_d%27Alene,_Idaho",
                  "wikiLink": "https://en.wikipedia.org/wiki/Coeur_d%27Alene,_Idaho"
              },
              "landmarksRound": [
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  }
              ],
              "uniqueId": "ztgK2Mkg",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Equality State",
                  "Prairie State/Land of Lincoln",
                  "Tar Heel State",
                  "Garden State",
                  "Show Me State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is bigger than all six New England states put together.",
                  "falseFact": "Though Idaho's capital is Boise, its most populous city by far is Coeur d'Alene.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Red Clover",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "Starved Rock State Park",
                  "USS Constitution",
                  "Devil's Tower National Monument",
                  "Sandia Peak Tramway",
                  "Klondike Gold Rush National Historic Park",
                  "Charging Bull",
                  "Alcatraz Island"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Lochsa River",
                  "photographer": "jdback",
                  "imageLink": "https://pixabay.com/photos/fall-colors-lochsa-river-idaho-4583016/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lochsa_River"
              },
              "landmark": {
                  "name": "Craters of the Moon",
                  "photographer": "Bureau of Land Management",
                  "imageLink": "https://en.wikipedia.org/wiki/Craters_of_the_Moon_National_Monument_and_Preserve#/media/File:My_Public_Lands_Roadtrip-_Craters_of_the_Moon_National_Monument_in_Idaho_(18801359775).jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Craters_of_the_Moon_National_Monument_and_Preserve",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Idaho Falls",
                  "photographer": "Eric Fredericks",
                  "imageLink": "https://en.wikipedia.org/wiki/Idaho_Falls,_Idaho",
                  "wikiLink": "https://en.wikipedia.org/wiki/Idaho_Falls,_Idaho"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "CT",
                      "number": 1
                  }
              ],
              "uniqueId": "BKJCWjhl",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Wolverine State/Great Lake State",
                  "Badger State/America's Dairyland",
                  "Equality State",
                  "Bluegrass State",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Idaho is known as the ____ capital of the world.",
                  "fillAnswer": "Lentil",
                  "fillAnswers": [
                      "Lentil",
                      "Flower",
                      "Soybean",
                      "Automobile"
                  ]
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Coast Rhododendron",
                  "Wild Rose"
              ],
              "landmarkPlaces": [
                  "Dinosaur National Monument",
                  "Lucy the Elephant",
                  "Theodore Roosevelt National Park",
                  "Klondike Gold Rush National Historic Park",
                  "The House on the Rock",
                  "World's Largest Ball of Twine",
                  "Acadia National Park"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Twin Falls",
                  "photographer": "USA_Reiseblogger",
                  "imageLink": "https://pixabay.com/photos/idaho-usa-america-nature-1675970/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Twin_Falls,_Idaho"
              },
              "landmark": {
                  "name": "Sawtooth National Forest",
                  "photographer": "MaritDW",
                  "imageLink": "https://pixabay.com/photos/sawtooth-mountains-idaho-usa-3870216/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sawtooth_National_Forest",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Boise",
                  "photographer": "Pinpals",
                  "imageLink": "https://en.wikipedia.org/wiki/Boise,_Idaho",
                  "wikiLink": "https://en.wikipedia.org/wiki/Boise,_Idaho"
              },
              "landmarksRound": [
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  }
              ],
              "uniqueId": "zkscdr8z",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Magnolia State",
                  "Granite State",
                  "Natural State",
                  "Pelican State/Creole State/Sugar State",
                  "North Star State/Gopher State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first destination ski resort in the U.S., Sun Valley Resort, is in Idaho.",
                  "falseFact": "Idaho is one of the most liberal states in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Red Clover",
                  "Flor De Maga",
                  "Magnolia"
              ],
              "landmarkPlaces": [
                  "International Peace Garden",
                  "Mount Hood",
                  "Little River Canyon National Preserve",
                  "Cave of the Mounds",
                  "Strataca",
                  "Oriole Park",
                  "Yale University"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Perrine Memorial Bridge",
                  "photographer": "Parkerjh",
                  "imageLink": "https://en.wikipedia.org/wiki/Perrine_Bridge#/media/File:Perrine_Bridge,_Twin_Falls,_ID.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Perrine_Bridge"
              },
              "landmark": {
                  "name": "Old Idaho Penitentiary",
                  "photographer": "Tamanoeconomico",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Old_Idaho_State_Penitentiary_%281%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Idaho_State_Penitentiary",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Nampa",
                  "photographer": "Visitor7",
                  "imageLink": "https://en.wikipedia.org/wiki/Nampa,_Idaho",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nampa,_Idaho"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "MO",
                      "number": 5
                  },
                  {
                      "code": "VI",
                      "number": 1
                  }
              ],
              "uniqueId": "VMMgSZm5",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "The Mount Rushmore State",
                  "Centennial State",
                  "Green Mountain State",
                  "Sooner State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The huckleberry is Idaho's official state fruit.",
                  "falseFact": "Idahoans have one of the most distinct accents in the country, with a characteristic twang on their vowels.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "Mayflower",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Saint-Gaudens National Historic Park",
                  "Cloud Gate",
                  "Pico El Yunque",
                  "Lincoln Memorial",
                  "Elephant Rocks State Park",
                  "Warner Theatre",
                  "Bourbon Street"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Freak Alley",
                  "photographer": "Jeff Hitchcock",
                  "imageLink": "https://www.flickr.com/photos/arbron/42414918684",
                  "wikiLink": "https://en.wikipedia.org/wiki/Perrine_Bridge"
              },
              "landmark": {
                  "name": "Minidoka National Historic Site",
                  "photographer": "Ken Lund",
                  "imageLink": "https://www.flickr.com/photos/kenlund/50426139326",
                  "wikiLink": "https://en.wikipedia.org/wiki/Minidoka_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Moscow",
                  "photographer": "Robbiegiles",
                  "imageLink": "https://en.wikipedia.org/wiki/Moscow,_Idaho",
                  "wikiLink": "https://en.wikipedia.org/wiki/Moscow,_Idaho"
              },
              "landmarksRound": [
                  {
                      "code": "WV",
                      "number": 5
                  },
                  {
                      "code": "TX",
                      "number": 2
                  },
                  {
                      "code": "MT",
                      "number": 1
                  }
              ],
              "uniqueId": "JRs5Lzjs",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Grand Canyon State",
                  "The Mount Rushmore State",
                  "Hawkeye State/Corn State",
                  "Magnolia State",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state produces more ____ than any other state.",
                  "fillAnswer": "Potatoes",
                  "fillAnswers": [
                      "Oranges",
                      "Potatoes",
                      "Apples",
                      "Almonds"
                  ]
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Flor De Maga",
                  "Wild Rose"
              ],
              "landmarkPlaces": [
                  "Louisville Water Tower",
                  "TransAmerica Pyramid",
                  "Maquoketa Caves",
                  "Churchill Downs",
                  "Taliesin East",
                  "Carlsbad Caverns",
                  "The Mark Twain House"
              ]
          }
      ],
      "capital": "Boise",
      "flower": {
          "name": "Mock Orange",
          "imgLink": "https://pixabay.com/photos/mock-orange-jasmin-blossoms-white-1365918/",
          "imgLink2": "https://pixabay.com/photos/mock-orange-jasmin-blossoms-white-505443/",
          "wikiLink": "https://en.wikipedia.org/wiki/Philadelphus_lewisii"
      },
      "nickname": "Gem State"
  },
  {
      "name": "Illinois",
      "code": "IL",
      "lat": 40.633125,
      "long": -89.398528,
      "cities": [
          "Springfield",
          "Chicago",
          "Peoria",
          "Rockford",
          "Champaign",
          "Knoxville"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Chicago",
                  "photographer": "JoshuaWoroniecki",
                  "imageLink": "https://pixabay.com/photos/buildings-skyscrapers-downtown-4324792/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chicago"
              },
              "landmark": {
                  "name": "Cloud Gate",
                  "photographer": "elcorredor",
                  "imageLink": "https://pixabay.com/photos/cloud-gate-sculpture-sightseeing-1343507/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cloud_Gate",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Springfield",
                  "photographer": "Eovart Caceir",
                  "imageLink": "https://en.wikipedia.org/wiki/Springfield,_Illinois",
                  "wikiLink": "https://en.wikipedia.org/wiki/Springfield,_Illinois"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "WV",
                      "number": 3
                  }
              ],
              "uniqueId": "5VnQbkYC",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Green Mountain State",
                  "Bluegrass State",
                  "Hawkeye State/Corn State",
                  "Buckeye State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state ranks as the ___ largest US state by population.",
                  "fillAnswer": "6th",
                  "fillAnswers": [
                      "3rd",
                      "6th",
                      "11th",
                      "23rd"
                  ]
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Saguaro Cactus Blossom",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "Monticello",
                  "Soldiers & Sailors Monument",
                  "Washington National Cathedral",
                  "Blanchard Springs Cavern",
                  "Fort Delaware",
                  "Indiana Caverns",
                  "Joshua Tree National Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Bahai House of Worship",
                  "photographer": "Relu Jianu",
                  "imageLink": "https://en.wikipedia.org/wiki/Bah%C3%A1%CA%BC%C3%AD_House_of_Worship#/media/File:Bah%C3%A1'%C3%AD_House_of_Worship.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bah%C3%A1%CA%BC%C3%AD_House_of_Worship"
              },
              "landmark": {
                  "name": "Navy Pier",
                  "photographer": "858265",
                  "imageLink": "https://pixabay.com/photos/navy-pier-chicago-skyline-river-view-2776326/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Navy_Pier",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Chicago",
                  "photographer": "Pexels",
                  "imageLink": "https://en.wikipedia.org/wiki/Chicago",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chicago"
              },
              "landmarksRound": [
                  {
                      "code": "VT",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  }
              ],
              "uniqueId": "hBqqhLPr",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Pine Tree State",
                  "Golden State",
                  "The Old Dominion/Mother of Presidents",
                  "Centennial State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Of Illinois' 12 million+ residents, more than 5 million live in Cook County, the county which contains Chicago and its neighboring suburbs.",
                  "falseFact": "This state became a territory in the 1750s.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "American Beauty Rose",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "Cedar Point",
                  "Aerial Lift Bridge",
                  "Maquoketa Caves",
                  "Reed Gold Mine",
                  "Myriad Botanical Gardens",
                  "Brown University",
                  "Indiana Caverns"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Northwestern University",
                  "photographer": "Joshsukoff",
                  "imageLink": "https://en.wikipedia.org/wiki/Northwestern_University#/media/File:Northwestern_University_Aerial.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Northwestern_University"
              },
              "landmark": {
                  "name": "Starved Rock State Park",
                  "photographer": "pieonane",
                  "imageLink": "https://pixabay.com/photos/library-card-to-state-park-nature-3523348/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Starved_Rock_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Peoria",
                  "photographer": "Bohao Zhao",
                  "imageLink": "https://en.wikipedia.org/wiki/Peoria,_Illinois",
                  "wikiLink": "https://en.wikipedia.org/wiki/Peoria,_Illinois"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "RI",
                      "number": 1
                  },
                  {
                      "code": "PR",
                      "number": 1
                  }
              ],
              "uniqueId": "pwFfFwjT",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Golden State",
                  "Tar Heel State",
                  "Evergreen State/Chinhook State",
                  "Grand Canyon State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Despite being known as a populous state, Illinois is 75% farmland.",
                  "falseFact": "Major river the Hudson River flows through Chicago.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Apple Blossom",
                  "Oregon Grape",
                  "Cherokee Rose"
              ],
              "landmarkPlaces": [
                  "Museum of the Rockies",
                  "Mendenhall Glacier",
                  "The Breakers",
                  "Waimea Canyon",
                  "Milwaukee Art Museum",
                  "Liberty Bell",
                  "Diamond Head"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Chicago",
                  "photographer": "HealthKindness",
                  "imageLink": "https://pixabay.com/photos/lake-michigan-chicago-lake-michigan-1666234/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chicago"
              },
              "landmark": {
                  "name": "Wrigley Field",
                  "photographer": "Sea Cow",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Wrigley_Field_in_line_with_sign.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wrigley_Field",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rockford",
                  "photographer": "Ben Jacobson",
                  "imageLink": "https://en.wikipedia.org/wiki/Rockford,_Illinois",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rockford,_Illinois"
              },
              "landmarksRound": [
                  {
                      "code": "WI",
                      "number": 2
                  },
                  {
                      "code": "KS",
                      "number": 3
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "vXR572GT",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Tar Heel State",
                  "Green Mountain State",
                  "Flickertail State/Sioux State",
                  "Treasure State/Big Sky Country",
                  "Show Me State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The world's first Ferris Wheel was built in Illinois for the 1893 World's Fair.",
                  "falseFact": "Illinois borders the Great Salt Lake.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Indian Paintbrush",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "San Xavier del Bac Mission",
                  "Lincoln Memorial",
                  "The Mark Twain House",
                  "Amana Colonies",
                  "Washington National Cathedral",
                  "USS Albacore",
                  "Fort Frederick"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Illinois River",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/tug-on-illinois-river-tug-tugboat-3777786/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Illinois_River"
              },
              "landmark": {
                  "name": "Willis Tower",
                  "photographer": "joestoltz",
                  "imageLink": "https://pixabay.com/photos/chicago-skyline-illinois-3404306/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Willis_Tower",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Champaign",
                  "photographer": "Dori",
                  "imageLink": "https://en.wikipedia.org/wiki/Champaign,_Illinois",
                  "wikiLink": "https://en.wikipedia.org/wiki/Champaign,_Illinois"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 4
                  },
                  {
                      "code": "AK",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 4
                  }
              ],
              "uniqueId": "rwHB8p4T",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Lone Star State",
                  "Wolverine State/Great Lake State",
                  "Silver State/Sagebrush State",
                  "Mountain State",
                  "Green Mountain State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state's official slogan is Land of ______.",
                  "fillAnswer": "Lincoln",
                  "fillAnswers": [
                      "Washington",
                      "Jefferson",
                      "Roosevelt",
                      "Lincoln"
                  ]
              },
              "flowerOptions": [
                  "Bitterroot",
                  "Sego Lily",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "Luray Caverns",
                  "Fort Abraham Lincoln State Park",
                  "Ravenel Bridge",
                  "Joshua Tree National Park",
                  "Basilica of the Sacred Heart",
                  "Tupelo National Battlefield",
                  "Mount Hood"
              ]
          }
      ],
      "capital": "Springfield",
      "flower": {
          "name": "Violet",
          "imgLink": "https://pixabay.com/photos/flower-liverflower-hepatica-nature-3329845/",
          "imgLink2": "https://pixabay.com/photos/fragrant-violets-violet-flower-1077136/",
          "wikiLink": "https://en.wikipedia.org/wiki/Viola_(plant)"
      },
      "nickname": "Prairie State/Land of Lincoln"
  },
  {
      "name": "Indiana",
      "code": "IN",
      "lat": 40.551217,
      "long": -85.602364,
      "cities": [
          "Indianapolis",
          "Fort Wayne",
          "Hammond",
          "Carmel",
          "Bloomington",
          "Tallahassee"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Benjamin Harrison Presidential Site",
                  "photographer": "Daniel Schwen",
                  "imageLink": "https://en.wikipedia.org/wiki/Benjamin_Harrison_Presidential_Site#/media/File:Benjamin_Harrison_Home.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Benjamin_Harrison_Presidential_Site"
              },
              "landmark": {
                  "name": "Basilica of the Sacred Heart",
                  "photographer": "Nheyob",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Basilica_of_the_Sacred_Heart_%28Notre_Dame,_IN%29_-_exterior.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Basilica_of_the_Sacred_Heart_(Notre_Dame)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Indianapolis",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Indianapolis",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indianapolis"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 2
                  },
                  {
                      "code": "CA",
                      "number": 2
                  },
                  {
                      "code": "MI",
                      "number": 5
                  }
              ],
              "uniqueId": "Y4cRK8F7",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "The District",
                  "Show Me State",
                  "Green Mountain State",
                  "Prairie State/Land of Lincoln"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has a town called Santa Claus where letters to the North Pole are sent.",
                  "falseFact": "The U.S. landmark Cloudgate -- which is a massive silver structure in the shape of a bean -- can be found in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Yucca Flower",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "Dinosaur National Monument",
                  "Washington National Cathedral",
                  "Beale Street",
                  "Old Faithful",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Navy Pier",
                  "Gateway Arch"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Indiana Dunes State Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/indiana-dunes-state-park-beach-1848559/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indiana_Dunes_State_Park"
              },
              "landmark": {
                  "name": "Indiana Caverns",
                  "photographer": "daveynin",
                  "imageLink": "https://www.flickr.com/photos/daveynin/4900027040",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indiana_Caverns",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fort Wayne",
                  "photographer": "Momoneymoproblemz",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Wayne,_Indiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Wayne,_Indiana"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "MI",
                      "number": 5
                  }
              ],
              "uniqueId": "fRysHHDM",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Beaver State",
                  "Keystone State",
                  "Lone Star State",
                  "Show Me State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Northwest Indiana is home to the major industry of ____ production.",
                  "fillAnswer": "Steel",
                  "fillAnswers": [
                      "Steel",
                      "Diamond",
                      "Lemon",
                      "Onion"
                  ]
              },
              "flowerOptions": [
                  "Magnolia",
                  "Mayflower",
                  "White Pine Cone And Tassel"
              ],
              "landmarkPlaces": [
                  "Temple Square",
                  "Fort Worden",
                  "Aerial Lift Bridge",
                  "The Keeper of the Plains",
                  "Old State House",
                  "Craters of the Moon",
                  "Disney World"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Indianapolis",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/indianapolis-indiana-city-urban-1872532/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indianapolis"
              },
              "landmark": {
                  "name": "Indianapolis Catacombs",
                  "photographer": "INvets",
                  "imageLink": "https://www.flickr.com/photos/invets/51629138113/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indianapolis_Catacombs",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hammond",
                  "photographer": "Hammondman",
                  "imageLink": "https://en.wikipedia.org/wiki/Hammond,_Indiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hammond,_Indiana"
              },
              "landmarksRound": [
                  {
                      "code": "WA",
                      "number": 4
                  },
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "CA",
                      "number": 1
                  }
              ],
              "uniqueId": "DTNm5VPM",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Wolverine State/Great Lake State",
                  "America’s Paradise",
                  "Prairie State/Land of Lincoln",
                  "The Last Frontier",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Some 90% of world popcorn production occurs in Indiana.",
                  "falseFact": "This state is home to more Mormons than any other U.S. state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "American Beauty Rose",
                  "Sagebrush"
              ],
              "landmarkPlaces": [
                  "Christiansted National Historic Site",
                  "Ark Encounter",
                  "High Roller",
                  "Olbrich Botanical Gardens",
                  "Ellis Island",
                  "Saint-Gaudens National Historic Park",
                  "Manitou Cliff Dwellings"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "University of Notre Dame",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/university-of-notre-dame-school-1888208/",
                  "wikiLink": "https://en.wikipedia.org/wiki/University_of_Notre_Dame"
              },
              "landmark": {
                  "name": "Lincoln Boyhood National Memorial",
                  "photographer": "Doug Kerr",
                  "imageLink": "https://www.flickr.com/photos/dougtone/7724425612",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lincoln_Boyhood_National_Memorial",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Carmel",
                  "photographer": "Carmel Central",
                  "imageLink": "https://en.wikipedia.org/wiki/Carmel,_Indiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carmel,_Indiana"
              },
              "landmarksRound": [
                  {
                      "code": "LA",
                      "number": 2
                  },
                  {
                      "code": "SD",
                      "number": 2
                  },
                  {
                      "code": "KS",
                      "number": 2
                  }
              ],
              "uniqueId": "QyNwCFMZ",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Palmetto State",
                  "Beaver State",
                  "Aloha State",
                  "The Last Frontier",
                  "Equality State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has a state fossil: the mastodon.",
                  "falseFact": "This state borders three of the Great Lakes: Lake Erie, Lake Michigan, and Lake Superior.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Peach Blossom",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "Flume Gorge",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Wynwood Walls",
                  "Grave Creek Mound",
                  "High Roller",
                  "Graceland",
                  "Taos Pueblo"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Indianapolis",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/world-war-i-memorial-indianapolis-4091043/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Indianapolis"
              },
              "landmark": {
                  "name": "Soldiers & Sailors Monument",
                  "photographer": "David Wilson",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:20170204_25_Soldiers_%26_Sailors_Monument,_Indianapolis,_Indiana.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Soldiers%27_and_Sailors%27_Monument_(Indianapolis)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bloomington",
                  "photographer": "Yahala",
                  "imageLink": "https://en.wikipedia.org/wiki/Bloomington,_Indiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bloomington,_Indiana"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 1
                  },
                  {
                      "code": "CA",
                      "number": 5
                  }
              ],
              "uniqueId": "R5Bmtd2D",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Lone Star State",
                  "First State/Diamond State",
                  "Bluegrass State",
                  "Sunshine State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The name Indiana means _____.",
                  "fillAnswer": "Indian Land",
                  "fillAnswers": [
                      "Rich Soil",
                      "Sunny Home",
                      "Big Forests",
                      "Indian Land"
                  ]
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Apple Blossom",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Taos Pueblo",
                  "The Mark Twain House",
                  "USS Albacore",
                  "Grand Teton",
                  "The House on the Rock",
                  "The Venetian",
                  "Fort Abraham Lincoln State Park"
              ]
          }
      ],
      "capital": "Indianapolis",
      "flower": {
          "name": "Peony",
          "imgLink": "https://pixabay.com/photos/peony-flower-flora-blossom-bloom-287062/",
          "imgLink2": "https://pixabay.com/photos/nature-plant-flower-peony-paeonia-3434158/",
          "wikiLink": "https://en.wikipedia.org/wiki/Peony"
      },
      "nickname": "Hoosier State"
  },
  {
      "name": "Kansas",
      "code": "KS",
      "lat": 39.011902,
      "long": -98.484246,
      "cities": [
          "Wichita",
          "Topeka",
          "Lawrence",
          "Kansas City",
          "Overland Park",
          "Lahaina"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Tallgrass Prarie National Preserve",
                  "photographer": "National Parks Gallery",
                  "imageLink": "https://picryl.com/media/spring-hill-farm-and-stock-ranch-bbf02d",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tallgrass_Prairie_National_Preserve"
              },
              "landmark": {
                  "name": "World's Largest Ball of Twine",
                  "photographer": "Heather Paul",
                  "imageLink": "https://www.flickr.com/photos/warriorwoman531/9806607774",
                  "wikiLink": "https://en.wikipedia.org/wiki/Biggest_ball_of_twine",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Wichita",
                  "photographer": "WeaponzingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Wichita,_Kansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wichita,_Kansas"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 5
                  },
                  {
                      "code": "PR",
                      "number": 3
                  },
                  {
                      "code": "NJ",
                      "number": 3
                  }
              ],
              "uniqueId": "fd8zlgNT",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Natural State",
                  "Equality State",
                  "Silver State/Sagebrush State",
                  "Wolverine State/Great Lake State",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Despite being known for farming, Kansas is actually also a great place for caving, with 528 total caves in the state.",
                  "falseFact": "President Herbert Hoover is from this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Rhododendron",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "TransAmerica Pyramid",
                  "Taliesin West",
                  "The Alamo",
                  "Grand Teton",
                  "Centennial Land Run Monument",
                  "Marble House",
                  "Hocking Hills State Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Keeper of the Plains",
                  "photographer": "ThisIsAmbient",
                  "imageLink": "https://en.wikipedia.org/wiki/File:ThisIsAmbient-KeeperOfThePlains-Day.png",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Keeper_of_the_Plains"
              },
              "landmark": {
                  "name": "Strataca",
                  "photographer": "undergrounddarkride",
                  "imageLink": "https://en.wikipedia.org/wiki/Strataca#/media/File:Carey_Salt_Car.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Strataca",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Topeka",
                  "photographer": "America's Power",
                  "imageLink": "https://en.wikipedia.org/wiki/Topeka,_Kansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Topeka,_Kansas"
              },
              "landmarksRound": [
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 5
                  },
                  {
                      "code": "NM",
                      "number": 1
                  }
              ],
              "uniqueId": "SfFDlPmY",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Empire State",
                  "Grand Canyon State",
                  "Free State/Old Line State",
                  "Hoosier State",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The _____ was invented in Kansas.",
                  "fillAnswer": "Helicopter",
                  "fillAnswers": [
                      "Helicopter",
                      "Submarine",
                      "Automobile",
                      "Airplane"
                  ]
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "Flowering Dogwood",
                  "Hawthorn"
              ],
              "landmarkPlaces": [
                  "Multnomah Falls",
                  "Fort Smith National Historic Site",
                  "Meteor Crater",
                  "Biltmore",
                  "Dinosaur Park",
                  "Grand Staircase",
                  "Busch Stadium"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Monument Rocks",
                  "photographer": "Seraph1888",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Milky_Way_over_Monument_Rocks,_Kansas,_USA.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Monument_Rocks_(Kansas)"
              },
              "landmark": {
                  "name": "Fort Larned",
                  "photographer": "mamcginnis",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Fort_Larned_National_Historic_Site_2013-09-26_14-43-52.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Larned_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lawrence",
                  "photographer": "Ian Ballinger",
                  "imageLink": "https://en.wikipedia.org/wiki/Lawrence,_Kansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lawrence,_Kansas"
              },
              "landmarksRound": [
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "HI",
                      "number": 5
                  },
                  {
                      "code": "CT",
                      "number": 5
                  }
              ],
              "uniqueId": "Cdr8Rw7y",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Beehive State",
                  "Pelican State/Creole State/Sugar State",
                  "Sooner State",
                  "Flickertail State/Sioux State",
                  "Nutmeg State/Constitution State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Amelia Earhart, a pilot who famously went missing on a round-the-world trip, is from this state.",
                  "falseFact": "Kool-Aid was invented in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "American Dogwood",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "Arlington National Cemetery",
                  "Ark Encounter",
                  "Everglades",
                  "Amicalola Falls",
                  "Minidoka National Historic Site",
                  "Castillo San Felipe Del Morro",
                  "Mammoth Cave"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Kansas City",
                  "photographer": "jotoya",
                  "imageLink": "https://pixabay.com/photos/kansas-city-skyline-dusk-beautiful-5111833/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kansas_City_metropolitan_area"
              },
              "landmark": {
                  "name": "The Keeper of the Plains",
                  "photographer": "RJA1988",
                  "imageLink": "https://pixabay.com/photos/keeper-of-the-plains-native-american-4568727/",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Keeper_of_the_Plains",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Kansas City",
                  "photographer": "Americasroof",
                  "imageLink": "https://en.wikipedia.org/wiki/Kansas_City,_Kansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kansas_City,_Kansas"
              },
              "landmarksRound": [
                  {
                      "code": "WI",
                      "number": 5
                  },
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 5
                  }
              ],
              "uniqueId": "BbWLlbnj",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Golden State",
                  "The Mount Rushmore State",
                  "Garden State",
                  "Grand Canyon State",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is part of Tornado Alley.",
                  "falseFact": "The Mojave Desert can be found in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Violet",
                  "Bitterroot",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Taliesin West",
                  "Missouri Botanical Garden",
                  "San Xavier del Bac Mission",
                  "Museum of the Rockies",
                  "Old Idaho Penitentiary",
                  "Scotts Bluff",
                  "Taos Pueblo"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Wichita",
                  "photographer": "RJA1988",
                  "imageLink": "https://pixabay.com/photos/arkansas-river-wichita-kansas-river-4568904/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wichita,_Kansas"
              },
              "landmark": {
                  "name": "Monument Rocks",
                  "photographer": "Brian W. Schaller",
                  "imageLink": "https://en.wikipedia.org/wiki/Monument_Rocks_(Kansas)#/media/File:A328,_Monument_Rocks_National_Natural_Landmark,_Gove_County,_Kansas,_2011.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Monument_Rocks_(Kansas)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Overland Park",
                  "photographer": "Brylie Oxley",
                  "imageLink": "https://en.wikipedia.org/wiki/Overland_Park,_Kansas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Overland_Park,_Kansas"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 1
                  },
                  {
                      "code": "HI",
                      "number": 1
                  }
              ],
              "uniqueId": "V6mryfFb",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Bay State/Old Colony State",
                  "Volunteer State",
                  "Free State/Old Line State",
                  "Mountain State",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Famous book (and movie) ______ begins in Kansas.",
                  "fillAnswer": "The Wizard of Oz",
                  "fillAnswers": [
                      "The Godfather",
                      "The Wizard of Oz",
                      "Die Hard",
                      "Gone With the Wind"
                  ]
              },
              "flowerOptions": [
                  "Iris",
                  "Oregon Grape",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "Pictograph Cave State Park",
                  "Black Canyon of the Gunnison",
                  "Multnomah Falls",
                  "Waimea Canyon",
                  "Old State Capitol",
                  "Mammoth Cave",
                  "Gillette Castle"
              ]
          }
      ],
      "capital": "Topeka",
      "flower": {
          "name": "Sunflower",
          "imgLink": "https://pixabay.com/photos/sunflower-flower-yellow-flower-3540266/",
          "imgLink2": "https://pixabay.com/photos/sunflower-yellow-flower-pollen-1627193/",
          "wikiLink": "https://en.wikipedia.org/wiki/Common_sunflower"
      },
      "nickname": "Sunflower State/Jayhawker State"
  },
  {
      "name": "Kentucky",
      "code": "KY",
      "lat": 37.839333,
      "long": -84.270018,
      "cities": [
          "Louisville",
          "Lexington",
          "Frankfort",
          "Owensboro",
          "Bowling Green",
          "Trenton"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Cumberland Falls",
                  "photographer": "USA-Reiseblogger",
                  "imageLink": "https://pixabay.com/photos/usa-america-kentucky-waterfall-1648863/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cumberland_Falls"
              },
              "landmark": {
                  "name": "Churchill Downs",
                  "photographer": "W.marsh",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:University_of_Louisville_marching_band,_Churchill_Downs_Twin_Spires.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Churchill_Downs",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Louisville",
                  "photographer": "Chris Watson",
                  "imageLink": "https://en.wikipedia.org/wiki/Louisville,_Kentucky",
                  "wikiLink": "https://en.wikipedia.org/wiki/Louisville,_Kentucky"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 2
                  },
                  {
                      "code": "IA",
                      "number": 1
                  },
                  {
                      "code": "AZ",
                      "number": 3
                  }
              ],
              "uniqueId": "Qnv26k6C",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Centennial State",
                  "Pine Tree State",
                  "Buckeye State",
                  "Beehive State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state's official state dance is clogging.",
                  "falseFact": "The official state beverage of this state is water.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Wood Violet",
                  "Pink And White Lady's Slipper"
              ],
              "landmarkPlaces": [
                  "Pictured Rocks",
                  "The Elms",
                  "Grave Creek Mound",
                  "Washington National Cathedral",
                  "Field of Dreams",
                  "Appalachian Trail",
                  "Thorncrown Chapel"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Ashland",
                  "photographer": "Corey Graese",
                  "imageLink": "https://en.wikipedia.org/wiki/Ashland,_Kentucky#/media/File:Downtown_Ashland,_KY.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ashland,_Kentucky"
              },
              "landmark": {
                  "name": "Mammoth Cave",
                  "photographer": "James St. John",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Historic_Entrance_%28Mammoth_Cave,_Kentucky,_USA%29_28_%2837096620394%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mammoth_Cave_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lexington",
                  "photographer": "Britt Selvitelle",
                  "imageLink": "https://en.wikipedia.org/wiki/Lexington,_Kentucky",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lexington,_Kentucky"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "MO",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "kDjlWZBT",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "Magnolia State",
                  "Cornhusker State/Beef State",
                  "Mountain State",
                  "North Star State/Gopher State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The biggest city in Kentucky is _______.",
                  "fillAnswer": "Louisville",
                  "fillAnswers": [
                      "Lexington",
                      "Louisville",
                      "Richmond",
                      "Bowling Green"
                  ]
              },
              "flowerOptions": [
                  "Bitterroot",
                  "Apple Blossom",
                  "Wild Rose"
              ],
              "landmarkPlaces": [
                  "Stonington Harbour Light",
                  "Milwaukee Art Museum",
                  "Fort Mandan State Historic Site",
                  "Bellagio Fountain",
                  "Marble House",
                  "Wrigley Field",
                  "Houmas House"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Abraham Lincoln Birthplace National Park",
                  "photographer": "Jamie",
                  "imageLink": "https://en.wikipedia.org/wiki/Abraham_Lincoln_Birthplace_National_Historical_Park#/media/File:Abraham_Lincoln_Birthplace_NHS.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Abraham_Lincoln_Birthplace_National_Historical_Park"
              },
              "landmark": {
                  "name": "Ark Encounter",
                  "photographer": "OlinEJ",
                  "imageLink": "https://pixabay.com/photos/ark-encounter-noah-s-ark-2514667/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ark_Encounter",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Frankfort",
                  "photographer": "Kenneth C. Zirkel",
                  "imageLink": "https://en.wikipedia.org/wiki/Frankfort,_Kentucky",
                  "wikiLink": "https://en.wikipedia.org/wiki/Frankfort,_Kentucky"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "MS",
                      "number": 1
                  }
              ],
              "uniqueId": "Z2ZX8Zrm",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "Beaver State",
                  "Natural State",
                  "The District",
                  "Show Me State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state is bordered on the east by the _________.",
                  "fillAnswer": "Appalachian Mountains",
                  "fillAnswers": [
                      "Rocky Mountains",
                      "Appalachian Mountains",
                      "Great Lakes",
                      "Great Plains"
                  ]
              },
              "flowerOptions": [
                  "Peony",
                  "Oklahoma Rose",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Akaka Falls",
                  "Chimney Rock",
                  "TransAmerica Pyramid",
                  "Gateway Arch",
                  "Seven Magic Mountains",
                  "Denail National Park",
                  "Ben & Jerry's"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Louisville",
                  "photographer": "islandworks",
                  "imageLink": "https://pixabay.com/photos/barn-farm-structure-farmington-2540726/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Louisville,_Kentucky"
              },
              "landmark": {
                  "name": "Red River Gorge",
                  "photographer": "Jarek Tuszynski",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Red_River_Gorge_-_Motherlode_1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_River_Gorge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Owensboro",
                  "photographer": "QuartierLatin1968",
                  "imageLink": "https://en.wikipedia.org/wiki/Owensboro,_Kentucky",
                  "wikiLink": "https://en.wikipedia.org/wiki/Owensboro,_Kentucky"
              },
              "landmarksRound": [
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "VI",
                      "number": 1
                  },
                  {
                      "code": "HI",
                      "number": 3
                  }
              ],
              "uniqueId": "52Vjw9b7",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Volunteer State",
                  "Show Me State",
                  "The Last Frontier",
                  "The Old Dominion/Mother of Presidents",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state claims to have invented bourbon.",
                  "falseFact": "The Country Music Capital of the World is in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Hawaiian Hibiscus",
                  "Peony"
              ],
              "landmarkPlaces": [
                  "Devil's Tower National Monument",
                  "Avalanche Lake",
                  "USS Constitution",
                  "Little Rock Central High School",
                  "Rock City Gardens",
                  "Meteor Crater",
                  "Minidoka National Historic Site"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Lexington",
                  "photographer": "Daderot",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Lexington_History_Center_-_Lexington,_Kentucky_-_DSC09073.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lexington,_Kentucky"
              },
              "landmark": {
                  "name": "Louisville Water Tower",
                  "photographer": "Bedford",
                  "imageLink": "https://en.wikipedia.org/wiki/Louisville_Water_Tower#/media/File:LWT_Distant.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Louisville_Water_Tower",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bowling Green",
                  "photographer": "OPMaster",
                  "imageLink": "https://en.wikipedia.org/wiki/Bowling_Green,_Kentucky",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bowling_Green,_Kentucky"
              },
              "landmarksRound": [
                  {
                      "code": "CO",
                      "number": 5
                  },
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "MT",
                      "number": 2
                  }
              ],
              "uniqueId": "5CH7vLT5",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Granite State",
                  "Empire State",
                  "Nutmeg State/Constitution State",
                  "Grand Canyon State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The biggest food chain started in this state is KFC.",
                  "falseFact": "This state has only 7 counties, one of the lowest figures of any state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "White Pine Cone And Tassel",
                  "Peach Blossom"
              ],
              "landmarkPlaces": [
                  "Harding Icefield",
                  "Rocky Statue",
                  "Old Faithful",
                  "The Elms",
                  "Cathedral Caverns State Park",
                  "Fort Smith National Historic Site",
                  "World of Coca Cola"
              ]
          }
      ],
      "capital": "Frankfort",
      "flower": {
          "name": "Goldenrod",
          "imgLink": "https://pixabay.com/photos/goldenrod-plant-yellow-late-summer-1586871/",
          "imgLink2": "https://pixabay.com/photos/giant-goldenrod-goldenrod-6632553/",
          "wikiLink": "https://en.wikipedia.org/wiki/Solidago_gigantea"
      },
      "nickname": "Bluegrass State"
  },
  {
      "name": "Louisiana",
      "code": "LA",
      "lat": 31.244823,
      "long": -92.145024,
      "cities": [
          "Baton Rouge",
          "Bossier City",
          "Metairie",
          "Lafayette",
          "New Orleans",
          "Paradise"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Louisiana's Old State Capitol",
                  "photographer": "glynn424",
                  "imageLink": "https://pixabay.com/photos/old-state-capitol-mansion-governor-169161/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Louisiana_State_Capitol"
              },
              "landmark": {
                  "name": "Bourbon Street",
                  "photographer": "Chris Litherland",
                  "imageLink": "https://en.wikipedia.org/wiki/Bourbon_Street#/media/File:ChrisLitherlandBourbonSt.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bourbon_Street",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Baton Rouge",
                  "photographer": "cfontenot",
                  "imageLink": "https://en.wikipedia.org/wiki/Baton_Rouge,_Louisiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Baton_Rouge,_Louisiana"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 1
                  },
                  {
                      "code": "WY",
                      "number": 2
                  },
                  {
                      "code": "IN",
                      "number": 1
                  }
              ],
              "uniqueId": "VHhFSg7C",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Buckeye State",
                  "North Star State/Gopher State",
                  "Pine Tree State",
                  "Silver State/Sagebrush State",
                  "Cotton State/Yellowhammer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The biggest city in this state, New Orleans, is home to a major Mardi Gras celebration each year.",
                  "falseFact": "Despite being part of the Confederacy, there were no Civil War battles in Louisiana.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Cherokee Rose",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "Centennial Land Run Monument",
                  "Christiansted National Historic Site",
                  "Baltimore Basilica",
                  "Magens Bay Beach",
                  "Museum of the Rockies",
                  "Hocking Hills State Park",
                  "High Roller"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "New Orleans",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/new-orleans-louisiana-81669/",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Orleans"
              },
              "landmark": {
                  "name": "Old State Capitol",
                  "photographer": "glynn424",
                  "imageLink": "https://pixabay.com/photos/old-state-capitol-mansion-governor-169157/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Louisiana_State_Capitol",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bossier City",
                  "photographer": "Shreveport-Bossier Convention and Tourist Bureau",
                  "imageLink": "https://en.wikipedia.org/wiki/Bossier_City,_Louisiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bossier_City,_Louisiana"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "Gs67zjMx",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Cornhusker State/Beef State",
                  "Land of Enchantment",
                  "Centennial State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Famous music genre _____ began in this state.",
                  "fillAnswer": "Jazz",
                  "fillAnswers": [
                      "Classical",
                      "Techno",
                      "Hip Hop",
                      "Jazz"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Hawthorn",
                  "Cherokee Rose"
              ],
              "landmarkPlaces": [
                  "Minneapolis Sculpture Garden",
                  "TransAmerica Pyramid",
                  "The House on the Rock",
                  "Brown University",
                  "Christiansted National Historic Site",
                  "Fort McHenry",
                  "Akaka Falls"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Jackson Square",
                  "photographer": "Diego Delso",
                  "imageLink": "https://en.wikipedia.org/wiki/Jackson_Square_(New_Orleans)#/media/File:Andrew_Jackson_monument,_New_Orleans,_USA.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jackson_Square_(New_Orleans)"
              },
              "landmark": {
                  "name": "Napoleon House",
                  "photographer": "Elisa Rolle",
                  "imageLink": "https://en.wikipedia.org/wiki/Napoleon_House#/media/File:Napoleon_House.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Napoleon_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Metairie",
                  "photographer": "PhotogMetairie",
                  "imageLink": "https://en.wikipedia.org/wiki/Metairie,_Louisiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Metairie,_Louisiana"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 4
                  },
                  {
                      "code": "NV",
                      "number": 2
                  },
                  {
                      "code": "IL",
                      "number": 2
                  }
              ],
              "uniqueId": "NFjPyn5l",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "Beaver State",
                  "Centennial State",
                  "The Old Dominion/Mother of Presidents",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state lends its name to a major purchase of land made by the U.S. government in 1803 which significantly expanded the size of the country.",
                  "falseFact": "The Mississippi River starts in this state, flowing north to the Great Lakes.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mayflower",
                  "Black-eyed Susan",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "Mackinaw Island",
                  "Thunder Hole",
                  "Golden Driller Statue",
                  "Cloud Gate",
                  "National Cowboy & Western Heritage Museum",
                  "Little River Canyon National Preserve",
                  "USS Constitution"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "French Quarter",
                  "photographer": "USA-Reiseblogger",
                  "imageLink": "https://pixabay.com/photos/usa-america-new-orleans-louisiana-1644525/",
                  "wikiLink": "https://en.wikipedia.org/wiki/French_Quarter"
              },
              "landmark": {
                  "name": "Fontainebleau State Park",
                  "photographer": "Mark Holloway",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Fontainebleau_State_Park,_Louisiana_2019_06.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fontainebleau_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lafayette",
                  "photographer": "TheLionHasSeen",
                  "imageLink": "https://en.wikipedia.org/wiki/Lafayette,_Louisiana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lafayette,_Louisiana"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 4
                  },
                  {
                      "code": "NV",
                      "number": 4
                  }
              ],
              "uniqueId": "mDgrmGY3",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "Pine Tree State",
                  "Hoosier State",
                  "Lone Star State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "No U.S. presidents are from Louisiana.",
                  "falseFact": "Hundreds of thousands of sheep are grown for their wool each year in Louisiana.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "Wild Prairie Rose",
                  "Camellia"
              ],
              "landmarkPlaces": [
                  "Old Faithful",
                  "John Brown's Fort",
                  "Bellagio Fountain",
                  "Basilica of the Sacred Heart",
                  "Strataca",
                  "Three Rivers",
                  "Falls Park on the Reedy"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Lafitte's Blacksmith Shop",
                  "photographer": "Teemu008",
                  "imageLink": "https://www.flickr.com/photos/teemu08/15662031137",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lafitte%27s_Blacksmith_Shop"
              },
              "landmark": {
                  "name": "Houmas House",
                  "photographer": "Tracy P",
                  "imageLink": "https://www.pexels.com/photo/houmas-plantation-house-15130447/",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Houmas",
                  "hasLandmark": true
              },
              "city": {
                  "name": "New Orleans",
                  "photographer": "otschnbrotschn",
                  "imageLink": "https://en.wikipedia.org/wiki/New_Orleans",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Orleans"
              },
              "landmarksRound": [
                  {
                      "code": "KS",
                      "number": 2
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 1
                  }
              ],
              "uniqueId": "8JP65qz9",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "Garden State",
                  "Gem State",
                  "Centennial State",
                  "Golden State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Unlike other states, Louisiana doesn't have counties, they have ______.",
                  "fillAnswer": "Parishes",
                  "fillAnswers": [
                      "Villages",
                      "Sub-states",
                      "Regions",
                      "Parishes"
                  ]
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Mountain Laurel",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Cedar Point",
                  "Willis Tower",
                  "Paw Paw Tunnel",
                  "Denail National Park",
                  "Golden Gate Bridge",
                  "Black Canyon of the Gunnison",
                  "Drayton Hall"
              ]
          }
      ],
      "capital": "Baton Rouge",
      "flower": {
          "name": "Magnolia",
          "imgLink": "https://pixabay.com/photos/magnolia-flower-spring-nature-5088974/",
          "imgLink2": "https://pixabay.com/photos/magnolia-magnolia-tree-blooming-4109847/",
          "wikiLink": "https://en.wikipedia.org/wiki/Magnolia"
      },
      "nickname": "Pelican State/Creole State/Sugar State"
  },
  {
      "name": "Massachusetts",
      "code": "MA",
      "lat": 42.407211,
      "long": -71.382437,
      "cities": [
          "Salem",
          "Cambridge",
          "Springfield",
          "Boston",
          "Worcester",
          "Dover"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Boston Common",
                  "photographer": "Ogutier",
                  "imageLink": "https://pixabay.com/photos/boston-back-bay-massachusetts-5000729/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Boston_Common"
              },
              "landmark": {
                  "name": "Old State House",
                  "photographer": "tball",
                  "imageLink": "https://pixabay.com/photos/old-state-house-boston-city-6736711/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_State_House_(Boston)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Salem",
                  "photographer": "Massachusetts Department of Environmental Protection",
                  "imageLink": "https://en.wikipedia.org/wiki/Salem,_Massachusetts",
                  "wikiLink": "https://en.wikipedia.org/wiki/Salem,_Massachusetts"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 5
                  },
                  {
                      "code": "CT",
                      "number": 3
                  },
                  {
                      "code": "MI",
                      "number": 1
                  }
              ],
              "uniqueId": "Sn2Y9wts",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Ocean State",
                  "Golden State",
                  "Volunteer State",
                  "Cornhusker State/Beef State",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Among the famous writers who are from this state include Emily Dickinson, E.E. Cummings, and Sylvia Plath.",
                  "falseFact": "Only one president, Barack Obama, is from this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawaiian Hibiscus",
                  "Orange Blossom",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "The Mark Twain House",
                  "John Dickinson Plantation",
                  "Sandia Peak Tramway",
                  "Multnomah Falls",
                  "Cheaha Mountain",
                  "Thorncrown Chapel",
                  "Flume Gorge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Boston",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/boston-massachusetts-404526/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Boston"
              },
              "landmark": {
                  "name": "USS Constitution",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/charlestown-massachusetts-91431/",
                  "wikiLink": "https://en.wikipedia.org/wiki/USS_Constitution",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cambridge",
                  "photographer": "Eric Kilby",
                  "imageLink": "https://en.wikipedia.org/wiki/Cambridge,_Massachusetts",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cambridge,_Massachusetts"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 5
                  },
                  {
                      "code": "MN",
                      "number": 1
                  }
              ],
              "uniqueId": "CDdtL54N",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Treasure State/Big Sky Country",
                  "Natural State",
                  "Beehive State",
                  "Silver State/Sagebrush State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Thanksgiving was first celebrated in Massachusetts.",
                  "falseFact": "One town in this state has banned celebration of Easter festivities after a bad salmonella outbreak from eggs in 1973.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Forget-me-not",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Ark Encounter",
                  "West Virginia Penitentiary",
                  "Cedar Point",
                  "Baltimore Basilica",
                  "Fort Abraham Lincoln State Park",
                  "Fenwick Island Lighthouse",
                  "Church Street Marketplace"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Faneuil Hall",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/boston-massachusetts-faneuil-hall-1631460/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Faneuil_Hall"
              },
              "landmark": {
                  "name": "Paul Revere House",
                  "photographer": "Beyond My Ken",
                  "imageLink": "https://en.wikipedia.org/wiki/Paul_Revere_House#/media/File:2017_Paul_Revere_House_from_east.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Paul_Revere_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Springfield",
                  "photographer": "John Phelan",
                  "imageLink": "https://en.wikipedia.org/wiki/Springfield,_Massachusetts",
                  "wikiLink": "https://en.wikipedia.org/wiki/Springfield,_Massachusetts"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 5
                  },
                  {
                      "code": "FL",
                      "number": 2
                  },
                  {
                      "code": "WV",
                      "number": 2
                  }
              ],
              "uniqueId": "XVTw2plW",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Garden State",
                  "Aloha State",
                  "Empire State",
                  "Silver State/Sagebrush State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Pilgrims finitially landed in this state.",
                  "falseFact": "The biggest city in this state sits below sea level, meaning barriers have been erected to keep the land from flooding.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Hawaiian Hibiscus",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "Fort Smith National Historic Site",
                  "A Christmas Story House",
                  "Denail National Park",
                  "Carlsbad Caverns",
                  "Louisville Water Tower",
                  "The Greenbrier",
                  "Waimea Canyon"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Southborough",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/snow-sunlight-trees-field-5733380/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Southborough,_Massachusetts"
              },
              "landmark": {
                  "name": "Bunker Hill Monument",
                  "photographer": "Brandon Turner",
                  "imageLink": "https://www.flickr.com/photos/brandonturner/8506247898",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bunker_Hill_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Boston",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Boston",
                  "wikiLink": "https://en.wikipedia.org/wiki/Boston"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 2
                  }
              ],
              "uniqueId": "ZLQQYK8V",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "The Mount Rushmore State",
                  "Hoosier State",
                  "Sunshine State",
                  "Gem State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The oldest and perhaps most prestiguous university in the United States, _______, can be found in this state.",
                  "fillAnswer": "Harvard University",
                  "fillAnswers": [
                      "Yale University",
                      "Princeton University",
                      "Columbia University",
                      "Harvard University"
                  ]
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Violet",
                  "Sego Lily"
              ],
              "landmarkPlaces": [
                  "Thunder Hole",
                  "Hearst Castle",
                  "TransAmerica Pyramid",
                  "San Antonio River Walk",
                  "Fort Griswold",
                  "Temple Square",
                  "Pike Place Market"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Gloucester",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/lighthouse-gloucester-massachusetts-7485712/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Gloucester,_Massachusetts"
              },
              "landmark": {
                  "name": "The Witch House at Salem",
                  "photographer": "Jen",
                  "imageLink": "https://en.wikipedia.org/wiki/The_Witch_House#/media/File:The_Witch_House.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Witch_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Worcester",
                  "photographer": "Viking1943",
                  "imageLink": "https://en.wikipedia.org/wiki/Worcester,_Massachusetts",
                  "wikiLink": "https://en.wikipedia.org/wiki/Worcester,_Massachusetts"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 3
                  },
                  {
                      "code": "MT",
                      "number": 3
                  },
                  {
                      "code": "UT",
                      "number": 1
                  }
              ],
              "uniqueId": "5Dh5ppdy",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Gem State",
                  "Tar Heel State",
                  "Sunflower State/Jayhawker State",
                  "Hoosier State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Alexander Graham Bell showed off his prototype of the _____ for the first time in Boston.",
                  "fillAnswer": "Telephone",
                  "fillAnswers": [
                      "Monograph",
                      "Telegraph",
                      "Radio",
                      "Telephone"
                  ]
              },
              "flowerOptions": [
                  "Violet",
                  "Rose",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Knife River Indian Villages",
                  "Fort Adams State Park",
                  "Ark Encounter",
                  "Scotts Bluff",
                  "Akaka Falls",
                  "Tryon Palace",
                  "Dinosaur Park"
              ]
          }
      ],
      "capital": "Boston",
      "flower": {
          "name": "Mayflower",
          "imgLink": "https://pixabay.com/photos/may-flower-bush-mayflower-blossoms-4166586/",
          "imgLink2": "https://pixabay.com/photos/may-flower-bush-mayflower-blossoms-4166586/",
          "wikiLink": "https://en.wikipedia.org/wiki/Epigaea_repens"
      },
      "nickname": "Bay State/Old Colony State"
  },
  {
      "name": "Maryland",
      "code": "MD",
      "lat": 39.045755,
      "long": -76.641271,
      "cities": [
          "Baltimore",
          "Annapolis",
          "Frederick",
          "Columbia",
          "Silver Spring",
          "New Haven"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Ocean City",
                  "photographer": "Drngogo",
                  "imageLink": "https://en.wikipedia.org/wiki/Ocean_City%2C_Maryland#/media/File:Ocean_City_Maryland_USA_2018-07-14.png",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ocean_City%2C_Maryland"
              },
              "landmark": {
                  "name": "Fort McHenry",
                  "photographer": "Bruce Emmerling",
                  "imageLink": "https://pixabay.com/photos/fort-mchenry-baltimore-history-237363/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_McHenry",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Baltimore",
                  "photographer": "BruceEmmerling",
                  "imageLink": "https://en.wikipedia.org/wiki/Baltimore",
                  "wikiLink": "https://en.wikipedia.org/wiki/Baltimore"
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 3
                  },
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "ID",
                      "number": 1
                  }
              ],
              "uniqueId": "kqTQxZWl",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Keystone State",
                  "Flickertail State/Sioux State",
                  "Cornhusker State/Beef State",
                  "Wolverine State/Great Lake State",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Alligators have occasionally been spotted in the Chesapeake Bay.",
                  "falseFact": "Because of its small population, this state has only 2 US Representatives and 2 Senators in the United States Congress.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Iris",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Battleship Memorial Park",
                  "Dinosaur National Monument",
                  "Sleeping Bear Dunes",
                  "Churchill Downs",
                  "High Roller",
                  "Indianapolis Catacombs",
                  "Black Canyon of the Gunnison"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Antietam National Battlefield",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/antietam-maryland-140390/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Antietam_National_Battlefield"
              },
              "landmark": {
                  "name": "Federal Hill Park",
                  "photographer": "Iracaz",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:FederalHillPark.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Federal_Hill_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Annapolis",
                  "photographer": "BruceEmmerling",
                  "imageLink": "https://en.wikipedia.org/wiki/Annapolis,_Maryland",
                  "wikiLink": "https://en.wikipedia.org/wiki/Annapolis,_Maryland"
              },
              "landmarksRound": [
                  {
                      "code": "MI",
                      "number": 5
                  },
                  {
                      "code": "AL",
                      "number": 4
                  },
                  {
                      "code": "NE",
                      "number": 1
                  }
              ],
              "uniqueId": "YgPPdr7j",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "Keystone State",
                  "Treasure State/Big Sky Country",
                  "Buckeye State",
                  "Nutmeg State/Constitution State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Maryland is home to the oldest continuously published newspaper in America.",
                  "falseFact": "Radiators were invented in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "California Poppy",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Pemaquid Point Lighthouse",
                  "Maquoketa Caves",
                  "Eastern State Penitentiary",
                  "Sawtooth National Forest",
                  "Minidoka National Historic Site",
                  "Castillo San Felipe Del Morro",
                  "Reed Gold Mine"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Baltimore",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/baltimore-harbor-bay-tourism-4027194/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Baltimore"
              },
              "landmark": {
                  "name": "Baltimore Basilica",
                  "photographer": "Bobindrums",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Baltimore_Basilica,_Baltimore,_Maryland.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Basilica_of_the_National_Shrine_of_the_Assumption_of_the_Blessed_Virgin_Mary",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Frederick",
                  "photographer": "DowntownFrederick",
                  "imageLink": "https://en.wikipedia.org/wiki/Frederick,_Maryland",
                  "wikiLink": "https://en.wikipedia.org/wiki/Frederick,_Maryland"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 3
                  }
              ],
              "uniqueId": "4t5b7yll",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Buckeye State",
                  "Wolverine State/Great Lake State",
                  "Pine Tree State",
                  "Gem State",
                  "Garden State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is sometimes called \"America in Miniature\" because of its range of geography.",
                  "falseFact": "Baltimore, in this state, is the sailing capital of the world.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Wood Violet",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "Flume Gorge",
                  "Vulcan Statue",
                  "Gateway Arch",
                  "Independence Rock",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Golden Gate Bridge",
                  "The Witch House at Salem"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Annapolis",
                  "photographer": "evantoddmedia",
                  "imageLink": "https://pixabay.com/photos/bridge-water-river-city-4800118/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Annapolis,_Maryland"
              },
              "landmark": {
                  "name": "Paw Paw Tunnel",
                  "photographer": "Nicolas Raymond",
                  "imageLink": "https://en.wikipedia.org/wiki/Paw_Paw_Tunnel#/media/File:Paw_Paw_Tunnel_-_HDR_(21296984493).jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Paw_Paw_Tunnel",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Columbia",
                  "photographer": "Payton Chung",
                  "imageLink": "https://en.wikipedia.org/wiki/Columbia,_Maryland",
                  "wikiLink": "https://en.wikipedia.org/wiki/Columbia,_Maryland"
              },
              "landmarksRound": [
                  {
                      "code": "OK",
                      "number": 3
                  },
                  {
                      "code": "DC",
                      "number": 5
                  },
                  {
                      "code": "DC",
                      "number": 1
                  }
              ],
              "uniqueId": "X8SVQdX8",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Aloha State",
                  "Cornhusker State/Beef State",
                  "The Mount Rushmore State",
                  "Sooner State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The official team sport of Maryland is _____.",
                  "fillAnswer": "Lacrosse",
                  "fillAnswers": [
                      "Lacrosse",
                      "Soccer",
                      "Football",
                      "Basketball"
                  ]
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Pasque Flower",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "La Fortaleza",
                  "Fontainebleau State Park",
                  "Haystack Rock",
                  "Beale Street",
                  "Arlington National Cemetery",
                  "The Elms",
                  "Bellagio Fountain"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Billy Goat Trail",
                  "photographer": "WikimediaImages",
                  "imageLink": "https://pixabay.com/photos/billy-goat-trail-purplehorse-beach-910219/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Billy_Goat_Trail"
              },
              "landmark": {
                  "name": "Oriole Park",
                  "photographer": "Carol Highsmith",
                  "imageLink": "https://picryl.com/media/aerial-camden-yards-stadium-baltimore-maryland-1",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oriole_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Silver Spring",
                  "photographer": "Farragutful",
                  "imageLink": "https://en.wikipedia.org/wiki/Silver_Spring,_Maryland",
                  "wikiLink": "https://en.wikipedia.org/wiki/Silver_Spring,_Maryland"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 4
                  },
                  {
                      "code": "TN",
                      "number": 5
                  },
                  {
                      "code": "CO",
                      "number": 1
                  }
              ],
              "uniqueId": "8sPDXnXJ",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Green Mountain State",
                  "Aloha State",
                  "Flickertail State/Sioux State",
                  "Equality State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Some of the land that now makes up _______ used to be part of Maryland.",
                  "fillAnswer": "Washington D.C.",
                  "fillAnswers": [
                      "North Carolina",
                      "Virginia",
                      "Washington D.C.",
                      "New York City"
                  ]
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Sego Lily",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "Shrine of the Grotto of the Redemption",
                  "Red River Gorge",
                  "Natural Bridge Caverns",
                  "Golden Driller Statue",
                  "Buffalo Bill Ranch",
                  "Louisville Water Tower",
                  "The Alamo"
              ]
          }
      ],
      "capital": "Annapolis",
      "flower": {
          "name": "Black-eyed Susan",
          "imgLink": "https://pixabay.com/photos/rudbeckia-flower-yellow-nature-661244/",
          "imgLink2": "https://pixabay.com/photos/rudbeckia-black-eyed-susan-flower-3576379/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rudbeckia_hirta"
      },
      "nickname": "Free State/Old Line State"
  },
  {
      "name": "Maine",
      "code": "ME",
      "lat": 45.253783,
      "long": -69.445469,
      "cities": [
          "Bangor",
          "Portland",
          "Augusta",
          "Lewiston",
          "Biddeford",
          "Raleigh"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Nubble Lighthouse",
                  "photographer": "JackBulmer",
                  "imageLink": "https://pixabay.com/photos/nubble-lighthouse-twilight-maine-7853491/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cape_Neddick_Light"
              },
              "landmark": {
                  "name": "Portland Head Light",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/portland-head-light-lighthouse-5539153/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Portland_Head_Light",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bangor",
                  "photographer": "David Wilson",
                  "imageLink": "https://en.wikipedia.org/wiki/Bangor,_Maine",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bangor,_Maine"
              },
              "landmarksRound": [
                  {
                      "code": "AZ",
                      "number": 4
                  },
                  {
                      "code": "PA",
                      "number": 4
                  },
                  {
                      "code": "DE",
                      "number": 5
                  }
              ],
              "uniqueId": "qYBn59YM",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Centennial State",
                  "Ocean State",
                  "Silver State/Sagebrush State",
                  "The Old Dominion/Mother of Presidents",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Clothing company L.L. Bean was founded in this state.",
                  "falseFact": "One county in this state has only three official residents.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "Mock Orange",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "Martin Luther King Jr. Childhood Home",
                  "Saint-Gaudens National Historic Park",
                  "Reed Gold Mine",
                  "Fort Christian",
                  "The Elms",
                  "Little River Canyon National Preserve",
                  "Cathedral Caverns State Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Coastal Maine Botanical Gardens",
                  "photographer": "Daderot",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Little_pond_Coastal_Maine_Botanical_Gardens_-_DSC03163.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Coastal_Maine_Botanical_Gardens"
              },
              "landmark": {
                  "name": "Acadia National Park",
                  "photographer": "ArtTower",
                  "imageLink": "https://pixabay.com/photos/acadia-national-park-massachusetts-62097/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Acadia_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Portland",
                  "photographer": "photosforyou",
                  "imageLink": "https://en.wikipedia.org/wiki/Portland,_Maine",
                  "wikiLink": "https://en.wikipedia.org/wiki/Portland,_Maine"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 5
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 2
                  }
              ],
              "uniqueId": "d67jGxH7",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "The Last Frontier",
                  "Mountain State",
                  "Golden State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The biggest island in this state, Mount Desert Island, contains Acadia National Park.",
                  "falseFact": "This state is the northernmost U.S. state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Sagebrush",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "World's Largest Ball of Twine",
                  "Mount Vernon",
                  "Ark Encounter",
                  "Thorncrown Chapel",
                  "Sandy Hook Lighthouse",
                  "National Cowboy & Western Heritage Museum",
                  "Natural Bridge Caverns"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Casco Bay",
                  "photographer": "jlynne",
                  "imageLink": "https://pixabay.com/photos/casco-bay-portland-maine-454646/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Casco_Bay"
              },
              "landmark": {
                  "name": "Victoria Mansion",
                  "photographer": "Staib",
                  "imageLink": "https://en.wikipedia.org/wiki/Victoria_Mansion#/media/File:Victoria_Mansion,_Portland,_Maine_USA.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Victoria_Mansion",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Augusta",
                  "photographer": "AlexiusHoratius",
                  "imageLink": "https://en.wikipedia.org/wiki/Augusta,_Maine",
                  "wikiLink": "https://en.wikipedia.org/wiki/Augusta,_Maine"
              },
              "landmarksRound": [
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 1
                  }
              ],
              "uniqueId": "FYmRcbKX",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Green Mountain State",
                  "Prairie State/Land of Lincoln",
                  "Bluegrass State",
                  "Natural State",
                  "Equality State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Around 40 million pounds a year of lobster are harvested in this state. ",
                  "falseFact": "This state elected the first black governor in the country since Reconstruction in 1989.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mock Orange",
                  "Yellow Elder",
                  "Wild Rose"
              ],
              "landmarkPlaces": [
                  "Three Rivers",
                  "Tryon Palace",
                  "Pikes Peak State Park",
                  "Natural Bridge Caverns",
                  "The Mark Twain House",
                  "Taos Pueblo",
                  "Bob Kerrey Pedestrian Bridge"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Old Post Office, Augusta",
                  "photographer": "Kenneth C. Zirkel",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Old_Post_Office_Augusta_Maine_2013.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Augusta,_Maine"
              },
              "landmark": {
                  "name": "Pemaquid Point Lighthouse",
                  "photographer": "Tiger635",
                  "imageLink": "https://en.wikipedia.org/wiki/Pemaquid_Point_Light#/media/File:Pemaquid-lighthouse.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pemaquid_Point_Light",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lewiston",
                  "photographer": "Backtoreality07",
                  "imageLink": "https://en.wikipedia.org/wiki/Lewiston,_Maine",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lewiston,_Maine"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 5
                  },
                  {
                      "code": "IA",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 2
                  }
              ],
              "uniqueId": "bhls3rzq",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "The District",
                  "Hoosier State",
                  "Island of Enchantment",
                  "Show Me State",
                  "Evergreen State/Chinhook State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Some ____% of U.S. blueberries are produced in Maine.",
                  "fillAnswer": "99",
                  "fillAnswers": [
                      "15",
                      "40",
                      "63",
                      "99"
                  ]
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Mayflower",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Pikes Peak State Park",
                  "Cave of the Mounds",
                  "The Breakers",
                  "Churchill Downs",
                  "Space Needle",
                  "Monument Rocks",
                  "John Dickinson Plantation"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Portland",
                  "photographer": "wjeffcrawford",
                  "imageLink": "https://pixabay.com/photos/maine-portland-coast-shoreline-3401937/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Portland,_Maine"
              },
              "landmark": {
                  "name": "Thunder Hole",
                  "photographer": "Famartin",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:2017-07-27_08_23_15_View_south-southeast_across_Thunder_Hole_within_Acadia_National_Park,_in_Bar_Harbor,_Hancock_County,_Maine.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Acadia_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Biddeford",
                  "photographer": "Tomasso",
                  "imageLink": "https://en.wikipedia.org/wiki/Biddeford,_Maine",
                  "wikiLink": "https://en.wikipedia.org/wiki/Biddeford,_Maine"
              },
              "landmarksRound": [
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "ID",
                      "number": 1
                  }
              ],
              "uniqueId": "SpWj7XpK",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "Green Mountain State",
                  "Peace State/Empire State of the South",
                  "Keystone State",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "If you're a fan of sweet breakfast foods, you'll like Maine, where _____ were invented.",
                  "fillAnswer": "Donut holes",
                  "fillAnswers": [
                      "Pancakes",
                      "Omelets",
                      "Donut holes",
                      "French toast sticks"
                  ]
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Wild Prairie Rose",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "Natural Bridge Caverns",
                  "Seven Magic Mountains",
                  "Sandy Hook Lighthouse",
                  "Delicate Arch",
                  "The House on the Rock",
                  "Hanauma Bay",
                  "Eastern State Penitentiary"
              ]
          }
      ],
      "capital": "Augusta",
      "flower": {
          "name": "White Pine Cone And Tassel",
          "imgLink": "https://pixabay.com/photos/cones-pine-tree-winter-snow-frost-7668034/",
          "imgLink2": "https://pixabay.com/photos/cone-pine-forest-winter-snow-cold-7717662/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pinus_strobus"
      },
      "nickname": "Pine Tree State"
  },
  {
      "name": "Michigan",
      "code": "MI",
      "lat": 44.314844,
      "long": -85.602364,
      "cities": [
          "Lansing",
          "Detroit",
          "Grand Rapids",
          "Ann Arbor",
          "Flint",
          "Charleston"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Mackinaw Bridge",
                  "photographer": "jasongillman",
                  "imageLink": "https://pixabay.com/photos/mackinac-bridge-northern-lights-1642445/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mackinac_Bridge"
              },
              "landmark": {
                  "name": "Sleeping Bear Dunes",
                  "photographer": "JulesKeeper777",
                  "imageLink": "https://pixabay.com/photos/lake-coast-sand-water-nature-6196650/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sleeping_Bear_Dunes_National_Lakeshore",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lansing",
                  "photographer": "cameonaton",
                  "imageLink": "https://en.wikipedia.org/wiki/Lansing,_Michigan",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lansing,_Michigan"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "TN",
                      "number": 4
                  },
                  {
                      "code": "DE",
                      "number": 1
                  }
              ],
              "uniqueId": "tLhmLHpg",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Show Me State",
                  "Hawkeye State/Corn State",
                  "Island of Enchantment",
                  "Green Mountain State",
                  "Sunshine State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Battle Creek Michigan is the ____ Capital of the World.",
                  "fillAnswer": "Cereal",
                  "fillAnswers": [
                      "Apple",
                      "Cereal",
                      "Wheat",
                      "Lacrosse"
                  ]
              },
              "flowerOptions": [
                  "Pasque Flower",
                  "Mayflower",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Pictograph Cave State Park",
                  "Martin Luther King Jr. Memorial",
                  "Akaka Falls",
                  "Cathedral Caverns State Park",
                  "Amana Colonies",
                  "Knife River Indian Villages",
                  "Marble House"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "University of Michigan Law Quad",
                  "photographer": "jeffwilcox",
                  "imageLink": "https://en.wikipedia.org/wiki/University_of_Michigan#/media/File:Umichlawquadrangle.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/University_of_Michigan"
              },
              "landmark": {
                  "name": "Pictured Rocks",
                  "photographer": "jjgunn",
                  "imageLink": "https://pixabay.com/photos/michigan-lake-superior-5761856/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pictured_Rocks_National_Lakeshore",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Detroit",
                  "photographer": "arthurpalac",
                  "imageLink": "https://en.wikipedia.org/wiki/Detroit",
                  "wikiLink": "https://en.wikipedia.org/wiki/Detroit"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 2
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "FL",
                      "number": 1
                  }
              ],
              "uniqueId": "T6Rz3wHk",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Centennial State",
                  "Land of Enchantment",
                  "The Old Dominion/Mother of Presidents",
                  "First State/Diamond State",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Michigan once went to war with Ohio over land dubbed the Toledo Strip.",
                  "falseFact": "This state borders Pennsylvania.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Iris",
                  "Rose",
                  "Peach Blossom"
              ],
              "landmarkPlaces": [
                  "Old Idaho Penitentiary",
                  "Little Rock Central High School",
                  "Joshua Tree National Park",
                  "Fort Laramie",
                  "Akaka Falls",
                  "Saint-Gaudens National Historic Park",
                  "Minneapolis Sculpture Garden"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Crisp Point Lighthouse",
                  "photographer": "csbonawitz",
                  "imageLink": "https://pixabay.com/photos/crisp-point-lighthouse-lighthouse-4025268/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Crisp_Point_Light"
              },
              "landmark": {
                  "name": "Isle Royale",
                  "photographer": "Ray Dumas",
                  "imageLink": "https://www.flickr.com/photos/rtdphotography/3047464978",
                  "wikiLink": "https://en.wikipedia.org/wiki/Isle_Royale_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Grand Rapids",
                  "photographer": "Rachel Kramer",
                  "imageLink": "https://en.wikipedia.org/wiki/Grand_Rapids,_Michigan",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Rapids,_Michigan"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 4
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 1
                  }
              ],
              "uniqueId": "VsfWD8nT",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "Magnolia State",
                  "Tar Heel State",
                  "Mountain State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "One island in this state, Mackinac Island, has banned all cars to maintain a historic and calm atmosphere.",
                  "falseFact": "The Upper Peninsula is barren of all natural resources except for timber.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Sego Lily",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "Old Faithful",
                  "Fort Adams State Park",
                  "Wynwood Walls",
                  "Taos Pueblo",
                  "Basilica of the Sacred Heart",
                  "The Keeper of the Plains",
                  "Cheaha Mountain"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Detroit",
                  "photographer": "PhotosbyMahin",
                  "imageLink": "https://pixabay.com/photos/detroit-downtown-city-architecture-1819179/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Detroit"
              },
              "landmark": {
                  "name": "Mackinaw Island",
                  "photographer": "wnk1029",
                  "imageLink": "https://pixabay.com/photos/mackinaw-mackinac-island-michigan-2650154/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mackinac_Island",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Ann Arbor",
                  "photographer": "maksmuno",
                  "imageLink": "https://en.wikipedia.org/wiki/Ann_Arbor,_Michigan",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ann_Arbor,_Michigan"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "UT",
                      "number": 3
                  },
                  {
                      "code": "NC",
                      "number": 1
                  }
              ],
              "uniqueId": "CRxnGrGY",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Cotton State/Yellowhammer State",
                  "Land of Enchantment",
                  "Natural State",
                  "Beaver State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This is the only state with two peninsulas.",
                  "falseFact": "This state borders all five of the Great Lakes.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Saguaro Cactus Blossom",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "Martin Luther King Jr. Memorial",
                  "Portland Head Light",
                  "Eastern State Penitentiary",
                  "John Brown's Fort",
                  "Mount Vernon",
                  "Fort Union Trading Post",
                  "Taliesin West"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Tahquamenon Falls",
                  "photographer": "jasongillman",
                  "imageLink": "https://pixabay.com/photos/tahquamenon-falls-waterfall-scenic-3584788/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tahquamenon_Falls"
              },
              "landmark": {
                  "name": "Arch Rock",
                  "photographer": "CateeBee",
                  "imageLink": "https://pixabay.com/photos/arch-rock-michigan-nature-2349902/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Arch_Rock_(Mackinac_Island)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Flint",
                  "photographer": "Flintmichigan",
                  "imageLink": "https://en.wikipedia.org/wiki/Flint,_Michigan",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flint,_Michigan"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 3
                  },
                  {
                      "code": "ND",
                      "number": 1
                  },
                  {
                      "code": "ID",
                      "number": 2
                  }
              ],
              "uniqueId": "KlL9TtKd",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Gem State",
                  "Pelican State/Creole State/Sugar State",
                  "Ocean State",
                  "The District"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The _____ Bridge in this state is sometimes called the eighth wonder of the world as its the longest suspension bridge in the Americans.",
                  "fillAnswer": "Mackinac",
                  "fillAnswers": [
                      "Golden Gate",
                      "International",
                      "Brooklyn",
                      "Mackinac"
                  ]
              },
              "flowerOptions": [
                  "Orange Blossom",
                  "Oregon Grape",
                  "White Pine Cone And Tassel"
              ],
              "landmarkPlaces": [
                  "Carlsbad Caverns",
                  "West Virginia Penitentiary",
                  "Centennial Land Run Monument",
                  "Fort Worden",
                  "Elephant Rocks State Park",
                  "Thorncrown Chapel",
                  "Cloud Gate"
              ]
          }
      ],
      "capital": "Lansing",
      "flower": {
          "name": "Apple Blossom",
          "imgLink": "https://pixabay.com/photos/apple-blossom-blossom-bloom-white-116409/",
          "imgLink2": "https://pixabay.com/photos/spring-pistil-wood-flower-1360468/",
          "wikiLink": "https://en.wikipedia.org/wiki/Malus"
      },
      "nickname": "Wolverine State/Great Lake State"
  },
  {
      "name": "Minnesota",
      "code": "MN",
      "lat": 46.729553,
      "long": -94.6859,
      "cities": [
          "Duluth",
          "Saint Paul",
          "Bloomington",
          "Rochester",
          "Minneapolis",
          "Dallas"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Voyageurs National Park",
                  "photographer": "Goodfreephotos_com",
                  "imageLink": "https://pixabay.com/photos/voyageurs-national-park-usa-347378/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Voyageurs_National_Park"
              },
              "landmark": {
                  "name": "Fort Snelling",
                  "photographer": "Tony Webster",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Historic_Fort_Snelling,_Minnesota_%2842343595262%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Snelling",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Duluth",
                  "photographer": "Tony Webster",
                  "imageLink": "https://en.wikipedia.org/wiki/Duluth,_Minnesota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Duluth,_Minnesota"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "TX",
                      "number": 4
                  }
              ],
              "uniqueId": "bcrPbrqQ",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Cotton State/Yellowhammer State",
                  "The Mount Rushmore State",
                  "Buckeye State",
                  "Pelican State/Creole State/Sugar State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The _____ River begins in this state.",
                  "fillAnswer": "Mississippi",
                  "fillAnswers": [
                      "Rio Grande",
                      "Mississippi",
                      "Yukon",
                      "Colorado"
                  ]
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "Yellow Jessamine",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Shrine of the Grotto of the Redemption",
                  "West Virginia Penitentiary",
                  "Vulcan Statue",
                  "Rock & Roll Hall of Fame",
                  "Little River Canyon National Preserve",
                  "Red River Gorge",
                  "Cadillac Ranch"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Duluth",
                  "photographer": "JamesDeMers",
                  "imageLink": "https://pixabay.com/photos/duluth-minnesota-waterfront-170208/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Duluth,_Minnesota"
              },
              "landmark": {
                  "name": "Minneapolis Sculpture Garden",
                  "photographer": "--",
                  "imageLink": "https://pxhere.com/en/photo/563570",
                  "wikiLink": "https://en.wikipedia.org/wiki/Minneapolis_Sculpture_Garden",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Saint Paul",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Saint_Paul,_Minnesota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Saint_Paul,_Minnesota"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  }
              ],
              "uniqueId": "ffpxnlVs",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Aloha State",
                  "Bluegrass State",
                  "Natural State",
                  "Hawkeye State/Corn State",
                  "Garden State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Mall of America can be found in this state.",
                  "falseFact": "Mount Rushmore was built here in 1927.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Yellow Jessamine",
                  "Camellia",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Blanchard Springs Cavern",
                  "Coral Castle",
                  "Red River Gorge",
                  "Ark Encounter",
                  "Grand Teton",
                  "John Dickinson Plantation",
                  "Disney World"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Split Rock Lighthouse",
                  "photographer": "lasseholsthansen",
                  "imageLink": "https://pixabay.com/photos/usa-lighthouse-coast-building-sky-4871731/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Split_Rock_Lighthouse"
              },
              "landmark": {
                  "name": "Mall of America",
                  "photographer": "Jeremy Noble",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Mall_of_America-2005-05-29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mall_of_America",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bloomington",
                  "photographer": "Gabriel Vanslette",
                  "imageLink": "https://en.wikipedia.org/wiki/Bloomington,_Minnesota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bloomington,_Minnesota"
              },
              "landmarksRound": [
                  {
                      "code": "NH",
                      "number": 3
                  },
                  {
                      "code": "LA",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 5
                  }
              ],
              "uniqueId": "YCg2Tb6L",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Keystone State",
                  "Gem State",
                  "Island of Enchantment",
                  "Lone Star State",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state joined the union in 1985.",
                  "falseFact": "The average low temperature in this state in January is -15 degrees Farenheit.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Black-eyed Susan",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "Carhenge",
                  "Field of Dreams",
                  "Stonington Harbour Light",
                  "Cloud Gate",
                  "Taos Pueblo",
                  "Soldiers & Sailors Monument",
                  "Federal Hill Park"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Carleton College",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/carleton-college-northfield-142708/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carleton_College"
              },
              "landmark": {
                  "name": "Cathedral of St. Paul",
                  "photographer": "Raghu Nandan L",
                  "imageLink": "https://en.wikipedia.org/wiki/Cathedral_of_Saint_Paul_(Minnesota)#/media/File:St.Paul_0432.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cathedral_of_Saint_Paul_(Minnesota)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rochester",
                  "photographer": "Jonathunder",
                  "imageLink": "https://en.wikipedia.org/wiki/Rochester,_Minnesota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rochester,_Minnesota"
              },
              "landmarksRound": [
                  {
                      "code": "WY",
                      "number": 3
                  },
                  {
                      "code": "NC",
                      "number": 1
                  },
                  {
                      "code": "AR",
                      "number": 1
                  }
              ],
              "uniqueId": "qDrXJbKd",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Empire State",
                  "Lone Star State",
                  "Beehive State",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Popular shopping center and grocery store ____ started in this state.",
                  "fillAnswer": "Target",
                  "fillAnswers": [
                      "Dollar Tree",
                      "Target",
                      "K-Mart",
                      "Walmart"
                  ]
              },
              "flowerOptions": [
                  "Yellow Jessamine",
                  "Yellow Elder",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "Field of Dreams",
                  "Monticello",
                  "Arlington National Cemetery",
                  "Denail National Park",
                  "Grand Ole Opry",
                  "The Venetian",
                  "Carlsbad Caverns"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Minneapolis",
                  "photographer": "noah210",
                  "imageLink": "https://pixabay.com/photos/snow-winter-frost-frozen-cold-3320917/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Minneapolis"
              },
              "landmark": {
                  "name": "Aerial Lift Bridge",
                  "photographer": "Captainspock89",
                  "imageLink": "https://www.flickr.com/photos/44534236@N00/15737169840",
                  "wikiLink": "https://en.wikipedia.org/wiki/Aerial_Lift_Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Minneapolis",
                  "photographer": "Stocksnap",
                  "imageLink": "https://en.wikipedia.org/wiki/Minneapolis",
                  "wikiLink": "https://en.wikipedia.org/wiki/Minneapolis"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 5
                  },
                  {
                      "code": "PA",
                      "number": 2
                  },
                  {
                      "code": "CA",
                      "number": 5
                  }
              ],
              "uniqueId": "RwNFTf5s",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Centennial State",
                  "Equality State",
                  "Hawkeye State/Corn State",
                  "Beehive State",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "One of this state's nicknames is the Land of 10,000 Lakes.",
                  "falseFact": "The Minnesota state treek is the mighty oak.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Pasque Flower",
                  "Bitterroot",
                  "Camellia"
              ],
              "landmarkPlaces": [
                  "Warner Theatre",
                  "The Mark Twain House",
                  "Biltmore",
                  "Thunder Hole",
                  "Dollywood",
                  "Rocky Statue",
                  "Taliesin East"
              ]
          }
      ],
      "capital": "Saint Paul",
      "flower": {
          "name": "Pink And White Lady's Slipper",
          "imgLink": "https://en.wikipedia.org/wiki/File:Cypripedium_reginae_MLA.jpg",
          "imgLink2": "https://commons.wikimedia.org/wiki/File:Showy_Lady%27s_Slipper_-_Minnesota_State_Flower_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cypripedium_reginae"
      },
      "nickname": "North Star State/Gopher State"
  },
  {
      "name": "Missouri",
      "code": "MO",
      "lat": 37.964253,
      "long": -91.831833,
      "cities": [
          "Kansas City",
          "Jefferson City",
          "St Louis",
          "Springfield",
          "Columbia",
          "Dallas"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Forest Park",
                  "photographer": "karlnorling",
                  "imageLink": "https://www.flickr.com/photos/karlnorling/46160344202",
                  "wikiLink": "https://en.wikipedia.org/wiki/Forest_Park_(St._Louis)"
              },
              "landmark": {
                  "name": "Gateway Arch",
                  "photographer": "mddawdy",
                  "imageLink": "https://pixabay.com/photos/st-louis-st-louis-arch-illinois-996718/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Gateway_Arch",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Kansas City",
                  "photographer": "webherper",
                  "imageLink": "https://en.wikipedia.org/wiki/Kansas_City,_Missouri",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kansas_City,_Missouri"
              },
              "landmarksRound": [
                  {
                      "code": "TX",
                      "number": 5
                  },
                  {
                      "code": "FL",
                      "number": 2
                  },
                  {
                      "code": "CT",
                      "number": 1
                  }
              ],
              "uniqueId": "YHStcndZ",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Treasure State/Big Sky Country",
                  "Bluegrass State",
                  "Cornhusker State/Beef State",
                  "Beaver State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Missouri has its own holiday: Missouri Day, which takes place in October.",
                  "falseFact": "Missouri did not become a state until after the Civil War.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Rose",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Basilica of the Sacred Heart",
                  "Diamond Head",
                  "Starved Rock State Park",
                  "Cathedral Caverns State Park",
                  "Field of Dreams",
                  "Isle Royale",
                  "West Virginia Penitentiary"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Jefferson City",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/jefferson-city-missouri-capitol-91210/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jefferson_City,_Missouri"
              },
              "landmark": {
                  "name": "Mark Twain Boyhood Home",
                  "photographer": "Jonathunder",
                  "imageLink": "https://en.wikipedia.org/wiki/Mark_Twain_Boyhood_Home_%26_Museum#/media/File:TwainBoyhoodHomeMuseum.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mark_Twain_Boyhood_Home_%26_Museum",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Jefferson City",
                  "photographer": "Gvolk",
                  "imageLink": "https://en.wikipedia.org/wiki/Jefferson_City,_Missouri",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jefferson_City,_Missouri"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "SD",
                      "number": 3
                  }
              ],
              "uniqueId": "Q6slsqDR",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Pine Tree State",
                  "Sunshine State",
                  "The Last Frontier",
                  "North Star State/Gopher State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The official state bird of Missouri is the eastern bluebird.",
                  "falseFact": "The name 'Missouri' means 'Missing Home.'",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Oregon Grape",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Appalachian Trail",
                  "Centennial Land Run Monument",
                  "Pike Place Market",
                  "Bunker Hill Monument",
                  "San Jacinto Battleground",
                  "Dinosaur Park",
                  "National Cowboy & Western Heritage Museum"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Nelson-Atkins Museum of Art",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/atkins-art-museum-building-landmark-1619504/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nelson-Atkins_Museum_of_Art"
              },
              "landmark": {
                  "name": "Elephant Rocks State Park",
                  "photographer": "Fredlyfish4",
                  "imageLink": "https://en.wikipedia.org/wiki/Elephant_Rocks_State_Park#/media/File:The_Elephant_Rocks.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Elephant_Rocks_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "St Louis",
                  "photographer": "Goodfreephotos_com",
                  "imageLink": "https://en.wikipedia.org/wiki/St._Louis",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._Louis"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "AR",
                      "number": 1
                  }
              ],
              "uniqueId": "9ZfkCdR3",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Lone Star State",
                  "America’s Paradise",
                  "Evergreen State/Chinhook State",
                  "First State/Diamond State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The most famous landmark in this state is the __________.",
                  "fillAnswer": "Gateway Arch",
                  "fillAnswers": [
                      "Gateway Arch",
                      "Statue of Liberty",
                      "Cloudgate",
                      "Lincoln Memorial"
                  ]
              },
              "flowerOptions": [
                  "Yucca Flower",
                  "Magnolia",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "The Mark Twain House",
                  "Beale Street",
                  "Fallingwater",
                  "Old Idaho Penitentiary",
                  "SkyWheel Myrtle Beach",
                  "Fort Mandan State Historic Site",
                  "Strataca"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Gateway Arch",
                  "photographer": "clarencealford",
                  "imageLink": "https://pixabay.com/photos/arch-symbol-landmark-architecture-6859127/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Gateway_Arch"
              },
              "landmark": {
                  "name": "Busch Stadium",
                  "photographer": "jradams",
                  "imageLink": "https://pixabay.com/photos/busch-stadium-baseball-city-6238191/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Busch_Stadium",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Springfield",
                  "photographer": "Diedrichb",
                  "imageLink": "https://en.wikipedia.org/wiki/Springfield,_Missouri",
                  "wikiLink": "https://en.wikipedia.org/wiki/Springfield,_Missouri"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 4
                  },
                  {
                      "code": "VI",
                      "number": 5
                  },
                  {
                      "code": "OH",
                      "number": 5
                  }
              ],
              "uniqueId": "vvrJRGyQ",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Ocean State",
                  "Land of Enchantment",
                  "Beaver State",
                  "Cornhusker State/Beef State",
                  "The District"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Major world event ______ took place in St. Louis, Missouri in 1904.",
                  "fillAnswer": "The World's Fair",
                  "fillAnswers": [
                      "The Queen's Jubilee",
                      "The Olympics",
                      "The World's Fair",
                      "The Presidential Inauguration"
                  ]
              },
              "flowerOptions": [
                  "Yellow Elder",
                  "White Pine Cone And Tassel",
                  "Cherokee Rose"
              ],
              "landmarkPlaces": [
                  "Fort Snelling",
                  "Church Street Marketplace",
                  "Pictured Rocks",
                  "Monticello",
                  "The Mark Twain House",
                  "Lake Champlain",
                  "Victoria Mansion"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Cathedral Basilica of Saint Louis",
                  "photographer": "Farragutful",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Cathedral_Basilica_of_St._Louis_01.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cathedral_Basilica_of_Saint_Louis_(St._Louis)"
              },
              "landmark": {
                  "name": "Missouri Botanical Garden",
                  "photographer": "Foodfreephotos_com",
                  "imageLink": "https://pixabay.com/photos/climatron-botanical-garden-missouri-347390/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Missouri_Botanical_Garden",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Columbia",
                  "photographer": "mgattorna",
                  "imageLink": "https://en.wikipedia.org/wiki/Columbia,_Missouri",
                  "wikiLink": "https://en.wikipedia.org/wiki/Columbia,_Missouri"
              },
              "landmarksRound": [
                  {
                      "code": "KY",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  }
              ],
              "uniqueId": "NCCKzCyn",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Peace State/Empire State of the South",
                  "Island of Enchantment",
                  "Gem State",
                  "Sunflower State/Jayhawker State",
                  "Hawkeye State/Corn State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Poet Maya Angelou is from Missouri.",
                  "falseFact": "The biggest university in this state is Washington University in St. Louis.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Peach Blossom",
                  "Saguaro Cactus Blossom",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Castillo San Felipe Del Morro",
                  "Mount Washington",
                  "West Virginia Penitentiary",
                  "Fort Griswold",
                  "Lucy the Elephant",
                  "Seven Magic Mountains",
                  "Elvis Presley Birthplace"
              ]
          }
      ],
      "capital": "Jefferson City",
      "flower": {
          "name": "Hawthorn",
          "imgLink": "https://pixabay.com/photos/hawthorn-flowers-spring-branch-6854620/",
          "imgLink2": "https://pixabay.com/photos/hawthorn-biel-spring-april-cereal-5092234/",
          "wikiLink": "https://en.wikipedia.org/wiki/Crataegus"
      },
      "nickname": "Show Me State"
  },
  {
      "name": "Mississippi",
      "code": "MS",
      "lat": 32.354668,
      "long": -89.398528,
      "cities": [
          "Biloxi",
          "Tupelo",
          "Jackson",
          "Gulfport",
          "Hattiesburg",
          "Lawton"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Red Bluff",
                  "photographer": "IcknieldRideway",
                  "imageLink": "https://en.wikipedia.org/wiki/Red_Bluff_(Mississippi_landmark)#/media/File:Red_Bluff.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_Bluff_(Mississippi_landmark)"
              },
              "landmark": {
                  "name": "Mississippi Petrified Forest",
                  "photographer": "faungg's photos",
                  "imageLink": "https://www.flickr.com/photos/44534236@N00/15737169840",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mississippi_Petrified_Forest",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Biloxi",
                  "photographer": "Zach123abc",
                  "imageLink": "https://en.wikipedia.org/wiki/Biloxi,_Mississippi",
                  "wikiLink": "https://en.wikipedia.org/wiki/Biloxi,_Mississippi"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "RI",
                      "number": 1
                  }
              ],
              "uniqueId": "vdvhyvLW",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Bluegrass State",
                  "Pelican State/Creole State/Sugar State",
                  "Volunteer State",
                  "First State/Diamond State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is the poorest U.S. state.",
                  "falseFact": "Mississippi has only been hit by 7 tornadoes in its history.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sunflower",
                  "Peony",
                  "Wild Prairie Rose"
              ],
              "landmarkPlaces": [
                  "Minneapolis Sculpture Garden",
                  "Brooklyn Bridge",
                  "Olbrich Botanical Gardens",
                  "Yale University",
                  "Ford's Theatre",
                  "Pikes Peak State Park",
                  "Tryon Palace"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Jackson",
                  "photographer": "NatalieMaynor",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:JacksonMS_CapitolSt.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jackson,_Mississippi"
              },
              "landmark": {
                  "name": "Elvis Presley Birthplace",
                  "photographer": "Ken Lund",
                  "imageLink": "https://www.flickr.com/photos/kenlund/6120216845",
                  "wikiLink": "https://en.wikipedia.org/wiki/Elvis_Presley_Birthplace",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tupelo",
                  "photographer": "Fredlyfish4",
                  "imageLink": "https://en.wikipedia.org/wiki/Tupelo,_Mississippi",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tupelo,_Mississippi"
              },
              "landmarksRound": [
                  {
                      "code": "SD",
                      "number": 5
                  },
                  {
                      "code": "IL",
                      "number": 2
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "qmrfz9cZ",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Mountain State",
                  "Grand Canyon State",
                  "Aloha State",
                  "Free State/Old Line State",
                  "The District"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first human heart transplant took place in this state.",
                  "falseFact": "Coca-Cola was first sold in Jackson, Mississippi.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mock Orange",
                  "American Dogwood",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Bryce Canyon",
                  "Cape Hatteras Lighthouse",
                  "Iolani Palace",
                  "Craters of the Moon",
                  "Museum of the Rockies",
                  "Sawtooth National Forest",
                  "Fort Christian"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Mammy's Cupboard",
                  "photographer": "Carol M. Highsmith",
                  "imageLink": "https://en.wikipedia.org/wiki/Mammy%27s_Cupboard#/media/File:Mammy's_Cupboard_Restaurant,_Natchez,_Mississippi,_by_Carol_M._Highsmith.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mammy%27s_Cupboard"
              },
              "landmark": {
                  "name": "Old Mississippi State Capitol",
                  "photographer": "Matt Howry",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Old_Mississippi_Capitol_Building_in_Jackson_Mississippi.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Mississippi_State_Capitol",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Jackson",
                  "photographer": "chemeredith",
                  "imageLink": "https://en.wikipedia.org/wiki/Jackson,_Mississippi",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jackson,_Mississippi"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 3
                  },
                  {
                      "code": "ME",
                      "number": 5
                  },
                  {
                      "code": "NM",
                      "number": 5
                  }
              ],
              "uniqueId": "hJVh54bs",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Beaver State",
                  "Equality State",
                  "Palmetto State",
                  "Granite State",
                  "Prairie State/Land of Lincoln"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The state motto of this state is in French, reading Virtute et Armis.",
                  "falseFact": "This state was the last to secede from the Union and join the Confederacy during the Civil War.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Iris",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Lincoln Memorial",
                  "Forsynth Park",
                  "Elephant Rocks State Park",
                  "Sandy Hook Lighthouse",
                  "Fremont Troll",
                  "The Mark Twain House",
                  "Mackinaw Island"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Vicksburg Bridge",
                  "photographer": "Damian Entwhistle",
                  "imageLink": "https://en.wikipedia.org/wiki/Mississippi#/media/File:Vicksburg_bridge_over_the_mississippi_morning-edit.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vicksburg_Bridge"
              },
              "landmark": {
                  "name": "Tupelo National Battlefield",
                  "photographer": "Ken Lund",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Tupelo_National_Battlefield,_Tupelo,_Mississippi_%286120781236%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tupelo_National_Battlefield",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Gulfport",
                  "photographer": "WeaponzingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Gulfport,_Mississippi",
                  "wikiLink": "https://en.wikipedia.org/wiki/Gulfport,_Mississippi"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 1
                  },
                  {
                      "code": "DE",
                      "number": 1
                  }
              ],
              "uniqueId": "pwHVLf5j",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "Lone Star State",
                  "America’s Paradise",
                  "Prairie State/Land of Lincoln",
                  "Flickertail State/Sioux State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Rockstar _______ was born in this state.",
                  "fillAnswer": "Elvis Presley",
                  "fillAnswers": [
                      "Elvis Presley",
                      "Dolly Parton",
                      "Jon Bon Jovi",
                      "Prince"
                  ]
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Apple Blossom",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "Eastern State Penitentiary",
                  "Luray Caverns",
                  "Delicate Arch",
                  "Shoshone Falls",
                  "Disney World",
                  "Fort McHenry",
                  "Golden Gate Bridge"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Ross Barnett Reservoir",
                  "photographer": "Eskimo.the",
                  "imageLink": "https://en.wikipedia.org/wiki/Mississippi#/media/File:Ross_Barnett_Reservoir_sunset_picture.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ross_Barnett_Reservoir"
              },
              "landmark": {
                  "name": "USS Cairo",
                  "photographer": "Ken Lund",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:USS_Cairo_at_the_Vicksburg_National_Military_Park.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/USS_Cairo",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Hattiesburg",
                  "photographer": "Woodlot",
                  "imageLink": "https://en.wikipedia.org/wiki/Hattiesburg,_Mississippi",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hattiesburg,_Mississippi"
              },
              "landmarksRound": [
                  {
                      "code": "UT",
                      "number": 3
                  },
                  {
                      "code": "NC",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  }
              ],
              "uniqueId": "BW862hTb",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Pine Tree State",
                  "The Old Dominion/Mother of Presidents",
                  "Cotton State/Yellowhammer State",
                  "The Last Frontier",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The world's only ____ plantation can be found in Mississippi.",
                  "fillAnswer": "Cactus",
                  "fillAnswers": [
                      "Cotton",
                      "Beaver",
                      "Sugar",
                      "Cactus"
                  ]
              },
              "flowerOptions": [
                  "Peach Blossom",
                  "Violet",
                  "Wild Prairie Rose"
              ],
              "landmarkPlaces": [
                  "Fort Frederick",
                  "Mall of America",
                  "Fallingwater",
                  "World's Largest Ball of Twine",
                  "Shoshone Falls",
                  "The World's Only Corn Palace",
                  "Joshua Tree National Park"
              ]
          }
      ],
      "capital": "Jackson",
      "flower": {
          "name": "Magnolia",
          "imgLink": "https://pixabay.com/photos/blossom-bloom-magnolia-3827769/",
          "imgLink2": "https://pixabay.com/photos/magnolia-macro-white-nature-2691296/",
          "wikiLink": "https://en.wikipedia.org/wiki/Magnolia"
      },
      "nickname": "Magnolia State"
  },
  {
      "name": "Montana",
      "code": "MT",
      "lat": 46.879682,
      "long": -110.362566,
      "cities": [
          "Bozeman",
          "Helena",
          "Billings",
          "Missoula",
          "Great Falls",
          "Danbury"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Glacier National Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/lake-mcdonald-glacier-national-park-1874263/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Glacier_National_Park_(U.S.)"
              },
              "landmark": {
                  "name": "Avalanche Lake",
                  "photographer": "CarinaChen",
                  "imageLink": "https://pixabay.com/photos/america-usa-montana-glacier-2191494/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Avalanche_Lake_(Flathead_County,_Montana)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bozeman",
                  "photographer": "AndreCarrotflower",
                  "imageLink": "https://en.wikipedia.org/wiki/Bozeman,_Montana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bozeman,_Montana"
              },
              "landmarksRound": [
                  {
                      "code": "ME",
                      "number": 1
                  },
                  {
                      "code": "WY",
                      "number": 4
                  },
                  {
                      "code": "LA",
                      "number": 1
                  }
              ],
              "uniqueId": "vbG4C4J9",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Palmetto State",
                  "Beaver State",
                  "Beehive State",
                  "Grand Canyon State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The motto of this state translates to \"gold and silver.\"",
                  "falseFact": "This is the largest of the 48 contiguous United States,",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "American Dogwood",
                  "Black-eyed Susan"
              ],
              "landmarkPlaces": [
                  "Indianapolis Catacombs",
                  "Lincoln Memorial",
                  "Fort Larned",
                  "The Witch House at Salem",
                  "Liberty Bell",
                  "Martin Luther King Jr. Memorial",
                  "Natural Bridge Caverns"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Lake McDonald",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/lake-mcdonald-glacier-national-park-1733307/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lake_McDonald"
              },
              "landmark": {
                  "name": "Little Bighorn Battlefield",
                  "photographer": "1025wil",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Little_Bighorn_Battlefield_National_Monument.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Little_Bighorn_Battlefield_National_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Helena",
                  "photographer": "Goodfreephotos_com",
                  "imageLink": "https://en.wikipedia.org/wiki/Helena,_Montana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Helena,_Montana"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 4
                  },
                  {
                      "code": "ND",
                      "number": 1
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "6Jnzwrjx",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Aloha State",
                  "Land of Enchantment",
                  "Sunshine State",
                  "Flickertail State/Sioux State",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The state animal of Montana is the grizzly bear.",
                  "falseFact": "This state has high sales taxes, at 10% for each sale.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Forget-me-not",
                  "Wild Rose",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Napoleon House",
                  "Old Faithful",
                  "Fort Christian",
                  "Haystack Rock",
                  "Bourbon Street",
                  "Harding Icefield",
                  "Dover Motor Speedway"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Flathead National Forest",
                  "photographer": "US Forest Service",
                  "imageLink": "https://www.flickr.com/photos/forestservicenw/27331488244",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flathead_National_Forest"
              },
              "landmark": {
                  "name": "Pictograph Cave State Park",
                  "photographer": "Tbennert",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Pictograph_Cave,_Billings,_Montana.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pictograph_Cave_(Billings,_Montana)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Billings",
                  "photographer": "Sara goth",
                  "imageLink": "https://en.wikipedia.org/wiki/Billings,_Montana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Billings,_Montana"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 5
                  },
                  {
                      "code": "ME",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  }
              ],
              "uniqueId": "vdGJ2Vcd",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Grand Canyon State",
                  "First State/Diamond State",
                  "Pine Tree State",
                  "The Old Dominion/Mother of Presidents",
                  "Natural State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "In this state, ordering a drink \"ditch\" means a drink with _____.",
                  "fillAnswer": "Water",
                  "fillAnswers": [
                      "Extra ice",
                      "Milk",
                      "Water",
                      "An extra shot"
                  ]
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Bluebonnet",
                  "Red Clover"
              ],
              "landmarkPlaces": [
                  "USS Constitution",
                  "Fremont Troll",
                  "Old Idaho Penitentiary",
                  "Lake Champlain",
                  "Elvis Presley Birthplace",
                  "Pico El Yunque",
                  "Bandelier National Monument"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Makoshika State Park",
                  "photographer": "The Turkducken",
                  "imageLink": "https://www.flickr.com/photos/turducken/42991642245",
                  "wikiLink": "https://en.wikipedia.org/wiki/Makoshika_State_Park"
              },
              "landmark": {
                  "name": "Museum of the Rockies",
                  "photographer": "Burley Packwood",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Museum_Of_The_Rockies_Montana3.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Museum_of_the_Rockies",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Missoula",
                  "photographer": "Prizrak 2084",
                  "imageLink": "https://en.wikipedia.org/wiki/Missoula,_Montana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Missoula,_Montana"
              },
              "landmarksRound": [
                  {
                      "code": "NH",
                      "number": 5
                  },
                  {
                      "code": "TX",
                      "number": 3
                  },
                  {
                      "code": "MA",
                      "number": 5
                  }
              ],
              "uniqueId": "T2wLDrQq",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "The District",
                  "Nutmeg State/Constitution State",
                  "Flickertail State/Sioux State",
                  "First State/Diamond State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Montana has a big livestock business: in this state, there are twice as many _____ as hunans.",
                  "fillAnswer": "Cows",
                  "fillAnswers": [
                      "Buffalo",
                      "Cows",
                      "Sheep",
                      "Bears"
                  ]
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "Flowering Dogwood",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "Three Rivers",
                  "Liberty Bell",
                  "Black Canyon of the Gunnison",
                  "La Fortaleza",
                  "Klondike Gold Rush National Historic Park",
                  "West Virginia Penitentiary",
                  "USS Albacore"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Garnet Ghost Town",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/garnet-ghost-town-montana-abandoned-3943047/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Garnet,_Montana"
              },
              "landmark": {
                  "name": "Fort Union Trading Post",
                  "photographer": "Xerxes2004",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Union_Trading_Post_National_Historic_Site#/media/File:Fort_Union_Trading_Post_NHS.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Union_Trading_Post_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Great Falls",
                  "photographer": "Ryan Villines",
                  "imageLink": "https://en.wikipedia.org/wiki/Great_Falls,_Montana",
                  "wikiLink": "https://en.wikipedia.org/wiki/Great_Falls,_Montana"
              },
              "landmarksRound": [
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "ID",
                      "number": 4
                  }
              ],
              "uniqueId": "Kb5gMCN2",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Gem State",
                  "Nutmeg State/Constitution State",
                  "Hoosier State",
                  "Golden State",
                  "Grand Canyon State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has only one area code -- 406 -- for all phones in the state.",
                  "falseFact": "Over 85% of this state's population lives in rural areas.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Wood Violet",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Denail National Park",
                  "Seven Magic Mountains",
                  "Mammoth Cave",
                  "Coral Castle",
                  "San Xavier del Bac Mission",
                  "Meteor Crater",
                  "Dollywood"
              ]
          }
      ],
      "capital": "Helena",
      "flower": {
          "name": "Bitterroot",
          "imgLink": "https://pixabay.com/photos/flower-wildflower-nature-peaceful-2373000/",
          "imgLink2": "https://pixabay.com/photos/flower-wildflower-nature-peaceful-2372987/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bitterroot"
      },
      "nickname": "Treasure State/Big Sky Country"
  },
  {
      "name": "North Carolina",
      "code": "NC",
      "lat": 35.759573,
      "long": -79.0193,
      "cities": [
          "Raleigh",
          "Durham",
          "Charlotte",
          "Asheville",
          "Greensboro",
          "Austin"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Tryon Palace",
                  "photographer": "Carol M. Highsmith",
                  "imageLink": "https://en.wikipedia.org/wiki/Tryon_Palace#/media/File:Tryon_Palace,_North_Carolina's_First_Colonial_Capital,_New_Bern_LCCN2011631094.tif",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tryon_Palace"
              },
              "landmark": {
                  "name": "Biltmore",
                  "photographer": "ghannen",
                  "imageLink": "https://pixabay.com/photos/biltmore-estates-ashville-1041309/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Biltmore_Estate",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Raleigh",
                  "photographer": "MarkThomas",
                  "imageLink": "https://en.wikipedia.org/wiki/Raleigh,_North_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Raleigh,_North_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 5
                  },
                  {
                      "code": "ME",
                      "number": 3
                  },
                  {
                      "code": "KS",
                      "number": 1
                  }
              ],
              "uniqueId": "8PsfBrPm",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Gem State",
                  "Lone Star State",
                  "Volunteer State",
                  "Land of Enchantment",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Outer Banks in this state are sometimes called the \"Graveyard of the Atlantic\".",
                  "falseFact": "The Venus Flytrap was introduced to this state in the early 1920s and soon became an invasive species.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Violet",
                  "Red Clover",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "Hot Springs National Park",
                  "Knife River Indian Villages",
                  "Fort Delaware",
                  "TransAmerica Pyramid",
                  "Blanchard Springs Cavern",
                  "Sawtooth National Forest",
                  "Battleship Memorial Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Chimney Rock State Park",
                  "photographer": "Domenico Covertini",
                  "imageLink": "https://www.flickr.com/photos/con4tini/31117353455",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chimney_Rock_State_Park"
              },
              "landmark": {
                  "name": "Cape Hatteras Lighthouse",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/cape-hatteras-lighthouse-landmark-4000164/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cape_Hatteras_Lighthouse",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Durham",
                  "photographer": "John Parsons Jr.",
                  "imageLink": "https://en.wikipedia.org/wiki/Durham,_North_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Durham,_North_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 1
                  }
              ],
              "uniqueId": "tfF8QB4R",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Magnolia State",
                  "Beaver State",
                  "Golden State",
                  "Sunflower State/Jayhawker State",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "_____, the first English settlement in the United States, was in modern-day North Carolina.",
                  "fillAnswer": "Roanoke",
                  "fillAnswers": [
                      "Jamestown",
                      "Roanoke",
                      "Plymouth",
                      "Georginia"
                  ]
              },
              "flowerOptions": [
                  "Scarlet Carnation",
                  "Yucca Flower",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "Falls Park on the Reedy",
                  "USS Cairo",
                  "Starved Rock State Park",
                  "Paul Revere House",
                  "Lake Champlain",
                  "Fort Laramie",
                  "Little Bighorn Battlefield"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Bodie Island Lighthouse",
                  "photographer": "t1cox",
                  "imageLink": "https://pixabay.com/photos/lighthouse-bodie-island-668225/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bodie_Island_Lighthouse"
              },
              "landmark": {
                  "name": "Wright Brothers National Memorial",
                  "photographer": "Kip Robinson",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Wright_Brothers_National_Memorial,_Kitty_Hawk,_North_Carolina,_USA.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wright_Brothers_National_Memorial",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Charlotte",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Charlotte,_North_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charlotte,_North_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 3
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  }
              ],
              "uniqueId": "CT8s9Mc5",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Show Me State",
                  "Green Mountain State",
                  "Land of Enchantment",
                  "Sunshine State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to multiple mountain ranges, including the Appalachian Mountains, the Greaty Smoky Mountains, and the Blue Ridge Mountains.",
                  "falseFact": "This state is the nation's biggest apple producer.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "Violet",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "John Brown's Fort",
                  "Bunker Hill Monument",
                  "Acadia National Park",
                  "George Washington Bridge",
                  "Rock & Roll Hall of Fame",
                  "Sandia Peak Tramway",
                  "Amicalola Falls"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Governor's Mansion, Raleigh",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/governor-s-mansion-raleigh-398716/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Raleigh,_North_Carolina"
              },
              "landmark": {
                  "name": "Reed Gold Mine",
                  "photographer": "Gus Jewell",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Entrance_to_an_underground_mine_at_the_Reed_Gold_Mine.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Reed_Gold_Mine",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Asheville",
                  "photographer": "Michael Tracy",
                  "imageLink": "https://en.wikipedia.org/wiki/Asheville,_North_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Asheville,_North_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 4
                  },
                  {
                      "code": "VT",
                      "number": 5
                  },
                  {
                      "code": "NJ",
                      "number": 4
                  }
              ],
              "uniqueId": "kqKTzhtb",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "Peace State/Empire State of the South",
                  "Free State/Old Line State",
                  "The District",
                  "Granite State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Among the Native American tribes that originally lived in this state include the Tuscarora, Catawba, and Cherokee tribes.",
                  "falseFact": "This state contains the smallest harbor in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Yellow Jessamine",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "Mall of America",
                  "Victoria Mansion",
                  "Waimea Canyon",
                  "Golden Driller Statue",
                  "Church Street Marketplace",
                  "Ellis Island",
                  "St. Michael the Archangel Orthodox Cathedral"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Fontana Dam",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/fontana-dam-north-carolina-lake-415847/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fontana_Dam"
              },
              "landmark": {
                  "name": "Tryon Palace",
                  "photographer": "gardener41",
                  "imageLink": "https://www.flickr.com/photos/gardener41/2074430946",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tryon_Palace",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Greensboro",
                  "photographer": "rgareyvmi",
                  "imageLink": "https://en.wikipedia.org/wiki/Greensboro,_North_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Greensboro,_North_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 5
                  },
                  {
                      "code": "ND",
                      "number": 3
                  }
              ],
              "uniqueId": "9ccTzJVr",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Island of Enchantment",
                  "Ocean State",
                  "Prairie State/Land of Lincoln",
                  "Empire State",
                  "First State/Diamond State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "In its early years, this state had a major problen with ____, even being visited by the famous Blackbeard.",
                  "fillAnswer": "Piracy",
                  "fillAnswers": [
                      "Diplomacy",
                      "Warmongering",
                      "Crime",
                      "Piracy"
                  ]
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Purple Lilac",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "Sandy Hook Lighthouse",
                  "Soldiers & Sailors Monument",
                  "Dinosaur National Monument",
                  "San Jacinto Battleground",
                  "Strataca",
                  "Washington National Cathedral",
                  "Minidoka National Historic Site"
              ]
          }
      ],
      "capital": "Raleigh",
      "flower": {
          "name": "Flowering Dogwood",
          "imgLink": "https://pixabay.com/photos/dogwood-flowers-branch-5801211/",
          "imgLink2": "https://pixabay.com/photos/flowering-dogwood-cornus-florida-7149830/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cornus_florida"
      },
      "nickname": "Tar Heel State"
  },
  {
      "name": "North Dakota",
      "code": "ND",
      "lat": 47.551493,
      "long": -101.002012,
      "cities": [
          "Fargo",
          "Bismarck",
          "Minot",
          "Grand Forks",
          "Williston",
          "Miami"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Scandinavian Heritage Association",
                  "photographer": "Bobak Ha'Eri",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:2009-0521-ND-Minot-ScanHerPark-StaveChurch.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Scandinavian_Heritage_Park"
              },
              "landmark": {
                  "name": "Theodore Roosevelt National Park",
                  "photographer": "GPoulsen",
                  "imageLink": "https://pixabay.com/photos/theodore-roosevelt-national-park-6683230/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Theodore_Roosevelt_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fargo",
                  "photographer": "Jonathan Geiger",
                  "imageLink": "https://en.wikipedia.org/wiki/Fargo,_North_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fargo,_North_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 5
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "QfWKdQh3",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Equality State",
                  "Badger State/America's Dairyland",
                  "Evergreen State/Chinhook State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has less forested land than any other state.",
                  "falseFact": "This state's official bird is the chickadee.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Goldenrod",
                  "Flowering Dogwood",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Seven Magic Mountains",
                  "Castillo de San Marcos",
                  "Wynwood Walls",
                  "USS Albacore",
                  "Craters of the Moon",
                  "Lake Champlain",
                  "Nemours Estate"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Cathedral of St. Mary, Fargo",
                  "photographer": "Farragutful",
                  "imageLink": "https://en.wikipedia.org/wiki/Fargo,_North_Dakota#/media/File:Cathedral_of_St._Mary_-_Fargo,_North_Dakota_03.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fargo,_North_Dakota"
              },
              "landmark": {
                  "name": "Fort Abraham Lincoln State Park",
                  "photographer": "MatthewUND",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Abraham_Lincoln#/media/File:CusterHouse.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Abraham_Lincoln",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bismarck",
                  "photographer": "RJA1988",
                  "imageLink": "https://en.wikipedia.org/wiki/Bismarck,_North_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bismarck,_North_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "LA",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "Sr5LnQlk",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Golden State",
                  "Aloha State",
                  "Garden State",
                  "The District",
                  "Keystone State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "In the Sioux language, Dakota means ________.",
                  "fillAnswer": "Friend",
                  "fillAnswers": [
                      "Vicious",
                      "Friend",
                      "Welcoming",
                      "Snowy"
                  ]
              },
              "flowerOptions": [
                  "Yucca Flower",
                  "Mountain Laurel",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "Paul Revere House",
                  "Hanauma Bay",
                  "The Mark Twain House",
                  "Diamond Head",
                  "Alcatraz Island",
                  "Black Canyon of the Gunnison",
                  "Fontainebleau State Park"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Theodore Roosevelt National Park",
                  "photographer": "Sam Kal",
                  "imageLink": "https://www.publicdomainpictures.net/en/view-image.php?image=442448&picture=theodore-roosevelt-national-park",
                  "wikiLink": "https://en.wikipedia.org/wiki/Theodore_Roosevelt_National_Park"
              },
              "landmark": {
                  "name": "Fort Mandan State Historic Site",
                  "photographer": "Gooseterrain2",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:The_reconstruction_of_Fort_Mandan_in_winter.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Mandan",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Minot",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Minot,_North_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Minot,_North_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "VI",
                      "number": 5
                  }
              ],
              "uniqueId": "pcWwrBXv",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Lone Star State",
                  "Green Mountain State",
                  "Sooner State",
                  "Volunteer State",
                  "Hawkeye State/Corn State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is about the same size as the southeast Asian nation of Cambodia.",
                  "falseFact": "This state is experiencing a population boom, with its population rising by more than 7% between 2020 and 2021.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Red Clover",
                  "Rose",
                  "Pink And White Lady's Slipper"
              ],
              "landmarkPlaces": [
                  "Gateway Arch",
                  "Hot Springs National Park",
                  "Mississippi Petrified Forest",
                  "Marble House",
                  "Old State Capitol",
                  "Pictograph Cave State Park",
                  "Grand Ole Opry"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Bismarck",
                  "photographer": "Andrew Filler",
                  "imageLink": "https://www.flickr.com/photos/afiler/3824814133",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bismarck,_North_Dakota"
              },
              "landmark": {
                  "name": "Knife River Indian Villages",
                  "photographer": "National Parks Gallery",
                  "imageLink": "https://picryl.com/media/knife-river-indian-villages-national-historic-site-50ad78",
                  "wikiLink": "https://en.wikipedia.org/wiki/Knife_River_Indian_Villages_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Grand Forks",
                  "photographer": "Brenda Riskey",
                  "imageLink": "https://en.wikipedia.org/wiki/Grand_Forks,_North_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Forks,_North_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 5
                  },
                  {
                      "code": "DC",
                      "number": 5
                  },
                  {
                      "code": "MT",
                      "number": 3
                  }
              ],
              "uniqueId": "xkBNXcsf",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Badger State/America's Dairyland",
                  "Nutmeg State/Constitution State",
                  "Land of Enchantment",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Major national park, ____________, is in North Dakota.",
                  "fillAnswer": "Theodore Roosevelt National Park",
                  "fillAnswers": [
                      "Everglades National Park",
                      "Acadia National Park",
                      "Joshua Tree National Park",
                      "Theodore Roosevelt National Park"
                  ]
              },
              "flowerOptions": [
                  "Magnolia",
                  "Hawaiian Hibiscus",
                  "Peony"
              ],
              "landmarkPlaces": [
                  "Missouri Botanical Garden",
                  "The House on the Rock",
                  "Castillo de San Marcos",
                  "Beale Street",
                  "Fort Laramie",
                  "Santa Fe Plaza",
                  "Independence Rock"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Theodore Roosevelt National Park",
                  "photographer": "jmrockeman",
                  "imageLink": "https://pixabay.com/photos/stallion-wild-horse-mustang-2223054/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Theodore_Roosevelt_National_Park"
              },
              "landmark": {
                  "name": "International Peace Garden",
                  "photographer": "Ken Lund",
                  "imageLink": "https://www.flickr.com/photos/kenlund/43974263881",
                  "wikiLink": "https://en.wikipedia.org/wiki/International_Peace_Garden",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Williston",
                  "photographer": "Andrew Filer",
                  "imageLink": "https://en.wikipedia.org/wiki/Williston,_North_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Williston,_North_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 2
                  },
                  {
                      "code": "IL",
                      "number": 3
                  },
                  {
                      "code": "UT",
                      "number": 2
                  }
              ],
              "uniqueId": "gGFlvyy7",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Hoosier State",
                  "Wolverine State/Great Lake State",
                  "Nutmeg State/Constitution State",
                  "Centennial State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "If you want a scenic drive, visit this state, where a highway surrounded by sculptures called The Enchanted Highway can be found.",
                  "falseFact": "North Dakota became a state more than five years before the neighboring South Dakota.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Iris",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "The Mark Twain House",
                  "Dover Motor Speedway",
                  "Cloud Gate",
                  "Garden of the Gods",
                  "World's Largest Ball of Twine",
                  "Martin Luther King Jr. Memorial",
                  "Bryce Canyon"
              ]
          }
      ],
      "capital": "Bismarck",
      "flower": {
          "name": "Wild Prairie Rose",
          "imgLink": "https://pixabay.com/photos/prairie-rose-rose-flower-raindrops-6331887/",
          "imgLink2": "https://www.flickr.com/photos/usfwsmtnprairie/5278733070",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosa_arkansana"
      },
      "nickname": "Flickertail State/Sioux State"
  },
  {
      "name": "Nebraska",
      "code": "NE",
      "lat": 41.492537,
      "long": -99.901813,
      "cities": [
          "Lincoln",
          "Omaha",
          "Bellevue",
          "Fremont",
          "Nebraska City",
          "Carolina"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Chimney Rock",
                  "photographer": "Mike Tegas",
                  "imageLink": "https://en.wikipedia.org/wiki/Chimney_Rock_National_Historic_Site#/media/File:Chimney_Rock_NE.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chimney_Rock_National_Historic_Site"
              },
              "landmark": {
                  "name": "Carhenge",
                  "photographer": "RJA1988",
                  "imageLink": "https://pixabay.com/photos/carhenge-car-art-vintage-auto-4572353/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carhenge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lincoln",
                  "photographer": "Collinulness",
                  "imageLink": "https://en.wikipedia.org/wiki/Lincoln,_Nebraska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lincoln,_Nebraska"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 5
                  },
                  {
                      "code": "KS",
                      "number": 2
                  },
                  {
                      "code": "OH",
                      "number": 4
                  }
              ],
              "uniqueId": "Q5533psn",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Granite State",
                  "Hawkeye State/Corn State",
                  "Silver State/Sagebrush State",
                  "Sunshine State",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The largest indoor rainforest in the United States can be found in Omaha, Nebraska.",
                  "falseFact": "The sports bra was invented in this state when two jockstraps were sewn together.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "American Dogwood",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "Old Faithful",
                  "Gateway Arch",
                  "Eastern State Penitentiary",
                  "Grand Teton",
                  "Little Bighorn Battlefield",
                  "Hoover Dam",
                  "Golden Gate Bridge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Lincoln",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/lincoln-nebraska-capitol-building-1624100/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lincoln,_Nebraska"
              },
              "landmark": {
                  "name": "Chimney Rock",
                  "photographer": "Doug Kerr",
                  "imageLink": "https://www.flickr.com/photos/dougtone/14417776626",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chimney_Rock_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Omaha",
                  "photographer": "Bradley Weber",
                  "imageLink": "https://en.wikipedia.org/wiki/Omaha,_Nebraska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Omaha,_Nebraska"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 5
                  },
                  {
                      "code": "PA",
                      "number": 5
                  },
                  {
                      "code": "MN",
                      "number": 1
                  }
              ],
              "uniqueId": "dK8X53BM",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Cotton State/Yellowhammer State",
                  "Nutmeg State/Constitution State",
                  "Treasure State/Big Sky Country",
                  "Palmetto State",
                  "Free State/Old Line State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Though you might think the reuben is Eastern European, these delicious Russian-inspired lunch was actually created in Nebraska.",
                  "falseFact": "This state is home to a replica of famous landmark the Taj Mahal, made out of old tires.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Magnolia",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "Joshua Tree National Park",
                  "Fort Larned",
                  "Church Street Marketplace",
                  "USS Albacore",
                  "Iolani Palace",
                  "USS Constitution",
                  "Mount Washington Cog"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Valentine",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/valentine-nebraska-river-water-166746/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Valentine,_Nebraska"
              },
              "landmark": {
                  "name": "Buffalo Bill Ranch",
                  "photographer": "Don Graham",
                  "imageLink": "https://www.flickr.com/photos/23155134@N06/10784354753",
                  "wikiLink": "https://en.wikipedia.org/wiki/Buffalo_Bill_Ranch",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bellevue",
                  "photographer": "Code36",
                  "imageLink": "https://en.wikipedia.org/wiki/Bellevue,_Nebraska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bellevue,_Nebraska"
              },
              "landmarksRound": [
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "WI",
                      "number": 2
                  },
                  {
                      "code": "IN",
                      "number": 5
                  }
              ],
              "uniqueId": "vNR55Pkp",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Magnolia State",
                  "Sunshine State",
                  "North Star State/Gopher State",
                  "Beaver State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Around 91% of land in Nebraska is dedicated to ______.",
                  "fillAnswer": "Farmland",
                  "fillAnswers": [
                      "Farmland",
                      "National parks",
                      "Solar power",
                      "Cranberry bogs"
                  ]
              },
              "flowerOptions": [
                  "Orange Blossom",
                  "Bitterroot",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Fort Worden",
                  "Mount Hood",
                  "Cheaha Mountain",
                  "Paul Revere House",
                  "Victoria Mansion",
                  "West Virginia Penitentiary",
                  "Petrified Forest National Park"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "North Platte",
                  "photographer": "RJA1988",
                  "imageLink": "https://pixabay.com/photos/railroad-classification-yard-railroad-4578535/",
                  "wikiLink": "https://en.wikipedia.org/wiki/North_Platte,_Nebraska"
              },
              "landmark": {
                  "name": "Scotts Bluff",
                  "photographer": "Doug Kerr",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Scotts_Bluff_National_Monument_-_Nebraska_%2814440745565%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Scotts_Bluff_National_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fremont",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Fremont,_Nebraska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fremont,_Nebraska"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "GA",
                      "number": 4
                  },
                  {
                      "code": "SC",
                      "number": 4
                  }
              ],
              "uniqueId": "28W6VxcD",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "Ocean State",
                  "Beaver State",
                  "Tar Heel State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "National environmental holiday Arbor Day was started in this state.",
                  "falseFact": "Nebraska is the only U.S. state that does not contain any lighthouses.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Wood Violet",
                  "Yellow Elder"
              ],
              "landmarkPlaces": [
                  "Manitou Cliff Dwellings",
                  "Klondike Gold Rush National Historic Park",
                  "Little Bighorn Battlefield",
                  "Strataca",
                  "Santa Fe Plaza",
                  "Mount Rushmore",
                  "Fallingwater"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Joslyn Art Museum",
                  "photographer": "shannonpatrick17",
                  "imageLink": "https://www.flickr.com/photos/shannonpatrick17/3312942403",
                  "wikiLink": "https://en.wikipedia.org/wiki/Joslyn_Art_Museum"
              },
              "landmark": {
                  "name": "Bob Kerrey Pedestrian Bridge",
                  "photographer": "Andrew Seaman",
                  "imageLink": "https://www.flickr.com/photos/inthe-arena/40925676644",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bob_Kerrey_Pedestrian_Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Nebraska City",
                  "photographer": "Ammodramus",
                  "imageLink": "https://en.wikipedia.org/wiki/Nebraska_City,_Nebraska",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nebraska_City,_Nebraska"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 5
                  },
                  {
                      "code": "VT",
                      "number": 1
                  }
              ],
              "uniqueId": "cF7RYNFl",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "The Old Dominion/Mother of Presidents",
                  "Keystone State",
                  "Sunshine State",
                  "Lone Star State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The current capital of Nebraska is Lincoln, but the capital used to be _________.",
                  "fillAnswer": "Omaha",
                  "fillAnswers": [
                      "Kearney",
                      "Nebraska City",
                      "Omaha",
                      "St. Louis"
                  ]
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Scarlet Carnation",
                  "Camellia"
              ],
              "landmarkPlaces": [
                  "Castillo San Felipe Del Morro",
                  "Amicalola Falls",
                  "Martin Luther King Jr. Memorial",
                  "Grand Staircase",
                  "Sandia Peak Tramway",
                  "San Antonio River Walk",
                  "Gateway Arch"
              ]
          }
      ],
      "capital": "Lincoln",
      "flower": {
          "name": "Goldenrod",
          "imgLink": "https://pixabay.com/photos/hover-fly-insect-goldenrod-flowers-7146023/",
          "imgLink2": "https://pixabay.com/photos/giant-goldenrod-yellow-flowers-6607117/",
          "wikiLink": "https://en.wikipedia.org/wiki/Solidago_gigantea"
      },
      "nickname": "Cornhusker State/Beef State"
  },
  {
      "name": "New Hampshire",
      "code": "NH",
      "lat": 43.193852,
      "long": -71.572395,
      "cities": [
          "Concord",
          "Portsmouth",
          "Manchester",
          "Nashua",
          "Derry",
          "Tuscaloosa"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Franconia Notch State Park",
                  "photographer": "woodleywonderworks",
                  "imageLink": "https://www.flickr.com/photos/wwworks/3895258160",
                  "wikiLink": "https://en.wikipedia.org/wiki/Franconia_Notch_State_Park"
              },
              "landmark": {
                  "name": "Flume Gorge",
                  "photographer": "Quintin3265",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Flumegorge.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flume_Gorge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Concord",
                  "photographer": "John Phelan",
                  "imageLink": "https://en.wikipedia.org/wiki/Concord,_New_Hampshire",
                  "wikiLink": "https://en.wikipedia.org/wiki/Concord,_New_Hampshire"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 3
                  },
                  {
                      "code": "NY",
                      "number": 1
                  },
                  {
                      "code": "TN",
                      "number": 4
                  }
              ],
              "uniqueId": "LmLCLdb3",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "Peace State/Empire State of the South",
                  "Equality State",
                  "Flickertail State/Sioux State",
                  "Pelican State/Creole State/Sugar State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The motto of this state is \"Live Free or Die.\"",
                  "falseFact": "This state is said to be very similar to the European country of Italy.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Violet",
                  "Oregon Grape",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Arch Rock",
                  "Multnomah Falls",
                  "Marble House",
                  "Klondike Gold Rush National Historic Park",
                  "Sawtooth National Forest",
                  "John Dickinson Plantation",
                  "Arlington National Cemetery"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "White Mountains",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/white-mountains-new-hampshire-forest-1904297/",
                  "wikiLink": "https://en.wikipedia.org/wiki/White_Mountains_(New_Hampshire)"
              },
              "landmark": {
                  "name": "USS Albacore",
                  "photographer": "Dmoore5556",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:USS_Albacore_%282018%29_10.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/USS_Albacore_(AGSS-569)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Portsmouth",
                  "photographer": "meganzopf",
                  "imageLink": "https://en.wikipedia.org/wiki/Portsmouth,_New_Hampshire",
                  "wikiLink": "https://en.wikipedia.org/wiki/Portsmouth,_New_Hampshire"
              },
              "landmarksRound": [
                  {
                      "code": "KY",
                      "number": 3
                  },
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 3
                  }
              ],
              "uniqueId": "DLv3B2HV",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Evergreen State/Chinhook State",
                  "Empire State",
                  "Beaver State",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was the first to seceed from Great Britain.",
                  "falseFact": "The official state bird of New Hampshire is the ewastern bluebird.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "Sunflower",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Bob Kerrey Pedestrian Bridge",
                  "Maquoketa Caves",
                  "The Greenbrier",
                  "Alcatraz Island",
                  "Appalachian Trail",
                  "Craters of the Moon",
                  "Cathedral of St. Paul"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Cranmore Mountain",
                  "photographer": "Mount Washington Valley Chamber of Commerce",
                  "imageLink": "https://www.flickr.com/photos/mwvchamber/12642137975/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cranmore_Mountain_Resort"
              },
              "landmark": {
                  "name": "Saint-Gaudens National Historic Park",
                  "photographer": "Ser Amantio di Nicolao",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Saint-Gaudens_National_Historic_Site_-_Little_Studio_from_Aspet.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Saint-Gaudens_National_Historical_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Manchester",
                  "photographer": "rickpilot_2000",
                  "imageLink": "https://en.wikipedia.org/wiki/Manchester,_New_Hampshire",
                  "wikiLink": "https://en.wikipedia.org/wiki/Manchester,_New_Hampshire"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "WI",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 5
                  }
              ],
              "uniqueId": "pM7CBZbj",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Volunteer State",
                  "Sunshine State",
                  "Green Mountain State",
                  "Flickertail State/Sioux State",
                  "North Star State/Gopher State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The largest point in New Hampshire can be found atop Mount _______.",
                  "fillAnswer": "Washington",
                  "fillAnswers": [
                      "Washington",
                      "Rushmore",
                      "Whitney",
                      "Elbert"
                  ]
              },
              "flowerOptions": [
                  "Yellow Jessamine",
                  "Scarlet Carnation",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Missouri Botanical Garden",
                  "Cadillac Ranch",
                  "Forsynth Park",
                  "Annaberg Sugar Plantation",
                  "Church Street Marketplace",
                  "Beale Street",
                  "Lake Champlain"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Concord",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/lake-reflection-fall-autumn-bridge-6084527/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Concord,_New_Hampshire"
              },
              "landmark": {
                  "name": "Mount Washington Cog",
                  "photographer": "DavidWBrooks",
                  "imageLink": "https://en.wikipedia.org/wiki/Mount_Washington_Cog_Railway#/media/File:Cog_railway_NH_with_hiker,_2016.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Washington_Cog_Railway",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Nashua",
                  "photographer": "Jon Platek",
                  "imageLink": "https://en.wikipedia.org/wiki/Nashua,_New_Hampshire",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nashua,_New_Hampshire"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "MO",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 4
                  }
              ],
              "uniqueId": "5RPFDwTx",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "America’s Paradise",
                  "Natural State",
                  "Pelican State/Creole State/Sugar State",
                  "Empire State",
                  "Buckeye State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has an official freshwater game fish (the brook trout) and an official saltwater game fish (the striped bass).",
                  "falseFact": "Though there are a lot of rivers in New Hampshire, there are not very many lakes, and there are no lakes at all that are larger than 10 acres in size.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Beauty Rose",
                  "Cherokee Rose",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "Little Bighorn Battlefield",
                  "La Fortaleza",
                  "Castillo San Cristobal",
                  "Annaberg Sugar Plantation",
                  "Biltmore",
                  "Mark Twain Boyhood Home",
                  "Fort Snelling"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Ice Castles",
                  "photographer": "Eric Kilby",
                  "imageLink": "https://www.flickr.com/photos/ekilby/11941256763",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Hampshire"
              },
              "landmark": {
                  "name": "Mount Washington",
                  "photographer": "meganzopf",
                  "imageLink": "https://pixabay.com/photos/omni-mount-washington-resort-hotel-6705048/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Washington",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Derry",
                  "photographer": "John Phelan",
                  "imageLink": "https://en.wikipedia.org/wiki/Derry,_New_Hampshire",
                  "wikiLink": "https://en.wikipedia.org/wiki/Derry,_New_Hampshire"
              },
              "landmarksRound": [
                  {
                      "code": "TN",
                      "number": 3
                  },
                  {
                      "code": "TN",
                      "number": 4
                  },
                  {
                      "code": "DC",
                      "number": 5
                  }
              ],
              "uniqueId": "H3bqqQn8",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Badger State/America's Dairyland",
                  "Natural State",
                  "Sooner State",
                  "Treasure State/Big Sky Country",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "In 2021, this state decided to designate an official State _____.",
                  "fillAnswer": "Spider",
                  "fillAnswers": [
                      "Spice",
                      "Element",
                      "Spider",
                      "Predator"
                  ]
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "American Beauty Rose",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Maquoketa Caves",
                  "Bryce Canyon",
                  "Basilica of the Sacred Heart",
                  "World of Coca Cola",
                  "Luray Caverns",
                  "Warner Theatre",
                  "Hocking Hills State Park"
              ]
          }
      ],
      "capital": "Concord",
      "flower": {
          "name": "Purple Lilac",
          "imgLink": "https://pixabay.com/photos/lilac-insect-bug-scarab-2310561/",
          "imgLink2": "https://pixabay.com/photos/lilac-spring-summer-plant-bloom-1385989/",
          "wikiLink": "https://en.wikipedia.org/wiki/Syringa_vulgaris"
      },
      "nickname": "Granite State"
  },
  {
      "name": "New Jersey",
      "code": "NJ",
      "lat": 40.058324,
      "long": -74.405661,
      "cities": [
          "Newark",
          "Jersey City",
          "Atlantic City",
          "Trenton",
          "Princeton",
          "Bridgeport"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Jersey Shore",
                  "photographer": "jatocreate",
                  "imageLink": "https://pixabay.com/photos/beach-sunrise-wallpaper-background-7436793/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jersey_Shore"
              },
              "landmark": {
                  "name": "Lucy the Elephant",
                  "photographer": "jimcintosh",
                  "imageLink": "https://pixabay.com/photos/lucy-elephant-margate-margate-city-2313472/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lucy_the_Elephant",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Newark",
                  "photographer": "BruceEmmerling",
                  "imageLink": "https://en.wikipedia.org/wiki/Newark,_New_Jersey",
                  "wikiLink": "https://en.wikipedia.org/wiki/Newark,_New_Jersey"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 4
                  }
              ],
              "uniqueId": "fWYh4wST",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Granite State",
                  "First State/Diamond State",
                  "North Star State/Gopher State",
                  "Cotton State/Yellowhammer State",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state was the ____ in the Union.",
                  "fillAnswer": "3rd",
                  "fillAnswers": [
                      "2nd",
                      "3rd",
                      "9th",
                      "13th"
                  ]
              },
              "flowerOptions": [
                  "Orange Blossom",
                  "American Beauty Rose",
                  "White Pine Cone And Tassel"
              ],
              "landmarkPlaces": [
                  "Sawtooth National Forest",
                  "Hanauma Bay",
                  "Tryon Palace",
                  "Waimea Canyon",
                  "Vulcan Statue",
                  "Gillette Castle",
                  "Hot Springs National Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Cape May",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/cape-may-new-jersey-houses-street-105590/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cape_May"
              },
              "landmark": {
                  "name": "Ocean City Boardwalk",
                  "photographer": "Bruce Emmerling",
                  "imageLink": "https://pixabay.com/photos/boardwalk-ocean-city-beach-ocean-224457/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ocean_City,_New_Jersey#Boardwalk",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Jersey City",
                  "photographer": "1778011",
                  "imageLink": "https://en.wikipedia.org/wiki/Jersey_City,_New_Jersey",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jersey_City,_New_Jersey"
              },
              "landmarksRound": [
                  {
                      "code": "VA",
                      "number": 2
                  },
                  {
                      "code": "GA",
                      "number": 1
                  },
                  {
                      "code": "MS",
                      "number": 1
                  }
              ],
              "uniqueId": "lr6VvXlY",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Centennial State",
                  "Palmetto State",
                  "The Old Dominion/Mother of Presidents",
                  "Flickertail State/Sioux State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "With nearly 1,200 people per mile, this is the most densely populated U.S. state.",
                  "falseFact": "This state is the Fashion Capital of the World.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Oregon Grape",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Willis Tower",
                  "Shrine of the Grotto of the Redemption",
                  "Minidoka National Historic Site",
                  "Basilica of the Sacred Heart",
                  "John Brown's Fort",
                  "Golden Driller Statue",
                  "Eastern State Penitentiary"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Hamilton",
                  "photographer": "rdshanky",
                  "imageLink": "https://pixabay.com/photos/sculpture-woman-pond-2099287/",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_Jersey"
              },
              "landmark": {
                  "name": "Warner Theatre",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/warner-theatre-atlantic-city-4023898/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Atlantic_City,_New_Jersey",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Atlantic City",
                  "photographer": "1778011",
                  "imageLink": "https://en.wikipedia.org/wiki/Atlantic_City,_New_Jersey",
                  "wikiLink": "https://en.wikipedia.org/wiki/Atlantic_City,_New_Jersey"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 4
                  },
                  {
                      "code": "MD",
                      "number": 4
                  },
                  {
                      "code": "TX",
                      "number": 4
                  }
              ],
              "uniqueId": "sSJxmTnk",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Cornhusker State/Beef State",
                  "The Last Frontier",
                  "Green Mountain State",
                  "The Mount Rushmore State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "A famous cryptid is said to lurk in Southern New Jersey: ________.",
                  "fillAnswer": "The Jersey Devil",
                  "fillAnswers": [
                      "Slenderman",
                      "The Trenton Terror",
                      "The Yeti",
                      "The Jersey Devil"
                  ]
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Wild Rose",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Bennington Battle Monument",
                  "Cathedral Caverns State Park",
                  "Castillo San Cristobal",
                  "Lincoln Boyhood National Memorial",
                  "Paw Paw Tunnel",
                  "Baltimore Basilica",
                  "Milwaukee Art Museum"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Hoboken",
                  "photographer": "pierre9x6",
                  "imageLink": "https://pixabay.com/photos/new-jersey-city-hoboken-america-4649453/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hoboken,_New_Jersey"
              },
              "landmark": {
                  "name": "George Washington Bridge",
                  "photographer": "1778011",
                  "imageLink": "https://en.wikipedia.org/wiki/George_Washington_Bridge",
                  "wikiLink": "https://en.wikipedia.org/wiki/George_Washington_Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Trenton",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/Trenton,_New_Jersey",
                  "wikiLink": "https://en.wikipedia.org/wiki/Trenton,_New_Jersey"
              },
              "landmarksRound": [
                  {
                      "code": "WI",
                      "number": 2
                  },
                  {
                      "code": "MA",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 5
                  }
              ],
              "uniqueId": "JZdgY8MD",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Wolverine State/Great Lake State",
                  "Sunflower State/Jayhawker State",
                  "Sooner State",
                  "Grand Canyon State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Some 296 battles were fought in New Jersey during the Revolutionary War.",
                  "falseFact": "Basketball was invented in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "California Poppy",
                  "Sego Lily",
                  "Wild Prairie Rose"
              ],
              "landmarkPlaces": [
                  "Independence Rock",
                  "Multnomah Falls",
                  "Isle Royale",
                  "Federal Hill Park",
                  "The Breakers",
                  "Pittock Mansion",
                  "Elephant Rocks State Park"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Liberty State Park",
                  "photographer": "ireallylikefood",
                  "imageLink": "https://pixabay.com/photos/liberty-state-park-park-skyline-5232839/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Liberty_State_Park"
              },
              "landmark": {
                  "name": "Sandy Hook Lighthouse",
                  "photographer": "Ccrabb1948",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Sandy_Hook_Lighthouse,_NJ.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sandy_Hook_Light",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Princeton",
                  "photographer": "Dan Komoda Photography",
                  "imageLink": "https://en.wikipedia.org/wiki/Princeton,_New_Jersey",
                  "wikiLink": "https://en.wikipedia.org/wiki/Princeton,_New_Jersey"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "WV",
                      "number": 5
                  },
                  {
                      "code": "MA",
                      "number": 4
                  }
              ],
              "uniqueId": "hSsRSgsx",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Palmetto State",
                  "Sunshine State",
                  "Mountain State",
                  "Show Me State",
                  "Beaver State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Famous Ivy League school Princeton University can be found in this state.",
                  "falseFact": "The motto of this state is \"Work hard and work together.\"",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Apple Blossom",
                  "Flor De Maga",
                  "Wild Rose"
              ],
              "landmarkPlaces": [
                  "Ford's Theatre",
                  "Indiana Caverns",
                  "Fort Abraham Lincoln State Park",
                  "Willis Tower",
                  "Delicate Arch",
                  "Centennial Land Run Monument",
                  "Taos Pueblo"
              ]
          }
      ],
      "capital": "Trenton",
      "flower": {
          "name": "Violet",
          "imgLink": "https://pixabay.com/photos/fragrant-violets-violet-flower-1077162/",
          "imgLink2": "https://pixabay.com/photos/fragrant-violets-violet-flower-1077159/",
          "wikiLink": "https://en.wikipedia.org/wiki/Viola_sororia"
      },
      "nickname": "Garden State"
  },
  {
      "name": "New Mexico",
      "code": "NM",
      "lat": 34.97273,
      "long": -105.032363,
      "cities": [
          "Santa Fe",
          "Roswell",
          "Las Cruces",
          "Albuquerque",
          "Rio Rancho",
          "Lawrence"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Santuario de Chimayo",
                  "photographer": "Michael & Sherry Martin",
                  "imageLink": "https://www.flickr.com/photos/martinvirtualtours/3485286020",
                  "wikiLink": "https://en.wikipedia.org/wiki/El_Santuario_de_Chimayo"
              },
              "landmark": {
                  "name": "Bandelier National Monument",
                  "photographer": "ArtTower",
                  "imageLink": "https://pixabay.com/photos/cliff-dwelling-55659/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bandelier_National_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Santa Fe",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Santa_Fe,_New_Mexico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Santa_Fe,_New_Mexico"
              },
              "landmarksRound": [
                  {
                      "code": "WI",
                      "number": 1
                  },
                  {
                      "code": "ME",
                      "number": 3
                  },
                  {
                      "code": "NH",
                      "number": 1
                  }
              ],
              "uniqueId": "qmchyMXF",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Prairie State/Land of Lincoln",
                  "Centennial State",
                  "Flickertail State/Sioux State",
                  "Hawkeye State/Corn State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Albuqerque, one of the biggest cities in New Mexico, hosts the world's largest hot air balloon festival each fall.",
                  "falseFact": "Because the vast majority of New Mexico is a desert, nobody has ever been able to successfully cultivate grapes here, and the state is known as a graveyard of failed vineyards and wineries.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "White Pine Cone And Tassel",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "Lake Champlain",
                  "Ocean City Boardwalk",
                  "The World's Only Corn Palace",
                  "Devil's Tower National Monument",
                  "Fort Delaware",
                  "Harding Icefield",
                  "Reed Gold Mine"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "White Sands National Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/white-sands-national-monument-1911395/",
                  "wikiLink": "https://en.wikipedia.org/wiki/White_Sands_National_Park"
              },
              "landmark": {
                  "name": "Carlsbad Caverns",
                  "photographer": "Christopher_OToole_Images",
                  "imageLink": "https://pixabay.com/photos/cavern-carlsbad-caverns-rock-3647889/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carlsbad_Caverns_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Roswell",
                  "photographer": "Urban~commonswiki",
                  "imageLink": "https://en.wikipedia.org/wiki/Roswell,_New_Mexico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Roswell,_New_Mexico"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "VA",
                      "number": 2
                  },
                  {
                      "code": "NC",
                      "number": 5
                  }
              ],
              "uniqueId": "SrpyglJB",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Flickertail State/Sioux State",
                  "Sunshine State",
                  "Pine Tree State",
                  "Natural State",
                  "Peace State/Empire State of the South"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The world's first ______ was tested in this state.",
                  "fillAnswer": "Atomic bomb",
                  "fillAnswers": [
                      "Submarine",
                      "Laptop",
                      "Atomic bomb",
                      "Machine gun"
                  ]
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Red Clover",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Nemours Estate",
                  "Soldiers & Sailors Monument",
                  "Pemaquid Point Lighthouse",
                  "Fort Delaware",
                  "Mount Washington Cog",
                  "Paw Paw Tunnel",
                  "Klondike Gold Rush National Historic Park"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Rockhound Park",
                  "photographer": "tomkranz",
                  "imageLink": "https://pixabay.com/photos/rockhound-park-new-mexico-rockhound-219572/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rockhound_State_Park"
              },
              "landmark": {
                  "name": "Santa Fe Plaza",
                  "photographer": "Ron Cogswell",
                  "imageLink": "https://www.flickr.com/photos/22711505@N05/9695107006",
                  "wikiLink": "https://en.wikipedia.org/wiki/Santa_Fe_Plaza",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Las Cruces",
                  "photographer": "David Herrera",
                  "imageLink": "https://en.wikipedia.org/wiki/Las_Cruces,_New_Mexico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Las_Cruces,_New_Mexico"
              },
              "landmarksRound": [
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "HI",
                      "number": 3
                  }
              ],
              "uniqueId": "vbl9V7Br",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Golden State",
                  "Evergreen State/Chinhook State",
                  "Bay State/Old Colony State",
                  "Aloha State",
                  "Flickertail State/Sioux State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to the oldest capital city in the United States, which was founded in 1610.",
                  "falseFact": "This state has some of the best roads in the nation, with nearly 95% of them being paved.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Iris",
                  "Camellia"
              ],
              "landmarkPlaces": [
                  "Old State Capitol",
                  "Klondike Gold Rush National Historic Park",
                  "Napoleon House",
                  "World's Largest Ball of Twine",
                  "Fort Delaware",
                  "Red River Gorge",
                  "Thunder Hole"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Very Large Array",
                  "photographer": "Obelixlatino",
                  "imageLink": "https://pixabay.com/photos/vla-the-very-large-array-new-mexico-5066630/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Very_Large_Array"
              },
              "landmark": {
                  "name": "Sandia Peak Tramway",
                  "photographer": "Mike McBey",
                  "imageLink": "https://www.flickr.com/photos/158652122@N02/45872520494",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sandia_Peak_Tramway",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Albuquerque",
                  "photographer": "meandcolors",
                  "imageLink": "https://en.wikipedia.org/wiki/Albuquerque,_New_Mexico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Albuquerque,_New_Mexico"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 3
                  },
                  {
                      "code": "NE",
                      "number": 1
                  },
                  {
                      "code": "RI",
                      "number": 3
                  }
              ],
              "uniqueId": "fTgPRJqz",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Aloha State",
                  "Garden State",
                  "Granite State",
                  "Buckeye State",
                  "Bluegrass State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has the most PhDs per capita.",
                  "falseFact": "The name of this state's capital city, Santa Fe, means Saint Iron.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "California Poppy",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Graceland",
                  "Basilica of the Sacred Heart",
                  "Houmas House",
                  "Cathedral Caverns State Park",
                  "Tryon Palace",
                  "Fort Delaware",
                  "Diamond Head"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Rio Grande",
                  "photographer": "rauschenberger",
                  "imageLink": "https://pixabay.com/photos/sunset-america-new-mexico-river-5149320/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rio_Grande"
              },
              "landmark": {
                  "name": "Taos Pueblo",
                  "photographer": "ComplexDiscovery",
                  "imageLink": "https://pixabay.com/photos/taos-adobe-pueblo-indian-830587/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Taos_Pueblo",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rio Rancho",
                  "photographer": "Riverrat303",
                  "imageLink": "https://en.wikipedia.org/wiki/Rio_Rancho,_New_Mexico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rio_Rancho,_New_Mexico"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 2
                  },
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  }
              ],
              "uniqueId": "Ttfvc48H",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Bay State/Old Colony State",
                  "The District",
                  "The Old Dominion/Mother of Presidents",
                  "Hoosier State",
                  "Equality State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "A famous UFO incident occurred in this state in 1947 when a suspected \"flying saucer\" crashed in ________ which was later determined to be a weather balloon.",
                  "fillAnswer": "Roswell",
                  "fillAnswers": [
                      "Area 51",
                      "Reno",
                      "Roswell",
                      "Gary"
                  ]
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Wild Rose",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Minidoka National Historic Site",
                  "Fontainebleau State Park",
                  "Bryce Canyon",
                  "Mendenhall Glacier",
                  "Tupelo National Battlefield",
                  "Centennial Land Run Monument",
                  "Yale University"
              ]
          }
      ],
      "capital": "Santa Fe",
      "flower": {
          "name": "Yucca Flower",
          "imgLink": "https://pixabay.com/photos/rain-wet-yucca-flowers-morning-fog-2367435/",
          "imgLink2": "https://pixabay.com/photos/flower-plant-silyuka-yucca-7273226/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yucca"
      },
      "nickname": "Land of Enchantment"
  },
  {
      "name": "Nevada",
      "code": "NV",
      "lat": 38.80261,
      "long": -116.419389,
      "cities": [
          "Reno",
          "Las Vegas",
          "Spring Valley",
          "Henderson",
          "Paradise",
          "Kansas City"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Valley of Fire",
                  "photographer": "esudroff",
                  "imageLink": "https://pixabay.com/photos/rainbow-rain-sunset-mountains-1401785/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Valley_of_Fire_State_Park"
              },
              "landmark": {
                  "name": "Bellagio Fountain",
                  "photographer": "nitli",
                  "imageLink": "https://pixabay.com/photos/las-vegas-bellagio-fountain-209936/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fountains_of_Bellagio",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Reno",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/Reno,_Nevada",
                  "wikiLink": "https://en.wikipedia.org/wiki/Reno,_Nevada"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 4
                  },
                  {
                      "code": "WI",
                      "number": 2
                  },
                  {
                      "code": "UT",
                      "number": 1
                  }
              ],
              "uniqueId": "cFx9jwy3",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Palmetto State",
                  "Pine Tree State",
                  "Pelican State/Creole State/Sugar State",
                  "Nutmeg State/Constitution State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is the driest in the U.S.",
                  "falseFact": "This state entered the Union in the 1870s, after the Civil War fervor had calmed down enough for regular government processes to resume.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Rose",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Houmas House",
                  "Milwaukee Art Museum",
                  "Colonial Williamsburg",
                  "Wright Brothers National Memorial",
                  "Devil's Tower National Monument",
                  "Fort Mandan State Historic Site",
                  "Cathedral Caverns State Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Carson City",
                  "photographer": "SLPix",
                  "imageLink": "https://pixabay.com/photos/western-film-city-carson-city-7746043/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carson_City,_Nevada"
              },
              "landmark": {
                  "name": "The Venetian",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/venetian-las-vegas-gondola-canal-562762/",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Venetian_Las_Vegas",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Las Vegas",
                  "photographer": "zzim780",
                  "imageLink": "https://en.wikipedia.org/wiki/Las_Vegas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Las_Vegas"
              },
              "landmarksRound": [
                  {
                      "code": "KY",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 3
                  },
                  {
                      "code": "MA",
                      "number": 2
                  }
              ],
              "uniqueId": "WrY9G4hn",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Mountain State",
                  "Volunteer State",
                  "Hawkeye State/Corn State",
                  "Beehive State",
                  "First State/Diamond State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The largest slot machine in the world can be found in this state.",
                  "falseFact": "The Mojave Desert, which is contained within Nevada, is the second-largest desert in the world.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Oklahoma Rose",
                  "Forget-me-not"
              ],
              "landmarkPlaces": [
                  "Taos Pueblo",
                  "Dollywood",
                  "Fort Delaware",
                  "Waimea Canyon",
                  "Timberline Lodge",
                  "Olbrich Botanical Gardens",
                  "Thorncrown Chapel"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Red Rock Canyon",
                  "photographer": "esudroff",
                  "imageLink": "https://pixabay.com/photos/red-rock-canyon-las-vegas-nevada-3066430/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_Rock_Canyon_National_Conservation_Area"
              },
              "landmark": {
                  "name": "Valley of Fire State Park",
                  "photographer": "nfaulk6",
                  "imageLink": "https://pixabay.com/photos/valley-of-fire-nevada-state-park-5203531/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Valley_of_Fire_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Spring Valley",
                  "photographer": "Rmvisuals",
                  "imageLink": "https://en.wikipedia.org/wiki/Spring_Valley,_Nevada",
                  "wikiLink": "https://en.wikipedia.org/wiki/Spring_Valley,_Nevada"
              },
              "landmarksRound": [
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 1
                  }
              ],
              "uniqueId": "5ylyYQDn",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Buckeye State",
                  "Sunflower State/Jayhawker State",
                  "Aloha State",
                  "Wolverine State/Great Lake State",
                  "Hoosier State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Las Vegas, Nevada's most famous city, is the ___ capital of the world.",
                  "fillAnswer": "Wedding",
                  "fillAnswers": [
                      "Wedding",
                      "Desert",
                      "Drinking",
                      "Live music"
                  ]
              },
              "flowerOptions": [
                  "Violet",
                  "Oklahoma Rose",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Old State House",
                  "Pittock Mansion",
                  "Fort Worden",
                  "Church Street Marketplace",
                  "USS Albacore",
                  "A Christmas Story House",
                  "Flume Gorge"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Luxor Hotel and Casino",
                  "photographer": "lindsayascott",
                  "imageLink": "https://pixabay.com/photos/las-vegas-pharaoh-egypt-vegas-1086414/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Luxor_Las_Vegas"
              },
              "landmark": {
                  "name": "High Roller",
                  "photographer": "maisielittaua",
                  "imageLink": "https://pixabay.com/photos/ferris-wheel-sky-las-vegas-3880254/",
                  "wikiLink": "https://en.wikipedia.org/wiki/High_Roller_(Ferris_wheel)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Henderson",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/Henderson,_Nevada",
                  "wikiLink": "https://en.wikipedia.org/wiki/Henderson,_Nevada"
              },
              "landmarksRound": [
                  {
                      "code": "KY",
                      "number": 1
                  },
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "SC",
                      "number": 4
                  }
              ],
              "uniqueId": "C5vvXwgv",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Palmetto State",
                  "Magnolia State",
                  "Bluegrass State",
                  "Treasure State/Big Sky Country",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Ever-popular wardrobe staple, the _____, were invented in this state.",
                  "fillAnswer": "Blue jeans",
                  "fillAnswers": [
                      "T-shirt",
                      "Sweatshirt",
                      "Blue jeans",
                      "Wool socks"
                  ]
              },
              "flowerOptions": [
                  "Yellow Elder",
                  "American Beauty Rose",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "St. Patrick's Cathedral",
                  "Paul Revere House",
                  "Vulcan Statue",
                  "Oriole Park",
                  "Fontainebleau State Park",
                  "Beale Street",
                  "Mammoth Cave"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Las Vegas",
                  "photographer": "jplenio",
                  "imageLink": "https://pixabay.com/photos/panorama-landscape-travel-3130907/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Las_Vegas"
              },
              "landmark": {
                  "name": "Seven Magic Mountains",
                  "photographer": "JamesHills",
                  "imageLink": "https://pixabay.com/photos/seven-magic-mountains-las-vegas-2650641/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ugo_Rondinone",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Paradise",
                  "photographer": "Daniel Ramirez",
                  "imageLink": "https://en.wikipedia.org/wiki/Paradise,_Nevada",
                  "wikiLink": "https://en.wikipedia.org/wiki/Paradise,_Nevada"
              },
              "landmarksRound": [
                  {
                      "code": "DE",
                      "number": 4
                  },
                  {
                      "code": "MI",
                      "number": 4
                  },
                  {
                      "code": "ID",
                      "number": 5
                  }
              ],
              "uniqueId": "qr378CtF",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Ocean State",
                  "North Star State/Gopher State",
                  "Garden State",
                  "Grand Canyon State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is the second-largest gold producer in the world.",
                  "falseFact": "The state animal of Nevada is the kangaroo rat, a desert-dwelling creature.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Orange Blossom",
                  "Bitterroot",
                  "White Pine Cone And Tassel"
              ],
              "landmarkPlaces": [
                  "Starved Rock State Park",
                  "Mendenhall Glacier",
                  "Thunder Hole",
                  "The Greenbrier",
                  "Puerta de San Juan",
                  "Santa Fe Plaza",
                  "Sawtooth National Forest"
              ]
          }
      ],
      "capital": "Carson City",
      "flower": {
          "name": "Sagebrush",
          "imgLink": "https://pixabay.com/photos/plants-sagebrush-blurred-background-6525220/",
          "imgLink2": "https://pixabay.com/photos/plants-sagebrush-blurred-background-6525219/",
          "wikiLink": "https://en.wikipedia.org/wiki/Artemisia_tridentata"
      },
      "nickname": "Silver State/Sagebrush State"
  },
  {
      "name": "New York",
      "code": "NY",
      "lat": 43.299428,
      "long": -74.217933,
      "cities": [
          "Albany",
          "Buffalo",
          "New York City",
          "Syracuse",
          "Rochester",
          "Memphis"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "NYC Chinatown",
                  "photographer": "noelsch",
                  "imageLink": "https://pixabay.com/photos/chinatown-streets-new-york-4582511/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chinatowns_in_the_Americas"
              },
              "landmark": {
                  "name": "Brooklyn Bridge",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/brooklyn-bridge-river-illuminated-1791001/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Brooklyn_Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Albany",
                  "photographer": "SuddenShutter",
                  "imageLink": "https://en.wikipedia.org/wiki/Albany,_New_York",
                  "wikiLink": "https://en.wikipedia.org/wiki/Albany,_New_York"
              },
              "landmarksRound": [
                  {
                      "code": "VT",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 4
                  },
                  {
                      "code": "DE",
                      "number": 1
                  }
              ],
              "uniqueId": "95fFXwJQ",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Sunshine State",
                  "The Old Dominion/Mother of Presidents",
                  "Palmetto State",
                  "America’s Paradise",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "New York landmark the Statue of Liberty was actually a gift from European ally, France.",
                  "falseFact": "In addition to being known for its pizza, New York City is known for pistachio cookies, which were invented in Manhattan.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Iris",
                  "Wild Prairie Rose",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "Lincoln Boyhood National Memorial",
                  "Ford's Theatre",
                  "Churchill Downs",
                  "Dignity Statue",
                  "Pemaquid Point Lighthouse",
                  "Dover Motor Speedway",
                  "Wright Brothers National Memorial"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "New York City",
                  "photographer": "BeansandSusages",
                  "imageLink": "https://pixabay.com/photos/new-york-streets-traffic-5265414/",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_York_City"
              },
              "landmark": {
                  "name": "Statue of Liberty",
                  "photographer": "itou365",
                  "imageLink": "https://pixabay.com/photos/new-york-statue-of-liberty-115626/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Statue_of_Liberty",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Buffalo",
                  "photographer": "PublicDomainPictures",
                  "imageLink": "https://en.wikipedia.org/wiki/Buffalo,_New_York",
                  "wikiLink": "https://en.wikipedia.org/wiki/Buffalo,_New_York"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 4
                  },
                  {
                      "code": "OK",
                      "number": 2
                  },
                  {
                      "code": "CA",
                      "number": 4
                  }
              ],
              "uniqueId": "B5CwnT4m",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "First State/Diamond State",
                  "Lone Star State",
                  "Natural State",
                  "North Star State/Gopher State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The oldest state park in the United States can be found in this state.",
                  "falseFact": "The official state bird of New York is the red cardinal.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Red Clover",
                  "Flowering Dogwood",
                  "Sagebrush"
              ],
              "landmarkPlaces": [
                  "Mount Vernon",
                  "Mount Washington",
                  "Carlsbad Caverns",
                  "Ocean City Boardwalk",
                  "Dover Motor Speedway",
                  "Everglades",
                  "Lincoln Boyhood National Memorial"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Central Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/central-park-new-york-city-road-142894/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Central_Park"
              },
              "landmark": {
                  "name": "Ellis Island",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/ellis-island-america-new-york-4023775/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ellis_Island",
                  "hasLandmark": true
              },
              "city": {
                  "name": "New York City",
                  "photographer": "wiggijo",
                  "imageLink": "https://en.wikipedia.org/wiki/New_York_City",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_York_City"
              },
              "landmarksRound": [
                  {
                      "code": "VT",
                      "number": 2
                  },
                  {
                      "code": "ME",
                      "number": 5
                  },
                  {
                      "code": "WY",
                      "number": 3
                  }
              ],
              "uniqueId": "9WVn5gLj",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Golden State",
                  "Grand Canyon State",
                  "Wolverine State/Great Lake State",
                  "Beehive State",
                  "Magnolia State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Around 2.5% of Americans live in New York City.",
                  "falseFact": "New York City is home to the largest library system in the world.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Camellia",
                  "Sagebrush"
              ],
              "landmarkPlaces": [
                  "Knife River Indian Villages",
                  "Multnomah Falls",
                  "Black Canyon of the Gunnison",
                  "Red Rocks Ampitheatre",
                  "Shoshone Falls",
                  "Christiansted National Historic Site",
                  "Willis Tower"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "High Line",
                  "photographer": "Dansnguyen",
                  "imageLink": "https://en.wikipedia.org/wiki/High_Line#/media/File:AHigh_Line_Park,_Section_1a.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/High_Line"
              },
              "landmark": {
                  "name": "St. Patrick's Cathedral",
                  "photographer": "alvaroreguly",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Saint_Patrick%27s_Cathedral,_looking_upwards.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._Patrick%27s_Cathedral_(Midtown_Manhattan)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Syracuse",
                  "photographer": "Don-vip",
                  "imageLink": "https://en.wikipedia.org/wiki/Syracuse,_New_York",
                  "wikiLink": "https://en.wikipedia.org/wiki/Syracuse,_New_York"
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 2
                  },
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "ND",
                      "number": 1
                  }
              ],
              "uniqueId": "Qk3zCTQG",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Beaver State",
                  "Nutmeg State/Constitution State",
                  "Bluegrass State",
                  "The Mount Rushmore State",
                  "Aloha State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Though New York eventually lost out of the title of U.S. capitol to Washington D.C., it does hold the title of _____ capital of the world. ",
                  "fillAnswer": "Financial",
                  "fillAnswers": [
                      "Diplomatic",
                      "Financial",
                      "Theater",
                      "Cultural"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Hawthorn",
                  "Flowering Dogwood"
              ],
              "landmarkPlaces": [
                  "Battleship Memorial Park",
                  "Arch Rock",
                  "Fontainebleau State Park",
                  "USS Albacore",
                  "Cadillac Ranch",
                  "Elvis Presley Birthplace",
                  "Knife River Indian Villages"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Flatiron Building",
                  "photographer": "nextvoyage",
                  "imageLink": "https://pixabay.com/photos/city-townscape-3179404/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flatiron_Building"
              },
              "landmark": {
                  "name": "Charging Bull",
                  "photographer": "Sam valadi",
                  "imageLink": "https://www.flickr.com/photos/132084522@N05/17086570218",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charging_Bull",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rochester",
                  "photographer": "Patrick Ashley",
                  "imageLink": "https://en.wikipedia.org/wiki/Rochester,_New_York",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rochester,_New_York"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 3
                  },
                  {
                      "code": "WI",
                      "number": 4
                  },
                  {
                      "code": "OH",
                      "number": 4
                  }
              ],
              "uniqueId": "WMVd9KsD",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Badger State/America's Dairyland",
                  "Grand Canyon State",
                  "The Last Frontier",
                  "The District",
                  "Cotton State/Yellowhammer State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "New York City was originally called _________.",
                  "fillAnswer": "New Amsterdam",
                  "fillAnswers": [
                      "New London",
                      "George's City",
                      "Victoria Island",
                      "New Amsterdam"
                  ]
              },
              "flowerOptions": [
                  "Sunflower",
                  "Black-eyed Susan",
                  "Hawaiian Hibiscus"
              ],
              "landmarkPlaces": [
                  "Museum of the Rockies",
                  "Mississippi Petrified Forest",
                  "Nemours Estate",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Christiansted National Historic Site",
                  "Blanchard Springs Cavern",
                  "Elephant Rocks State Park"
              ]
          }
      ],
      "capital": "Albany",
      "flower": {
          "name": "Rose",
          "imgLink": "https://pixabay.com/photos/rose-flower-red-rose-rose-bloom-3802424/",
          "imgLink2": "https://pixabay.com/photos/roses-flowers-garden-pink-rose-208980/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rose"
      },
      "nickname": "Empire State"
  },
  {
      "name": "Ohio",
      "code": "OH",
      "lat": 40.417287,
      "long": -82.907123,
      "cities": [
          "Columbus",
          "Cincinnati",
          "Cleveland",
          "Toledo",
          "Dayton",
          "Bridgeport"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Ohio State University",
                  "photographer": "jsjessee",
                  "imageLink": "https://en.wikipedia.org/wiki/Ohio_State_University#/media/File:Columbus,_Ohio_JJ_79.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ohio_State_University"
              },
              "landmark": {
                  "name": "Rock & Roll Hall of Fame",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/rock-and-roll-hall-of-fame-landmark-898933/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rock_and_Roll_Hall_of_Fame",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Columbus",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Columbus,_Ohio",
                  "wikiLink": "https://en.wikipedia.org/wiki/Columbus,_Ohio"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "NY",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  }
              ],
              "uniqueId": "HrFhRc9h",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Natural State",
                  "Pelican State/Creole State/Sugar State",
                  "Badger State/America's Dairyland",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The _______, invented by the Wright Brothers, was created in Ohio.",
                  "fillAnswer": "Airplane",
                  "fillAnswers": [
                      "Steam engine",
                      "Airplane",
                      "Bicycle",
                      "Tractor"
                  ]
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Peach Blossom",
                  "Forget-me-not"
              ],
              "landmarkPlaces": [
                  "Haystack Rock",
                  "Taos Pueblo",
                  "Warner Theatre",
                  "Devil's Tower National Monument",
                  "St. Patrick's Cathedral",
                  "Martin Luther King Jr. Memorial",
                  "Eastern State Penitentiary"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Lake Erie",
                  "photographer": "miketoler820",
                  "imageLink": "https://pixabay.com/photos/lighthouse-lake-erie-ohio-coastal-2731685/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lake_Erie"
              },
              "landmark": {
                  "name": "Cedar Point",
                  "photographer": "Jeremy Thompson",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Cedar_Point_amusement_rides_090_%289550420996%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cedar_Point",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cincinnati",
                  "photographer": "EEJCC",
                  "imageLink": "https://en.wikipedia.org/wiki/Cincinnati",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cincinnati"
              },
              "landmarksRound": [
                  {
                      "code": "LA",
                      "number": 1
                  },
                  {
                      "code": "DC",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 4
                  }
              ],
              "uniqueId": "BvpMJnwk",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Lone Star State",
                  "Natural State",
                  "Hawkeye State/Corn State",
                  "Volunteer State",
                  "Bluegrass State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The third-largest university in the U.S., _________, is located in Ohio.",
                  "fillAnswer": "The Ohio State University",
                  "fillAnswers": [
                      "Bowling Green State University",
                      "Ohio University",
                      "The Ohio State University",
                      "Kenyon College"
                  ]
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Red Clover",
                  "Cherokee Rose"
              ],
              "landmarkPlaces": [
                  "Tupelo National Battlefield",
                  "Golden Driller Statue",
                  "Martin Luther King Jr. Memorial",
                  "Golden Gate Bridge",
                  "Ben & Jerry's",
                  "Cathedral Caverns State Park",
                  "Charging Bull"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Roebling Bridge",
                  "photographer": "emilyd3",
                  "imageLink": "https://pixabay.com/photos/bridge-roebling-bridge-suspension-2739453/",
                  "wikiLink": "https://en.wikipedia.org/wiki/John_A._Roebling_Suspension_Bridge"
              },
              "landmark": {
                  "name": "A Christmas Story House",
                  "photographer": "Ron Cogswell",
                  "imageLink": "https://www.flickr.com/photos/22711505@N05/52590180014",
                  "wikiLink": "https://en.wikipedia.org/wiki/A_Christmas_Story_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cleveland",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Cleveland",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cleveland"
              },
              "landmarksRound": [
                  {
                      "code": "WV",
                      "number": 2
                  },
                  {
                      "code": "MI",
                      "number": 2
                  },
                  {
                      "code": "MN",
                      "number": 1
                  }
              ],
              "uniqueId": "f5T6HvGV",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Volunteer State",
                  "Aloha State",
                  "Bluegrass State",
                  "Nutmeg State/Constitution State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Ohio is known for mining rock salt halite from Lake Erie.",
                  "falseFact": "This state has voted for the Democratic candidate in the U.S. Presidential election for every year since 1962.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wood Violet",
                  "Yellow Elder",
                  "Saguaro Cactus Blossom"
              ],
              "landmarkPlaces": [
                  "Akaka Falls",
                  "Sandy Hook Lighthouse",
                  "Old Mississippi State Capitol",
                  "Craters of the Moon",
                  "The Mark Twain House",
                  "Carhenge",
                  "Pemaquid Point Lighthouse"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Cleveland",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/cleveland-ohio-america-panorama-4100096/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cleveland"
              },
              "landmark": {
                  "name": "Hocking Hills State Park",
                  "photographer": "XChen0219",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Hocking_Hills_State_Park.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hocking_Hills_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Toledo",
                  "photographer": "MrJacon000",
                  "imageLink": "https://en.wikipedia.org/wiki/Toledo,_Ohio",
                  "wikiLink": "https://en.wikipedia.org/wiki/Toledo,_Ohio"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 2
                  }
              ],
              "uniqueId": "k7R3zCD7",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Volunteer State",
                  "Centennial State",
                  "Sooner State",
                  "Bluegrass State",
                  "Natural State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has the only non-rectangular flag of any U.S. state.",
                  "falseFact": "No U.S. presidents have ever been born in Ohio.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mock Orange",
                  "Camellia",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Castillo de San Marcos",
                  "Indianapolis Catacombs",
                  "Taos Pueblo",
                  "Fontainebleau State Park",
                  "Natural Bridge Caverns",
                  "Fort Christian",
                  "The Breakers"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Cincinatti Music Hall",
                  "photographer": "Fred Hasser",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Cincinnati_Music_Hall_%2842707046%29.jpeg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cincinnati"
              },
              "landmark": {
                  "name": "Cuyahoga Valley",
                  "photographer": "rustyc",
                  "imageLink": "https://pixabay.com/photos/cuyahoga-valley-national-park-376352/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cuyahoga_Valley_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Dayton",
                  "photographer": "PublicDomainPictures",
                  "imageLink": "https://en.wikipedia.org/wiki/Dayton,_Ohio",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dayton,_Ohio"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 5
                  },
                  {
                      "code": "NY",
                      "number": 1
                  },
                  {
                      "code": "AK",
                      "number": 1
                  }
              ],
              "uniqueId": "zxJPX4Fl",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "North Star State/Gopher State",
                  "Cornhusker State/Beef State",
                  "America’s Paradise",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Rock & Roll Hall of Fame can be found in this state.",
                  "falseFact": "The word \"Ohio\" originated as a greeting, based off the word \"hi!\"",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Purple Lilac",
                  "California Poppy"
              ],
              "landmarkPlaces": [
                  "The Witch House at Salem",
                  "Tryon Palace",
                  "Arch Rock",
                  "Avalanche Lake",
                  "Old Idaho Penitentiary",
                  "Iolani Palace",
                  "Disney World"
              ]
          }
      ],
      "capital": "Columbus",
      "flower": {
          "name": "Scarlet Carnation",
          "imgLink": "https://pixabay.com/photos/flower-carnation-red-flower-nature-7840259/",
          "imgLink2": "https://pixabay.com/photos/carnations-flowers-red-bouquet-6292136/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dianthus_caryophyllus"
      },
      "nickname": "Buckeye State"
  },
  {
      "name": "Oklahoma",
      "code": "OK",
      "lat": 35.007752,
      "long": -97.092877,
      "cities": [
          "Tulsa",
          "Norman",
          "Oklahoma City",
          "Lawton",
          "Broken Arrow",
          "Columbus"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Philbrook Museum of Art",
                  "photographer": "whiterabbitart",
                  "imageLink": "https://www.flickr.com/photos/whiterabbitart/3850700637",
                  "wikiLink": "https://en.wikipedia.org/wiki/Philbrook_Museum_of_Art"
              },
              "landmark": {
                  "name": "Blue Whale of Catoosa",
                  "photographer": "The Erika Chang",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Big_Blue_Whale,_Catoosa_USA_-_panoramio_%281%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Blue_Whale_of_Catoosa",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tulsa",
                  "photographer": "Caleb Long",
                  "imageLink": "https://en.wikipedia.org/wiki/Tulsa,_Oklahoma",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tulsa,_Oklahoma"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "NM",
                      "number": 5
                  },
                  {
                      "code": "WI",
                      "number": 5
                  }
              ],
              "uniqueId": "72r6RVHg",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Bluegrass State",
                  "Cotton State/Yellowhammer State",
                  "Golden State",
                  "Prairie State/Land of Lincoln",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The National ___ Hall of Fame is in this state.",
                  "fillAnswer": "Cowboy",
                  "fillAnswers": [
                      "Cowboy",
                      "Rock & Roll",
                      "Country Music",
                      "Movie"
                  ]
              },
              "flowerOptions": [
                  "Mock Orange",
                  "Mountain Laurel",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Annaberg Sugar Plantation",
                  "Lake Champlain",
                  "Vulcan Statue",
                  "Cadillac Ranch",
                  "Milwaukee Art Museum",
                  "Baltimore Basilica",
                  "Washington National Cathedral"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Canton Lake",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/sun-over-sandy-cove-sun-sky-water-3920371/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Canton_Lake_(Oklahoma)"
              },
              "landmark": {
                  "name": "Golden Driller Statue",
                  "photographer": "The Erika Chang",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Golden_Driller,_Tulsa_USA_-_panoramio.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Golden_Driller",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Norman",
                  "photographer": "Michael Barera",
                  "imageLink": "https://en.wikipedia.org/wiki/Norman,_Oklahoma",
                  "wikiLink": "https://en.wikipedia.org/wiki/Norman,_Oklahoma"
              },
              "landmarksRound": [
                  {
                      "code": "PA",
                      "number": 3
                  },
                  {
                      "code": "VI",
                      "number": 4
                  },
                  {
                      "code": "VI",
                      "number": 2
                  }
              ],
              "uniqueId": "H9fLMYz5",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "America’s Paradise",
                  "Peace State/Empire State of the South",
                  "Golden State",
                  "Show Me State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first tornado warning in the country was issued in Okalhoma, a state that is known for the large number of tornadoes within its borders.",
                  "falseFact": "Hair spray was invented in this state in 1923.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Orange Blossom",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Akaka Falls",
                  "Myrtle Beach",
                  "Chimney Rock",
                  "Drayton Hall",
                  "The Parthenon",
                  "Ravenel Bridge",
                  "Fort Laramie"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Tulsa",
                  "photographer": "jerrywaxman",
                  "imageLink": "https://pixabay.com/photos/downtown-tulsa-oklahoma-city-188848/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tulsa,_Oklahoma"
              },
              "landmark": {
                  "name": "National Cowboy & Western Heritage Museum",
                  "photographer": "Marcin Wichary",
                  "imageLink": "https://www.flickr.com/photos/mwichary/2835367816",
                  "wikiLink": "https://en.wikipedia.org/wiki/National_Cowboy_%26_Western_Heritage_Museum",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Oklahoma City",
                  "photographer": "Kerwin Moore",
                  "imageLink": "https://en.wikipedia.org/wiki/Oklahoma_City",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oklahoma_City"
              },
              "landmarksRound": [
                  {
                      "code": "WV",
                      "number": 5
                  },
                  {
                      "code": "KY",
                      "number": 2
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  }
              ],
              "uniqueId": "sbBV53Jc",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "Evergreen State/Chinhook State",
                  "Mountain State",
                  "Beehive State",
                  "Garden State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Oklahoma sees on average ____ tornadoes each year.",
                  "fillAnswer": "54",
                  "fillAnswers": [
                      "2",
                      "11",
                      "54",
                      "267"
                  ]
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Hawaiian Hibiscus",
                  "Black-eyed Susan"
              ],
              "landmarkPlaces": [
                  "Fenwick Island Lighthouse",
                  "Denail National Park",
                  "Arch Rock",
                  "Taos Pueblo",
                  "Little Bighorn Battlefield",
                  "Joshua Tree National Park",
                  "Olbrich Botanical Gardens"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Oklahoma City",
                  "photographer": "filmDASH_OKC",
                  "imageLink": "https://pixabay.com/photos/capital-city-oklahoma-rural-tree-1907537/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oklahoma_City"
              },
              "landmark": {
                  "name": "Centennial Land Run Monument",
                  "photographer": "Serge Melki",
                  "imageLink": "https://www.flickr.com/photos/sergemelki/2527660225",
                  "wikiLink": "https://en.wikipedia.org/wiki/Centennial_Land_Run_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Lawton",
                  "photographer": "duggar11",
                  "imageLink": "https://en.wikipedia.org/wiki/Lawton,_Oklahoma",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lawton,_Oklahoma"
              },
              "landmarksRound": [
                  {
                      "code": "UT",
                      "number": 4
                  },
                  {
                      "code": "PR",
                      "number": 2
                  },
                  {
                      "code": "TN",
                      "number": 1
                  }
              ],
              "uniqueId": "W2wxb7q9",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Sunshine State",
                  "Volunteer State",
                  "Centennial State",
                  "North Star State/Gopher State",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Oklahoma has 200 man-made lakes, more than any other state.",
                  "falseFact": "The state motto is written in Spanish, and means \"Live bold and free.\"",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Magnolia",
                  "American Dogwood",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "Fort Laramie",
                  "Mississippi Petrified Forest",
                  "Shoshone Falls",
                  "Brown University",
                  "Wright Brothers National Memorial",
                  "Portland Head Light",
                  "Scotts Bluff"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Fort Reno",
                  "photographer": "15299",
                  "imageLink": "https://pixabay.com/photos/fort-reno-oklahoma-long-barn-101224/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Reno_(Oklahoma)"
              },
              "landmark": {
                  "name": "Myriad Botanical Gardens",
                  "photographer": "Kool Cats Photography",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Myriad_Botanical_Gardens2.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Myriad_Botanical_Gardens",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Broken Arrow",
                  "photographer": "Jordanmac",
                  "imageLink": "https://en.wikipedia.org/wiki/Broken_Arrow,_Oklahoma",
                  "wikiLink": "https://en.wikipedia.org/wiki/Broken_Arrow,_Oklahoma"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 1
                  },
                  {
                      "code": "WY",
                      "number": 5
                  },
                  {
                      "code": "AL",
                      "number": 1
                  }
              ],
              "uniqueId": "5vMzwy78",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Flickertail State/Sioux State",
                  "Green Mountain State",
                  "The Mount Rushmore State",
                  "Bay State/Old Colony State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to more Native Americans than any other U.S. state.",
                  "falseFact": "The Oklahoma panhandle exists because Kansas was forced to surrender the land after they defied the President.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Mayflower",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Flume Gorge",
                  "Washington Monument",
                  "Pikes Peak State Park",
                  "Cathedral of St. Paul",
                  "Castillo San Felipe Del Morro",
                  "World's Largest Ball of Twine",
                  "Martin Luther King Jr. Memorial"
              ]
          }
      ],
      "capital": "Oklahoma City",
      "flower": {
          "name": "Oklahoma Rose",
          "imgLink": "https://www.flickr.com/photos/cq-biker/5726422735",
          "imgLink2": "https://commons.wikimedia.org/wiki/File:Rosa_Oklahoma_1zz.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosa_%27Oklahoma%27"
      },
      "nickname": "Sooner State"
  },
  {
      "name": "Oregon",
      "code": "OR",
      "lat": 43.804133,
      "long": -120.554201,
      "cities": [
          "Portland",
          "Bend",
          "Eugene",
          "Salem",
          "Oregon City",
          "Metairie"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Cannon Beach",
                  "photographer": "Ken1843",
                  "imageLink": "https://pixabay.com/photos/beach-sunset-oregon-coast-7084237/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cannon_Beach,_Oregon"
              },
              "landmark": {
                  "name": "Pittock Mansion",
                  "photographer": "Jim Bahn",
                  "imageLink": "https://www.flickr.com/photos/gcwest/3645035337",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pittock_Mansion",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Portland",
                  "photographer": "King of Hearts",
                  "imageLink": "https://en.wikipedia.org/wiki/Portland,_Oregon",
                  "wikiLink": "https://en.wikipedia.org/wiki/Portland,_Oregon"
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 5
                  },
                  {
                      "code": "UT",
                      "number": 5
                  },
                  {
                      "code": "CT",
                      "number": 5
                  }
              ],
              "uniqueId": "k3YPbJq2",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Sunshine State",
                  "Nutmeg State/Constitution State",
                  "Cornhusker State/Beef State",
                  "Garden State",
                  "Gem State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Portland is sometimes called the City of Roses, and is home to the International Rose Test Garden where new species are grown.",
                  "falseFact": "Portland is a deceptively demure city: it's known for having fewer strip clubs, per capita, than any other U.S. city or state. ",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Scarlet Carnation",
                  "Wild Rose",
                  "Goldenrod"
              ],
              "landmarkPlaces": [
                  "Valley of Fire State Park",
                  "Drayton Hall",
                  "Sandy Hook Lighthouse",
                  "Fort Union Trading Post",
                  "Cedar Point",
                  "Cloud Gate",
                  "Taliesin East"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Wood River",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/oregon-wood-river-marsh-water-139402/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wood_River_(Oregon)"
              },
              "landmark": {
                  "name": "Haystack Rock",
                  "photographer": "enrenb",
                  "imageLink": "https://pixabay.com/photos/haystack-rock-oregon-cannon-beach-1993176/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Haystack_Rock",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bend",
                  "photographer": "UpdateNerd",
                  "imageLink": "https://en.wikipedia.org/wiki/Bend%2C_Oregon",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bend%2C_Oregon"
              },
              "landmarksRound": [
                  {
                      "code": "UT",
                      "number": 5
                  },
                  {
                      "code": "KY",
                      "number": 3
                  },
                  {
                      "code": "NC",
                      "number": 4
                  }
              ],
              "uniqueId": "9wlTvDkn",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Golden State",
                  "The Mount Rushmore State",
                  "Buckeye State",
                  "Silver State/Sagebrush State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Oregon's flag features a _____ on its reverse side.",
                  "fillAnswer": "Beaver",
                  "fillAnswers": [
                      "Beaver",
                      "Mountain",
                      "Deer",
                      "Rose"
                  ]
              },
              "flowerOptions": [
                  "Peach Blossom",
                  "Flowering Dogwood",
                  "Wood Violet"
              ],
              "landmarkPlaces": [
                  "Cheaha Mountain",
                  "The Elms",
                  "Joshua Tree National Park",
                  "Alcatraz Island",
                  "Fenwick Island Lighthouse",
                  "Klondike Gold Rush National Historic Park",
                  "Old Mississippi State Capitol"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Bend",
                  "photographer": "15079075",
                  "imageLink": "https://pixabay.com/photos/sahalie-oregon-waterfall-lush-5160010/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bend,_Oregon"
              },
              "landmark": {
                  "name": "Timberline Lodge",
                  "photographer": "M.O. Stevens",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Exterior_looking_west_-_Timberline_Lodge_Oregon.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Timberline_Lodge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Eugene",
                  "photographer": "Rick Obst",
                  "imageLink": "https://en.wikipedia.org/wiki/Eugene,_Oregon",
                  "wikiLink": "https://en.wikipedia.org/wiki/Eugene,_Oregon"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 5
                  },
                  {
                      "code": "CO",
                      "number": 1
                  },
                  {
                      "code": "UT",
                      "number": 3
                  }
              ],
              "uniqueId": "8LDmPyvB",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Peace State/Empire State of the South",
                  "North Star State/Gopher State",
                  "Gem State",
                  "Cotton State/Yellowhammer State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Oregon became a state on Valentine's Day in 1859.",
                  "falseFact": "Oregon is known for its peanut production, and is the biggest producer of peanuts in the U.S.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flor De Maga",
                  "Black-eyed Susan",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Chihuly Garden and Glass",
                  "San Jacinto Battleground",
                  "Wright Brothers National Memorial",
                  "Alcatraz Island",
                  "San Xavier del Bac Mission",
                  "High Roller",
                  "Diamond Head"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Mount Jefferson",
                  "photographer": "jacobdeb",
                  "imageLink": "https://pixabay.com/photos/mountains-snow-sunset-sunrise-5995081/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Jefferson_(Oregon)"
              },
              "landmark": {
                  "name": "Multnomah Falls",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/multnomah-falls-oregon-landmark-1899890/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Multnomah_Falls",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Salem",
                  "photographer": "M.O. Stevens",
                  "imageLink": "https://en.wikipedia.org/wiki/Salem,_Oregon",
                  "wikiLink": "https://en.wikipedia.org/wiki/Salem,_Oregon"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 3
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "KY",
                      "number": 3
                  }
              ],
              "uniqueId": "hH7kSBb2",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Grand Canyon State",
                  "The District",
                  "First State/Diamond State",
                  "Prairie State/Land of Lincoln",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Ten Native American tribes live in this state.",
                  "falseFact": "This state is the largest producer of apples in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Colorado Blue Columbine",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "TransAmerica Pyramid",
                  "Gillette Castle",
                  "Maquoketa Caves",
                  "Cathedral of St. Paul",
                  "Hanauma Bay",
                  "Battleship Memorial Park",
                  "A Christmas Story House"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Pacific Ocean",
                  "photographer": "agape357",
                  "imageLink": "https://pixabay.com/photos/pacific-ocean-fog-oregon-ocean-4430530/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pacific_Ocean"
              },
              "landmark": {
                  "name": "Mount Hood",
                  "photographer": "jdblack",
                  "imageLink": "https://pixabay.com/photos/mount-hood-oregon-5259526/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Hood",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Oregon City",
                  "photographer": "brx0",
                  "imageLink": "https://en.wikipedia.org/wiki/Oregon_City,_Oregon",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oregon_City,_Oregon"
              },
              "landmarksRound": [
                  {
                      "code": "WV",
                      "number": 5
                  },
                  {
                      "code": "MT",
                      "number": 5
                  },
                  {
                      "code": "ND",
                      "number": 2
                  }
              ],
              "uniqueId": "GfjyXrC7",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Magnolia State",
                  "Treasure State/Big Sky Country",
                  "The Last Frontier",
                  "Volunteer State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Portland, Oregon was almost named _____.",
                  "fillAnswer": "Boston",
                  "fillAnswers": [
                      "Portsmouth",
                      "Washington City",
                      "Boston",
                      "Chicago"
                  ]
              },
              "flowerOptions": [
                  "California Poppy",
                  "Peach Blossom",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Little River Canyon National Preserve",
                  "Busch Stadium",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Martin Luther King Jr. Childhood Home",
                  "Castillo San Felipe Del Morro",
                  "Mississippi Petrified Forest",
                  "Cuyahoga Valley"
              ]
          }
      ],
      "capital": "Salem",
      "flower": {
          "name": "Oregon Grape",
          "imgLink": "https://pixabay.com/photos/oregon-state-flower-mahonia-flowers-65432/",
          "imgLink2": "https://pixabay.com/photos/mahogany-flowers-buds-6194464/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mahonia_aquifolium"
      },
      "nickname": "Beaver State"
  },
  {
      "name": "Pennsylvania",
      "code": "PA",
      "lat": 41.203322,
      "long": -77.194525,
      "cities": [
          "Pittsburgh",
          "Harrisburg",
          "Philadelphia",
          "Allentown",
          "Scranton",
          "Houston"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Independence National Historic Park",
                  "photographer": "Toniklemm",
                  "imageLink": "https://en.wikipedia.org/wiki/Independence_National_Historical_Park#/media/File:Philadelphia_Independence_Mall.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Independence_National_Historical_Park"
              },
              "landmark": {
                  "name": "Fallingwater",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/fallingwater-landmark-historic-378309/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fallingwater",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Pittsburgh",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Pittsburgh",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pittsburgh"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 5
                  },
                  {
                      "code": "ME",
                      "number": 1
                  },
                  {
                      "code": "MS",
                      "number": 4
                  }
              ],
              "uniqueId": "W4wmPRYM",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Equality State",
                  "Silver State/Sagebrush State",
                  "North Star State/Gopher State",
                  "Badger State/America's Dairyland",
                  "The Old Dominion/Mother of Presidents"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is the 5th most populous in the country. ",
                  "falseFact": "The official beverage of Pennsylvania is lemonade.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Peony",
                  "Violet",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Hot Springs National Park",
                  "Portland Head Light",
                  "Centennial Land Run Monument",
                  "Harding Icefield",
                  "Statue of Liberty",
                  "Victoria Mansion",
                  "Golden Gate Bridge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "LOVE Statue",
                  "photographer": "Michael Gray",
                  "imageLink": "https://www.flickr.com/photos/kathika/2461970640",
                  "wikiLink": "https://en.wikipedia.org/wiki/Philadelphia"
              },
              "landmark": {
                  "name": "Eastern State Penitentiary",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/eastern-state-penitentiary-prison-216456/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Eastern_State_Penitentiary",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Harrisburg",
                  "photographer": "redcastlephotography",
                  "imageLink": "https://en.wikipedia.org/wiki/Harrisburg,_Pennsylvania",
                  "wikiLink": "https://en.wikipedia.org/wiki/Harrisburg,_Pennsylvania"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 2
                  },
                  {
                      "code": "MN",
                      "number": 1
                  },
                  {
                      "code": "MT",
                      "number": 1
                  }
              ],
              "uniqueId": "RDCBzSXY",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Gem State",
                  "Granite State",
                  "Badger State/America's Dairyland",
                  "Silver State/Sagebrush State",
                  "Aloha State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Hershey, Pennsylvania is the chocolate capital of the U.S.",
                  "falseFact": "The United Nations headquarters are located in Philadelphia in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Iris",
                  "Goldenrod",
                  "Sego Lily"
              ],
              "landmarkPlaces": [
                  "Cape Hatteras Lighthouse",
                  "Sandy Hook Lighthouse",
                  "Fort Delaware",
                  "Cadillac Ranch",
                  "Myriad Botanical Gardens",
                  "Fort Snelling",
                  "Louisville Water Tower"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Scranton",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/scranton-pennsylvania-train-80494/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Scranton,_Pennsylvania"
              },
              "landmark": {
                  "name": "Three Rivers",
                  "photographer": "Roy Luck",
                  "imageLink": "https://www.flickr.com/photos/royluck/8899996529",
                  "wikiLink": "https://en.wikipedia.org/wiki/Three_Rivers_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Philadelphia",
                  "photographer": "BruceEmmerling",
                  "imageLink": "https://en.wikipedia.org/wiki/Philadelphia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Philadelphia"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 3
                  },
                  {
                      "code": "NE",
                      "number": 1
                  },
                  {
                      "code": "ME",
                      "number": 5
                  }
              ],
              "uniqueId": "RkFBLhhT",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Ocean State",
                  "First State/Diamond State",
                  "Evergreen State/Chinhook State",
                  "Beaver State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Constitution and Declaration of Independence were both signed in this state.",
                  "falseFact": "This state has the longest Atlantic border of any New England state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Red Clover",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Fort Abraham Lincoln State Park",
                  "Ellis Island",
                  "Stonington Harbour Light",
                  "Hot Springs National Park",
                  "The Alamo",
                  "Mammoth Cave",
                  "Ocean City Boardwalk"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Delaware Water Gap",
                  "photographer": "Perkons",
                  "imageLink": "https://pixabay.com/photos/delaware-water-gap-delaware-river-2633627/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Delaware_Water_Gap"
              },
              "landmark": {
                  "name": "Liberty Bell",
                  "photographer": "andreahamilton264",
                  "imageLink": "https://pixabay.com/photos/liberty-bell-independence-2345150/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Liberty_Bell",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Allentown",
                  "photographer": "Atwngirl",
                  "imageLink": "https://en.wikipedia.org/wiki/Allentown,_Pennsylvania",
                  "wikiLink": "https://en.wikipedia.org/wiki/Allentown,_Pennsylvania"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 2
                  },
                  {
                      "code": "NV",
                      "number": 1
                  }
              ],
              "uniqueId": "swbJZxdT",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Mountain State",
                  "Flickertail State/Sioux State",
                  "Show Me State",
                  "Free State/Old Line State",
                  "The District"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Pittsburg is sometimes called the City of _______.",
                  "fillAnswer": "Bridges",
                  "fillAnswers": [
                      "Sports",
                      "Love",
                      "Bridges",
                      "Lights"
                  ]
              },
              "flowerOptions": [
                  "Black-eyed Susan",
                  "Iris",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Fort Mandan State Historic Site",
                  "TransAmerica Pyramid",
                  "Amana Colonies",
                  "Little Bighorn Battlefield",
                  "A Christmas Story House",
                  "Fort Smith National Historic Site",
                  "Timberline Lodge"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Erie",
                  "photographer": "AndreCarrotflower",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Erie_Pa_from_Bicentennial_Tower.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Erie,_Pennsylvania"
              },
              "landmark": {
                  "name": "Rocky Statue",
                  "photographer": "--",
                  "imageLink": "https://www.rawpixel.com/search/rocky%20statue?page=1&sort=curated&topic_group=_topics",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rocky_Steps",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Scranton",
                  "photographer": "Christopher Seliga",
                  "imageLink": "https://en.wikipedia.org/wiki/Scranton,_Pennsylvania",
                  "wikiLink": "https://en.wikipedia.org/wiki/Scranton,_Pennsylvania"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 3
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "MA",
                      "number": 1
                  }
              ],
              "uniqueId": "6zzmgtLy",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Sunshine State",
                  "Sooner State",
                  "Natural State",
                  "Garden State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The very first _______ was created in this state by Betsy Ross.",
                  "fillAnswer": "US Flag",
                  "fillAnswers": [
                      "Sports jacket",
                      "US Flag",
                      "Pantaloons",
                      "Parachute"
                  ]
              },
              "flowerOptions": [
                  "Mayflower",
                  "Sego Lily",
                  "Pink And White Lady's Slipper"
              ],
              "landmarkPlaces": [
                  "Pike Place Market",
                  "Grand Teton",
                  "Amana Colonies",
                  "USS Cairo",
                  "Saint-Gaudens National Historic Park",
                  "Cape Hatteras Lighthouse",
                  "Cathedral Caverns State Park"
              ]
          }
      ],
      "capital": "Harrisburg",
      "flower": {
          "name": "Mountain Laurel",
          "imgLink": "https://pixabay.com/photos/mountain-laurel-laurel-rose-blossoms-5278114/",
          "imgLink2": "https://pixabay.com/photos/mountain-laurel-flower-blooms-pink-5324929/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kalmia_latifolia"
      },
      "nickname": "Keystone State"
  },
  {
      "name": "Puerto Rico",
      "code": "PR",
      "lat": 18.220833,
      "long": -66.590149,
      "cities": [
          "Bayamon",
          "San Juan",
          "Ponce",
          "Carolina",
          "Caguas",
          "Las Cruces"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "San Juan",
                  "photographer": "zarelho",
                  "imageLink": "https://pixabay.com/photos/graveyard-san-juan-puerto-rico-602357/",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico"
              },
              "landmark": {
                  "name": "Castillo San Felipe Del Morro",
                  "photographer": "John Scofield",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Castillo_San_Felipe_del_Morro_%2810_of_1%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Castillo_San_Felipe_del_Morro",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Bayamon",
                  "photographer": "Yarfpr",
                  "imageLink": "https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "TN",
                      "number": 4
                  },
                  {
                      "code": "KY",
                      "number": 1
                  }
              ],
              "uniqueId": "SGFmW5vg",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "Free State/Old Line State",
                  "Evergreen State/Chinhook State",
                  "Natural State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Puerto Rico is an archipelago with multiple islands.",
                  "falseFact": "Puerto Rico's official state animal is the parrot.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Orange Blossom",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "The House on the Rock",
                  "Bellagio Fountain",
                  "Dollywood",
                  "Paw Paw Tunnel",
                  "Bunker Hill Monument",
                  "Wright Brothers National Memorial",
                  "Elephant Rocks State Park"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Raices Fountain",
                  "photographer": "Oscar Rohena",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Raices_Fountain_in_San_Juan,_Puerto_Rico.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Paseo_de_La_Princesa"
              },
              "landmark": {
                  "name": "La Fortaleza",
                  "photographer": "JERRY & ROY KLOTZ MD",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:LA_FORTALEZA.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/La_Fortaleza",
                  "hasLandmark": true
              },
              "city": {
                  "name": "San Juan",
                  "photographer": "N00",
                  "imageLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico"
              },
              "landmarksRound": [
                  {
                      "code": "GA",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 2
                  },
                  {
                      "code": "DE",
                      "number": 2
                  }
              ],
              "uniqueId": "3CCZWRc9",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Garden State",
                  "Beaver State",
                  "Hawkeye State/Corn State",
                  "Hoosier State",
                  "Badger State/America's Dairyland"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Puerto Rico was first colonized by European explorers when the ________ came over in the 1400s.",
                  "fillAnswer": "Spanish",
                  "fillAnswers": [
                      "French",
                      "Spanish",
                      "Portugese",
                      "English"
                  ]
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Peony",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "Cadillac Ranch",
                  "Joshua Tree National Park",
                  "Arlington National Cemetery",
                  "John Brown's Fort",
                  "Wrigley Field",
                  "Fort Griswold",
                  "Shrine of the Grotto of the Redemption"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Seralles Castle",
                  "photographer": "Ian Poellet",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Castillo_Serralles_from_below_-_Ponce_Puerto_Rico.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Castillo_Serrall%C3%A9s"
              },
              "landmark": {
                  "name": "Castillo San Cristobal",
                  "photographer": "Mariordo",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Fortaleza_San_Cristobal_SJU_06_2019_8294.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Castillo_San_Crist%C3%B3bal_(San_Juan)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Ponce",
                  "photographer": "Jose Oquendo",
                  "imageLink": "https://en.wikipedia.org/wiki/Ponce,_Puerto_Rico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ponce,_Puerto_Rico"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 4
                  },
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "CT",
                      "number": 2
                  }
              ],
              "uniqueId": "v2RnfPKR",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Beaver State",
                  "Golden State",
                  "Volunteer State",
                  "Magnolia State",
                  "Pine Tree State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Becuse Puerto Rico is a commonwealth of the United States, Puerto Ricans are U.S. citizens.",
                  "falseFact": "Despite being a tropical island, Puerto Rico has no rainforests.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Sego Lily",
                  "Goldenrod"
              ],
              "landmarkPlaces": [
                  "Forsynth Park",
                  "Statue of Liberty",
                  "Soldiers & Sailors Monument",
                  "The Witch House at Salem",
                  "Dinosaur Park",
                  "The Mark Twain House",
                  "Blue Whale of Catoosa"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Flamenco Beach",
                  "photographer": "alexortiz05",
                  "imageLink": "https://pixabay.com/photos/puerto-rico-culebra-flamenco-beach-2354518/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Flamenco_Beach"
              },
              "landmark": {
                  "name": "Pico El Yunque",
                  "photographer": "Harvey Barrison",
                  "imageLink": "https://en.wikipedia.org/wiki/El_Yunque_(Puerto_Rico)#/media/File:Clearer_view_of_Yunque's_top_from_the_Yokah%C3%BA_Tower.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/El_Yunque_(Puerto_Rico)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Carolina",
                  "photographer": "Madrican",
                  "imageLink": "https://en.wikipedia.org/wiki/Carolina,_Puerto_Rico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Carolina,_Puerto_Rico"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 5
                  }
              ],
              "uniqueId": "4H7FYkhy",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Sooner State",
                  "Green Mountain State",
                  "Palmetto State",
                  "Beaver State",
                  "Show Me State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Arecibo Observatory is in Puerto Rico, the largest single-dish radio telescope in the world.",
                  "falseFact": "Puerto Rico is about the same size as Pennsylvania.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Peony",
                  "Wood Violet",
                  "Yellow Elder"
              ],
              "landmarkPlaces": [
                  "Dinosaur National Monument",
                  "Garden of the Gods",
                  "Golden Gate Bridge",
                  "Cave of the Mounds",
                  "The Witch House at Salem",
                  "Nemours Estate",
                  "Ark Encounter"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "El Capitolo",
                  "photographer": "Brad Clinesmith",
                  "imageLink": "https://en.wikipedia.org/wiki/Capitol_of_Puerto_Rico#/media/File:Capitolio_de_Puerto_Rico_(28755163211).jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Capitol_of_Puerto_Rico"
              },
              "landmark": {
                  "name": "Puerta de San Juan",
                  "photographer": "Mariordo",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Puerta_San_Juan_SJU_06_2019_8666.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Port_of_San_Juan",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Caguas",
                  "photographer": "Caguas Gov",
                  "imageLink": "https://en.wikipedia.org/wiki/Caguas,_Puerto_Rico",
                  "wikiLink": "https://en.wikipedia.org/wiki/Caguas,_Puerto_Rico"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 4
                  },
                  {
                      "code": "NJ",
                      "number": 3
                  },
                  {
                      "code": "ND",
                      "number": 5
                  }
              ],
              "uniqueId": "YGymmm2b",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "The District",
                  "Granite State",
                  "Green Mountain State",
                  "Beaver State",
                  "Empire State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Puerto Rico came under the control of the United States in __________.",
                  "fillAnswer": "1898",
                  "fillAnswers": [
                      "1675",
                      "1816",
                      "1898",
                      "1945"
                  ]
              },
              "flowerOptions": [
                  "Forget-me-not",
                  "Orange Blossom",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Fort Laramie",
                  "Thunder Hole",
                  "Stonington Harbour Light",
                  "Taliesin East",
                  "USS Constitution",
                  "Milwaukee Art Museum",
                  "Ellis Island"
              ]
          }
      ],
      "capital": "San Juan",
      "flower": {
          "name": "Flor De Maga",
          "imgLink": "https://en.wikipedia.org/wiki/Thespesia_grandiflora#/media/File:Flower_of_the_maga_tree.jpg",
          "imgLink2": "https://pixabay.com/photos/flower-maga-puerto-rico-3428241/",
          "wikiLink": "https://en.wikipedia.org/wiki/Thespesia_grandiflora"
      },
      "nickname": "Island of Enchantment"
  },
  {
      "name": "Rhode Island",
      "code": "RI",
      "lat": 41.580095,
      "long": -71.477429,
      "cities": [
          "Newport",
          "Providence",
          "Warwick",
          "Cranston",
          "Pawtucket",
          "Tacoma"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Rose Island",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/rose-island-panorama-lighthouse-1632524/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rose_Island_(Rhode_Island)"
              },
              "landmark": {
                  "name": "Fort Adams State Park",
                  "photographer": "Dietmar Rabich",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Newport_%28Rhode_Island,_USA%29,_Fort_Adams_--_2006_--_3146.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Adams_State_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Newport",
                  "photographer": "PublicDomainPictures",
                  "imageLink": "https://en.wikipedia.org/wiki/Newport,_Rhode_Island",
                  "wikiLink": "https://en.wikipedia.org/wiki/Newport,_Rhode_Island"
              },
              "landmarksRound": [
                  {
                      "code": "MA",
                      "number": 4
                  },
                  {
                      "code": "NV",
                      "number": 3
                  },
                  {
                      "code": "HI",
                      "number": 1
                  }
              ],
              "uniqueId": "dm5wvh5t",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Beehive State",
                  "Centennial State",
                  "Palmetto State",
                  "Show Me State",
                  "Prairie State/Land of Lincoln"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "There are some ___ lighthouses in the small state of Rhode Island.",
                  "fillAnswer": "21",
                  "fillAnswers": [
                      "4",
                      "21",
                      "52",
                      "103"
                  ]
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "American Dogwood",
                  "Pasque Flower"
              ],
              "landmarkPlaces": [
                  "Fort Worden",
                  "Bandelier National Monument",
                  "Bellagio Fountain",
                  "Wrigley Field",
                  "SkyWheel Myrtle Beach",
                  "Mississippi Petrified Forest",
                  "Fenwick Island Lighthouse"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Narraganset",
                  "photographer": "Wickedgood",
                  "imageLink": "https://pixabay.com/photos/storm-surge-breech-hurricane-3735936/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Narragansett,_Rhode_Island"
              },
              "landmark": {
                  "name": "The Breakers",
                  "photographer": "xiquinhosilva",
                  "imageLink": "https://en.wikipedia.org/wiki/The_Breakers#/media/File:The_Breakers,_exterior_ii.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Breakers",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Providence",
                  "photographer": "HensleyStudios",
                  "imageLink": "https://en.wikipedia.org/wiki/Providence,_Rhode_Island",
                  "wikiLink": "https://en.wikipedia.org/wiki/Providence,_Rhode_Island"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 1
                  },
                  {
                      "code": "VA",
                      "number": 2
                  },
                  {
                      "code": "ME",
                      "number": 3
                  }
              ],
              "uniqueId": "YXfLpqjW",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "The District",
                  "Centennial State",
                  "Lone Star State",
                  "Evergreen State/Chinhook State",
                  "Sunshine State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Rhode Island is the _____ U.S. state.",
                  "fillAnswer": "Smallest",
                  "fillAnswers": [
                      "Biggest",
                      "Smallest",
                      "Oldest",
                      "Driest"
                  ]
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Yucca Flower",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "Martin Luther King Jr. Childhood Home",
                  "Mount Vernon",
                  "Bunker Hill Monument",
                  "Tupelo National Battlefield",
                  "Fort Griswold",
                  "Shrine of the Grotto of the Redemption",
                  "Oriole Park"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Block Island",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/block-island-rhode-island-lighthouse-7397395/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Block_Island"
              },
              "landmark": {
                  "name": "Brown University",
                  "photographer": "Kenneth C. Zirkel",
                  "imageLink": "https://en.wikipedia.org/wiki/Brown_University#/media/File:Brown's_University_Hall_in_2007.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Brown_University",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Warwick",
                  "photographer": "Dietmar Rabich",
                  "imageLink": "https://en.wikipedia.org/wiki/Warwick,_Rhode_Island",
                  "wikiLink": "https://en.wikipedia.org/wiki/Warwick,_Rhode_Island"
              },
              "landmarksRound": [
                  {
                      "code": "NH",
                      "number": 1
                  },
                  {
                      "code": "UT",
                      "number": 3
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  }
              ],
              "uniqueId": "Lc7lfk8B",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Tar Heel State",
                  "Free State/Old Line State",
                  "Hoosier State",
                  "Green Mountain State",
                  "Aloha State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first circus occurred in this state.",
                  "falseFact": "George Washington, the Nation's First President, was born in Rhode Island.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "Oklahoma Rose",
                  "Hawaiian Hibiscus"
              ],
              "landmarkPlaces": [
                  "Everglades",
                  "Old Idaho Penitentiary",
                  "Coral Castle",
                  "Wynwood Walls",
                  "Space Needle",
                  "Centennial Land Run Monument",
                  "Fort Snelling"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Block Island",
                  "photographer": "MOHANN",
                  "imageLink": "https://pixabay.com/photos/block-island-rhode-island-lighthouse-7397399/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Block_Island"
              },
              "landmark": {
                  "name": "Marble House",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/the-marble-house-newport-364188/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Marble_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cranston",
                  "photographer": "formulanone",
                  "imageLink": "https://en.wikipedia.org/wiki/Cranston,_Rhode_Island",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cranston,_Rhode_Island"
              },
              "landmarksRound": [
                  {
                      "code": "IN",
                      "number": 5
                  },
                  {
                      "code": "VT",
                      "number": 5
                  },
                  {
                      "code": "ME",
                      "number": 5
                  }
              ],
              "uniqueId": "sJYyQnTH",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Empire State",
                  "Beehive State",
                  "Bluegrass State",
                  "Palmetto State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Rhode Island sank an English ship called The Gaspee in the first military action of the Revolutionary War.",
                  "falseFact": "Rhode Island is the golf capital of the world.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "Wild Rose",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Space Needle",
                  "Fort Laramie",
                  "Missouri Botanical Garden",
                  "Rocky Statue",
                  "Fontainebleau State Park",
                  "Devil's Tower National Monument",
                  "Starved Rock State Park"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Kingscote",
                  "photographer": "Dms1788",
                  "imageLink": "https://en.wikipedia.org/wiki/Kingscote_(mansion)#/media/File:Kingscote_2018-06-14.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kingscote_(mansion)"
              },
              "landmark": {
                  "name": "The Elms",
                  "photographer": "Marco Almbauer",
                  "imageLink": "https://en.wikipedia.org/wiki/The_Elms_(Newport,_Rhode_Island)#/media/File:The_Elms_-_Rhode_Island.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Elms_(Newport,_Rhode_Island)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Pawtucket",
                  "photographer": "Kenneth C. Zirkel",
                  "imageLink": "https://en.wikipedia.org/wiki/Pawtucket,_Rhode_Island",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pawtucket,_Rhode_Island"
              },
              "landmarksRound": [
                  {
                      "code": "NY",
                      "number": 2
                  },
                  {
                      "code": "IN",
                      "number": 5
                  },
                  {
                      "code": "MS",
                      "number": 3
                  }
              ],
              "uniqueId": "Djv8kzSW",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Hoosier State",
                  "Equality State",
                  "Flickertail State/Sioux State",
                  "Hawkeye State/Corn State",
                  "The District"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Rhode Island was the very first state in the union to abolish slavery. ",
                  "falseFact": "Rhode Island was not actually a colony; it was inhabited land that was not formalyl recognized until after the United States gained independence.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Wild Rose",
                  "Peony"
              ],
              "landmarkPlaces": [
                  "Bourbon Street",
                  "Thunder Hole",
                  "Seven Magic Mountains",
                  "Fremont Troll",
                  "Pictograph Cave State Park",
                  "Old State House",
                  "Eastern State Penitentiary"
              ]
          }
      ],
      "capital": "Providence",
      "flower": {
          "name": "Violet",
          "imgLink": "https://pixabay.com/photos/flowers-viola-bloom-botany-blossom-7171115/",
          "imgLink2": "https://pixabay.com/photos/violet-viola-purple-plant-flower-292373/",
          "wikiLink": "https://en.wikipedia.org/wiki/Viola_(plant)"
      },
      "nickname": "Ocean State"
  },
  {
      "name": "South Carolina",
      "code": "SC",
      "lat": 33.836081,
      "long": -81.163725,
      "cities": [
          "Charleston",
          "Columbia",
          "Greenville",
          "Myrtle Beach",
          "Clemson",
          "Rutland City"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Angel Oak Tree",
                  "photographer": "RegalShave",
                  "imageLink": "https://pixabay.com/photos/angel-oak-charleston-oak-tree-2020783/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Angel_Oak"
              },
              "landmark": {
                  "name": "Myrtle Beach",
                  "photographer": "tball",
                  "imageLink": "https://pixabay.com/photos/myrtle-beach-beach-summer-travel-7394921/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Myrtle_Beach,_South_Carolina",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Charleston",
                  "photographer": "1778011",
                  "imageLink": "https://en.wikipedia.org/wiki/Charleston,_South_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charleston,_South_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "PA",
                      "number": 5
                  },
                  {
                      "code": "IA",
                      "number": 4
                  },
                  {
                      "code": "VI",
                      "number": 1
                  }
              ],
              "uniqueId": "wnYxCfLn",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Volunteer State",
                  "Green Mountain State",
                  "Pelican State/Creole State/Sugar State",
                  "First State/Diamond State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "King Charles was the namesake for this state.",
                  "falseFact": "This state is home to a landmark called Cockroach Island which is home to more than a dozen cockroach species.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Yellow Elder",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Fontainebleau State Park",
                  "St. Patrick's Cathedral",
                  "Statue of Liberty",
                  "Centennial Land Run Monument",
                  "Shoshone Falls",
                  "Cedar Point",
                  "Blanchard Springs Cavern"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Morris Island Lighthouse",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/morris-island-lighthouse-landmark-3996252/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Morris_Island_Lighthouse"
              },
              "landmark": {
                  "name": "Drayton Hall",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/drayton-hall-charleston-4046948/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Drayton_Hall",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Columbia",
                  "photographer": "Akhenaton06",
                  "imageLink": "https://en.wikipedia.org/wiki/Columbia,_South_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Columbia,_South_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "AR",
                      "number": 1
                  },
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "NY",
                      "number": 3
                  }
              ],
              "uniqueId": "5Jyfj2Zc",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Badger State/America's Dairyland",
                  "Pine Tree State",
                  "Equality State",
                  "Pelican State/Creole State/Sugar State",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The Angel Oak tree near Charlestone, South Carolina is the ____ tree east of the Mississippi.",
                  "fillAnswer": "Oldest",
                  "fillAnswers": [
                      "Biggest",
                      "Oldest",
                      "Tallest",
                      "Thickest"
                  ]
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Yellow Elder",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "Biltmore",
                  "Cheaha Mountain",
                  "Cuyahoga Valley",
                  "Cave of the Mounds",
                  "Myriad Botanical Gardens",
                  "Devil's Tower National Monument",
                  "Busch Stadium"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Myrtle Beach",
                  "photographer": "bootsbowsandbeaches",
                  "imageLink": "https://pixabay.com/photos/myrtle-beach-sunrise-beach-ocean-3386548/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Myrtle_Beach,_South_Carolina"
              },
              "landmark": {
                  "name": "Ravenel Bridge",
                  "photographer": "1778011",
                  "imageLink": "https://pixabay.com/photos/ravenel-bridge-architecture-4046936/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Arthur_Ravenel_Jr._Bridge",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Greenville",
                  "photographer": "Donald West",
                  "imageLink": "https://en.wikipedia.org/wiki/Greenville,_South_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Greenville,_South_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "DE",
                      "number": 2
                  },
                  {
                      "code": "VT",
                      "number": 4
                  }
              ],
              "uniqueId": "7QqQq3dC",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "North Star State/Gopher State",
                  "The Mount Rushmore State",
                  "Aloha State",
                  "Tar Heel State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The loggerhead turtle is the official state reptile of South Carolina.",
                  "falseFact": "The Hoover Dam can be found in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Forget-me-not",
                  "Apple Blossom",
                  "Wild Prairie Rose"
              ],
              "landmarkPlaces": [
                  "Mark Twain Boyhood Home",
                  "Shoshone Falls",
                  "Fort Frederick",
                  "Castillo de San Marcos",
                  "Indianapolis Catacombs",
                  "Independence Rock",
                  "Christiansted National Historic Site"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Edisto Island",
                  "photographer": "donwhite84",
                  "imageLink": "https://pixabay.com/photos/edisto-island-beach-south-carolina-378596/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Edisto_Island"
              },
              "landmark": {
                  "name": "Falls Park on the Reedy",
                  "photographer": "usaf314",
                  "imageLink": "https://pixabay.com/photos/greenville-falls-carolina-park-1939233/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Falls_Park_on_the_Reedy",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Myrtle Beach",
                  "photographer": "James Willamor",
                  "imageLink": "https://en.wikipedia.org/wiki/Myrtle_Beach,_South_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Myrtle_Beach,_South_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "MO",
                      "number": 1
                  }
              ],
              "uniqueId": "N4hNc295",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "America’s Paradise",
                  "Island of Enchantment",
                  "Cornhusker State/Beef State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Sweet tea, a favored Southern beverage, was first sipped in this state.",
                  "falseFact": "South Carolina was the unofficial 14th colony.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Camellia",
                  "Wild Prairie Rose",
                  "Hawaiian Hibiscus"
              ],
              "landmarkPlaces": [
                  "Wynwood Walls",
                  "Ford's Theatre",
                  "George Washington Bridge",
                  "Hanauma Bay",
                  "Pittock Mansion",
                  "Delicate Arch",
                  "Ellis Island"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Charleston City Market",
                  "photographer": "Brian Stansberry",
                  "imageLink": "https://en.wikipedia.org/wiki/City_Market_(Charleston,_South_Carolina)#/media/File:Market-hall-charleston-sc1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/City_Market_(Charleston,_South_Carolina)"
              },
              "landmark": {
                  "name": "SkyWheel Myrtle Beach",
                  "photographer": "Claire P",
                  "imageLink": "https://en.wikipedia.org/wiki/Myrtle_Beach_SkyWheel#/media/File:Myrtle_Beach_SkyWheel.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Myrtle_Beach_SkyWheel",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Clemson",
                  "photographer": "blahedo",
                  "imageLink": "https://en.wikipedia.org/wiki/Clemson,_South_Carolina",
                  "wikiLink": "https://en.wikipedia.org/wiki/Clemson,_South_Carolina"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 3
                  },
                  {
                      "code": "TX",
                      "number": 4
                  },
                  {
                      "code": "PR",
                      "number": 2
                  }
              ],
              "uniqueId": "rvWDp6sk",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "The District",
                  "Buckeye State",
                  "Cotton State/Yellowhammer State",
                  "Green Mountain State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "South Carolina is relatively small, comapred to other U.S. states, coming in ____ in terms of land size.",
                  "fillAnswer": "40th",
                  "fillAnswers": [
                      "27th",
                      "31st",
                      "34th",
                      "40th"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Pink And White Lady's Slipper",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "Santa Fe Plaza",
                  "The Mark Twain House",
                  "Waimea Canyon",
                  "Joshua Tree National Park",
                  "Tryon Palace",
                  "Everglades",
                  "Dignity Statue"
              ]
          }
      ],
      "capital": "Columbia",
      "flower": {
          "name": "Yellow Jessamine",
          "imgLink": "https://commons.wikimedia.org/wiki/File:Gelsemium_sempervirens_7zz.jpg",
          "imgLink2": "https://commons.wikimedia.org/wiki/File:Gelsemium_sempervirens_003.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Gelsemium_sempervirens"
      },
      "nickname": "Palmetto State"
  },
  {
      "name": "South Dakota",
      "code": "SD",
      "lat": 43.969515,
      "long": -99.901813,
      "cities": [
          "Sioux Falls",
          "Pierre",
          "Rapid City",
          "Watertown",
          "Mitchell",
          "St Louis"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Badlands",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/stormy-badlands-badlands-national-3658494/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Badlands_National_Park"
              },
              "landmark": {
                  "name": "Mount Rushmore",
                  "photographer": "janeb13",
                  "imageLink": "https://pixabay.com/photos/mount-rushmore-1157477/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Rushmore",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Sioux Falls",
                  "photographer": "Maxpower2727",
                  "imageLink": "https://en.wikipedia.org/wiki/Sioux_Falls,_South_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Sioux_Falls,_South_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 3
                  },
                  {
                      "code": "MN",
                      "number": 2
                  },
                  {
                      "code": "KY",
                      "number": 1
                  }
              ],
              "uniqueId": "j3M9KgPj",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Buckeye State",
                  "Lone Star State",
                  "Golden State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The state slogan of this state is \"Great faces, great places.\"",
                  "falseFact": "The state animal of South Dakota is the bison.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Forget-me-not",
                  "Wild Rose",
                  "Hawaiian Hibiscus"
              ],
              "landmarkPlaces": [
                  "Devil's Tower National Monument",
                  "Old Faithful",
                  "Castillo San Felipe Del Morro",
                  "Sandy Hook Lighthouse",
                  "Seven Magic Mountains",
                  "Temple Square",
                  "Delicate Arch"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Badlands",
                  "photographer": "elljay",
                  "imageLink": "https://pixabay.com/photos/badlands-rock-formations-blue-sky-1110546/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Badlands_National_Park"
              },
              "landmark": {
                  "name": "The Mammoth Site",
                  "photographer": "Jeff the quiet",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Mammoth_Site_Hot_Springs.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Mammoth_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Pierre",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Pierre,_South_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pierre,_South_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "CO",
                      "number": 5
                  },
                  {
                      "code": "PR",
                      "number": 5
                  },
                  {
                      "code": "KS",
                      "number": 4
                  }
              ],
              "uniqueId": "lzd7kcjK",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Aloha State",
                  "Beaver State",
                  "Flickertail State/Sioux State",
                  "Cotton State/Yellowhammer State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The official state insect of this state is the honey bee.",
                  "falseFact": "This state has around 128 people per square mile.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Forget-me-not",
                  "Goldenrod"
              ],
              "landmarkPlaces": [
                  "Mount Hood",
                  "Aerial Lift Bridge",
                  "Falls Park on the Reedy",
                  "Ocean City Boardwalk",
                  "Bennington Battle Monument",
                  "Field of Dreams",
                  "Cadillac Ranch"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Deadwood",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/deadwood-south-dakota-town-210363/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Deadwood,_South_Dakota"
              },
              "landmark": {
                  "name": "The World's Only Corn Palace",
                  "photographer": "ArtTower",
                  "imageLink": "https://pixabay.com/photos/corn-palace-mitchell-south-dakota-50053/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Corn_Palace",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rapid City",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Rapid_City,_South_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rapid_City,_South_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "VA",
                      "number": 2
                  },
                  {
                      "code": "MI",
                      "number": 1
                  }
              ],
              "uniqueId": "Vc7B6jY8",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Evergreen State/Chinhook State",
                  "Land of Enchantment",
                  "Hawkeye State/Corn State",
                  "Golden State",
                  "Bluegrass State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has an official state fossil: the triceratops.",
                  "falseFact": "Denali National Park sprawls across most of the northern half of this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "Sunflower",
                  "Yellow Elder"
              ],
              "landmarkPlaces": [
                  "Elvis Presley Birthplace",
                  "Minidoka National Historic Site",
                  "Indiana Caverns",
                  "Aerial Lift Bridge",
                  "Lake Champlain",
                  "Maquoketa Caves",
                  "Stonington Harbour Light"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Black Hills",
                  "photographer": "couponblaze",
                  "imageLink": "https://pixabay.com/photos/sylvan-lake-black-hills-stars-3355241/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Black_Hills"
              },
              "landmark": {
                  "name": "Dinosaur Park",
                  "photographer": "Cti613",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Dinosaur_Park_Rapid_City_South_Dakota.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dinosaur_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Watertown",
                  "photographer": "Jon Roanhaus",
                  "imageLink": "https://en.wikipedia.org/wiki/Watertown,_South_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Watertown,_South_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 3
                  },
                  {
                      "code": "CA",
                      "number": 5
                  },
                  {
                      "code": "NJ",
                      "number": 5
                  }
              ],
              "uniqueId": "WhQZxYMG",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Green Mountain State",
                  "Hawkeye State/Corn State",
                  "Granite State",
                  "Tar Heel State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "One person who was considered to be added to Mount Rushmore, but who was eventually dismissed as an option, was _______.",
                  "fillAnswer": "Sioux chief Red Cloud",
                  "fillAnswers": [
                      "Civil rights leader Martin Luther King Jr.",
                      "President John Adams",
                      "President Dwight D. Eisenhower",
                      "Sioux chief Red Cloud"
                  ]
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Sagebrush",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "St George Utah Temple",
                  "Mount Washington",
                  "Buffalo Bill Ranch",
                  "Luray Caverns",
                  "Houmas House",
                  "National Cowboy & Western Heritage Museum",
                  "Fort Worden"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Badlands",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/deer-in-badlands-grass-deer-grass-3935108/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Badlands_National_Park"
              },
              "landmark": {
                  "name": "Dignity Statue",
                  "photographer": "clarencealford",
                  "imageLink": "https://pixabay.com/photos/statue-monument-woman-female-4574071/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dignity_(statue)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Mitchell",
                  "photographer": "Richie Diesterheft",
                  "imageLink": "https://en.wikipedia.org/wiki/Mitchell,_South_Dakota",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mitchell,_South_Dakota"
              },
              "landmarksRound": [
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "MS",
                      "number": 4
                  },
                  {
                      "code": "WA",
                      "number": 5
                  }
              ],
              "uniqueId": "q6VPP7P3",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "The Last Frontier",
                  "Cornhusker State/Beef State",
                  "Volunteer State",
                  "Pelican State/Creole State/Sugar State",
                  "North Star State/Gopher State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Mount Rushmore, the most famous landmark in this state, to ______.",
                  "fillAnswer": "Boost tourism",
                  "fillAnswers": [
                      "Get rid of excess money",
                      "Convince state Republicans to override a voting rights bill",
                      "Boost tourism",
                      "Provide navigable landmarks in the desert"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Iris",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Old Mississippi State Capitol",
                  "Ellis Island",
                  "Sleeping Bear Dunes",
                  "Mount Washington Cog",
                  "Bryce Canyon",
                  "The Alamo",
                  "Monticello"
              ]
          }
      ],
      "capital": "Pierre",
      "flower": {
          "name": "Pasque Flower",
          "imgLink": "https://pixabay.com/photos/flower-cow-bells-nature-7870537/",
          "imgLink2": "https://pixabay.com/photos/pasque-flower-flower-nature-spring-4102126/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pulsatilla"
      },
      "nickname": "The Mount Rushmore State"
  },
  {
      "name": "Tennessee",
      "code": "TN",
      "lat": 35.517491,
      "long": -86.580447,
      "cities": [
          "Knoxville",
          "Memphis",
          "Nashville",
          "Clarksville",
          "Chattanooga",
          "Cedar Rapids"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Great Smoky Mountains",
                  "photographer": "nerka3",
                  "imageLink": "https://pixabay.com/photos/smoky-mountains-tennessee-landscape-969223/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Great_Smoky_Mountains"
              },
              "landmark": {
                  "name": "Graceland",
                  "photographer": "Terry Waggoner",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Rear_view_of_Graceland,_the_home_of_Elvis_Presley.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Graceland",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Knoxville",
                  "photographer": "djgmix",
                  "imageLink": "https://en.wikipedia.org/wiki/Knoxville,_Tennessee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Knoxville,_Tennessee"
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 1
                  },
                  {
                      "code": "DC",
                      "number": 5
                  },
                  {
                      "code": "MO",
                      "number": 1
                  }
              ],
              "uniqueId": "LpD4mqZg",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Empire State",
                  "North Star State/Gopher State",
                  "Badger State/America's Dairyland",
                  "Treasure State/Big Sky Country",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Graceland, Elvis Presley's former home, is in this state.",
                  "falseFact": "Blueberries are a popular Tennessee symbol.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "California Poppy",
                  "Mock Orange",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Natural Bridge Caverns",
                  "Vermont State House",
                  "Knife River Indian Villages",
                  "Klondike Gold Rush National Historic Park",
                  "Castillo San Felipe Del Morro",
                  "Fort Union Trading Post",
                  "The Elms"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Chattanooga",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/chattanooga-tennessee-city-cities-1719772/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chattanooga,_Tennessee"
              },
              "landmark": {
                  "name": "Grand Ole Opry",
                  "photographer": "Daniel Schwen",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Ryman_Auditorium.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Ole_Opry",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Memphis",
                  "photographer": "Luca Sartoni",
                  "imageLink": "https://en.wikipedia.org/wiki/Memphis,_Tennessee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Memphis,_Tennessee"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 5
                  },
                  {
                      "code": "MA",
                      "number": 2
                  },
                  {
                      "code": "UT",
                      "number": 1
                  }
              ],
              "uniqueId": "X5FBxdd7",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Sunshine State",
                  "Nutmeg State/Constitution State",
                  "Beehive State",
                  "Centennial State",
                  "Garden State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Popular soda _____ was first sold in Tennessee.",
                  "fillAnswer": "Mountain Dew",
                  "fillAnswers": [
                      "Coca Cola",
                      "Pepsi Cola",
                      "Sprite",
                      "Mountain Dew"
                  ]
              },
              "flowerOptions": [
                  "Scarlet Carnation",
                  "Violet",
                  "Orange Blossom"
              ],
              "landmarkPlaces": [
                  "TransAmerica Pyramid",
                  "Minidoka National Historic Site",
                  "Cedar Point",
                  "Devil's Tower National Monument",
                  "Mackinaw Island",
                  "Hocking Hills State Park",
                  "Hanauma Bay"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Cades Cove",
                  "photographer": "USA-Reiseblogger",
                  "imageLink": "https://pixabay.com/photos/usa-america-nature-national-park-1647305/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cades_Cove"
              },
              "landmark": {
                  "name": "Dollywood",
                  "photographer": "Coasterman1234",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Mystery_Mine_%28Dollywood%29_04.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dollywood",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Nashville",
                  "photographer": "paulbr75",
                  "imageLink": "https://en.wikipedia.org/wiki/Nashville,_Tennessee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Nashville,_Tennessee"
              },
              "landmarksRound": [
                  {
                      "code": "CO",
                      "number": 2
                  },
                  {
                      "code": "MA",
                      "number": 3
                  },
                  {
                      "code": "NM",
                      "number": 4
                  }
              ],
              "uniqueId": "Q3j9P5vN",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Mountain State",
                  "Lone Star State",
                  "Sooner State",
                  "Centennial State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Nashville, Tennessee is sometimes called the _____ of the South.",
                  "fillAnswer": "Athens",
                  "fillAnswers": [
                      "London",
                      "Boston",
                      "New York City",
                      "Athens"
                  ]
              },
              "flowerOptions": [
                  "Bitterroot",
                  "Wild Rose",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "Fenwick Island Lighthouse",
                  "Churchill Downs",
                  "Fort Laramie",
                  "Cathedral Caverns State Park",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Castillo San Cristobal",
                  "Mississippi Petrified Forest"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Tennessee State Capitol",
                  "photographer": "Ichabod",
                  "imageLink": "https://en.wikipedia.org/wiki/Tennessee_State_Capitol#/media/File:Tennessee_State_Capitol_Nighttime.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tennessee_State_Capitol"
              },
              "landmark": {
                  "name": "The Parthenon",
                  "photographer": "White.alister.t",
                  "imageLink": "https://en.wikipedia.org/wiki/Parthenon_(Nashville)#/media/File:Parthenon_Nashville.png",
                  "wikiLink": "https://en.wikipedia.org/wiki/Parthenon_(Nashville)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Clarksville",
                  "photographer": "Jugarum",
                  "imageLink": "https://en.wikipedia.org/wiki/Clarksville,_Tennessee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Clarksville,_Tennessee"
              },
              "landmarksRound": [
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  }
              ],
              "uniqueId": "TH75Hh5T",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Island of Enchantment",
                  "Lone Star State",
                  "North Star State/Gopher State",
                  "Beehive State",
                  "Green Mountain State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Country Music Capital of the World, Nashville, is in Tennessee.",
                  "falseFact": "The first Black U.S. Senator hailed from Tennessee.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Peach Blossom",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Olbrich Botanical Gardens",
                  "Fort McHenry",
                  "Shrine of the Grotto of the Redemption",
                  "Martin Luther King Jr. Childhood Home",
                  "Red River Gorge",
                  "Soldiers & Sailors Monument",
                  "Mount Washington"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Memphis",
                  "photographer": "Thomas R Matchnitski",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Memphis_skyline_pyramid.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Memphis,_Tennessee"
              },
              "landmark": {
                  "name": "Beale Street",
                  "photographer": "BruceEmmerling",
                  "imageLink": "https://pixabay.com/photos/beale-street-memphis-blues-music-4236496/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Beale_Street",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Chattanooga",
                  "photographer": "WeaponizingArchitecture",
                  "imageLink": "https://en.wikipedia.org/wiki/Chattanooga,_Tennessee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chattanooga,_Tennessee"
              },
              "landmarksRound": [
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "SD",
                      "number": 3
                  },
                  {
                      "code": "NM",
                      "number": 5
                  }
              ],
              "uniqueId": "5pCVmTDR",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "Peace State/Empire State of the South",
                  "Palmetto State",
                  "The Last Frontier",
                  "Land of Enchantment"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first female senator, Hattie Caraway, was born in this state.",
                  "falseFact": "Red licorice was first invented in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Hawthorn",
                  "Orange Blossom",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "USS Constitution",
                  "Fort Worden",
                  "George Washington Bridge",
                  "Scotts Bluff",
                  "Myrtle Beach",
                  "Warner Theatre",
                  "Joshua Tree National Park"
              ]
          }
      ],
      "capital": "Nashville",
      "flower": {
          "name": "Iris",
          "imgLink": "https://pixabay.com/photos/iris-iris-flower-sword-lily-family-3385749/",
          "imgLink2": "https://pixabay.com/photos/flower-iris-meadow-wildflower-76336/",
          "wikiLink": "https://en.wikipedia.org/wiki/Iris_(plant)"
      },
      "nickname": "Volunteer State"
  },
  {
      "name": "Texas",
      "code": "TX",
      "lat": 31.968599,
      "long": -99.901813,
      "cities": [
          "San Antonio",
          "Dallas",
          "Austin",
          "Houston",
          "Fort Worth",
          "Myrtle Beach"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Rio Grande",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/rio-grande-river-texas-1581917/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rio_Grande"
              },
              "landmark": {
                  "name": "The Alamo",
                  "photographer": "elcorredor",
                  "imageLink": "https://pixabay.com/photos/the-alamo-texas-san-antonio-usa-1644473/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Alamo_Mission",
                  "hasLandmark": true
              },
              "city": {
                  "name": "San Antonio",
                  "photographer": "Goodfreephotos_com",
                  "imageLink": "https://en.wikipedia.org/wiki/San_Antonio",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Antonio"
              },
              "landmarksRound": [
                  {
                      "code": "NE",
                      "number": 4
                  },
                  {
                      "code": "VT",
                      "number": 5
                  },
                  {
                      "code": "ND",
                      "number": 4
                  }
              ],
              "uniqueId": "vpNtR2tY",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "The Last Frontier",
                  "Land of Enchantment",
                  "Wolverine State/Great Lake State",
                  "Beaver State",
                  "Ocean State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Texas has been owned, at various times, by Spain, France, Mexico, and the United States. ",
                  "falseFact": "At one point, Texas tried to become an independent state, which would have been called the Republic of Texas, but it never successfully seceeded.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Rose",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Maquoketa Caves",
                  "Annaberg Sugar Plantation",
                  "The Mark Twain House",
                  "The Greenbrier",
                  "Rocky Statue",
                  "Vulcan Statue",
                  "Cave of the Mounds"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Houston",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/houston-texas-city-urban-cityscape-1631453/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Houston"
              },
              "landmark": {
                  "name": "Cadillac Ranch",
                  "photographer": "RosZie",
                  "imageLink": "https://pixabay.com/photos/cars-cadillac-ranch-cadillac-usa-7035228/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cadillac_Ranch",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Dallas",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Dallas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Dallas"
              },
              "landmarksRound": [
                  {
                      "code": "RI",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 1
                  },
                  {
                      "code": "OR",
                      "number": 4
                  }
              ],
              "uniqueId": "3pflklsS",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Bluegrass State",
                  "Island of Enchantment",
                  "Treasure State/Big Sky Country",
                  "Equality State",
                  "Palmetto State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "There is a ranch in Texas called King Ranch which is larger than Rhode Island.",
                  "falseFact": "Texas is one of the slimmest U.S. states, with one of the lowest average BMIs in the nation.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Red Clover",
                  "Yellow Elder",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "Portland Head Light",
                  "Garden of the Gods",
                  "Dinosaur National Monument",
                  "Red River Gorge",
                  "Mount Rushmore",
                  "Ellis Island",
                  "Fenwick Island Lighthouse"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Big Bend National Park",
                  "photographer": "Goodfreephotos_com",
                  "imageLink": "https://pixabay.com/photos/sunset-texas-desert-landscape-255280/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Big_Bend_National_Park"
              },
              "landmark": {
                  "name": "San Antonio River Walk",
                  "photographer": "desmarsol",
                  "imageLink": "https://pixabay.com/photos/walk-pathway-san-antonio-riverwalk-292995/",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Antonio_River_Walk",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Austin",
                  "photographer": "K Baucherel",
                  "imageLink": "https://en.wikipedia.org/wiki/Austin,_Texas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Austin,_Texas"
              },
              "landmarksRound": [
                  {
                      "code": "NC",
                      "number": 3
                  },
                  {
                      "code": "WI",
                      "number": 4
                  },
                  {
                      "code": "LA",
                      "number": 2
                  }
              ],
              "uniqueId": "3WKBhWJ4",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Keystone State",
                  "The Last Frontier",
                  "Ocean State",
                  "Volunteer State",
                  "Sooner State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The most famous landmark in Texas is The Alamo.",
                  "falseFact": "Texas is the only Central U.S. state to have never had any buffalo.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Mountain Laurel",
                  "Yellow Jessamine"
              ],
              "landmarkPlaces": [
                  "West Virginia Penitentiary",
                  "The Mark Twain House",
                  "Biltmore",
                  "Thunder Hole",
                  "Denail National Park",
                  "Federal Hill Park",
                  "Cuyahoga Valley"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Austin",
                  "photographer": "kbhall17",
                  "imageLink": "https://pixabay.com/photos/capitol-building-austin-texas-96827/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Austin,_Texas"
              },
              "landmark": {
                  "name": "Natural Bridge Caverns",
                  "photographer": "Rei",
                  "imageLink": "https://en.wikipedia.org/wiki/Natural_Bridge_Caverns#/media/File:NaturalBridgeCaverns11.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Natural_Bridge_Caverns",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Houston",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Houston",
                  "wikiLink": "https://en.wikipedia.org/wiki/Houston"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 5
                  },
                  {
                      "code": "AZ",
                      "number": 1
                  },
                  {
                      "code": "NC",
                      "number": 1
                  }
              ],
              "uniqueId": "6wknH6d4",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "The District",
                  "Golden State",
                  "Bluegrass State",
                  "Bay State/Old Colony State",
                  "Island of Enchantment"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Texas holds ___ of the top 15 fastest growing cities in the U.S.",
                  "fillAnswer": "3",
                  "fillAnswers": [
                      "2",
                      "3",
                      "5",
                      "7"
                  ]
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "Yellow Elder",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "Dover Motor Speedway",
                  "Blue Whale of Catoosa",
                  "Golden Driller Statue",
                  "George Washington Bridge",
                  "Scotts Bluff",
                  "Mount Rushmore",
                  "Garden of the Gods"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Big Bend National Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/rio-grande-river-texas-1581918/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Big_Bend_National_Park"
              },
              "landmark": {
                  "name": "San Jacinto Battleground",
                  "photographer": "Steve Rowell",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:UAV_view_of_the_San_Jacinto_Battlefield_site_and_Texas_Monument.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/San_Jacinto_Battleground_State_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Fort Worth",
                  "photographer": "thecuratedsoul",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Worth,_Texas",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Worth,_Texas"
              },
              "landmarksRound": [
                  {
                      "code": "DE",
                      "number": 5
                  },
                  {
                      "code": "NV",
                      "number": 2
                  },
                  {
                      "code": "GA",
                      "number": 3
                  }
              ],
              "uniqueId": "K3RG5bNP",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "The Mount Rushmore State",
                  "Keystone State",
                  "Prairie State/Land of Lincoln",
                  "Free State/Old Line State",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The official state mammal of Texas is the ______.",
                  "fillAnswer": "Armadillo",
                  "fillAnswers": [
                      "Armadillo",
                      "Buffalo",
                      "Deer",
                      "Gopher"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "White Pine Cone And Tassel",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Minidoka National Historic Site",
                  "Denail National Park",
                  "Cathedral Caverns State Park",
                  "Soldiers & Sailors Monument",
                  "Tryon Palace",
                  "Black Canyon of the Gunnison",
                  "Craters of the Moon"
              ]
          }
      ],
      "capital": "Austin",
      "flower": {
          "name": "Bluebonnet",
          "imgLink": "https://pixabay.com/photos/bluebonnets-texas-bluebonnets-spring-2433508/",
          "imgLink2": "https://pixabay.com/photos/bluebonnet-wildflower-texas-flower-4020209/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bluebonnet_(plant)"
      },
      "nickname": "Lone Star State"
  },
  {
      "name": "Utah",
      "code": "UT",
      "lat": 39.32098,
      "long": -111.093731,
      "cities": [
          "Park City",
          "Ogden",
          "Salt Lake City",
          "St. George",
          "Provo",
          "Fayetteville"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Zion National Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/mountains-valley-landscape-139012/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Zion_National_Park"
              },
              "landmark": {
                  "name": "Temple Square",
                  "photographer": "cjou3600",
                  "imageLink": "https://pixabay.com/photos/temple-square-mormon-temple-780110/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Temple_Square",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Park City",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://en.wikipedia.org/wiki/Park_City,_Utah",
                  "wikiLink": "https://en.wikipedia.org/wiki/Park_City,_Utah"
              },
              "landmarksRound": [
                  {
                      "code": "IA",
                      "number": 5
                  },
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "NV",
                      "number": 1
                  }
              ],
              "uniqueId": "MXVSMMw4",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Centennial State",
                  "Empire State",
                  "Sunflower State/Jayhawker State",
                  "Natural State",
                  "Bluegrass State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Utah's literacy rate, with 91% of adults reading above a 4th-grade level, is the ____ in the country.",
                  "fillAnswer": "Highest",
                  "fillAnswers": [
                      "Lowest",
                      "Highest",
                      "Fastest-growing",
                      "Best-reported"
                  ]
              },
              "flowerOptions": [
                  "Magnolia",
                  "Apple Blossom",
                  "Goldenrod"
              ],
              "landmarkPlaces": [
                  "The Elms",
                  "The Mammoth Site",
                  "Seven Magic Mountains",
                  "Fort Griswold",
                  "Magens Bay Beach",
                  "Fort Frederick",
                  "Luray Caverns"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Canyonlands National Park",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/desert-rock-formations-erosion-1730077/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Canyonlands_National_Park"
              },
              "landmark": {
                  "name": "Delicate Arch",
                  "photographer": "ISchneider",
                  "imageLink": "https://pixabay.com/photos/nature-arches-national-park-1681444/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Delicate_Arch",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Ogden",
                  "photographer": "Scott Catron",
                  "imageLink": "https://en.wikipedia.org/wiki/Ogden%2C_Utah",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ogden%2C_Utah"
              },
              "landmarksRound": [
                  {
                      "code": "KS",
                      "number": 2
                  },
                  {
                      "code": "DC",
                      "number": 1
                  },
                  {
                      "code": "AR",
                      "number": 1
                  }
              ],
              "uniqueId": "RXkXsDwB",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Natural State",
                  "Wolverine State/Great Lake State",
                  "Sooner State",
                  "Badger State/America's Dairyland",
                  "Pelican State/Creole State/Sugar State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Great Salt Lake, near Salty Lake City, is the largest lake west of the Mississippi.",
                  "falseFact": "This state was the 36th to join the United States.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Hawaiian Hibiscus",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Golden Gate Bridge",
                  "Acadia National Park",
                  "Tryon Palace",
                  "Chimney Rock",
                  "Strataca",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Old State Capitol"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Monument Valley",
                  "photographer": "manniguttenburger",
                  "imageLink": "https://pixabay.com/photos/monument-valley-desert-road-highway-5499156/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Monument_Valley"
              },
              "landmark": {
                  "name": "Grand Staircase",
                  "photographer": "ducken99",
                  "imageLink": "https://pixabay.com/photos/escalante-national-monument-3058307/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Staircase",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Salt Lake City",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Salt_Lake_City",
                  "wikiLink": "https://en.wikipedia.org/wiki/Salt_Lake_City"
              },
              "landmarksRound": [
                  {
                      "code": "IN",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 3
                  },
                  {
                      "code": "DE",
                      "number": 1
                  }
              ],
              "uniqueId": "SRmXtKqv",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "The Last Frontier",
                  "Show Me State",
                  "The Old Dominion/Mother of Presidents",
                  "Wolverine State/Great Lake State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Famous film festival ______ takes place each year in Salt Lake City.",
                  "fillAnswer": "Sundance",
                  "fillAnswers": [
                      "Venice",
                      "Sundance",
                      "Cannes",
                      "South by Southwest"
                  ]
              },
              "flowerOptions": [
                  "Orange Blossom",
                  "Apple Blossom",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "Annaberg Sugar Plantation",
                  "Baltimore Basilica",
                  "Harding Icefield",
                  "Portland Head Light",
                  "Fort Delaware",
                  "Old Idaho Penitentiary",
                  "Little River Canyon National Preserve"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Bryce Canyon National Park",
                  "photographer": "derwiki",
                  "imageLink": "https://pixabay.com/photos/fairyland-canyon-utah-park-1632749/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bryce_Canyon_National_Park"
              },
              "landmark": {
                  "name": "St George Utah Temple",
                  "photographer": "Ricardo630",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:St._George_Temple.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._George_Utah_Temple",
                  "hasLandmark": true
              },
              "city": {
                  "name": "St. George",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/St._George,_Utah",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._George,_Utah"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "VI",
                      "number": 1
                  },
                  {
                      "code": "PA",
                      "number": 4
                  }
              ],
              "uniqueId": "RwXMdBsL",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Sooner State",
                  "Aloha State",
                  "The Last Frontier",
                  "Green Mountain State",
                  "Hawkeye State/Corn State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state has more Mormons than any other U.S. state.",
                  "falseFact": "It has not snowed in Utah in over ten years.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Wood Violet",
                  "Scarlet Carnation"
              ],
              "landmarkPlaces": [
                  "Mackinaw Island",
                  "Fort Frederick",
                  "Appalachian Trail",
                  "Castillo de San Marcos",
                  "Mount Rushmore",
                  "The Alamo",
                  "Fallingwater"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Moab",
                  "photographer": "Ken Lund",
                  "imageLink": "https://www.flickr.com/photos/kenlund/40910757761",
                  "wikiLink": "https://en.wikipedia.org/wiki/Moab,_Utah"
              },
              "landmark": {
                  "name": "Bryce Canyon",
                  "photographer": "derwiki",
                  "imageLink": "https://pixabay.com/photos/dusk-bryce-canyon-utah-canyon-1802031/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bryce_Canyon_National_Park",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Provo",
                  "photographer": "Javin Weaver",
                  "imageLink": "https://en.wikipedia.org/wiki/Provo,_Utah",
                  "wikiLink": "https://en.wikipedia.org/wiki/Provo,_Utah"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "MO",
                      "number": 1
                  },
                  {
                      "code": "SC",
                      "number": 4
                  }
              ],
              "uniqueId": "gqWvYqCK",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Sunshine State",
                  "Show Me State",
                  "Aloha State",
                  "Golden State",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state is home to many national parks, including Zion National Park and Arches National Park.",
                  "falseFact": "This state is known as a coffee hub with the second-highest coffee consumption of any U.S. state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Indian Paintbrush",
                  "Purple Lilac",
                  "Oregon Grape"
              ],
              "landmarkPlaces": [
                  "Christiansted National Historic Site",
                  "Busch Stadium",
                  "Martin Luther King Jr. Memorial",
                  "Mammoth Cave",
                  "Pittock Mansion",
                  "Carlsbad Caverns",
                  "St. Michael the Archangel Orthodox Cathedral"
              ]
          }
      ],
      "capital": "Salt Lake City",
      "flower": {
          "name": "Sego Lily",
          "imgLink": "https://pixabay.com/photos/sego-lily-wildflowers-4462542/",
          "imgLink2": "https://pixabay.com/photos/sego-lily-west-colorado-landscape-5047926/",
          "wikiLink": "https://en.wikipedia.org/wiki/Calochortus_nuttallii"
      },
      "nickname": "Beehive State"
  },
  {
      "name": "Virginia",
      "code": "VA",
      "lat": 37.431573,
      "long": -78.656894,
      "cities": [
          "Norfolk",
          "Richmond",
          "Arlington",
          "Alexandria",
          "Virginia Beach",
          "Colorado Springs"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Historic Jamestown Church",
                  "photographer": "Tony Fischer",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:1639_Jamestown_Church_%282883847775%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Historic_Jamestown"
              },
              "landmark": {
                  "name": "Luray Caverns",
                  "photographer": "adoborepublic",
                  "imageLink": "https://pixabay.com/photos/luray-virginia-caves-caverns-184172/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Luray_Caverns",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Norfolk",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Norfolk,_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Norfolk,_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "ND",
                      "number": 5
                  },
                  {
                      "code": "OK",
                      "number": 4
                  }
              ],
              "uniqueId": "q652b4Nz",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Flickertail State/Sioux State",
                  "Grand Canyon State",
                  "Evergreen State/Chinhook State",
                  "Ocean State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Arlington Cemetery, the National Cemetery, is located in Virginia just across the border from Washington D.C.",
                  "falseFact": "This state is sometimes called The Bright Lady.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Coast Rhododendron",
                  "Hawaiian Hibiscus",
                  "Goldenrod"
              ],
              "landmarkPlaces": [
                  "Black Canyon of the Gunnison",
                  "Louisville Water Tower",
                  "Thunder Hole",
                  "Pictograph Cave State Park",
                  "Ark Encounter",
                  "Pemaquid Point Lighthouse",
                  "Waimea Canyon"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Meadlowlark Botanical Gardens",
                  "photographer": "Fotocitizen",
                  "imageLink": "https://pixabay.com/photos/virginia-gazebo-park-water-nature-868416/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Meadowlark_Botanical_Gardens"
              },
              "landmark": {
                  "name": "Mount Vernon",
                  "photographer": "Press Factor",
                  "imageLink": "https://pixabay.com/photos/mount-vernon-landmark-vernon-6475579/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Vernon",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Richmond",
                  "photographer": "andrewawood",
                  "imageLink": "https://en.wikipedia.org/wiki/Richmond,_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Richmond,_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 5
                  },
                  {
                      "code": "HI",
                      "number": 5
                  },
                  {
                      "code": "NE",
                      "number": 1
                  }
              ],
              "uniqueId": "FKmFCQPK",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "Golden State",
                  "Keystone State",
                  "Sunflower State/Jayhawker State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was named after \"Virgin Queen\" Elizabeth I.",
                  "falseFact": "The official state bird of Virginia is the eastern bluebird.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Rhododendron",
                  "Oregon Grape",
                  "Mountain Laurel"
              ],
              "landmarkPlaces": [
                  "Portland Head Light",
                  "Missouri Botanical Garden",
                  "TransAmerica Pyramid",
                  "High Roller",
                  "Louisville Water Tower",
                  "Cave of the Mounds",
                  "St George Utah Temple"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Norfolk",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/norfolk-virginia-marina-harbor-bay-1758195/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Norfolk,_Virginia"
              },
              "landmark": {
                  "name": "Arlington National Cemetery",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/arlington-national-cemetery-79576/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Arlington_National_Cemetery",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Arlington",
                  "photographer": "APK",
                  "imageLink": "https://en.wikipedia.org/wiki/Arlington_County,_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Arlington_County,_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "AL",
                      "number": 2
                  },
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "MI",
                      "number": 2
                  }
              ],
              "uniqueId": "GtMLgp9f",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Hawkeye State/Corn State",
                  "Volunteer State",
                  "Granite State",
                  "Ocean State",
                  "Grand Canyon State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The tourism slogan of Virgina is \"__________.\"",
                  "fillAnswer": "Virginia is for Lovers",
                  "fillAnswers": [
                      "Live Like Your Ancestors",
                      "Experience Warmth and Joy",
                      "Welcome to Paradise",
                      "Virginia is for Lovers"
                  ]
              },
              "flowerOptions": [
                  "Pasque Flower",
                  "Hawthorn",
                  "Mayflower"
              ],
              "landmarkPlaces": [
                  "Navy Pier",
                  "Tupelo National Battlefield",
                  "Waimea Canyon",
                  "Old Mississippi State Capitol",
                  "Statue of Liberty",
                  "Coral Castle",
                  "Nemours Estate"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Maymont",
                  "photographer": "JamesDeMers",
                  "imageLink": "https://pixabay.com/photos/museum-old-house-maymont-park-house-53776/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Maymont"
              },
              "landmark": {
                  "name": "Monticello",
                  "photographer": "James DeMers",
                  "imageLink": "https://pixabay.com/photos/monticello-museum-thomas-jefferson-60823/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Monticello",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Alexandria",
                  "photographer": "Duane Lempke",
                  "imageLink": "https://en.wikipedia.org/wiki/Alexandria,_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Alexandria,_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 3
                  },
                  {
                      "code": "DE",
                      "number": 4
                  },
                  {
                      "code": "MA",
                      "number": 3
                  }
              ],
              "uniqueId": "ScVjdr2m",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Ocean State",
                  "Pine Tree State",
                  "Keystone State",
                  "Mountain State",
                  "Natural State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state was once called the ____________.",
                  "fillAnswer": "Birthplace of Presidents",
                  "fillAnswers": [
                      "Birthplace of Presidents",
                      "Cradle of Democracy",
                      "Center of the Revolution",
                      "Home of Revolutionaries"
                  ]
              },
              "flowerOptions": [
                  "Oklahoma Rose",
                  "American Beauty Rose",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "Black Canyon of the Gunnison",
                  "Cape Hatteras Lighthouse",
                  "Fort Union Trading Post",
                  "Shoshone Falls",
                  "Bandelier National Monument",
                  "Garden of the Gods",
                  "Martin Luther King Jr. Childhood Home"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Arlington National Cemetery",
                  "photographer": "esclphotograf",
                  "imageLink": "https://pixabay.com/photos/arlington-arlington-national-cemetery-820751/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Arlington_National_Cemetery"
              },
              "landmark": {
                  "name": "Colonial Williamsburg",
                  "photographer": "James DeMers",
                  "imageLink": "https://pixabay.com/photos/governor-s-palace-mansion-palace-58551/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Colonial_Williamsburg",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Virginia Beach",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Virginia_Beach,_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Virginia_Beach,_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "RI",
                      "number": 2
                  },
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "NH",
                      "number": 4
                  }
              ],
              "uniqueId": "llgynQ8T",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "The Mount Rushmore State",
                  "Badger State/America's Dairyland",
                  "Golden State",
                  "Silver State/Sagebrush State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Pentagon, a major military base in Virgina, is technically the largest office building in the world.",
                  "falseFact": "Clam chowder was first invented in this state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Yellow Jessamine",
                  "Cherokee Rose"
              ],
              "landmarkPlaces": [
                  "Dover Motor Speedway",
                  "George Washington Bridge",
                  "Ben & Jerry's",
                  "Bob Kerrey Pedestrian Bridge",
                  "Hearst Castle",
                  "Old Idaho Penitentiary",
                  "Myriad Botanical Gardens"
              ]
          }
      ],
      "capital": "Richmond",
      "flower": {
          "name": "American Dogwood",
          "imgLink": "https://commons.wikimedia.org/wiki/File:Flowering_Dogwood_Cornus_florida_Flower_High_DoF.JPG",
          "imgLink2": "https://commons.wikimedia.org/wiki/File:Flowering_Dogwood_Cornus_florida_Yellow_Flowers_3008px.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Cornus_florida"
      },
      "nickname": "The Old Dominion/Mother of Presidents"
  },
  {
      "name": "U.S. Virgin Islands",
      "code": "VI",
      "lat": 18.3358,
      "long": -64.8963,
      "cities": [
          "Charlotte Amalie",
          "Frederiksted",
          "Christiansted",
          "Cruz Bay",
          "Red Hook",
          "Sitka"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Virgin Islands National Park",
                  "photographer": "National Parks Service",
                  "imageLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park#/media/File:Virgin_Islands_National_Park.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park"
              },
              "landmark": {
                  "name": "Fort Christian",
                  "photographer": "Prayitno",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Fort_Christian_%2838403977634%29.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Christian",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Charlotte Amalie",
                  "photographer": "wolseleyrd",
                  "imageLink": "https://en.wikipedia.org/wiki/Charlotte_Amalie,_U.S._Virgin_Islands",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charlotte_Amalie,_U.S._Virgin_Islands"
              },
              "landmarksRound": [
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "ND",
                      "number": 3
                  }
              ],
              "uniqueId": "8Dqvp2Mw",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "Grand Canyon State",
                  "Pelican State/Creole State/Sugar State",
                  "Wolverine State/Great Lake State",
                  "Cornhusker State/Beef State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Along with English and Spanish, _____ is commonly spoken in the US Virgin Islands.",
                  "fillAnswer": "French Creole",
                  "fillAnswers": [
                      "French Creole",
                      "Portugese",
                      "Dutch",
                      "Italian"
                  ]
              },
              "flowerOptions": [
                  "Wild Rose",
                  "Red Clover",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Minneapolis Sculpture Garden",
                  "Scotts Bluff",
                  "Fallingwater",
                  "Church Street Marketplace",
                  "Mall of America",
                  "Sandy Hook Lighthouse",
                  "La Fortaleza"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Charlotte Amalie",
                  "photographer": "Rogerwilson",
                  "imageLink": "https://pixabay.com/photos/st-thomas-yachts-bay-travel-sea-1169232/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charlotte_Amalie,_U.S._Virgin_Islands"
              },
              "landmark": {
                  "name": "Christiansted National Historic Site",
                  "photographer": "Christine Hawks",
                  "imageLink": "https://en.wikipedia.org/wiki/Christiansted_National_Historic_Site#/media/File:Stcroixhistoricchristiansted.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Christiansted_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Frederiksted",
                  "photographer": "Cumulus Clouds",
                  "imageLink": "https://en.wikipedia.org/wiki/Frederiksted,_U.S._Virgin_Islands",
                  "wikiLink": "https://en.wikipedia.org/wiki/Frederiksted,_U.S._Virgin_Islands"
              },
              "landmarksRound": [
                  {
                      "code": "CA",
                      "number": 3
                  },
                  {
                      "code": "TX",
                      "number": 1
                  },
                  {
                      "code": "UT",
                      "number": 3
                  }
              ],
              "uniqueId": "MQ4DVsk2",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Centennial State",
                  "Cotton State/Yellowhammer State",
                  "The Last Frontier",
                  "Sooner State",
                  "Pelican State/Creole State/Sugar State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "As much as ____ of all land in the Virgin Islands is designated as part of a national park.",
                  "fillAnswer": "2/3",
                  "fillAnswers": [
                      "1/6",
                      "1/4",
                      "1/3",
                      "2/3"
                  ]
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "Red Clover",
                  "Violet"
              ],
              "landmarkPlaces": [
                  "Valley of Fire State Park",
                  "Tryon Palace",
                  "Fort Larned",
                  "Bellagio Fountain",
                  "The Breakers",
                  "Fort Griswold",
                  "Gateway Arch"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Virgin Islands National Park",
                  "photographer": "U.S. Geological Survey",
                  "imageLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park#/media/File:Sunscreen_in_the_Water_(24775121614).jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park"
              },
              "landmark": {
                  "name": "Fort Frederick",
                  "photographer": "Matt H. Wade",
                  "imageLink": "https://en.wikipedia.org/wiki/Fort_Frederik#/media/File:Fort_Frederik_2012_Wade.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Frederik",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Christiansted",
                  "photographer": "Jpheym",
                  "imageLink": "https://en.wikipedia.org/wiki/Christiansted,_U.S._Virgin_Islands",
                  "wikiLink": "https://en.wikipedia.org/wiki/Christiansted,_U.S._Virgin_Islands"
              },
              "landmarksRound": [
                  {
                      "code": "OR",
                      "number": 4
                  },
                  {
                      "code": "CA",
                      "number": 1
                  },
                  {
                      "code": "WV",
                      "number": 3
                  }
              ],
              "uniqueId": "Tsg2VZMy",
              "weatherMonth": "may",
              "nicknameOptions": [
                  "Badger State/America's Dairyland",
                  "Nutmeg State/Constitution State",
                  "The District",
                  "Show Me State",
                  "Tar Heel State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Unlike in the rest of the U.S., those in the Virgin Island drive on the left.",
                  "falseFact": "Famous painter Vincent Van Gogh lived his later years on the U.S. Virgin Islands.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sunflower",
                  "Wood Violet",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Fontainebleau State Park",
                  "Napoleon House",
                  "Hocking Hills State Park",
                  "The Parthenon",
                  "Fort Adams State Park",
                  "Timberline Lodge",
                  "Cape Hatteras Lighthouse"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Red Rock Island",
                  "photographer": "Amaury Lapote",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Red_Rock_Island_in_US_Virgin_Islands_1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_Rock_Island"
              },
              "landmark": {
                  "name": "Magens Bay Beach",
                  "photographer": "Fred Hsu",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:St_Thomas_Magens_Bay_1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Magens_Bay",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cruz Bay",
                  "photographer": "Flonight",
                  "imageLink": "https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 1
                  },
                  {
                      "code": "AL",
                      "number": 5
                  },
                  {
                      "code": "MO",
                      "number": 1
                  }
              ],
              "uniqueId": "h5T6vCNL",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Natural State",
                  "Centennial State",
                  "Empire State",
                  "Cornhusker State/Beef State",
                  "Sunshine State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The U.S. Virgin Islands became a part of the U.S. after they were purchased for 25 million dollars from Denmark.",
                  "falseFact": "The US Virgin Islands cover only around 100 square miles of land.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Cherokee Rose",
                  "Mountain Laurel",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "Aerial Lift Bridge",
                  "Old Mississippi State Capitol",
                  "Castillo San Cristobal",
                  "SkyWheel Myrtle Beach",
                  "World of Coca Cola",
                  "Sandia Peak Tramway",
                  "Indiana Caverns"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Virgin Islands National Park",
                  "photographer": "Fred Hsu",
                  "imageLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park#/media/File:Caneel_Bay_Honeymoon_Beach_1.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Virgin_Islands_National_Park"
              },
              "landmark": {
                  "name": "Annaberg Sugar Plantation",
                  "photographer": "Linda L Kung",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:99_Steps.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charlotte_Amalie%2C_U.S._Virgin_Islands",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Red Hook",
                  "photographer": "Smallbones",
                  "imageLink": "https://en.wikipedia.org/wiki/Red_Hook,_U.S._Virgin_Islands",
                  "wikiLink": "https://en.wikipedia.org/wiki/Red_Hook,_U.S._Virgin_Islands"
              },
              "landmarksRound": [
                  {
                      "code": "ME",
                      "number": 3
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "KS",
                      "number": 1
                  }
              ],
              "uniqueId": "Hjz6HXsz",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "Aloha State",
                  "The Old Dominion/Mother of Presidents",
                  "Buckeye State",
                  "Empire State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The Knights of Malta once ruled this state.",
                  "falseFact": "The Virgin Islands were originally occupied by the Cherokee.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Bluebonnet",
                  "Bitterroot",
                  "Indian Paintbrush"
              ],
              "landmarkPlaces": [
                  "San Xavier del Bac Mission",
                  "Ravenel Bridge",
                  "Ark Encounter",
                  "Cuyahoga Valley",
                  "Sleeping Bear Dunes",
                  "Red River Gorge",
                  "Dover Motor Speedway"
              ]
          }
      ],
      "capital": "Charlotte Amalie",
      "flower": {
          "name": "Yellow Elder",
          "imgLink": "https://pixabay.com/photos/yellow-elder-yellow-trumpetbush-7255647/",
          "imgLink2": "https://pixabay.com/photos/yellow-elder-flowers-plant-7068385/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tecoma_stans"
      },
      "nickname": "America’s Paradise"
  },
  {
      "name": "Vermont",
      "code": "VT",
      "lat": 44.558803,
      "long": -72.577841,
      "cities": [
          "Burlington",
          "Stowe",
          "Montpelier",
          "Essex",
          "Rutland City",
          "Manchester"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Vermont State House",
                  "photographer": "Jonathanking",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Vermont_State_House_in_Montpelier.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vermont_State_House"
              },
              "landmark": {
                  "name": "Bennington Battle Monument",
                  "photographer": "King of Hearts",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Bennington_Battle_Monument_October_2021_003.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Bennington_Battle_Monument",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Burlington",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://en.wikipedia.org/wiki/Burlington,_Vermont",
                  "wikiLink": "https://en.wikipedia.org/wiki/Burlington,_Vermont"
              },
              "landmarksRound": [
                  {
                      "code": "AK",
                      "number": 1
                  },
                  {
                      "code": "KS",
                      "number": 1
                  },
                  {
                      "code": "MN",
                      "number": 1
                  }
              ],
              "uniqueId": "v5lDl6r9",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Mountain State",
                  "Cornhusker State/Beef State",
                  "Hoosier State",
                  "Show Me State",
                  "The Old Dominion/Mother of Presidents"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The state bird of Vermont is the hermit thrush.",
                  "falseFact": "Three presidents have been born in Vermont including James A. Garfield, Rutherford B. Hayes, and James Madison.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "California Poppy",
                  "American Dogwood"
              ],
              "landmarkPlaces": [
                  "Minneapolis Sculpture Garden",
                  "TransAmerica Pyramid",
                  "Sandy Hook Lighthouse",
                  "The Parthenon",
                  "Navy Pier",
                  "Appalachian Trail",
                  "Red River Gorge"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Taftsville Covered Bridge",
                  "photographer": "Davis Staedtler",
                  "imageLink": "https://www.flickr.com/photos/voxaeterno/30607626286",
                  "wikiLink": "https://en.wikipedia.org/wiki/Taftsville_Covered_Bridge"
              },
              "landmark": {
                  "name": "Church Street Marketplace",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/church-street-marketplace-burlington-6611316/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Church_Street_Marketplace",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Stowe",
                  "photographer": "LunchboxLarry",
                  "imageLink": "https://en.wikipedia.org/wiki/Stowe,_Vermont",
                  "wikiLink": "https://en.wikipedia.org/wiki/Stowe,_Vermont"
              },
              "landmarksRound": [
                  {
                      "code": "NJ",
                      "number": 2
                  },
                  {
                      "code": "ND",
                      "number": 3
                  },
                  {
                      "code": "NE",
                      "number": 3
                  }
              ],
              "uniqueId": "WM9Jnw5r",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Free State/Old Line State",
                  "Hoosier State",
                  "Evergreen State/Chinhook State",
                  "America’s Paradise",
                  "Natural State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Vermont banned _______ as a way to help protect the environment and scenery in the 1960s.",
                  "fillAnswer": "Billboards",
                  "fillAnswers": [
                      "Coal",
                      "Street lamps",
                      "Billboards",
                      "Plastic wrap"
                  ]
              },
              "flowerOptions": [
                  "Flowering Dogwood",
                  "Wild Prairie Rose",
                  "Yellow Elder"
              ],
              "landmarkPlaces": [
                  "The Alamo",
                  "Fort Adams State Park",
                  "Meteor Crater",
                  "Old Mississippi State Capitol",
                  "St. Michael the Archangel Orthodox Cathedral",
                  "Napoleon House",
                  "Churchill Downs"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Stowe",
                  "photographer": "Peter Rintels",
                  "imageLink": "https://www.flickr.com/photos/off-the-road/8527062053",
                  "wikiLink": "https://en.wikipedia.org/wiki/Stowe,_Vermont"
              },
              "landmark": {
                  "name": "Ben & Jerry's",
                  "photographer": "Watts",
                  "imageLink": "https://www.flickr.com/photos/watts_photos/43650637145",
                  "wikiLink": "https://en.wikipedia.org/wiki/Ben_%26_Jerry%27s",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Montpelier",
                  "photographer": "Georgio",
                  "imageLink": "https://en.wikipedia.org/wiki/Montpelier,_Vermont",
                  "wikiLink": "https://en.wikipedia.org/wiki/Montpelier,_Vermont"
              },
              "landmarksRound": [
                  {
                      "code": "MS",
                      "number": 5
                  },
                  {
                      "code": "DE",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 5
                  }
              ],
              "uniqueId": "2gpJStkM",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Sooner State",
                  "The Last Frontier",
                  "Flickertail State/Sioux State",
                  "Buckeye State",
                  "Volunteer State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Though the capital of this state is Montpelier, its biggest city is Burlington.",
                  "falseFact": "This state is a major producer of poultry, with some of the biggest chicken farming operations in the country.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Wood Violet",
                  "Wild Prairie Rose",
                  "Oklahoma Rose"
              ],
              "landmarkPlaces": [
                  "Rocky Statue",
                  "St. Patrick's Cathedral",
                  "Busch Stadium",
                  "San Antonio River Walk",
                  "USS Constitution",
                  "Maquoketa Caves",
                  "Amicalola Falls"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Mount Mansfield",
                  "photographer": "Niranjan Arminius",
                  "imageLink": "https://en.wikipedia.org/wiki/Mount_Mansfield#/media/File:Mt_Mansfield_20061018.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Mansfield"
              },
              "landmark": {
                  "name": "Vermont State House",
                  "photographer": "King of Hearts",
                  "imageLink": "https://en.wikipedia.org/wiki/Vermont_State_House#/media/File:Vermont_State_House_Montpelier_October_2021_HDR.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vermont_State_House",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Essex",
                  "photographer": "Adam Moss",
                  "imageLink": "https://en.wikipedia.org/wiki/Essex,_Vermont",
                  "wikiLink": "https://en.wikipedia.org/wiki/Essex,_Vermont"
              },
              "landmarksRound": [
                  {
                      "code": "TX",
                      "number": 4
                  },
                  {
                      "code": "NV",
                      "number": 3
                  },
                  {
                      "code": "MO",
                      "number": 5
                  }
              ],
              "uniqueId": "Bfb5gLrV",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Show Me State",
                  "Garden State",
                  "Bay State/Old Colony State",
                  "Gem State",
                  "Nutmeg State/Constitution State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Vermont is the biggest U.S. producer of _________.",
                  "fillAnswer": "Maple syrup",
                  "fillAnswers": [
                      "Almonds",
                      "Peaches",
                      "Maple syrup",
                      "Corn"
                  ]
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Yellow Elder",
                  "Colorado Blue Columbine"
              ],
              "landmarkPlaces": [
                  "Mall of America",
                  "Federal Hill Park",
                  "Devil's Tower National Monument",
                  "World's Largest Ball of Twine",
                  "Little Rock Central High School",
                  "San Antonio River Walk",
                  "Chihuly Garden and Glass"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "St Albans",
                  "photographer": "Michelle_Maria",
                  "imageLink": "https://pixabay.com/photos/vermont-st-albans-rural-person-1578477/",
                  "wikiLink": "https://en.wikipedia.org/wiki/St._Albans_(town),_Vermont"
              },
              "landmark": {
                  "name": "Lake Champlain",
                  "photographer": "Hbbrown18",
                  "imageLink": "https://en.wikipedia.org/wiki/Lake_Champlain#/media/File:Lake_Champlain,_Vermont.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Lake_Champlain",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Rutland City",
                  "photographer": "Ken Lund",
                  "imageLink": "https://en.wikipedia.org/wiki/Rutland,_Vermont_(city)",
                  "wikiLink": "https://en.wikipedia.org/wiki/Rutland,_Vermont_(city)"
              },
              "landmarksRound": [
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "NM",
                      "number": 2
                  },
                  {
                      "code": "VA",
                      "number": 2
                  }
              ],
              "uniqueId": "Jt4Ty62G",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Palmetto State",
                  "Land of Enchantment",
                  "Evergreen State/Chinhook State",
                  "Gem State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Vermont has more bridges per square mile than any other U.S. state.",
                  "falseFact": "Vermont produces more silver than any other U.S. state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Oregon Grape",
                  "Peach Blossom",
                  "Forget-me-not"
              ],
              "landmarkPlaces": [
                  "Little Bighorn Battlefield",
                  "Fort Mandan State Historic Site",
                  "Ark Encounter",
                  "SkyWheel Myrtle Beach",
                  "The Breakers",
                  "Baltimore Basilica",
                  "Multnomah Falls"
              ]
          }
      ],
      "capital": "Montpelier",
      "flower": {
          "name": "Red Clover",
          "imgLink": "https://pixabay.com/photos/red-clover-flowers-meadow-purple-4246842/",
          "imgLink2": "https://pixabay.com/photos/meadow-clover-red-clover-clover-7502407/",
          "wikiLink": "https://en.wikipedia.org/wiki/Trifolium_pratense"
      },
      "nickname": "Green Mountain State"
  },
  {
      "name": "Washington",
      "code": "WA",
      "lat": 47.751074,
      "long": -120.740139,
      "cities": [
          "Olympia",
          "Seattle",
          "Vancouver",
          "Tacoma",
          "Spokane",
          "Indianapolis"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Leavenworth",
                  "photographer": "donwhite84",
                  "imageLink": "https://pixabay.com/photos/icicle-river-river-505421/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Leavenworth,_Washington"
              },
              "landmark": {
                  "name": "Space Needle",
                  "photographer": "Michael_Baucham",
                  "imageLink": "https://pixabay.com/photos/landmark-travel-tourism-seattle-4891747/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Space_Needle",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Olympia",
                  "photographer": "Buzzworth",
                  "imageLink": "https://en.wikipedia.org/wiki/Olympia,_Washington",
                  "wikiLink": "https://en.wikipedia.org/wiki/Olympia,_Washington"
              },
              "landmarksRound": [
                  {
                      "code": "CO",
                      "number": 2
                  },
                  {
                      "code": "MS",
                      "number": 1
                  },
                  {
                      "code": "CA",
                      "number": 4
                  }
              ],
              "uniqueId": "VrlsNZZR",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Bluegrass State",
                  "Peace State/Empire State of the South",
                  "Sunshine State",
                  "Mountain State",
                  "Empire State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The classic family holiday of Father's Day has its origins in this state.",
                  "falseFact": "A 1963 eruption of volcano Mount Rainer in this state killed 16 people.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sagebrush",
                  "Magnolia",
                  "Peony"
              ],
              "landmarkPlaces": [
                  "Delicate Arch",
                  "Charging Bull",
                  "Coral Castle",
                  "Scotts Bluff",
                  "Avalanche Lake",
                  "Valley of Fire State Park",
                  "Statue of Liberty"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Mount Rainier",
                  "photographer": "Kranich17",
                  "imageLink": "https://pixabay.com/photos/mount-rainier-seattle-mountain-snow-3966557/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_Rainier"
              },
              "landmark": {
                  "name": "Fremont Troll",
                  "photographer": "David Herrera",
                  "imageLink": "https://www.flickr.com/photos/dph1110/2672806808",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fremont_Troll",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Seattle",
                  "photographer": "Pexels",
                  "imageLink": "https://en.wikipedia.org/wiki/Seattle",
                  "wikiLink": "https://en.wikipedia.org/wiki/Seattle"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "AR",
                      "number": 2
                  },
                  {
                      "code": "MS",
                      "number": 2
                  }
              ],
              "uniqueId": "Z7hPp2bz",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Beehive State",
                  "Pine Tree State",
                  "First State/Diamond State",
                  "Show Me State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first Starbucks was started in this state.",
                  "falseFact": "Three states, including this state, are named after former U.S. presidents.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Yellow Jessamine",
                  "Mayflower",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "The Keeper of the Plains",
                  "Stonington Harbour Light",
                  "Cathedral Caverns State Park",
                  "Scotts Bluff",
                  "Navy Pier",
                  "Luray Caverns",
                  "Iolani Palace"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Mount St. Helens",
                  "photographer": "sergiumarvel",
                  "imageLink": "https://pixabay.com/photos/mountains-washington-state-6792383/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Mount_St._Helens"
              },
              "landmark": {
                  "name": "Pike Place Market",
                  "photographer": "Olichel",
                  "imageLink": "https://pixabay.com/photos/market-seattle-washington-pike-4020378/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pike_Place_Market",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Vancouver",
                  "photographer": "Library of Congress",
                  "imageLink": "https://en.wikipedia.org/wiki/Vancouver,_Washington",
                  "wikiLink": "https://en.wikipedia.org/wiki/Vancouver,_Washington"
              },
              "landmarksRound": [
                  {
                      "code": "TX",
                      "number": 1
                  },
                  {
                      "code": "NV",
                      "number": 1
                  },
                  {
                      "code": "OK",
                      "number": 5
                  }
              ],
              "uniqueId": "4yG9445G",
              "weatherMonth": "march",
              "nicknameOptions": [
                  "Sunshine State",
                  "The Mount Rushmore State",
                  "Tar Heel State",
                  "Show Me State",
                  "Mountain State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The longest continuously operating farmer's market in the U.S. can be found at ________ in Seattle.",
                  "fillAnswer": "Pike Place Market",
                  "fillAnswers": [
                      "Powell's",
                      "Fisherman's Wharf",
                      "Steel Yard Market",
                      "Pike Place Market"
                  ]
              },
              "flowerOptions": [
                  "Pink And White Lady's Slipper",
                  "Colorado Blue Columbine",
                  "Mock Orange"
              ],
              "landmarkPlaces": [
                  "Independence Rock",
                  "Fort Frederick",
                  "Puerta de San Juan",
                  "Colonial Williamsburg",
                  "Lake Champlain",
                  "Olbrich Botanical Gardens",
                  "Knife River Indian Villages"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Seattle",
                  "photographer": "InfiniteThought",
                  "imageLink": "https://pixabay.com/photos/seattle-sunset-marina-harbor-sky-2426307/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Seattle"
              },
              "landmark": {
                  "name": "Fort Worden",
                  "photographer": "Mamabrooking",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Fort_Worden_Lighthouse.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Worden",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Tacoma",
                  "photographer": "Olichel",
                  "imageLink": "https://en.wikipedia.org/wiki/Tacoma,_Washington",
                  "wikiLink": "https://en.wikipedia.org/wiki/Tacoma,_Washington"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 1
                  }
              ],
              "uniqueId": "BLJgMmBt",
              "weatherMonth": "june",
              "nicknameOptions": [
                  "Sunflower State/Jayhawker State",
                  "Hoosier State",
                  "Show Me State",
                  "Natural State",
                  "Lone Star State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The major city of _____, Washington has a higher percentage of college degree-holding residents than any other city.",
                  "fillAnswer": "Seattle",
                  "fillAnswers": [
                      "Spokane",
                      "Seattle",
                      "Portland",
                      "Olympia"
                  ]
              },
              "flowerOptions": [
                  "Wild Prairie Rose",
                  "Cherokee Rose",
                  "Peony"
              ],
              "landmarkPlaces": [
                  "Three Rivers",
                  "USS Cairo",
                  "St. Patrick's Cathedral",
                  "Paw Paw Tunnel",
                  "Falls Park on the Reedy",
                  "Fallingwater",
                  "Drayton Hall"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Olympic National Park",
                  "photographer": "4311868",
                  "imageLink": "https://pixabay.com/photos/beach-pacific-coastline-ocean-coast-2089974/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Olympic_National_Park"
              },
              "landmark": {
                  "name": "Chihuly Garden and Glass",
                  "photographer": "Jay Galvin",
                  "imageLink": "https://www.flickr.com/photos/jaygalvin/49465851922",
                  "wikiLink": "https://en.wikipedia.org/wiki/Chihuly_Garden_and_Glass",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Spokane",
                  "photographer": "Ron Reiring",
                  "imageLink": "https://en.wikipedia.org/wiki/Spokane,_Washington",
                  "wikiLink": "https://en.wikipedia.org/wiki/Spokane,_Washington"
              },
              "landmarksRound": [
                  {
                      "code": "CT",
                      "number": 1
                  },
                  {
                      "code": "IL",
                      "number": 1
                  },
                  {
                      "code": "KS",
                      "number": 2
                  }
              ],
              "uniqueId": "Bp2tcK3d",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Flickertail State/Sioux State",
                  "Mountain State",
                  "The Last Frontier",
                  "Hoosier State",
                  "Beehive State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The goldfinch is the official state bird.",
                  "falseFact": "Washington is the only state with yellow in its flag.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Purple Lilac",
                  "Peach Blossom",
                  "Rose"
              ],
              "landmarkPlaces": [
                  "Fort Adams State Park",
                  "La Fortaleza",
                  "USS Cairo",
                  "West Virginia Penitentiary",
                  "Ravenel Bridge",
                  "Yale University",
                  "Old State House"
              ]
          }
      ],
      "capital": "Olympia",
      "flower": {
          "name": "Coast Rhododendron",
          "imgLink": "https://en.wikipedia.org/wiki/File:Rhododendron_macrophyllum_4861.JPG",
          "imgLink2": "https://www.flickr.com/photos/john_d_rusk/17074810567",
          "wikiLink": "https://en.wikipedia.org/wiki/Rhododendron_macrophyllum"
      },
      "nickname": "Evergreen State/Chinhook State"
  },
  {
      "name": "Wisconsin",
      "code": "WI",
      "lat": 43.78444,
      "long": -88.787868,
      "cities": [
          "Milwaukee",
          "Green Bay",
          "Madison",
          "Eau Claire",
          "Kenosha",
          "Minot"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Madison",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/madison-wisconsin-city-urban-1809576/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Madison,_Wisconsin"
              },
              "landmark": {
                  "name": "The House on the Rock",
                  "photographer": "bogdanstephniak",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:House_on_the_Rock,_Spring_Green_Wisconsin_USA_-_panoramio.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/House_on_the_Rock",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Milwaukee",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Milwaukee",
                  "wikiLink": "https://en.wikipedia.org/wiki/Milwaukee"
              },
              "landmarksRound": [
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "LA",
                      "number": 1
                  },
                  {
                      "code": "GA",
                      "number": 3
                  }
              ],
              "uniqueId": "4Zs2jg6N",
              "weatherMonth": "february",
              "nicknameOptions": [
                  "Pelican State/Creole State/Sugar State",
                  "North Star State/Gopher State",
                  "First State/Diamond State",
                  "America’s Paradise",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The world's largest cranberry festival occurs in Wisconsin, the #1 producer of American cranberries.",
                  "falseFact": "The biggest Wisconsin sport team, the Green Bay Packers, are owned by Warren Buffet.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Beauty Rose",
                  "Rose",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Tupelo National Battlefield",
                  "Biltmore",
                  "Fort Griswold",
                  "Coral Castle",
                  "Portland Head Light",
                  "Saint-Gaudens National Historic Park",
                  "The Elms"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Milwaukee",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/milwaukee-wisconsin-city-urban-1826837/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Milwaukee"
              },
              "landmark": {
                  "name": "Taliesin East",
                  "photographer": "Stephen Matthew Milligan",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Residence;_Taliesin;_Spring_Green,_Wisconsin;_June_5,_2012.JPG",
                  "wikiLink": "https://en.wikipedia.org/wiki/Taliesin_(studio)",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Green Bay",
                  "photographer": "Chris Rand",
                  "imageLink": "https://en.wikipedia.org/wiki/Green_Bay,_Wisconsin",
                  "wikiLink": "https://en.wikipedia.org/wiki/Green_Bay,_Wisconsin"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 5
                  },
                  {
                      "code": "FL",
                      "number": 1
                  },
                  {
                      "code": "NY",
                      "number": 1
                  }
              ],
              "uniqueId": "mnVjfGmc",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Keystone State",
                  "Natural State",
                  "The Mount Rushmore State",
                  "Volunteer State",
                  "Bay State/Old Colony State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "This state has more ____ per square acre of land area than anywhere in the world.",
                  "fillAnswer": "Water parks",
                  "fillAnswers": [
                      "Lakes",
                      "Water parks",
                      "Buffalo",
                      "Rivers"
                  ]
              },
              "flowerOptions": [
                  "Saguaro Cactus Blossom",
                  "American Beauty Rose",
                  "Rhododendron"
              ],
              "landmarkPlaces": [
                  "Gateway Arch",
                  "Napoleon House",
                  "USS Constitution",
                  "St George Utah Temple",
                  "The Keeper of the Plains",
                  "Castillo de San Marcos",
                  "Dover Motor Speedway"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Wisconsin State Capitol",
                  "photographer": "Lectrician2",
                  "imageLink": "https://en.wikipedia.org/wiki/Wisconsin_State_Capitol#/media/File:Wisconsin_State_Capitol_Aerial.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wisconsin_State_Capitol"
              },
              "landmark": {
                  "name": "Olbrich Botanical Gardens",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/golden-thai-pavilion-at-olbrich-3890545/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Olbrich_Botanical_Gardens",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Madison",
                  "photographer": "12019",
                  "imageLink": "https://en.wikipedia.org/wiki/Madison,_Wisconsin",
                  "wikiLink": "https://en.wikipedia.org/wiki/Madison,_Wisconsin"
              },
              "landmarksRound": [
                  {
                      "code": "HI",
                      "number": 5
                  },
                  {
                      "code": "NJ",
                      "number": 2
                  },
                  {
                      "code": "NJ",
                      "number": 4
                  }
              ],
              "uniqueId": "dJKxLPj6",
              "weatherMonth": "october",
              "nicknameOptions": [
                  "Silver State/Sagebrush State",
                  "Land of Enchantment",
                  "Mountain State",
                  "Hoosier State",
                  "First State/Diamond State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Wisconsin's state motto is simply \"Forward.\"",
                  "falseFact": "Wisconsin's state bird was the cardinal.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Colorado Blue Columbine",
                  "Pink And White Lady's Slipper",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Oriole Park",
                  "Sleeping Bear Dunes",
                  "Stonington Harbour Light",
                  "Yale University",
                  "Amicalola Falls",
                  "Nemours Estate",
                  "Old State House"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Oconomowoc",
                  "photographer": "islandworks",
                  "imageLink": "https://pixabay.com/photos/water-outdoors-lake-country-3114622/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Oconomowoc,_Wisconsin"
              },
              "landmark": {
                  "name": "Milwaukee Art Museum",
                  "photographer": "WOKANDAPIX",
                  "imageLink": "https://pixabay.com/photos/milwaukee-art-museum-milwaukee-2018552/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Milwaukee_Art_Museum",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Eau Claire",
                  "photographer": "Thieded",
                  "imageLink": "https://en.wikipedia.org/wiki/Eau_Claire,_Wisconsin",
                  "wikiLink": "https://en.wikipedia.org/wiki/Eau_Claire,_Wisconsin"
              },
              "landmarksRound": [
                  {
                      "code": "IN",
                      "number": 5
                  },
                  {
                      "code": "ID",
                      "number": 1
                  },
                  {
                      "code": "CO",
                      "number": 1
                  }
              ],
              "uniqueId": "CvfPSGLn",
              "weatherMonth": "april",
              "nicknameOptions": [
                  "Granite State",
                  "Garden State",
                  "Free State/Old Line State",
                  "Green Mountain State",
                  "Hawkeye State/Corn State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "You should have lived in Wisconsin in 2020: Green Bay used to be known as the ______ Capital of the World.",
                  "fillAnswer": "Toilet paper",
                  "fillAnswers": [
                      "Vaccine",
                      "Tissue paper",
                      "Medical",
                      "Toilet paper"
                  ]
              },
              "flowerOptions": [
                  "Magnolia",
                  "Sagebrush",
                  "Forget-me-not"
              ],
              "landmarkPlaces": [
                  "Temple Square",
                  "Mackinaw Island",
                  "Colonial Williamsburg",
                  "Cathedral Caverns State Park",
                  "Louisville Water Tower",
                  "Blue Whale of Catoosa",
                  "St. Patrick's Cathedral"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Pabst Mansion",
                  "photographer": "Sailko",
                  "imageLink": "https://en.wikipedia.org/wiki/Pabst_Mansion#/media/File:Milwaukee,_pabst_mansion_01.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Pabst_Mansion"
              },
              "landmark": {
                  "name": "Cave of the Mounds",
                  "photographer": "Cave boy",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Cave_of_the_Mounds_South_Cavern.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cave_of_the_Mounds",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Kenosha",
                  "photographer": "latrelljennings",
                  "imageLink": "https://en.wikipedia.org/wiki/Kenosha,_Wisconsin",
                  "wikiLink": "https://en.wikipedia.org/wiki/Kenosha,_Wisconsin"
              },
              "landmarksRound": [
                  {
                      "code": "DC",
                      "number": 2
                  },
                  {
                      "code": "MT",
                      "number": 1
                  },
                  {
                      "code": "MD",
                      "number": 1
                  }
              ],
              "uniqueId": "Y5bNrlK4",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Palmetto State",
                  "The District",
                  "Show Me State",
                  "Green Mountain State",
                  "America’s Paradise"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "People from Wisconsin are sometimes called \"cheeseheads\" because of Wisonsin's large dairy industry.",
                  "falseFact": "Wisconsin's state symbol is the wolverine.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Iris",
                  "Red Clover",
                  "Magnolia"
              ],
              "landmarkPlaces": [
                  "Busch Stadium",
                  "Martin Luther King Jr. Memorial",
                  "The Mark Twain House",
                  "Gillette Castle",
                  "Thorncrown Chapel",
                  "Wynwood Walls",
                  "Liberty Bell"
              ]
          }
      ],
      "capital": "Madison",
      "flower": {
          "name": "Wood Violet",
          "imgLink": "https://www.flickr.com/photos/wackybadger/29974996885",
          "imgLink2": "https://commons.wikimedia.org/wiki/File:Common_Blue_Violet_-_Viola_sororia_Willd_%288659616227%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Viola_sororia"
      },
      "nickname": "Badger State/America's Dairyland"
  },
  {
      "name": "West Virginia",
      "code": "WV",
      "lat": 38.597626,
      "long": -80.454903,
      "cities": [
          "Charles Town",
          "Harper's Ferry",
          "Charleston",
          "Huntington",
          "Parkersburg",
          "Orlando"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Endless Wall in New River Gorge National Park",
                  "photographer": "VitaleBaby",
                  "imageLink": "https://en.wikipedia.org/wiki/New_River_Gorge_National_Park_and_Preserve#/media/File:EndlessWallWV.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/New_River_Gorge_National_Park_and_Preserve"
              },
              "landmark": {
                  "name": "West Virginia Penitentiary",
                  "photographer": "Taber Andrew Bain",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:West_Virginia_Penitentiary_.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/West_Virginia_Penitentiary",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Charles Town",
                  "photographer": "Tim Kiser",
                  "imageLink": "https://en.wikipedia.org/wiki/Charles_Town,_West_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charles_Town,_West_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "AL",
                      "number": 1
                  },
                  {
                      "code": "VT",
                      "number": 4
                  },
                  {
                      "code": "CO",
                      "number": 5
                  }
              ],
              "uniqueId": "TvF5P237",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Palmetto State",
                  "Golden State",
                  "Wolverine State/Great Lake State",
                  "Sooner State",
                  "Aloha State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The first battle of the _______ occurred in this state.",
                  "fillAnswer": "Civil War",
                  "fillAnswers": [
                      "Revolutionary War",
                      "War of 1812",
                      "Civil War",
                      "Great War"
                  ]
              },
              "flowerOptions": [
                  "American Beauty Rose",
                  "Cherokee Rose",
                  "Yucca Flower"
              ],
              "landmarkPlaces": [
                  "Sandia Peak Tramway",
                  "SkyWheel Myrtle Beach",
                  "Centennial Land Run Monument",
                  "Mount Hood",
                  "San Jacinto Battleground",
                  "The House on the Rock",
                  "Little River Canyon National Preserve"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "West Virginia Independence Hall",
                  "photographer": "Library of Congress",
                  "imageLink": "https://picryl.com/media/west-virginia-independence-hall-in-wheeling-west-virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/West_Virginia_Independence_Hall"
              },
              "landmark": {
                  "name": "The Greenbrier",
                  "photographer": "Harry W. McCormack",
                  "imageLink": "https://en.wikipedia.org/wiki/The_Greenbrier#/media/File:THE_GREENBRIER_-_WHITE_SULPHUR_SPRINGS,_WV_copy.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/The_Greenbrier",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Harper's Ferry",
                  "photographer": "PublicDomainPictures",
                  "imageLink": "https://en.wikipedia.org/wiki/Harpers_Ferry,_West_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Harpers_Ferry,_West_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "WA",
                      "number": 5
                  },
                  {
                      "code": "MD",
                      "number": 1
                  },
                  {
                      "code": "OH",
                      "number": 1
                  }
              ],
              "uniqueId": "VCwYyfGt",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Garden State",
                  "Treasure State/Big Sky Country",
                  "Magnolia State",
                  "Centennial State",
                  "Sooner State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "West Virginia is slightly larger than Croatia.",
                  "falseFact": "The original West Virginia flag was done in the color orange before it was agreed that the color scheme was too garish and changed to a more demure blue and white.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "California Poppy",
                  "Hawthorn",
                  "Bluebonnet"
              ],
              "landmarkPlaces": [
                  "Milwaukee Art Museum",
                  "Denail National Park",
                  "Lincoln Memorial",
                  "The Elms",
                  "Santa Fe Plaza",
                  "Strataca",
                  "Fort Frederick"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Wheeling Suspension Bridge",
                  "photographer": "Jack Boucher",
                  "imageLink": "https://en.wikipedia.org/wiki/Wheeling_Suspension_Bridge#/media/File:WheelingSuspBridge.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Wheeling_Suspension_Bridge"
              },
              "landmark": {
                  "name": "Grave Creek Mound",
                  "photographer": "Tim Kiser",
                  "imageLink": "https://en.wikipedia.org/wiki/Grave_Creek_Mound#/media/File:Grave_Creek_Mound.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grave_Creek_Mound",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Charleston",
                  "photographer": "Andrea Faessler",
                  "imageLink": "https://en.wikipedia.org/wiki/Charleston,_West_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Charleston,_West_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "OK",
                      "number": 1
                  },
                  {
                      "code": "WI",
                      "number": 5
                  },
                  {
                      "code": "CO",
                      "number": 1
                  }
              ],
              "uniqueId": "8Vrxlsk8",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "First State/Diamond State",
                  "Silver State/Sagebrush State",
                  "The Old Dominion/Mother of Presidents",
                  "Bluegrass State",
                  "Treasure State/Big Sky Country"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Though West Virginia is known as a major mining state, it's biggest industry is actually ________.",
                  "fillAnswer": "Tourism",
                  "fillAnswers": [
                      "Coal production",
                      "Solar energy",
                      "Farming",
                      "Tourism"
                  ]
              },
              "flowerOptions": [
                  "White Pine Cone And Tassel",
                  "Hawaiian Hibiscus",
                  "Bitterroot"
              ],
              "landmarkPlaces": [
                  "Centennial Land Run Monument",
                  "Mississippi Petrified Forest",
                  "Santa Fe Plaza",
                  "San Jacinto Battleground",
                  "Fremont Troll",
                  "Red Rocks Ampitheatre",
                  "Rocky Statue"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Blackwater Falls",
                  "photographer": "jatocreate",
                  "imageLink": "https://pixabay.com/photos/blackwater-falls-waterfall-6745743/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Blackwater_Falls_State_Park"
              },
              "landmark": {
                  "name": "John Brown's Fort",
                  "photographer": "petechacalos",
                  "imageLink": "https://pixabay.com/photos/west-virginia-harpers-ferry-historic-4963884/",
                  "wikiLink": "https://en.wikipedia.org/wiki/John_Brown%27s_Fort",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Huntington",
                  "photographer": "Wv funnyman",
                  "imageLink": "https://en.wikipedia.org/wiki/Huntington,_West_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Huntington,_West_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "TN",
                      "number": 2
                  },
                  {
                      "code": "IL",
                      "number": 5
                  },
                  {
                      "code": "TX",
                      "number": 1
                  }
              ],
              "uniqueId": "KmjCclJv",
              "weatherMonth": "september",
              "nicknameOptions": [
                  "Empire State",
                  "Magnolia State",
                  "The District",
                  "Silver State/Sagebrush State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "One name that was considered for this state was \"Kanawha.\"",
                  "falseFact": "West Virginia is known for its flat terrain, ideal for farming.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Sego Lily",
                  "Rose",
                  "Hawthorn"
              ],
              "landmarkPlaces": [
                  "Strataca",
                  "Museum of the Rockies",
                  "Vermont State House",
                  "Ford's Theatre",
                  "Hot Springs National Park",
                  "Eastern State Penitentiary",
                  "Charging Bull"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Cheat Lake",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/cheat-lake-west-virginia-water-1933322/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cheat_Lake"
              },
              "landmark": {
                  "name": "Appalachian Trail",
                  "photographer": "Zeete",
                  "imageLink": "https://commons.wikimedia.org/wiki/File:Winchester_and_Potomac_Railroad_Bridge,_Harpers_Ferry,_WV_-_Appalachian_Trail_sign.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Appalachian_Trail",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Parkersburg",
                  "photographer": "Michael A. Hays",
                  "imageLink": "https://en.wikipedia.org/wiki/Parkersburg,_West_Virginia",
                  "wikiLink": "https://en.wikipedia.org/wiki/Parkersburg,_West_Virginia"
              },
              "landmarksRound": [
                  {
                      "code": "ND",
                      "number": 2
                  },
                  {
                      "code": "MO",
                      "number": 1
                  },
                  {
                      "code": "IA",
                      "number": 1
                  }
              ],
              "uniqueId": "c3DXkJtK",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Grand Canyon State",
                  "Free State/Old Line State",
                  "Keystone State",
                  "Sunshine State",
                  "First State/Diamond State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "This state was originally part of Virginia, before it seceeded to form its own territory in 1863.",
                  "falseFact": "This state has been hit by more hurricanes than any other U.S. state.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Peach Blossom",
                  "Sego Lily",
                  "Hawthorn"
              ],
              "landmarkPlaces": [
                  "Little River Canyon National Preserve",
                  "Lincoln Memorial",
                  "Washington National Cathedral",
                  "Houmas House",
                  "Fontainebleau State Park",
                  "Centennial Land Run Monument",
                  "Old Mississippi State Capitol"
              ]
          }
      ],
      "capital": "Charleston",
      "flower": {
          "name": "Rhododendron",
          "imgLink": "https://pixabay.com/photos/azalea-flower-blossoms-plant-1436344/",
          "imgLink2": "https://pixabay.com/photos/azalea-palestrina-azalea-flower-3387734/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rhododendron_maximum"
      },
      "nickname": "Mountain State"
  },
  {
      "name": "Wyoming",
      "code": "WY",
      "lat": 43.075968,
      "long": -107.290284,
      "cities": [
          "Cheyenne",
          "Casper",
          "Jackson",
          "Laramie",
          "Cody",
          "Manchester"
      ],
      "game": [
          {
              "number": 1,
              "mainImage": {
                  "name": "Old Faithful Inn",
                  "photographer": "Jim Peaco",
                  "imageLink": "https://en.wikipedia.org/wiki/Old_Faithful_Inn#/media/File:Old_Faithful_Inn_main_facade.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Faithful_Inn"
              },
              "landmark": {
                  "name": "Devil's Tower National Monument",
                  "photographer": "Steppinstars",
                  "imageLink": "https://pixabay.com/photos/devil-s-tower-national-monument-60675/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Devils_Tower",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cheyenne",
                  "photographer": "Vasiliymeshko",
                  "imageLink": "https://en.wikipedia.org/wiki/Cheyenne,_Wyoming",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cheyenne,_Wyoming"
              },
              "landmarksRound": [
                  {
                      "code": "IL",
                      "number": 3
                  },
                  {
                      "code": "CO",
                      "number": 5
                  },
                  {
                      "code": "IN",
                      "number": 5
                  }
              ],
              "uniqueId": "pFBxHDp9",
              "weatherMonth": "august",
              "nicknameOptions": [
                  "Buckeye State",
                  "Prairie State/Land of Lincoln",
                  "Cotton State/Yellowhammer State",
                  "Sunflower State/Jayhawker State",
                  "The Last Frontier"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "Wyoming has fewer residents than any other state, including Rhode Island.",
                  "falseFact": "Wyoming's biggest university, the University of Wyoming, was one of the first universities to be established in the US, with the first group of students graduating in 1807.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Iris",
                  "Black-eyed Susan",
                  "American Beauty Rose"
              ],
              "landmarkPlaces": [
                  "Fort Union Trading Post",
                  "Colonial Williamsburg",
                  "Blue Whale of Catoosa",
                  "Fort Frederick",
                  "Yale University",
                  "International Peace Garden",
                  "Little River Canyon National Preserve"
              ]
          },
          {
              "number": 2,
              "mainImage": {
                  "name": "Devil's Tower National Monument",
                  "photographer": "Mike Goad",
                  "imageLink": "https://pixabay.com/photos/wyoming-s-devils-tower-devils-tower-3915790/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Devils_Tower"
              },
              "landmark": {
                  "name": "Fort Laramie",
                  "photographer": "ArtTower",
                  "imageLink": "https://pixabay.com/photos/fort-laramie-wyoming-usa-building-51616/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Fort_Laramie_National_Historic_Site",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Casper",
                  "photographer": "Adbay",
                  "imageLink": "https://en.wikipedia.org/wiki/Casper,_Wyoming",
                  "wikiLink": "https://en.wikipedia.org/wiki/Casper,_Wyoming"
              },
              "landmarksRound": [
                  {
                      "code": "NM",
                      "number": 1
                  },
                  {
                      "code": "AL",
                      "number": 2
                  },
                  {
                      "code": "OR",
                      "number": 3
                  }
              ],
              "uniqueId": "P2mBvYyW",
              "weatherMonth": "july",
              "nicknameOptions": [
                  "Nutmeg State/Constitution State",
                  "North Star State/Gopher State",
                  "Grand Canyon State",
                  "Free State/Old Line State",
                  "Sunflower State/Jayhawker State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "Wyoming produces more ____ than any other state.",
                  "fillAnswer": "Coal",
                  "fillAnswers": [
                      "Silver",
                      "Lamb",
                      "Coal",
                      "Beef"
                  ]
              },
              "flowerOptions": [
                  "Mountain Laurel",
                  "Bitterroot",
                  "Apple Blossom"
              ],
              "landmarkPlaces": [
                  "Theodore Roosevelt National Park",
                  "World of Coca Cola",
                  "Cedar Point",
                  "Cave of the Mounds",
                  "Strataca",
                  "Fort McHenry",
                  "Yale University"
              ]
          },
          {
              "number": 3,
              "mainImage": {
                  "name": "Upper Mesa Falls, Caribou-Targhee National Forest",
                  "photographer": "Malcom Manners",
                  "imageLink": "https://www.flickr.com/photos/mmmavocado/9330183040",
                  "wikiLink": "https://en.wikipedia.org/wiki/Caribou%E2%80%93Targhee_National_Forest"
              },
              "landmark": {
                  "name": "Old Faithful",
                  "photographer": "MikeGoad",
                  "imageLink": "https://pixabay.com/photos/yellowstone-s-old-faithful-upper-3866138/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Old_Faithful",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Jackson",
                  "photographer": "digibarter",
                  "imageLink": "https://en.wikipedia.org/wiki/Jackson,_Wyoming",
                  "wikiLink": "https://en.wikipedia.org/wiki/Jackson,_Wyoming"
              },
              "landmarksRound": [
                  {
                      "code": "PR",
                      "number": 1
                  },
                  {
                      "code": "NJ",
                      "number": 1
                  },
                  {
                      "code": "MT",
                      "number": 3
                  }
              ],
              "uniqueId": "KWb2tryv",
              "weatherMonth": "november",
              "nicknameOptions": [
                  "Treasure State/Big Sky Country",
                  "Bluegrass State",
                  "Bay State/Old Colony State",
                  "Peace State/Empire State of the South",
                  "The Mount Rushmore State"
              ],
              "facts": {
                  "trueFalse": false,
                  "trueFact": "",
                  "falseFact": "",
                  "fillQuestion": "The first national park in the world, _____________, can be found in Wyoming.",
                  "fillAnswer": "Yellowstone National Park",
                  "fillAnswers": [
                      "Yosemite National Park",
                      "Acadia National Park",
                      "Grand Canyon National Park",
                      "Yellowstone National Park"
                  ]
              },
              "flowerOptions": [
                  "Red Clover",
                  "Orange Blossom",
                  "Flor De Maga"
              ],
              "landmarkPlaces": [
                  "Mount Washington",
                  "Church Street Marketplace",
                  "Oriole Park",
                  "USS Albacore",
                  "The Alamo",
                  "Flume Gorge",
                  "USS Cairo"
              ]
          },
          {
              "number": 4,
              "mainImage": {
                  "name": "Grand Teton National Park",
                  "photographer": "srkcalifano",
                  "imageLink": "https://pixabay.com/photos/wyoming-grand-teton-teton-landscape-4786394/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Teton_National_Park"
              },
              "landmark": {
                  "name": "Independence Rock",
                  "photographer": "Goretexguy",
                  "imageLink": "https://en.wikipedia.org/wiki/Independence_Rock#/media/File:Independence_Rock,_Wyoming,_USA,_July_2015.jpg",
                  "wikiLink": "https://en.wikipedia.org/wiki/Independence_Rock",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Laramie",
                  "photographer": "Nyttend",
                  "imageLink": "https://en.wikipedia.org/wiki/Laramie,_Wyoming",
                  "wikiLink": "https://en.wikipedia.org/wiki/Laramie,_Wyoming"
              },
              "landmarksRound": [
                  {
                      "code": "OH",
                      "number": 1
                  },
                  {
                      "code": "ND",
                      "number": 2
                  },
                  {
                      "code": "PR",
                      "number": 1
                  }
              ],
              "uniqueId": "WbHWNfTj",
              "weatherMonth": "december",
              "nicknameOptions": [
                  "Land of Enchantment",
                  "Hawkeye State/Corn State",
                  "Magnolia State",
                  "Gem State",
                  "Centennial State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "The first female governor in the United States, Nellie Tayloe Ross, led this state from 1925 to 1927.",
                  "falseFact": "The Wyoming official state animal is the moose.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "American Dogwood",
                  "Sego Lily",
                  "Iris"
              ],
              "landmarkPlaces": [
                  "Rock & Roll Hall of Fame",
                  "Denail National Park",
                  "Little Bighorn Battlefield",
                  "Washington Monument",
                  "Natural Bridge Caverns",
                  "Vermont State House",
                  "Multnomah Falls"
              ]
          },
          {
              "number": 5,
              "mainImage": {
                  "name": "Hole-in-the-Wall",
                  "photographer": "12019",
                  "imageLink": "https://pixabay.com/photos/hole-in-the-wall-wyoming-valley-72350/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Hole-in-the-Wall"
              },
              "landmark": {
                  "name": "Grand Teton",
                  "photographer": "Steppinstars",
                  "imageLink": "https://pixabay.com/photos/horses-mountains-landscape-wyoming-208827/",
                  "wikiLink": "https://en.wikipedia.org/wiki/Grand_Teton",
                  "hasLandmark": true
              },
              "city": {
                  "name": "Cody",
                  "photographer": "Indy beetle",
                  "imageLink": "https://en.wikipedia.org/wiki/Cody,_Wyoming",
                  "wikiLink": "https://en.wikipedia.org/wiki/Cody,_Wyoming"
              },
              "landmarksRound": [
                  {
                      "code": "MO",
                      "number": 5
                  },
                  {
                      "code": "NY",
                      "number": 1
                  },
                  {
                      "code": "FL",
                      "number": 1
                  }
              ],
              "uniqueId": "hf54Yjrh",
              "weatherMonth": "january",
              "nicknameOptions": [
                  "Bluegrass State",
                  "Show Me State",
                  "Sooner State",
                  "Pelican State/Creole State/Sugar State",
                  "Flickertail State/Sioux State"
              ],
              "facts": {
                  "trueFalse": true,
                  "trueFact": "When ranking the happiest and healthiest states, Wyoming comes in second behind only Hawaii.",
                  "falseFact": "President Rutherford B. Hayes was born in Wyoming.",
                  "fillQuestion": "",
                  "fillAnswer": "",
                  "fillAnswers": []
              },
              "flowerOptions": [
                  "Red Clover",
                  "American Beauty Rose",
                  "Purple Lilac"
              ],
              "landmarkPlaces": [
                  "St. Patrick's Cathedral",
                  "The Breakers",
                  "Appalachian Trail",
                  "Thorncrown Chapel",
                  "Magens Bay Beach",
                  "Tupelo National Battlefield",
                  "Pictured Rocks"
              ]
          }
      ],
      "capital": "Cheyenne",
      "flower": {
          "name": "Indian Paintbrush",
          "imgLink": "https://pixabay.com/photos/indian-paintbrush-castilleja-miniata-383564/",
          "imgLink2": "https://pixabay.com/photos/flower-indian-paintbrush-red-nature-1574884/",
          "wikiLink": "https://en.wikipedia.org/wiki/Castilleja_linariifolia"
      },
      "nickname": "Equality State"
  }
]
