import { Component, Input } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-city-guess',
  templateUrl: './city-guess.component.html',
  styleUrls: ['./city-guess.component.scss']
})
export class CityGuessComponent {

  @Input() guess!: any;

  state$: Observable<Game>
  unit$: Observable<string>;

  constructor(private data: DataService, private events: EventsService) {
    this.state$ = this.data.getState();
    this.unit$ = this.data.getState().pipe(
      map(val => val.distUnit)
    );
  }

  ngOnInit(): void {

  }

  boxClicked() {
    this.events.boxClickedEvent('click');
  }
}
