<div class="header mt-2 md:mt-0 border-b-2 border-slate-200">
  <div class="buttons mb-1">
    <div class="types" >
      <a routerLink="/account" *ngIf="user$ | async as user" class="mt-0.5">
        <img class="rounded-3xl" *ngIf="user.loggedIn && user.isPhotoValid" [src]="user.photoURL" alt="Image of you">
        <img *ngIf="user.loggedIn && !user.isPhotoValid" src="/assets/turtle.png" alt="User logged in" />
        <img *ngIf="!user.loggedIn" src="/assets/user.png" alt="User icon button" class="dark:invert" />
      </a>
      <a routerLink="/rules">
        <img src="/assets/question.png" alt="Rules icon button" class="" />
      </a>
    </div>
    <div>
      <!-- <p class="font-bold text-xs text-end">US</p> -->
      <div class="flex gap-2 items-center justify-center">
        <h1 class="font-bold text-black dark:!text-white text-2xl md:text-3xl">
          <span>WHERE</span>
          <span class="text-blue-600 dark:text-blue-300 ml-1">TAKEN</span>
          <span class="sr-only">USA</span>
        </h1>
        <img class="w-6 md:w-8" ngSrc="./assets/flag.png" width="72" height="72" />
      </div>
    </div>

    <div class="modes" *ngIf="(state$ | async)?.gameMode as mode">
      <a routerLink="/stats">
        <img src="/assets/stats-new.png" alt="Stats icon button" class="" />
      </a>
      <a class=" text-black dark:!text-white" routerLink="/settings">
        <img src="/assets/cog-new.png" alt="Settings icon button" class="" />
      </a>
    </div>
  </div>
</div>
