<div *ngIf="user$ | async as user" class="mt-3 text-black dark:!text-white relative flex flex-col gap-3">
  <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>

  <h2 class="text-xl font-bold">Archive</h2>
  <div *ngIf="user?.subscription?.isPremium; else notPremium">
    <p class="mb-3">Here are all the games since 14th March 2023.</p>
    <div (click)="playRandomGame()" class="text-center border border-black p-2 rounded-md shadow-sm hover:bg-slate-200 cursor-pointer mb-2">Play a random game</div>
    <div class="grid grid-cols-2 gap-2">
      <div (click)="goToArchiveGame(game.code)" *ngFor="let game of archiveGames" class="text-center border border-black p-2 rounded-md shadow-sm hover:bg-slate-200 cursor-pointer">
        <a>Game {{ game.number }}</a>
      </div>
    </div>
  </div>
  <ng-template #notPremium>
    <div>
      <p class="mb-3">Access to the game archive is an exclusive feature for Premium Members. Register now through the link below and unlock the ability to play all the games that have ever existed!</p>
      <app-become-premium-member></app-become-premium-member>
    </div>
  </ng-template>
</div>
