<div *ngIf="state$ | async as state">
  <h4 class="text-lg font-bold text-center mb-2 mt-3">
    In what range is the average high temperature in {{ state.country }} during {{ state.weatherGuess.month }}?
  </h4>
  <div class="grid grid-cols-2 gap-2 mb-3 mt-3">
    <div (click)="weatherGuess(weather, i)" *ngFor="let weather of state.weatherGuess.weatherOptions; let i = index"
      [ngClass]="{
              '!bg-green-300 !text-black': weather.isCorrect && weather.isGuessed,
              '!bg-white dark:!bg-slate-600 cursor-pointer' : !weather.isGuessed && !state.weatherGuess.roundOver,
              'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !weather.isGuessed && !state.weatherGuess.roundOver,
              'shake': weather.shake,
              'bg-slate-400' : !weather.isCorrect && weather.isGuessed
          }" class="border-2 border-slate-300 rounded-md box select-none shadow-md">
      <div class="w-full h-full px-2 py-3 flex justify-center items-center text-center">
        <span *ngIf="state.distUnit === 'mi'">{{weather.value}}</span>
        <span *ngIf="state.distUnit === 'km'">{{weather.valueC}}</span>
      </div>
    </div>
  </div>
  <!-- <p *ngIf="state.weatherGuess.roundOver" class="text-center mt-3 font-bold">
    The average high in {{ state.weatherGuess.month }} is <span *ngIf="state.distUnit === 'mi'">{{ state.weatherGuess.temp }}°F</span><span *ngIf="state.distUnit === 'km'">{{ state.weatherGuess.tempC }}°C</span>
  </p> -->
</div>
