import { Component } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-capital-city-guesses',
  templateUrl: './capital-city-guesses.component.html',
  styleUrls: ['./capital-city-guesses.component.scss']
})
export class CapitalCityGuessesComponent {

  game$: Observable<any>;
  guesses$: Observable<any>;

  constructor(private data: DataService) {
    this.game$ = this.data.getState();
    this.guesses$ = this.game$.pipe(
      map((game) => game.capitalCity.guesses),
    );
  }

}
