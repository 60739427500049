<div class="mt-3 text-black dark:!text-white flex flex-col gap-3">
    <a (click)="navigateBack()" class="text-blue-500 hover:text-blue-400 cursor-pointer">&#8592; Back</a>

    <h4><b>Settings</b></h4>
    <div class="flex flex-col mb-3 items-start">
        <label for="distUnits" class="mb-2 font-bold">Metric or Imperial System</label>
        <select [formControl]="units" (change)="unitsChanged()" name="distUnits" class="shadow-md p-1 border border-black dark:bg-slate-700 responsive-select">
          <option value="km">Metric (km & °C)</option>
            <option value="mi">Imperial (mi & °F)</option>
        </select>
    </div>
    <div class="flex flex-col mb-3 items-start">
        <label for="theme" class="mb-2 font-bold">Theme</label>
        <select [formControl]="theme" (change)="themeChanged()" name="theme" class="shadow-md p-1 border border-black dark:bg-slate-700 responsive-select">
          <option value="light">Light</option>
            <option value="dark">Dark</option>
        </select>
    </div>
    <div class="flex flex-col items-start">
        <label for="distUnits" class="mb-2 font-bold">Difficulty</label>
        <select [formControl]="difficulty" (change)="difficultyChanged()" name="distUnits" class="shadow-md p-1 mb-4 border border-black dark:bg-slate-700 responsive-select">
          <option value="normal">Normal</option>
            <option value="hard">Pro</option>
            <option value="veryhard">Expert</option>
        </select>

        <div *ngIf="showAlert" class="bg-green-100 dark:bg-green-300 border-2 border-green-600 text-green-900 px-4 py-3 rounded-md mb-4" role="alert">
            <p class="font-bold">Difficulty changed</p>
            <p class="text-sm">Your new difficulty level will start tomorrow.</p>
          </div>

        <h5 class="font-bold mb-2">Difficulty levels</h5>
        <p class="mb-1">Normal - Distance and direction shown.</p>
        <p class="mb-1">Pro - Direction shown.</p>
        <p class="mb-1">Expert - No distance or direction shown.</p>
    </div>
</div>
