<div *ngIf="state$ | async as state">
  <h4 class="text-lg font-bold text-center mb-2 mt-3">
    What is the state flower of {{ state.country }}?
  </h4>
  <app-image [imgSrc]="state.flowerGuess.imgSrc" class="w-full"></app-image>
  <div class="grid grid-cols-2 gap-2 mb-3 mt-3">
      <div (click)="flowerGuess(flower, i)" *ngFor="let flower of state.flowerGuess.flowers; let i = index"
          [ngClass]="{
              '!bg-green-300 !text-black': flower.isCorrect && flower.isGuessed,
              '!bg-white dark:!bg-slate-600 cursor-pointer' : !flower.isGuessed && !state.flowerGuess.roundOver,
              'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !flower.isGuessed && !state.flowerGuess.roundOver,
              'shake': flower.shake,
              'bg-slate-400' : !flower.isCorrect && flower.isGuessed
          }" class="border-2 border-slate-300 rounded-md box select-none shadow-md">
          <div class="w-full h-full px-2 py-3 flex justify-center items-center text-center">
              <span>{{flower.value}}</span>
          </div>
      </div>
  </div>
</div>
