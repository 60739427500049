import { Component } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { WeatherGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent {

  state$: Observable<any>;
  unit$: Observable<string>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
    this.unit$ = this.data.getState().pipe(
      map(val => val.distUnit)
    );
  }

  weatherGuess(weather: WeatherGuess, index: number) {
    this.data.weatherGuess(weather, index);
  }

}
