import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { Observable, Subscription, take } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-svg-map',
  templateUrl: './svg-map.component.html',
  styleUrls: ['./svg-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SvgMapComponent implements OnInit, AfterViewChecked {

  @Input() public name?: string;
  @Input() public page?: string;
  @Input() public countryCode?: string;
  @Input() public admin: boolean = false;

  @ViewChild('map', { read: ElementRef, static: false }) map!: ElementRef;

  public svgIcon: any;
  loadedClickEvents: boolean = false;
  svgTarget!: any;
  initialSetup: boolean = false;

  stateSub!: Subscription;
  clientSub!: Subscription;

  state$: Observable<Game>

  constructor(
    private httpClient: HttpClient,
    private renderer: Renderer2,
    private data: DataService
  ) {
    this.state$ = this.data.getState();
  }

  ngOnInit(): void {
    if (!this.countryCode) {
      this.svgIcon = '';
      return;
    }
    this.clientSub = this.httpClient
      .get(`assets/states/${this.countryCode.toLowerCase()}/map.svg`, { responseType: 'text' })
      .pipe(take(1))
      .subscribe(value => {
        this.svgIcon = value;
        this.bindEvents();
      });

    this.checkUIUpdates();
  }

  ngAfterViewChecked() {
    if (!this.map?.nativeElement?.children[0]?.children) {
      return;
    }
    // Give ellipses initial classes and styles
    if (!this.initialSetup) {
      this.state$.pipe(take(1)).subscribe(val => {
        this.updateCircles(val)
      })
      this.initialSetup = true;
    }
  }

  checkUIUpdates() {
    this.stateSub = this.state$.subscribe(val => {
      if (this.map?.nativeElement?.children[0]?.children) {
        this.updateCircles(val);
      }
    })
  }

  addDarkMode() {
    let elements = this.map.nativeElement.children[0].children;
    for (let i = 0; i < elements.length; i++) {
      // Iterate over the ellipses
      if (elements[i].nodeName == "path") {
        this.renderer.addClass(elements[i], "dark:invert");
      }
    }

  }
  updateCircles(val: Game) {
    let elements = this.map.nativeElement.children[0].children;
    // Check if circles need to be updated
    for (let i = 0; i < elements.length; i++) {
      // Iterate over the ellipses
      if (elements[i].nodeName == "path") {
        this.renderer.addClass(elements[i], "dark:invert");
      }
      if (elements[i].nodeName == "ellipse") {
        const vals = elements[i].id.split(",");
        let valsNums: number[] = [];
        vals.forEach((e: string | number) => valsNums.push(+e))

        // Make the ellipse blue
        if (!elements[i].classList.contains("hotspot")) {
          this.renderer.addClass(elements[i], "ellipse");
        }
        // Turn red if incorrect and green if correct - also turn green if end of game
        if (!elements[i].classList.contains("hotspot")) {
          if (val.map.incorrectGuesses.includes(elements[i].id)) {
            this.renderer.addClass(elements[i], "incorrect");
          } else if (((val.map.guessed || val.map.roundOver) && elements[i].id == 'correct') || (this.admin && elements[i].id == 'correct')) {
            this.renderer.addClass(elements[i], "correct");
          }
        }
      }
    }
  }

  bindEvents() {
    // Don't update state if this is in admin panel
    if(this.admin) {
      return;
    }
    this.svgTarget = document.getElementById("map-select-image");
    this.svgTarget.addEventListener('click', (e: any) => {
      if (e?.target?.id && e.target.id !== '' && e.target.tagName === 'ellipse') {
        this.data.mapGuess(e.target.id);
      }
    });
  }

  ngOnDestroy() {
    this.stateSub && this.stateSub.unsubscribe();
    this.clientSub && this.clientSub.unsubscribe();
    this.svgTarget && this.svgTarget.removeEventListener('click', () => { });
  }
}
