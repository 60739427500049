import { Data } from '../interfaces/Data';
import {
  ArchiveGame,
  dailyStats,
  Game,
  Settings,
  Stats,
} from '../interfaces/interfaces';
import { UserDetails } from '../interfaces/UserDetails';

export const data: Data = {
  userFetched: false,
  isArchiveGame: false,
}

export const userDetails: UserDetails = {
  loggedIn: false,
  firstName: '',
  photoURL: '',
  isPhotoValid: false,
  marketingOptIn: '',
  wheretaken: '',
  wheretakenusa: '',
  worldle: '',
  flagle: '',
  statele: '',
  emovi: '',
  subscription: {
    autoRenew: false,
    isPremium: false,
    subscriptionEndDate: "null"
  }
}


export const globalStats: dailyStats = {
  totalGames: 0,
  guesses: [0, 0, 0, 0, 0, 0, 0],
  highest: 0,
  common: [
    {
      country: 'Loading countries',
      total: 0,
    },
  ],
};

export const settings: Settings = {
  difficulty: 'normal',
};

export const archiveGame: ArchiveGame = {
  isArchiveGame: false,
  uniqueId: "",
  gameNumber: 0,
  countryIndex: 0,
}

export const stats: Stats = {
  played: 0,
  win: 0,
  winPercent: 0,
  currentStreak: 0,
  gameNumber: 0,
  lastWinDate: "",
  maxStreak: 0,
  guessDist: [0, 0, 0, 0, 0, 0],
};

export const initialData: Game = {
  newGame: true,
  uniqueId: "",
  previousPage: "/",
  country: '',
  capital: '',
  countryCode: '',
  gameType: 'country',
  gameMode: 'Daily',
  showAlert: false,
  showShare: false,
  showAnswer: false,
  gameNumber: 0,
  guessed: false,
  imgSrcLink: '',
  imgSrc: '',
  imgName: '',
  wikiLink: '',
  lat: 0,
  long: 0,
  guessValue: '',
  guessNumber: 0,
  distUnit: '',
  shownConfetti: false,
  gameWon: false,
  difficulty: 'normal',
  turtleSrc: "",
  showLinks: false,
  photoCode: -1,
  topAdOpen: true,
  data: {
    supportsWebP: false,
    imgExtension: "jpg"
  },
  stars: {
    number: 0,
    percent: 0,
    stars: [
      {
        isGuessed: false,
        shownAnimation: false
      },
      {
        isGuessed: false,
        shownAnimation: false
      },
      {
        isGuessed: false,
        shownAnimation: false
      },
      {
        isGuessed: false,
        shownAnimation: false
      },
      {
        isGuessed: false,
        shownAnimation: false
      }
    ]
  },
  landmarks: {
    guessed: false,
    guesses: 0,
    buttons: [false, false, false, false, false, false],
    answer: "",
    answerSrc: "",
    locations: [],
    roundOver: false,
    shownConfetti: false,
    guessesRemaining: 2,
    zoom: false,
    zoomImage: "",
    imgSrc: "",
    wikiLink: ""
  },
  landmarksGuess: {
    showRound: false,
    shownConfetti: false,
    guessed: false,
    shake: false,
    roundOver: false,
    guessesRemaining: 2,
    landmarks: []
  },
  cityGuess: {
    imgSrc: "",
    imgSrcLink: "",
    answer: "",
    noImage: false,
    guessed: false,
    roundOver: false,
    guessesRemaining: 2,
    shownConfetti: false,
    cities: [],
    wikiLink: ""
  },
  flowerGuess: {
    imgSrc: "",
    imgSrcLink: "",
    answer: "",
    guessed: false,
    roundOver: false,
    showRound: false,
    guessesRemaining: 2,
    shownConfetti: false,
    flowers: [],
    wikiLink: ""
  },
  weatherGuess: {
    month: "",
    temp: 0,
    tempC: 0,
    guessed: false,
    roundOver: false,
    showRound: false,
    guessesRemaining: 2,
    shownConfetti: false,
    weatherOptions: [],
  },
  nicknameGuess: {
    answer: "",
    guessed: false,
    roundOver: false,
    showRound: true,
    guessesRemaining: 2,
    shownConfetti: false,
    nicknameOptions: [],
  },
  randomFactGuess: {
    title: "",
    guessed: false,
    showRound: false,
    roundOver: false,
    guessesRemaining: 2,
    shownConfetti: false,
    factOptions: [],
  },
  map: {
    showRound: false,
    guessed: false,
    location: "",
    incorrectGuesses: [],
    shake: false,
    guesses: 0,
    shownConfetti: false,
    guessesRemaining: 2,
    roundOver: false,
    imgSrc: "",
    wikiLink: ""
  },
  capitalCity: {
    guessed: false,
    guessValue: "",
    answer: "",
    shownConfetti: false,
    showConfetti: false,
    roundOver: false,
    showAnswer: false,
    guessNumber: 0,
    imgSrc: "",
    wikiLink: "",
    guesses: [
      {
        id: 1,
        city: "",
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ["grey", "grey", "grey", "grey", "grey"]
      },
      {
        id: 2,
        city: "",
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ["grey", "grey", "grey", "grey", "grey"]
      },
      {
        id: 3,
        city: "",
        isCorrect: false,
        isBlank: true,
        isLoading: false,
        isGuessed: false,
        squares: ["grey", "grey", "grey", "grey", "grey"]
      },
    ]
  },
  guesses: [
    {
      id: 0,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 1,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 2,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 3,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 4,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
    {
      id: 5,
      distance: 0,
      distanceMI: 0,
      nextTo: false,
      direction: '',
      percent: 0,
      country: '',
      isBlank: true,
      isLoading: false,
      isGuessed: false,
      squares: [],
    },
  ],
};
