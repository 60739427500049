import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as confetti from 'canvas-confetti';
import { Observable, Subscription, take } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Game } from 'src/app/interfaces/interfaces';
import { AdsService } from 'src/app/services/ads.service';
import { DataService } from 'src/app/services/data.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.scss']
})
export class FactsComponent {

  user$: Observable<UserDetails>;
  state$: Observable<Game>;
  state!: Subscription;

  @ViewChild('confettiContainer', { static: false }) confettiContainer!: ElementRef;
  @ViewChild('secondQuestion', { static: false }) secondQuestion!: ElementRef;
  @ViewChild('thirdQuestion', { static: false }) thirdQuestion!: ElementRef;
  @ViewChild('fourthQuestion', { static: false }) fourthQuestion!: ElementRef;

  @ViewChild('bonusRound', { static: false })
  bonusRound!: ElementRef;

  messageCreated = false;
  message: string = "";
  confetti = false;
  showConfetti = true;

  factScrolledTo = false;
  flowerScrolledTo = false;
  weatherScrolledTo = false;

  adsAuctioned = false;
  adsScrolledTo = false;

  constructor(private data: DataService,
    private dialog: DialogsService,
    private title: Title,
    private renderer: Renderer2,
    private ads: AdsService,
    private user: UserService
  ) {
    this.state$ = this.data.getState();
    this.title.setTitle('Facts - WhereTaken USA');
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {
    this.data.updatePreviousPage("/facts");
    this.checkState();
    this.updateScrolledTo();
  }

  updateScrolledTo() {
    this.state = this.state$.pipe(take(1)).subscribe((val: Game) => {
      if(val.randomFactGuess.roundOver && !this.factScrolledTo) {
        this.factScrolledTo = true;
      }
      if(val.flowerGuess.roundOver && !this.flowerScrolledTo) {
        this.flowerScrolledTo = true;
      }
      if(val.weatherGuess.roundOver && !this.weatherScrolledTo) {
        this.weatherScrolledTo = true;
      }
    })
  }

  scrollToMobileAds(state: Game) {
      setTimeout(() => {
        this.bonusRound.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: 'center' });
      }, 1100)
      this.adsScrolledTo = true;
  }

  startAuctionMobile() {
    if(!this.adsAuctioned) {
      this.ads.startAuction("end_mobile", 'mobile');
      this.adsAuctioned = true;
    }
  }

  checkState() {
    this.state = this.state$.subscribe((val: Game) => {
      this.checkConfetti(val);
      const results = [val.nicknameGuess.roundOver, val.randomFactGuess.roundOver, val.flowerGuess.roundOver, val.weatherGuess.roundOver];
      if (!this.messageCreated && this.checkTrueValues(results) >= 4) {
        this.message = this.createMessage(val);
      }
      if(val.weatherGuess.roundOver && !this.adsScrolledTo) {
        this.startAuctionMobile();
        this.scrollToMobileAds(val);
      } else if(val.flowerGuess.roundOver && !this.weatherScrolledTo) {
        setTimeout(() => {
          this.fourthQuestion.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
          this.startAuctionMobile();
        }, 1100)
        this.weatherScrolledTo = true;
      } else if(val.randomFactGuess.roundOver && !this.flowerScrolledTo) {
        setTimeout(() => {
          this.thirdQuestion.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
        }, 1100)
        this.flowerScrolledTo = true;
      } else if(val.nicknameGuess.roundOver && !this.factScrolledTo) {
        setTimeout(() => {
          this.secondQuestion.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
        }, 1100)
        this.factScrolledTo = true;
      }
    });
  }

  checkConfetti(val: Game) {
    if (val.nicknameGuess.guessed && !val.nicknameGuess.shownConfetti) {
      this.confettiRun();
    }
    if (val.randomFactGuess.guessed && !val.randomFactGuess.shownConfetti) {
      this.confettiRun();
    }
    if (val.flowerGuess.guessed && !val.flowerGuess.shownConfetti) {
      this.confettiRun();
    }
    if (val.weatherGuess.guessed && !val.weatherGuess.shownConfetti) {
      this.confettiRun();
    }
  }

  createMessage(val: Game) {
    this.messageCreated = true;
    const result = [val.nicknameGuess.guessed, val.randomFactGuess.guessed, val.flowerGuess.guessed, val.weatherGuess.guessed];
    const count = this.checkTrueValues(result);
    if (count === result.length) {
      return "Nice one! You got all the facts right! Here is a star!";
    } else if (count === result.length - 1) {
      return `You got ${count} out of ${result.length} facts right! Here is a star!`;
    } else if(count === result.length - 2) {
      return `You got ${count} out of ${result.length} facts right. You just missed out on a star!`
    } else {
      return `You got ${count} out of ${result.length} facts right. You missed out on a star today!`
    }
  }

  // Check how many true values there are in an array
  checkTrueValues(arr: boolean[]) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]) count++;
    }
    return count;
  }

  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);

    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });

    setTimeout(() => {
      myConfetti.reset();
    }, 1000);
  }

  ngOnDestroy() {
    this.state && this.state.unsubscribe();
  }
}
