import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import * as confetti from 'canvas-confetti';
import { Title } from '@angular/platform-browser';
import { AdsService } from 'src/app/services/ads.service';

@Component({
  selector: 'app-landmarks',
  templateUrl: './landmarks.component.html',
  styleUrls: ['./landmarks.component.scss'],
})
export class LandmarksComponent implements OnInit, AfterViewInit {

  @ViewChild('confettiContainer', { static: false })
  confettiContainer!: ElementRef;
  @ViewChild('secondQuestion', { static: false })
  secondQuestion!: ElementRef;
  @ViewChild('bonusRound', { static: false })
  bonusRound!: ElementRef;

  state!: Subscription;

  confetti = false;
  showConfetti = true;
  scrolledTo = false;
  adsAuctioned = false;
  adsScrolledTo = false;

  buttons = [false, false, false, false, false, false];

  state$: Observable<Game>

  constructor(private data: DataService, private renderer: Renderer2, private title: Title, private ads: AdsService) {
    this.state$ = this.data.getState();
  }

  ngOnInit(): void {
    this.data.updatePreviousPage("/landmarks");
    this.title.setTitle('Landmarks - WhereTaken USA');
  }

  ngAfterViewInit() : void {
    setTimeout(() => {
      this.checkState();
    }, 200)
  }

  scrollToMobileAds(state: Game) {
    if (state.landmarksGuess.roundOver && !this.adsScrolledTo)
      setTimeout(() => {
        this.bonusRound.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: 'center' });
      }, 500)
      this.adsScrolledTo = true;
  }

  startAuctionMobile() {
    if(!this.adsAuctioned) {
      this.ads.startAuction("end_mobile", 'mobile');
      this.adsAuctioned = true;
    }
  }

  checkState() {
    this.state = this.state$.subscribe((val) => {
      this.checkConfettiLandmark(val);
      if (val.landmarksGuess.roundOver) {
        this.startAuctionMobile();
        this.scrollToMobileAds(val)
      }
      else if (!this.scrolledTo && val.landmarksGuess.showRound) {
        setTimeout(() => {
          this.scrolledTo = true;
          this.secondQuestion.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
          this.startAuctionMobile();
        }, 400)
      }
    });
  }

  checkConfettiLandmark(val: any) {
    if (val.landmarks.guessed && !val.landmarks.shownConfetti) {
      this.confettiRun();
    }
    if (val.landmarksGuess.guessed && !val.landmarksGuess.shownConfetti) {
      this.confettiRun();
    }
  }

  guess(landmark: any, index: number) {
    this.buttons[index] = false;
    this.data.landmarkGuess(landmark);
  }

  showButtons(landmark: any, index: number) {
    if (window.innerWidth > 1024) {
      this.guess(landmark, index);
    } else {
      this.data.showButtons(index);
    }
  }

  hideButtons(index: number) {
    this.data.hideButtons(index);
  }

  zoom(landmark: any) {
    this.data.updateLandmarkZoom(landmark.id);
  }

  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);

    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });

    setTimeout(() => {
      myConfetti.reset();
    }, 1000);
  }

  ngOnDestroy() {
    this.state && this.state.unsubscribe();
  }
 }
