<div *ngIf="state$ | async as state" class="mt-3 text-black dark:!text-white relative">
  <a routerLink="/map" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
  <div *ngIf="!state.cityGuess?.noImage">
    <h4 class="text-lg font-bold text-center mb-1 mt-3">
      Which city in {{ state.country }} was this photo taken in?
    </h4>
    <app-image [imgSrc]="state.cityGuess.imgSrc"></app-image>
  </div>
  <div *ngIf="state.cityGuess?.noImage" class="text-center mb-6">
    <h4 class="text-lg font-bold text-center mb-1 mt-3">
      There is no city round today
    </h4>
    <p>But because you came this far, here is a free star!</p>
  </div>
  <app-progress></app-progress>
  <app-city-guesses *ngIf="!state.cityGuess?.noImage"></app-city-guesses>
  <div class="confetti pointer-events-none" #confettiContainer></div>
  <div *ngIf="!state.cityGuess.roundOver" class="mt-2">
    <p class="text-center">
      Click on a city. You have <span>{{ state.cityGuess.guessesRemaining }}</span> guess<span
        *ngIf="state.map.guessesRemaining >= 2 || state.cityGuess.guessesRemaining <=0">es</span> remaining.
    </p>
  </div>
  <div #thanks *ngIf="state.showLinks" class="text-center">
    <p *ngIf="!state.cityGuess.guessed && !state.cityGuess?.noImage" class="text-center mt-3 font-bold">Oh no, you
      didn't get the city today!</p>
      <button (click)="replayGame()" class="border bg-neutral-100 hover:bg-neutral-200 border-black rounded-md text-black font-bold py-2 px-4 shadow-md mt-3">Replay today's game</button>


    <app-share-buttons></app-share-buttons>
    <div class="mt-1">
      <a (click)="openResultDialog()" class="text-blue-600 dark:text-blue-400 hover:text-blue-400 cursor-pointer">See
        your result</a>
    </div>
    <div class="mt-4">
      <h5 class="text-lg font-bold">Thanks for playing today.</h5>
      <h5 class="text-md">We'll see you tomorrow for another game!</h5>
      <h5 class="text-md mt-1">Scroll down for photo links 👇</h5>
      <app-end-mobile-ad></app-end-mobile-ad>
      <h5 class="text-lg font-bold mt-5 mb-2">Photo and Wikipedia links</h5>

      <div class="flex flex-col items-center justify-center">
        <h6 class="font-bold mb-1">Main round</h6>
        <img class="image shadow-md rounded" [ngSrc]="state.imgSrc" width="744" height="540">
        <p class="mt-1">{{ state.imgName }}</p>
        <div class="mt-2 flex gap-3">
          <p>📷 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.imgSrcLink"
              target="_blank">View
              original image</a></p>
          <p>📚 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.wikiLink"
              target="_blank">View on
              Wikipedia</a></p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center mt-4">
        <h6 class="font-bold mb-1">Facts round</h6>
        <img class="image shadow-md rounded" [ngSrc]="state.flowerGuess.imgSrc" width="744" height="540">
        <p class="mt-1">{{ state.flowerGuess.answer }}</p>
        <div class="mt-2 flex gap-3">
          <p>📷 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.flowerGuess.imgSrcLink"
              target="_blank">View
              original image</a></p>
          <p>📚 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.flowerGuess.wikiLink"
              target="_blank">View on
              Wikipedia</a></p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center mt-4">
        <h6 class="font-bold mb-1">Landmark round</h6>
        <img class="image shadow-md rounded" [ngSrc]="state.landmarks.answerSrc" width="744" height="540">
        <p class="mt-1">{{ state.landmarks.answer }}</p>
        <div class="mt-2 flex gap-3">
          <p>📷 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.landmarks.imgSrc"
              target="_blank">View
              original image</a></p>
          <p>📚 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.landmarks.wikiLink"
              target="_blank">View on
              Wikipedia</a></p>
        </div>
      </div>
      <div *ngIf="!state.cityGuess?.noImage" class="flex flex-col items-center justify-center mt-4">
        <h6 class="font-bold mb-1">City round</h6>
        <img class="image shadow-md rounded" [ngSrc]="state.cityGuess.imgSrc" width="744" height="540">
        <p class="mt-1">{{ state.cityGuess.answer }}</p>
        <div class="mt-2 flex gap-3">
          <p>📷 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.cityGuess.imgSrcLink"
              target="_blank">View
              original image</a></p>
          <p>📚 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500" [href]="state.cityGuess.wikiLink"
              target="_blank">View on
              Wikipedia</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
