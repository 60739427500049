<div class="guess" *ngIf="state$ | async as state">
  <!-- Initial box -->
  <div (click)="boxClicked()" *ngIf="guess.isBlank" class="pre-guess bg-gray-200 dark:bg-slate-600"></div>


  <div class="is-not-blank">
    <!-- Loading boxes -->
    <div *ngIf="guess.isLoading" class="is-loading">
      <span *ngFor="let square of guess.squares; let i = index" class="{{ square }} fade-in-{{ i }}"></span>
    </div>

    <div class="is-guessed" *ngIf="guess.isGuessed" ngClass="text-black dark:!text-white">
      <div class="city">
        {{ guess.city | textLimit }}
      </div>
    </div>
    <div class="percent-wrapper text-black dark:!text-white" *ngIf="guess.isLoading || guess.isGuessed">
      <div class="percent">
        <span *ngIf="!guess.isCorrect && guess.isGuessed">❌</span>
        <span *ngIf="guess.isCorrect && guess.isGuessed">✅</span>
      </div>
    </div>
  </div>