<div class="input-container" *ngIf="state$ | async as state">
  <div *ngIf="!state.showShare">
    <div class="input-wrapper">
      <div #dropdown *ngIf="showListBox" class="list-box">
        <a (click)="countrySelect(item)" class="item text-black dark:!text-white !bg-white dark:!bg-slate-800"
          *ngFor="let item of list">
          {{ item }}
        </a>
      </div>
      <input [formControl]="input" #inputBox (focus)="onFocus()" (click)="onFocus()" class="text-black dark:!text-white"
        type="text" placeholder="Enter a state..." />
    </div>
    <button (click)="guess()"
      class="text-black dark:!text-white font-bold p-1 flex items-center justify-center border-2 uppercase my-0.5 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700 w-full rounded">
      <b>
        <span class="text-lg">📷</span>
        GUESS
      </b>
    </button>
  </div>
  <div [ngClass]="{ 'confetti-under': !showConfetti }" class="confetti" #confettiContainer></div>
  <div class="relative" *ngIf="state.showShare">
    <div class="mt-2 text-center" *ngIf="state.showAnswer">
      <p class="text-black dark:!text-white font-bold">You didn't get it today 😢</p>
    </div>
    <div class="mb-2 p-2 rounded text-center flex flex-col justify-center items-center gap-1" *ngIf="state.showShare && state.imgName !== ''">
      <p class="text-black dark:!text-white "><span class="font-bold"><span *ngIf="!state.showAnswer">Nice one! </span>This photo was taken in {{ state.imgName }}, {{ state.country }}</span>
      </p>
      <div class="flex gap-3">
        <p>📷 <a class="text-blue-600 dark:text-blue-400  hover:text-blue-500 text-sm" [href]="state.imgSrcLink" target="_blank">View
            original image</a></p>
        <p>📚 <a class="text-blue-600 dark:text-blue-400 hover:text-blue-500 text-sm" [href]="state.wikiLink" target="_blank">View on
            Wikipedia</a></p>
      </div>
    </div>

    <div #bonusRound class="p-2 bg-gray-300 dark:bg-slate-700 rounded text-center flex flex-col justify-center items-center gap-1"
      *ngIf="state.showShare">
      <p class="font-bold text-black dark:!text-white">
        <span class="text-orange-700">★</span> Bonus Round ~ 1/4 - Facts <span class="text-orange-700">★</span>
      </p>
      <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" routerLink="/facts">
        <b>🎁 Play the bonus round</b>
      </button>
    </div>
    <div>
      <app-share-buttons></app-share-buttons>
      <app-end-mobile-ad></app-end-mobile-ad>
    </div>
  </div>
</div>
