<mat-dialog-content>
    <div class="text-black dark:!text-white" *ngIf="state$ | async as state">
        <h4 class="font-bold text-center mt-3 mb-3">
            <div *ngIf="state.stars.number === 0">
                <div class="text-2xl mb-1">Ah what happened?</div>
                <div class="text-xl">Today you're a hermit</div>
            </div>
            <div *ngIf="state.stars.number === 1">
                <div class="text-2xl mb-1">Good job!</div>
                <div class="text-xl">Today you're a tourist</div>
            </div>
            <div *ngIf="state.stars.number === 2">
                <div class="text-2xl mb-1">Good job!</div>
                <div class="text-xl">Today you're a traveler</div>
            </div>
            <div *ngIf="state.stars.number === 3">
                <div class="text-2xl mb-1">Great job!</div>
                <div class="text-xl">Today, you're an explorer</div>
            </div>
            <div *ngIf="state.stars.number === 4">
                <div class="text-2xl mb-1">Great job!</div>
                <div class="text-xl">Today, you're an expert</div>
            </div>
            <div *ngIf="state.stars.number === 5">
                <div class="text-2xl mb-1">Great job!</div>
                <div class="text-xl">Today, you're a superstar</div>
            </div>
        </h4>
        <div class="flex justify-center gap-3">
            <div *ngFor="let star of stars" class="w-12 md:w-16">
                <img *ngIf="!star.show" ngSrc="./assets/star.svg" width="200" height="200" alt="Empty star">
                <img class="scale" [hidden]="!star.show" ngSrc="./assets/star-filled.svg" width="200" height="200"
                    alt="Empty star">
            </div>
        </div>
        <div class="flex justify-center mt-4">
            <div class="md:w-8/12">
                <img class="rounded" [ngSrc]="state.turtleSrc" width="800" height="800">
            </div>
        </div>
        <app-share-buttons></app-share-buttons>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="w-full flex justify-end items-end">
        <button mat-button mat-dialog-close class="button-blue text-white font-bold py-2 px-4 rounded">Close</button>
    </div>
</mat-dialog-actions>
