import { Component, Input, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Game, Stats } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { PlatformService } from 'src/app/services/platform.service';
import { UserService } from 'src/app/services/user.service';



@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtonsComponent implements OnInit {

  canShare: boolean = false;

  copied = false;
  state$: Observable<Game>;
  stats$: Observable<Stats>;

  constructor(private data: DataService, private platform: PlatformService, private user: UserService) {
    this.state$ = this.data.getState();
    this.stats$ = this.user.getStats();
  }

  ngOnInit(): void {
    if(!this.platform.isInBrowser()) return;
    const nav = window.navigator as any;
    this.canShare = nav.share ? true : false;
  }

  copy() {
    const msg = this.data.getShareMsg();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = msg;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  share() {
    if (this.canShare) {
      const msg = this.data.getShareMsg();
      navigator.share({
        text: msg
      })
    } else {
      this.copy()
    }
    return;
  }
}
