import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, take } from 'rxjs';
import { Data } from 'src/app/interfaces/Data';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Country, Game, Stats } from 'src/app/interfaces/interfaces';
import { CountriesService } from 'src/app/services/countries.service';
import { DataService } from 'src/app/services/data.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, AfterViewInit {

  queryParams!: Subscription;
  showRoundSub!: Subscription;

  state$: Observable<Game>;
  stats$: Observable<Stats>;
  user$: Observable<UserDetails>;
  data$: Observable<Data>;
  hasPlayedToday$: Observable<any>;

  places: Country[];
  showRound: boolean = true;
  isArchiveGame: boolean = false;

  constructor(
    private data: DataService,
    private user: UserService,
    private countries: CountriesService,
    private title: Title,
    private route: ActivatedRoute,
    private dialog: DialogsService,
    private router: Router
  ) {
    this.state$ = this.data.getState();
    this.places = this.countries.getCountries();
    this.title.setTitle('WhereTaken USA');
    this.stats$ = this.user.getStats();
    this.user$ = this.user.getUserDetails();
    this.data$ = this.user.getData();
    this.hasPlayedToday$ = this.user.getHasPlayedToday();
    console.log("creating game");
  }

  ngOnInit(): void {
    this.queryParams = this.route.queryParams.pipe(take(1)).subscribe(val => {
      if (val['game']) {
        this.user$.pipe(take(1)).subscribe(user => {
          if(user?.subscription?.isPremium) {
            this.data.setArchiveGame(val['game']);
            this.user.setArchiveGame(true);
          } else {
            this.router.navigate(['/archive']);
          }
        });
      }
      if (val?.['deleted'] === 'true') {
        this.dialog.openDeleteSuccess();
      }
      if (val?.['error'] === 'true') {
        console.log("opening error");
        this.dialog.openError();
      }
    });
    this.data.createGame();
    this.checkIfPlayedOnOtherBrowser();
  }

  // If the stats in the DB say they were saved today, and the local storage says they havent played
  // Dont let them play as they are on another browser
  checkIfPlayedOnOtherBrowser() {
    this.showRoundSub = this.hasPlayedToday$.subscribe(hasPlayed => {
      if (hasPlayed) {
        this.state$.pipe(take(1)).subscribe(state => {
          if(!state.showShare) {
            this.showRound = false;
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.data.updatePreviousPage("/");
  }

  ngOnDestroy() {
    this.queryParams && this.queryParams.unsubscribe();
    this.showRoundSub && this.showRoundSub.unsubscribe();
  }
}
