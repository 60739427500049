<div *ngIf="state$ | async as state">
  <h4 class="text-lg font-bold text-center mb-2 mt-3">
    {{ state.randomFactGuess.title }}
  </h4>
  <div class="grid grid-cols-2 gap-2 mb-3 mt-3">
    <div (click)="randomFactGuess(fact, i)" *ngFor="let fact of state.randomFactGuess.factOptions; let i = index"
      [ngClass]="{
              '!bg-green-300 !text-black': fact.isCorrect && fact.isGuessed,
              '!bg-white dark:!bg-slate-600 cursor-pointer' : !fact.isGuessed && !state.randomFactGuess.roundOver,
              'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !fact.isGuessed && !state.randomFactGuess.roundOver,
              'shake': fact.shake,
              'bg-slate-400' : !fact.isCorrect && fact.isGuessed
          }" class="border-2 border-slate-300 rounded-md box select-none shadow-md">
      <div class="w-full h-full px-2 py-3 flex justify-center items-center text-center">
        <span>{{fact.value}}</span>
      </div>
    </div>
  </div>
</div>
