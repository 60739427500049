import { Component } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { CityGuess, FlowerGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-flower',
  templateUrl: './flower.component.html',
  styleUrls: ['./flower.component.scss']
})
export class FlowerComponent {

  state$: Observable<any>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
  }

  flowerGuess(flower: FlowerGuess, index: number) {
    this.data.flowerGuess(flower, index);
  }
}
