<div class="mt-3 text-black dark:!text-white">
  <a routerLink="/rules" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
  <div class="mt-3">
    <h2 class="text-xl">
      <b>About</b>
    </h2>
    <div>
      <p class="mt-3">Introducing <b>WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN🇺🇸</span></b> the daily
        US States geography game where you explore The United States through a unique blend of
        photography and trivia. This engaging and educational experience challenges your knowledge of The
        United States, offering a fun way to learn.</p>
      <p class="mt-3">In the main round, you guess the US State from a photograph, then there are 4 bonus rounds. For
        every round you complete, you'll earn a star. So, gear up to collect all five stars today and showcase your
        expertise in US geography!</p>
    </div>
    <div class="mt-5">
      <h2 class="text-xl">
        <b>How to play</b>
      </h2>
      <div class="mt-3">
        <div class="grid md:grid-cols-2 gap-3 mt-2">
          <img class="shadow-md rounded" src="/assets/states/az/main1.jpg">
          <div class="order-first md:!order-last">
            <h3 class="font-bold text-base">Main round</h3>
            <p>In this round, you guess the US State the image was taken in.</p>
            <p class="pt-3">If you guess incorrectly, the game will tell yopu how far away you are, e.g. 785mi, and the
              direction ➡️ towards the correct US State.</p>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="grid md:grid-cols-2 gap-3 mt-2">
          <div >
            <h3 class="font-bold text-base">Facts round</h3>
            <p>Guess the nickname, a random fact, the flower and weather of the US State you just guessed.</p>
            <p class="pt-3">All questions are multiple choice.</p>
          </div>
          <img class="shadow-md rounded" src="/assets/states/ca/flower1.jpg">
        </div>
      </div>
      <div class="mt-10">
        <div class="grid md:grid-cols-2 gap-3 mt-2">
          <img class="shadow-md rounded" src="/assets/states/ct/landmark2.jpg">
          <div class="order-first md:!order-last">
            <h3 class="font-bold text-base">Landmark round</h3>
            <p>Guess which landmark is in the US State from a series of 4 landmarks.</p>
            <p class="pt-3">Once you have guessed the landmark, guess the landmark name!</p>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="grid md:grid-cols-2 gap-3 mt-2">
          <div>
            <h3 class="font-bold text-base">Map round</h3>
            <p>First, guess the capital city of the the US State.</p>
            <p class="pt-3">Then, place that capital on the map!</p>
          </div>
          <img class="shadow-md rounded" src="/assets/states/ct/map.svg">
        </div>
      </div>
      <div class="mt-10">
        <div class="grid md:grid-cols-2 gap-3 mt-2">
          <img class="shadow-md rounded" src="/assets/states/ca/city1.jpg">
          <div class="order-first md:!order-last">
            <h3 class="font-bold text-base">City round</h3>
            <p>For the final round you are shown a photo in a city in that US State.</p>
            <p class="pt-3">Guess the correct city to get a star!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
