<div *ngIf="state$ | async as state">
  <div class="footer-wrap" *ngIf="user$ | async as user">
    <app-top-banner-ad></app-top-banner-ad>
    <div>
      <div class="container bg-gray-50 dark:!bg-slate-900 relative lg:pt-0" [ngClass]="{
        'pt-24' : state.topAdOpen && !user?.subscription?.isPremium,
        'pt-4' : !state.topAdOpen
      }">
        <div class="absolute z-50">
          <app-alert class="alert" *ngIf="state.showAlert"></app-alert>
        </div>
        <app-header></app-header>
        <div *ngIf="archive$ | async as archive">
          <div *ngIf="archive.isArchiveGame"
            class="flex flex-col items-center justify-center mt-2 bg-slate-200 px-2 py-1 rounded-md shadow-md">
            <p class="text-sm font-bold">Archive mode</p>
            <a class="cursor-pointer text-blue-600 text-xs" (click)="returnToToday()">Return to today's game</a>
          </div>
        </div>
        <router-outlet></router-outlet>
        <!-- Ads -->
        <div class="sidebar-ad-desktop">
          <div id="adngin-sidebar_left-0"></div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>

<!-- <pre *ngIf="stats$ | async as state">
  {{ state | json  }}
</pre> -->

<!-- <pre *ngIf="state$ | async as state">
  {{ state | json  }}
</pre>
 -->



<!-- <pre *ngIf="archive$ | async as state">
  {{ state | json   }}
</pre> -->
<!-- <pre *ngIf="user$ | async as user">
  {{ user | json   }}
</pre> -->
