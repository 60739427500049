<div *ngIf="state$ | async as state">
    <div class="grid grid-cols-2 gap-2 mb-3 mt-3">
        <div (click)="cityGuess(city, i)" *ngFor="let city of state.cityGuess.cities; let i = index"
            [ngClass]="{
                '!bg-green-300 !text-black': city.isCorrect && city.isGuessed,
                '!bg-white dark:!bg-slate-600 cursor-pointer' : !city.isGuessed && !state.cityGuess.roundOver,
                'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !city.isGuessed && !state.cityGuess.roundOver,
                'shake': city.shake,
                'bg-slate-400' : !city.isCorrect && city.isGuessed
            }" class="border-2 border-slate-300 rounded-md box select-none shadow-md">
            <div class="w-full px-2 py-3 flex justify-center items-center text-center">
                <span>{{city.value}}</span>
            </div>
        </div>
    </div>
</div>
