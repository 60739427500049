<div *ngIf="state$ | async as state">
  <h4 class="text-lg font-bold text-center mb-2 mt-3">
    What is the nickname of {{ state.country }}?
  </h4>
  <div class="grid grid-cols-2 gap-2 mb-3 mt-3">
    <div (click)="nicknameGuess(nickname, i)" *ngFor="let nickname of state.nicknameGuess.nicknameOptions; let i = index"
      [ngClass]="{
              '!bg-green-300 !text-black': nickname.isCorrect && nickname.isGuessed,
              '!bg-white dark:!bg-slate-600 cursor-pointer' : !nickname.isGuessed && !state.nicknameGuess.roundOver,
              'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !nickname.isGuessed && !state.nicknameGuess.roundOver,
              'shake': nickname.shake,
              'bg-slate-400' : !nickname.isCorrect && nickname.isGuessed
          }" class="border-2 border-slate-300 rounded-md box select-none shadow-md">
      <div class="w-full h-full px-2 py-3 flex justify-center items-center text-center">
        <span>{{nickname.value}}</span>
      </div>
    </div>
  </div>
</div>
