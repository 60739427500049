import { Component } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { CityGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-city-guesses',
  templateUrl: './city-guesses.component.html',
  styleUrls: ['./city-guesses.component.scss']
})
export class CityGuessesComponent {

  state$: Observable<any>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
  }

  cityGuess(city: CityGuess, index: number) {
    this.data.cityGuess(city, index);
  }
}
