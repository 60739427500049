<div class="guess" *ngIf="state$ | async as state">
  <div
    *ngIf="guess.isBlank"
    class="pre-guess bg-gray-200 dark:bg-slate-600"
    (click)="boxClicked()"

  ></div>
  <div class="is-not-blank">
    <div
      *ngIf="guess.isLoading && state.difficulty == 'normal'"
      class="is-loading"
    >
      <span
        *ngFor="let square of guess.squares; let i = index"
        class="{{ square }} fade-in-{{ i }}"
      ></span>
    </div>
    <div
      *ngIf="guess.isLoading && !(state.difficulty == 'normal')"
      class="is-loading"
    >
      <span
        *ngFor="let square of guess.squares; let i = index"
        class="grey fade-in-{{ i }}"
      ></span>
    </div>
    <div
      class="is-guessed"
      *ngIf="guess.isGuessed"
      ngClass="text-black dark:!text-white"
    >
      <div class="country">
        {{ guess.country | textLimit }}
      </div>
      <div class="kilometers">
        <span *ngIf="state.difficulty == 'normal'">
          <span *ngIf="!guess.nextTo">
            <span *ngIf="(unit$ | async) == 'km'"> {{ guess.distance }}km </span>
            <span *ngIf="(unit$ | async) == 'mi'">
              {{ guess.distanceMI }}mi
            </span>
          </span>
          <span *ngIf="guess.nextTo">borders</span>
        </span>
      </div>
      <div class="direction">
        <span *ngIf="guess.percentage == 100">
          {{ guess.direction }}
        </span>
        <span
          *ngIf="state.difficulty === 'normal' || state.difficulty === 'hard'"
        >
          {{ guess.direction }}
        </span>
      </div>
    </div>
    <div
      class="percent-wrapper text-black dark:!text-white"
      *ngIf="guess.isLoading || guess.isGuessed"
    >
      <div *ngIf="state.difficulty === 'normal'">
        <div class="percent">
          <span [countUp]="guess.percent" [options]="{ duration: 1.7 }"></span>%
        </div>
      </div>
      <div *ngIf="!(state.difficulty === 'normal')">
        <div class="percent">
          <span *ngIf="guess.isGuessed && guess.percent < 100">❌</span>
          <span *ngIf="guess.isGuessed && guess.percent == 100">✅</span>
        </div>
      </div>
    </div>
  </div>
</div>
