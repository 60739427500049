<div class="mt-3">
  <a (click)="navigateBack()" class="text-blue-500 hover:text-blue-400 cursor-pointer">&#8592; Back</a>
  <div
    *ngIf="stats$ | async as stats"
    class="flex justify-center items-start gap-4 mt-4 text-black dark:!text-white"
  >
    <div
      class="flex flex-col justify-center items-center max-w-min text-center"
    >
      <p class="text-2xl font-bold">{{ stats.played }}</p>
      <p>Games<br />played</p>
    </div>
    <div
      class="flex flex-col justify-center items-center max-w-min text-center"
    >
      <p class="text-2xl font-bold">{{ stats.winPercent }}%</p>
      <p>Win<br />%</p>
    </div>
    <div
      class="flex flex-col justify-center items-center max-w-min text-center"
    >
      <p class="text-2xl font-bold">{{ stats.currentStreak }}</p>
      <p>Current<br />Streak</p>
    </div>
    <div
      class="flex flex-col justify-center items-center max-w-min text-center"
    >
      <p class="text-2xl font-bold">{{ stats.maxStreak }}</p>
      <p>Max Streak</p>
    </div>
  </div>
  <div class="mt-5">
    <h5 class="mb-2 font-bold text-black dark:!text-white text-center">
      Guess distribution
    </h5>
    <div *ngFor="let bar of bars; let i = index" class="flex mb-1 font-bold">
      <span class="text-black dark:!text-white number">{{ i + 1 }}</span>
      <div
        class="text-end ml-1 bar-margin dark:!text-black"
        [style]="'flex: 0 1 ' + (bar.percent-8) + '%'"
      >
        {{ bar.val }}
      </div>
    </div>
  </div>
</div>
