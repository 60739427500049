import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RulesComponent } from './pages/rules/rules.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { StatsComponent } from './pages/stats/stats.component';

import { GameComponent } from './pages/game/game.component';
import { MapComponent } from './pages/map/map.component';
import { LandmarksComponent } from './pages/landmarks/landmarks.component';
import { LandmarksGuard } from './guards/landmarks.guard';
import { LandmarksMapGuard } from './guards/landmarks-map.guard';
import { CityComponent } from './pages/city/city.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminGuard } from './guards/admin.guard';
import { AdminAreaComponent } from './pages/admin-area/admin-area.component';
import { FactsComponent } from './pages/facts/facts.component';
import { AboutComponent } from './pages/about/about.component';
import { AccountComponent } from './pages/account/account.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ReplayComponent } from './pages/replay/replay.component';

const routes: Routes = [
  { path: '', component: GameComponent },
  {
    path: 'rules', component: RulesComponent,
  },
  {
    path: 'stats', component: StatsComponent,

  },
  {
    path: 'settings', component: SettingsComponent,
  },
  {
    path: 'about', component: AboutComponent,
  },
  {
    path: 'account', component: AccountComponent,
  },
  {
    path: 'facts', component: FactsComponent,
    canActivate: [LandmarksGuard],
  },
  {
    path: 'map', component: MapComponent,
    canActivate: [LandmarksMapGuard],
  },
  {
    path: 'landmarks',
    component: LandmarksComponent,
    canActivate: [LandmarksGuard],
  },
  {
    path: 'city',
    component: CityComponent,
    canActivate: [LandmarksGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: []
  },
  {
    path: 'admin/portal',
    component: AdminAreaComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'archive',
    component: ArchiveComponent
  },
  {
    path: 'replay',
    component: ReplayComponent
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  static forChild(): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
    throw new Error('Method not implemented.');
  }
}
