<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <h4 class="font-bold text-center mt-3 mb-3">
      Your account was successfully deleted
    </h4>
    <p class="text-center">We're sorry to see you go. We have left your stats saved to this browser. If you would like to clear those stats, you need to clear the browser cookies for this website.</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end items-end">
    <button mat-button mat-dialog-close class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Close</button>
  </div>
</mat-dialog-actions>
