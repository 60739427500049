import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Stats } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

interface bars {
  percent: number;
  val: number;
}

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  bars: bars[] = [];
  stats$: Observable<Stats>;

  constructor(private data: DataService, private title: Title, private user: UserService) {
    this.stats$ = this.user.getStats();
    this.title.setTitle('Stats - WhereTaken USA');
  }

  ngOnInit(): void {
    this.stats$.subscribe((val) => {
      this.bars = [];
      const max = Math.max(...val.guessDist);
      val.guessDist.forEach((dist) => {
        this.bars.push({
          percent: (dist / max) * 100,
          val: dist,
        });
      });
    });
  }

  navigateBack() {
    this.data.navigateToPreviousPage();
  }
}
