import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { countries } from 'src/app/data/countries';
import { randomPerms } from 'src/app/data/perms';
import { weatherByState } from 'src/app/data/weather';
import { Country } from 'src/app/interfaces/interfaces';
import { DialogsService } from 'src/app/services/dialogs.service';

interface Game {
  date: string,
  state: string,
  code: string,
  gameNumber: number,
  stateGame: number,
  photoCode: number,
  mainImage: string,
  landmarkImage: string,
  cityImage: string,
  cityName: string,
  allCities: string[]
  landmarkName: string,
  mapImage: string
  otherLandmarks: LandmarkRound[],
  landmarkPlaces: string[],
  landmarkCheck: boolean,
  cityCheck: boolean,
  past: boolean,
  mainGameName: boolean,
  gameUrl: string,
  capitalCity: string,
  flowerImage: string
}

interface LandmarkRound {
  code: string,
  number: number
}

@Component({
  selector: 'app-admin-area',
  templateUrl: './admin-area.component.html',
  styleUrls: ['./admin-area.component.scss']
})
export class AdminAreaComponent implements OnInit {

  pages = [true, false, false, false, false]
  games: Game[] = [];
  perms = randomPerms;
  states = countries;
  showUpcoming: boolean = true;
  page$: BehaviorSubject<string> = new BehaviorSubject("upcoming");
  gameByState$: BehaviorSubject<Country> = new BehaviorSubject(countries[0]);
  stateCode: string = 'ak';
  showMap: boolean = true;
  statePlusCode: string = "";
  capital: string = "";

  cityCheck: boolean = false;
  errors: string[] = [];
  mainCheck: boolean = false;
  nicknameCheck: boolean = false;
  factCheck: boolean = false;
  flowerCheck: boolean = false;
  weatherCheck: boolean = false;
  landmarkCheck: boolean = false;

  constructor(private dialog: DialogsService) { }

  enlargeImage(val: string) {
    this.dialog.updateImage(val);
    this.dialog.openEnlargeImage();
  }

  togglePage(page: number) {
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i] = false;
    }
    this.pages[page] = true;

    switch (page) {
      case 0: {
        this.populateGames(true)
        break;
      }
      case 1: {
        this.populateGames(false)
        break
      }
      case 3: {
        this.changeMap("Alaska");
        break;
      }
      case 4: {
        this.games = [];
        this.runChecks();
      }
    }
  }

  changeMap(stateName: string) {
    const state = this.states.find(v => v.name === stateName);
    if (state) {
      this.showMap = false;
      this.stateCode = state.code;
      this.statePlusCode = stateName + " (" + state.code.toUpperCase() + ")";
      this.capital = state.capital;
    }
    setTimeout(() => {
      this.showMap = true;
    }, 50)
  }

  runChecks() {
    const states = countries;

    // Check if each game in countries.ts has a main image with a name, imageLink and wikiLink
    this.cityCheck = true;
    this.mainCheck = true;
    this.nicknameCheck = true;
    this.factCheck = true;
    this.flowerCheck = true;
    this.weatherCheck = true;
    this.landmarkCheck = true;
    states.forEach(state => {
      if(state.cities.length !== 6 && state.name !== "Washington D.C") {
        this.errors.push(`Missing cities for ${state.name}`);
        this.cityCheck = false;
      }

      state.game.forEach((game, i) => {
        if (state.name !== "Washington D.C") {
          if (game.city.name === "") {
            this.errors.push(`Missing city name for ${state.name} (Game ${i + 1})`);
            this.cityCheck = false;
          }
          if(!state.cities.includes(game.city.name)) {
            this.errors.push(`City name for ${state.name} (Game ${i + 1}) is not in the list of cities`);
            this.cityCheck = false;
          }

          if (game.city.imageLink === "") {
            this.errors.push(`Missing city image link for ${state.name} (Game ${i + 1})`);
            this.cityCheck = false;
          }
          if (game.city.wikiLink === "") {
            this.errors.push(`Missing city wikipedia link for ${state.name} (Game ${i + 1})`);
            this.cityCheck = false;
          }
        }
        if (game.mainImage.name === "") {
          this.errors.push(`Missing mainImage name for ${state.name} (Game ${i + 1})`);
          this.mainCheck = false;
        }
        if (game.mainImage.imageLink === "") {
          this.errors.push(`Missing mainImage image link for ${state.name} (Game ${i + 1})`);
          this.mainCheck = false;
        }
        if (game.mainImage.wikiLink === "") {
          this.errors.push(`Missing mainImage wikipedia link for ${state.name} (Game ${i + 1})`);
          this.mainCheck = false;
        }
        if (game.nicknameOptions.length !== 5 || this.hasDuplicates(game.nicknameOptions) || state.nickname === "" || !state.nickname) {
          this.errors.push(`Nickname round missing nicknames for ${state.name} (Game ${i + 1})`);
          this.nicknameCheck = false;
        }
        if (game.facts.trueFalse) {
          if (game.facts.trueFact === "" || game.facts.falseFact === "") {
            this.errors.push(`Fact round missing answers for true/false question for ${state.name} (Game ${i + 1})`);
            this.factCheck = false;
          }
        } else {
          if (game.facts.fillAnswer === "" || this.hasDuplicates(game.facts.fillAnswers) || game.facts.fillAnswers.length !== 4 || game.facts.fillQuestion === "" || !game.facts.fillAnswers.includes(game.facts.fillAnswer)) {
            this.errors.push(`Fact round missing answers for multiple choice question for ${state.name} (Game ${i + 1})`);
            this.factCheck = false;
          }
        }
        // Flower check
        if(state.flower.name === "" || state.flower.imgLink === "" || state.flower.imgLink2 === "" || state.flower.wikiLink === "") {
          this.errors.push(`Missing flower information for ${state.name}`);
          this.flowerCheck = false;
        }
        if(game.flowerOptions.length !== 3) {
          this.errors.push(`Flower round missing answers for ${state.name} (Game ${i + 1})`);
          this.flowerCheck = false;
        }
        if(this.hasDuplicates(game.flowerOptions)) {
          this.errors.push(`Flower round has duplicate answers for ${state.name} (Game ${i + 1})`);
          this.flowerCheck = false;
        }
        if(game.flowerOptions.includes(state.flower.name)) {
          this.errors.push(`Flower round has correct answer as a second option for ${state.name} (Game ${i + 1})`);
          this.flowerCheck = false;
        }

        // Landmarks check
        if((game.landmark.name === "" || game.landmark.imageLink === "" || game.landmark.wikiLink === "")) {
          this.errors.push(`Landmark round missing data for ${state.name} (Game ${i + 1})`);
          this.landmarkCheck = false;
        }

        if(game.landmarksRound.length !== 3 || this.hasDuplicates(game.landmarksRound.map(v => v.code + v.number))) {
          this.errors.push(`Landmarks round has duplicate answers for ${state.name} (Game ${i + 1})`);
          this.landmarkCheck = false;
        }

        if(game.landmarkPlaces.length !== 7 || this.hasDuplicates(game.landmarkPlaces) || game.landmarkPlaces.includes(game.landmark.name)) {
          this.errors.push(`Landmarks round has duplicate answers for ${state.name} (Game ${i + 1})`);
          this.landmarkCheck = false;
        }
      });
    });

    if(states.length !== weatherByState.length) {
      this.weatherCheck = false;
      this.errors.push(`Missing state in weatherByState.ts`);
    }
    weatherByState.forEach(weather => {
      const state = states.find(v => v.name === weather.state);
      if(!state) {
        this.weatherCheck = false;
        this.errors.push(`Missing state in countries.ts for ${weather.state}`);
      }
      if(weather.weather.length !== 12) {
        this.weatherCheck = false;
        this.errors.push(`Missing weather for ${weather.state}`);
      }
    });

  }

  hasDuplicates(array: string[]) {
    return (new Set(array)).size !== array.length;
  }

  changeState(state: string) {
    this.games = [];

    const pos = countries.map(e => e.name).indexOf(state);
    this.gameByState$.next(countries[pos]);
    const game = countries[pos];

    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    let currentGameNum = 0;

    this.perms.forEach(perm => {
      if (perm.date === dateString) {
        currentGameNum = perm.number;
      }
    });

    game.game.forEach((g, i) => {
      let date = "";
      let photoCode = 0;
      let stateGameNumber = 0;
      let globalGameNumber = 0

      this.perms.forEach(perm => {
        if (perm.state === game.name && perm.photoCode === i + 1) {
          date = perm.date;
          photoCode = perm.photoCode;
          stateGameNumber = perm.photoCode;
          globalGameNumber = perm.number;
        }
      });
      const flowerIndex = photoCode % 2;
      let flowerString;
      if (flowerIndex === 0) {
        flowerString = "2";
      } else {
        flowerString = "1";
      }

      if (date !== "") {
        this.games.push({
          date: date,
          gameNumber: globalGameNumber,
          stateGame: stateGameNumber,
          photoCode: photoCode,
          code: game.code.toLowerCase(),
          state: game.name,
          mainImage: `./assets/states/${game.code.toLowerCase()}/main${photoCode}.webp`,
          landmarkImage: `./assets/states/${game.code.toLowerCase()}/landmark${photoCode}.webp`,
          cityImage: `./assets/states/${game.code.toLowerCase()}/city${photoCode}.webp`,
          mapImage: `./assets/states/${game.code.toLowerCase()}/map.svg`,
          flowerImage: `./assets/states/${game.code.toLowerCase()}/flower${flowerString}.webp`,
          otherLandmarks: game.game[photoCode - 1].landmarksRound,
          landmarkPlaces: game.game[photoCode - 1].landmarkPlaces,
          cityName: game.game[photoCode - 1].city.name,
          landmarkName: game.game[photoCode - 1].landmark.name,
          landmarkCheck: game.game[photoCode - 1].landmarkPlaces.length === 7 ? true : false,
          cityCheck: game.cities.length === 6 ? true : false,
          past: currentGameNum > globalGameNumber ? true : false,
          mainGameName: game.game[photoCode - 1].mainImage.name !== "" ? true : false,
          allCities: game.cities,
          gameUrl: `/?game=${game.game[photoCode - 1].uniqueId}`,
          capitalCity: game.capital
        })
      }
    });
  }

  ngOnInit(): void {
    this.populateGames(true);
  }

  populateGames(upcoming: boolean) {
    this.games = [];

    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    let start = false;
    if (!upcoming) {
      start = true;
    }
    let count = 0;
    this.perms.forEach(perm => {
      if (start) {
        const state = this.states.find(v => v.name === perm.state);
        if (state && count < 60) {
          let landmarkCheck = state.game[perm.photoCode - 1].landmarkPlaces.length === 7 ? true : false;
          let cityCheck = state.cities.length === 6 ? true : false;

          this.games.push({
            date: perm.date,
            gameNumber: perm.number,
            stateGame: 0,
            photoCode: perm.photoCode,
            code: state.code.toLowerCase(),
            state: state.name,
            mainImage: `./assets/states/${state.code.toLowerCase()}/main${perm.photoCode}.webp`,
            landmarkImage: `./assets/states/${state.code.toLowerCase()}/landmark${perm.photoCode}.webp`,
            cityImage: `./assets/states/${state.code.toLowerCase()}/city${perm.photoCode}.webp`,
            mapImage: `./assets/states/${state.code.toLowerCase()}/map.svg`,
            flowerImage: `./assets/states/${state.code.toLowerCase()}/flower${((perm.photoCode % 2) + 1)}.webp`,
            otherLandmarks: state.game[perm.photoCode - 1].landmarksRound,
            landmarkPlaces: state.game[perm.photoCode - 1].landmarkPlaces,
            cityName: state.game[perm.photoCode - 1].city.name,
            landmarkName: state.game[perm.photoCode - 1].landmark.name,
            landmarkCheck: landmarkCheck,
            cityCheck: cityCheck,
            past: false,
            mainGameName: state.game[perm.photoCode - 1].mainImage.name !== "" ? true : false,
            allCities: state.cities,
            gameUrl: `/?game=${state.game[perm.photoCode - 1].uniqueId}`,
            capitalCity: state.capital
          })
        }
        count++;
      }
      if (perm.date === dateString) {
        start = true;
      }
      if (!upcoming && perm.date === dateString) {
        start = false;
      }
    });
    if (!upcoming) {
      this.games = this.games.reverse();
    }
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = document.head.baseURI.substring(0, document.head.baseURI.length - 1) + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
