import { Borders } from "../interfaces/interfaces";


export const borders : Borders[] = [
  {
      "name": "Alaska",
      "borders": []
  },
  {
      "name": "Alabama",
      "borders": [
          "Mississippi",
          "Tennessee",
          "Florida",
          "Georgia"
      ]
  },
  {
      "name": "Arizona",
      "borders": [
          "Nevada",
          "New Mexico",
          "Utah",
          "California",
          "Colorado"
      ]
  },
  {
      "name": "Arkansas",
      "borders": [
          "Oklahoma",
          "Tennessee",
          "Texas",
          "Louisiana",
          "Mississippi",
          "Missouri"
      ]
  },
  {
      "name": "California",
      "borders": [
          "Oregon",
          "Arizona",
          "Nevada"
      ]
  },
  {
      "name": "Colorado",
      "borders": [
          "New Mexico",
          "Oklahoma",
          "Utah",
          "Wyoming",
          "Arizona",
          "Kansas",
          "Nebraska"
      ]
  },
  {
      "name": "Connecticut",
      "borders": [
          "New York",
          "Rhode Island",
          "Massachusetts"
      ]
  },
  {
      "name": "Delaware",
      "borders": [
          "New Jersey",
          "Pennsylvania",
          "Maryland"
      ]
  },
  {
      "name": "Florida",
      "borders": [
          "Georgia",
          "Alabama"
      ]
  },
  {
      "name": "Georgia",
      "borders": [
          "North Carolina",
          "South Carolina",
          "Tennessee",
          "Alabama",
          "Florida"
      ]
  },
  {
      "name": "Idaho",
      "borders": [
          "Utah",
          "Washington",
          "Wyoming",
          "Montana",
          "Nevada",
          "Oregon"
      ]
  },
  {
      "name": "Illinois",
      "borders": [
          "Kentucky",
          "Missouri",
          "Wisconsin",
          "Indiana",
          "Iowa",
          "Michigan"
      ]
  },
  {
      "name": "Indiana",
      "borders": [
          "Michigan",
          "Ohio",
          "Illinois",
          "Kentucky"
      ]
  },
  {
      "name": "Iowa",
      "borders": [
          "Nebraska",
          "South Dakota",
          "Wisconsin",
          "Illinois",
          "Minnesota",
          "Missouri"
      ]
  },
  {
      "name": "Kansas",
      "borders": [
          "Nebraska",
          "Oklahoma",
          "Colorado",
          "Missouri"
      ]
  },
  {
      "name": "Kentucky",
      "borders": [
          "Tennessee",
          "Virginia",
          "West Virginia",
          "Illinois",
          "Indiana",
          "Missouri",
          "Ohio"
      ]
  },
  {
      "name": "Louisiana",
      "borders": [
          "Texas",
          "Arkansas",
          "Mississippi"
      ]
  },
  {
      "name": "Maine",
      "borders": [
          "New Hampshire"
      ]
  },
  {
      "name": "Maryland",
      "borders": [
          "Washington D.C",
          "Virginia",
          "West Virginia",
          "Delaware",
          "Pennsylvania"
      ]
  },
  {
      "name": "Virginia",
      "borders": [
          "Washington D.C",
          "North Carolina",
          "Tennessee",
          "West Virginia",
          "Kentucky",
          "Maryland"
      ]
  },
  {
      "name": "Massachusetts",
      "borders": [
          "New York",
          "Rhode Island",
          "Vermont",
          "Connecticut",
          "New Hampshire"
      ]
  },
  {
      "name": "Michigan",
      "borders": [
          "Ohio",
          "Illinois",
          "Indiana",
          "Wisconsin",
          "Minnesota"
      ]
  },
  {
      "name": "Minnesota",
      "borders": [
          "North Dakota",
          "South Dakota",
          "Iowa",
          "Wisconsin",
          "Michigan"
      ]
  },
  {
      "name": "Mississippi",
      "borders": [
          "Louisiana",
          "Tennessee",
          "Alabama",
          "Arkansas"
      ]
  },
  {
      "name": "Missouri",
      "borders": [
          "Nebraska",
          "Oklahoma",
          "Tennessee",
          "Arkansas",
          "Illinois",
          "Iowa",
          "Kansas",
          "Kentucky"
      ]
  },
  {
      "name": "Montana",
      "borders": [
          "South Dakota",
          "Wyoming",
          "Idaho",
          "North Dakota"
      ]
  },
  {
      "name": "Nebraska",
      "borders": [
          "Missouri",
          "South Dakota",
          "Wyoming",
          "Colorado",
          "Iowa",
          "Kansas"
      ]
  },
  {
      "name": "Nevada",
      "borders": [
          "Idaho",
          "Oregon",
          "Utah",
          "Arizona",
          "California"
      ]
  },
  {
      "name": "New Hampshire",
      "borders": [
          "Vermont",
          "Maine",
          "Massachusetts"
      ]
  },
  {
      "name": "New Jersey",
      "borders": [
          "Pennsylvania",
          "Delaware",
          "New York"
      ]
  },
  {
      "name": "New Mexico",
      "borders": [
          "Oklahoma",
          "Texas",
          "Utah",
          "Arizona",
          "Colorado"
      ]
  },
  {
      "name": "New York",
      "borders": [
          "Pennsylvania",
          "Rhode Island",
          "Vermont",
          "Connecticut",
          "Massachusetts",
          "New Jersey"
      ]
  },
  {
      "name": "North Carolina",
      "borders": [
          "Tennessee",
          "Virginia",
          "Georgia",
          "South Carolina"
      ]
  },
  {
      "name": "North Dakota",
      "borders": [
          "South Dakota",
          "Minnesota",
          "Montana"
      ]
  },
  {
      "name": "Ohio",
      "borders": [
          "Michigan",
          "Pennsylvania",
          "West Virginia",
          "Indiana",
          "Kentucky"
      ]
  },
  {
      "name": "Oklahoma",
      "borders": [
          "Missouri",
          "New Mexico",
          "Texas",
          "Arkansas",
          "Colorado",
          "Kansas"
      ]
  },
  {
      "name": "Oregon",
      "borders": [
          "Nevada",
          "Washington",
          "California",
          "Idaho"
      ]
  },
  {
      "name": "Pennsylvania",
      "borders": [
          "New York",
          "Ohio",
          "West Virginia",
          "Delaware",
          "Maryland",
          "New Jersey"
      ]
  },
  {
      "name": "Rhode Island",
      "borders": [
          "Massachusetts",
          "New York",
          "Connecticut"
      ]
  },
  {
      "name": "South Carolina",
      "borders": [
          "North Carolina",
          "Georgia"
      ]
  },
  {
      "name": "South Dakota",
      "borders": [
          "Nebraska",
          "North Dakota",
          "Wyoming",
          "Iowa",
          "Minnesota",
          "Montana"
      ]
  },
  {
      "name": "Tennessee",
      "borders": [
          "Mississippi",
          "Missouri",
          "North Carolina",
          "Virginia",
          "Alabama",
          "Arkansas",
          "Georgia",
          "Kentucky"
      ]
  },
  {
      "name": "Texas",
      "borders": [
          "New Mexico",
          "Oklahoma",
          "Arkansas",
          "Louisiana"
      ]
  },
  {
      "name": "Utah",
      "borders": [
          "Nevada",
          "New Mexico",
          "Wyoming",
          "Arizona",
          "Colorado",
          "Idaho"
      ]
  },
  {
      "name": "Vermont",
      "borders": [
          "New Hampshire",
          "New York",
          "Massachusetts"
      ]
  },
  {
      "name": "Washington",
      "borders": [
          "Oregon",
          "Idaho"
      ]
  },
  {
      "name": "West Virginia",
      "borders": [
          "Pennsylvania",
          "Virginia",
          "Kentucky",
          "Maryland",
          "Ohio"
      ]
  },
  {
      "name": "Wisconsin",
      "borders": [
          "Michigan",
          "Minnesota",
          "Illinois",
          "Iowa"
      ]
  },
  {
      "name": "Wyoming",
      "borders": [
          "Nebraska",
          "South Dakota",
          "Utah",
          "Colorado",
          "Idaho",
          "Montana"
      ]
  },
  {
      "name": "Washington D.C",
      "borders": [
          "Maryland",
          "Virginia"
      ]
  }
];
