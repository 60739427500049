import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Game, Settings } from 'src/app/interfaces/interfaces';
import { DataService } from '../../services/data.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  stateSub!: Subscription;
  diffSub!: Subscription;

  units!: FormControl;
  theme!: FormControl;
  difficulty!: FormControl;

  showAlert = false;
  darkMode!: boolean;

  state$: Observable<Game>;
  difficulty$: Observable<Settings>;

  constructor(private data: DataService, private title: Title, private platform: PlatformService) {
    this.state$ = this.data.getState();
    this.difficulty$ = this.data.getSettings();
    this.title.setTitle('Settings - WhereTaken USA');
  }

  ngOnInit(): void {
    this.units = new FormControl('km');
    this.difficulty = new FormControl('hard');
    this.theme = new FormControl(this.getThemeFromLocalStorage());

    this.stateSub = this.state$.subscribe((val) => {
      this.units.setValue(val.distUnit);
    });

    this.diffSub = this.difficulty$.subscribe((val) => {
      this.difficulty.setValue(val.difficulty);
    });
  }

  navigateBack() {
    this.data.navigateToPreviousPage();
  }

  unitsChanged() {
    this.data.updateDistUnit(this.units.value);
  }

  difficultyChanged() {
    this.data.updateDifficulty(this.difficulty.value);
    this.showAlert = true;
  }

  themeChanged() {
    if(!this.platform.isInBrowser()) return;
    if(this.theme.value === 'light') {
      document.documentElement.classList.remove('dark')
      localStorage.setItem("dark-mode", "false");
      this.darkMode = false;
    } else {
      document.documentElement.classList.add('dark')
      localStorage.setItem("dark-mode", "true");
      this.darkMode = true;
    }
  }

  ngOnDestroy() {
    this.stateSub.unsubscribe();
  }

  getThemeFromLocalStorage() : string {
    if(!this.platform.isInBrowser()) return "light";
    if(localStorage.getItem("dark-mode") === null) {
      if (localStorage['theme'] === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark')
        localStorage.setItem("dark-mode", "true")
        this.darkMode = true;
      } else {
        document.documentElement.classList.remove('dark')
        localStorage.setItem("dark-mode", "false")
        this.darkMode = false;
      }
      return "light";
    } else {
      if(localStorage.getItem("dark-mode") === "true") {
        document.documentElement.classList.add('dark');
        this.darkMode = true;
        return "dark";
      } else {
        document.documentElement.classList.remove('dark');
        this.darkMode = false;
        return "light";
      }
    }
  }
}
