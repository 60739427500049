import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FactGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-random-fact',
  templateUrl: './random-fact.component.html',
  styleUrls: ['./random-fact.component.scss']
})
export class RandomFactComponent {

  state$: Observable<any>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
  }

  randomFactGuess(fact: FactGuess, index: number) {
    this.data.randomFactGuess(fact, index);
  }
}
