import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, catchError, firstValueFrom, of, take, tap } from 'rxjs';
import { data, stats, userDetails } from '../data/data';
import { PlatformService } from './platform.service';
import { DatabaseService } from './database.service';
import { DialogsService } from './dialogs.service';
import { Router } from '@angular/router';
import { ImageService } from './image.service';
import { CookieService } from './cookies.service';
import { UserDetails } from '../interfaces/UserDetails';
import { UtilitiesService } from './utilities.service';
import { Game, Stats } from '../interfaces/interfaces';
import { AdsService } from './ads.service';
import { Data } from '../interfaces/Data';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userDetails: BehaviorSubject<UserDetails> = new BehaviorSubject(JSON.parse(JSON.stringify(userDetails)));
  private stats = new BehaviorSubject(JSON.parse(JSON.stringify(stats)));
  private data: BehaviorSubject<Data> = new BehaviorSubject(JSON.parse(JSON.stringify(data)));
  hasPlayedToday = new BehaviorSubject<any>(false);

  constructor(
    private platform: PlatformService,
    private db: DatabaseService,
    private dialog: DialogsService,
    private router: Router,
    private cookies: CookieService,
    private utilities: UtilitiesService,
    private image: ImageService,
    private ads: AdsService,
  ) { }

  getUserDetails(): Observable<UserDetails> {
    return this.userDetails.asObservable();
  }

  getHasPlayedToday(): Observable<boolean> {
    return this.hasPlayedToday.asObservable();
  }

  getStats(): Observable<Stats> {
    return this.stats;
  }

  getStatsObj(): Stats {
    return this.stats.value;
  }

  getStatsBS(): BehaviorSubject<Stats> {
    return this.stats;
  }

  getData(): Observable<Data> {
    return this.data.asObservable();
  }

  setArchiveGame(val: boolean) {
    let data = this.data.value;
    data.isArchiveGame = val;
    this.data.next(data);
  }

  async loadUserAndRefreshTokens() {
    // Get user from localStorage
    this.loadUserFromLocalStorage();

    const isLoggedIn = this.cookies.getCookie('isLoggedIn');
    if (isLoggedIn === "true") {
      try {
        await this.refreshTokens();
        this.getUserDetailsFromDatabase();
      } catch (e) {
        this.ads.loadAdScript();
        console.error('Error refreshing tokens:', e);
      }
    } else {
      // Set user fetched to true so it won't continue showing the spinner
      this.ads.loadAdScript();
      this.userFetched();
    }
  }

  refreshTokens() {
    return new Promise((resolve, reject) => {
      const encodedRefreshTime = this.cookies.getCookie('refreshDate');
      const refreshTime = encodedRefreshTime ? decodeURIComponent(encodedRefreshTime) : null;
      console.log('Refresh time:', refreshTime);
      if (refreshTime && this.utilities.isSameUtcDay(refreshTime)) {
        console.log('Not refreshing tokens - already done today');
        resolve(null);
        return;
      }
      console.log('Refreshing tokens - going to do it...');
      this.db.refreshTokens().pipe(
        take(1),
        catchError((e: any) => {
          this.dialog.openWeLoggedYouOut();
          this.signOut();
          return EMPTY;
        }),
        tap((data: any) => {
          console.log(data);
        })
      ).subscribe({
        next: () => resolve(null),
        error: reject
      });
    });
  }

  async getUserDetailsFromDatabase() {
    try {
      await firstValueFrom(this.db.getUserDetails().pipe(
        take(1),
        catchError((e: any) => {
          console.error('Error getting user details:', e);
          this.dialog.openError();
          this.signOut();
          return of(null);
        }),
        tap((data: any) => {
          if (data !== null) {
            console.log('User details from database:', data);
            this.updateLocalUserDetails(data);
            this.updateLocalStats(data.wheretakenusa);
            const user: UserDetails = this.userDetails.value;
            // Save user to localStorage
            if (!user?.subscription?.isPremium) {
              this.ads.loadAdScript();
            }
          }
          this.userFetched();
        })
      ));
    } catch (error) {
      console.log("Here is the error");
      console.log(error);
      this.dialog.openError();
      this.signOut();
    }
  }

  saveStatsToDB() {
    if (!this.platform.isInBrowser()) return;
    const stats = this.stats.value;
    const user: UserDetails = this.userDetails.value

    if (!user.loggedIn) return;
    this.db.saveStats(stats).pipe(
      take(1),
      catchError((e: any) => {
        return of(null);
      }
      )).subscribe((data: any) => {
        if (data.success) {
          this.updateLocalUserDetails(data.user);
          this.updateLocalStats(data.user.wheretakenusa);
        }
      });
  }


  // Utilities
  updateLocalUserDetails(user: UserDetails) {
    this.userDetails.next(user);
    let userDetails = this.userDetails.value;
    userDetails.loggedIn = true;
    this.userDetails.next(userDetails);
    localStorage.setItem('wtusa-user', JSON.stringify(user));
    this.utilities.checkPhotoValidity(userDetails.photoURL).then((isPhotoValid) => {
      userDetails.isPhotoValid = isPhotoValid;
      this.userDetails.next(userDetails);
      localStorage.setItem('wtusa-user', JSON.stringify(user));
    });
  }

  loadUserFromLocalStorage() {
    const userDetails = this.userDetails.value;
    const user = localStorage.getItem('wtusa-user');
    if (user) {
      this.userDetails.next(JSON.parse(user));
    }
  }

  updateLocalStats(stats: string) {
    console.log("Data from getStatsFromDB: ", stats);
    if (stats == "" || !stats) {
      return;
    }

    const today = new Date().toISOString();
    const statsParsed: Stats = JSON.parse(stats);

    // Update stats locally, only if the number of games played is greater than or equal to the number of games played locally
    let localStats = localStorage.getItem('us-stats');
    if (localStats) {
      let stats: Stats = JSON.parse(localStats);
      if (statsParsed && statsParsed.played >= stats.played) {
        this.updateStatsFromDB(statsParsed);
      }

    } else {
      if (statsParsed) {
        this.updateStatsFromDB(statsParsed);
      }
    }

    const localGame = localStorage.getItem('us-game');
    if (localGame) {
      const game: Game = JSON.parse(localGame);
      if (!game.showShare) {
        // If the game has been played on another device today, set hasPlayedToday to true
        if (statsParsed.gameNumber !== 0 && statsParsed.gameNumber === this.image.getGameNumber()) {
          if (statsParsed.lastWinDate && this.utilities.isSameDay(statsParsed.lastWinDate, today)) {
            this.hasPlayedToday.next(true);
          }
        }
      }
    }

  }

  updateStatsFromDB(incomingStats: any) {
    this.stats.next(incomingStats);
    localStorage.setItem('us-stats', JSON.stringify(incomingStats));
  }

  userFetched() {
    let data: Data = this.data.value;
    data['userFetched'] = true;
    this.data.next(data);
  }

  signOut() {
    if (!this.platform.isInBrowser()) return;
    this.userDetails.next(JSON.parse(JSON.stringify(userDetails)));
    this.cookies.clearCookie('isLoggedIn');
    this.router.navigate(['/']);
  }
}
