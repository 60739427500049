<div class="mt-3 text-black dark:!text-white">
    <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <div class="flex flex-col items-center justify-center">
      <h4 class="text-lg font-bold text-center mt-3">
        Admin area
      </h4>
    </div>
    <div class="flex justify-center">
      <div class="mt-2 flex flex-col md:w-3/6">
          <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter the password</label>
          <input [formControl]="input" type="password" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Password" required>
          <button (click)="passwordEntered()" class="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2">Enter</button>
      </div>
    </div>
    <div *ngIf="showAlert" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6" role="alert">
      <strong class="font-bold">Oh no!&nbsp;</strong>
      <span class="block sm:inline">We don't recognize that password.</span>
    </div>
</div>