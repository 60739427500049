import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { NicknameGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-nickname',
  templateUrl: './nickname.component.html',
  styleUrls: ['./nickname.component.scss']
})
export class NicknameComponent {
  state$: Observable<any>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
  }

  nicknameGuess(nickname: NicknameGuess, index: number) {
    this.data.nicknameGuess(nickname, index);
  }
}
