import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { randomPerms } from '../data/perms';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  isLoading = new BehaviorSubject<boolean>(true);

  constructor() { }

  getGameNumber(): number {
    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const perm = randomPerms.find(perm => perm.date === dateString);
    return perm?.number || 1;
  }

  supportsWebP() {
    let elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    else {
      // very old browser like IE 8, canvas not supported
      return false;
    }
  }

  async checkImage(url: string){
    const res = await fetch(url);
    const buff = await res.blob();
    return buff.type.startsWith('image/')
}

  getState(): Observable<boolean> {
    return this.isLoading;
  }
  setLoading() {
    this.isLoading.next(true);
  }
  setLoaded() {
    this.isLoading.next(false);
  }
}
