<div class="wrapper" *ngIf="state$ | async as state">
    <div class="bar bg-black dark:!bg-slate-200">
        <div class="bar-progress blue" [style]="'width: ' + state.stars.percent +'%'"></div>
        <div class="bar-progress grey bg-stone-300 dark:bg-slate-900"></div>
    </div>
    <!-- Black circles -->
    <div class="circle circle1 bg-black dark:!bg-slate-200"></div>
    <div class="circle circle2 bg-black dark:!bg-slate-200"></div>
    <div class="circle circle3 bg-black dark:!bg-slate-200"></div>
    <div class="circle circle4 bg-black dark:!bg-slate-200"></div>
    <div class="circle circle4-1 bg-black dark:!bg-slate-200"></div>

    <!-- Grey circles -->
    <div class="circle-inner circle5 grey bg-stone-300 dark:!bg-slate-900"></div>
    <div class="circle-inner circle6 grey bg-stone-300 dark:!bg-slate-900"></div>
    <div class="circle-inner circle7 grey bg-stone-300 dark:!bg-slate-900"></div>
    <div class="circle-inner circle8 grey bg-stone-300 dark:!bg-slate-900"></div>
    <div class="circle-inner circle8-1 grey bg-stone-300 dark:!bg-slate-900"></div>

    <!-- Blue circles -->
    <div *ngIf="state.stars.stars[0].isGuessed" class="circle-inner circle5 blue"></div>
    <div *ngIf="state.stars.stars[1].isGuessed" class="circle-inner circle6 blue"></div>
    <div *ngIf="state.stars.stars[2].isGuessed" class="circle-inner circle7 blue"></div>
    <div *ngIf="state.stars.stars[3].isGuessed" class="circle-inner circle8 blue"></div>
    <div *ngIf="state.stars.stars[4].isGuessed" class="circle-inner circle8-1 blue"></div>

    <!-- Stars -->
    <div class="star star1 dark:invert">
        <img ngSrc="/assets/star.svg" width="200" height="200" alt="star icon in progress bar">
    </div>
    <div class="star star2 dark:invert">
        <img ngSrc="/assets/star.svg" width="200" height="200" alt="star icon in progress bar">
    </div>
    <div class="star star3 dark:invert">
        <img ngSrc="/assets/star.svg" width="200" height="200" alt="star icon in progress bar">
    </div>
    <div class="star star4 dark:invert">
        <img ngSrc="/assets/star.svg" width="200" height="200" alt="star icon in progress bar">
    </div>

    <div class="star star5 dark:invert">
        <img ngSrc="/assets/star.svg" width="200" height="200" alt="star icon in progress bar">
    </div>

    <!-- Gold stars -->
    <div [hidden]="!state.stars.stars[0].isGuessed" class="star star1"
        [ngClass]="{'star-animation' : state.stars.stars[0].isGuessed && !state.stars.stars[0].shownAnimation}">
        <img ngSrc="/assets/star-filled.svg" width="200" height="200" alt="filled in star icon in progress bar">
    </div>
    <div [hidden]="!state.stars.stars[1].isGuessed" class="star star2"
        [ngClass]="{'star-animation' : state.stars.stars[1].isGuessed && !state.stars.stars[1].shownAnimation}">
        <img ngSrc="/assets/star-filled.svg" width="200" height="200" alt="filled in star icon in progress bar">
    </div>
    <div [hidden]="!state.stars.stars[2].isGuessed" class="star star3"
        [ngClass]="{'star-animation' : state.stars.stars[2].isGuessed && !state.stars.stars[2].shownAnimation}">
        <img ngSrc="/assets/star-filled.svg" width="200" height="200" alt="filled in star icon in progress bar">
    </div>
    <div [hidden]="!state.stars.stars[3].isGuessed" class="star star4"
        [ngClass]="{'star-animation' : state.stars.stars[3].isGuessed && !state.stars.stars[3].shownAnimation}">
        <img ngSrc="/assets/star-filled.svg" width="200" height="200" alt="filled in star icon in progress bar">
    </div>
    <div [hidden]="!state.stars.stars[4].isGuessed" class="star star5"
        [ngClass]="{'star-animation' : state.stars.stars[4].isGuessed && !state.stars.stars[4].shownAnimation}">
        <img ngSrc="/assets/star-filled.svg" width="200" height="200" alt="filled in star icon in progress bar">
    </div>
</div>
