import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-end-mobile-ad',
  templateUrl: './end-mobile-ad.component.html',
  styleUrls: ['./end-mobile-ad.component.scss']
})
export class EndMobileAdComponent {

  user$: Observable<UserDetails>;

  constructor(private user: UserService) {
    this.user$ = this.user.getUserDetails();
  }

}
