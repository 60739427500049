import { Weather } from "../interfaces/interfaces";

export const weatherByState : Weather[] = [
  {
      "state": "Alabama",
      "code": "AL",
      "weather": [
          56,
          61,
          68,
          76,
          83,
          88,
          91,
          90,
          86,
          77,
          66,
          58
      ]
  },
  {
      "state": "Alaska",
      "code": "AK",
      "weather": [
          11,
          16,
          21,
          35,
          49,
          60,
          62,
          58,
          48,
          33,
          19,
          13
      ]
  },
  {
      "state": "Arizona",
      "code": "AZ",
      "weather": [
          56,
          59,
          66,
          74,
          83,
          93,
          95,
          93,
          88,
          77,
          65,
          55
      ]
  },
  {
      "state": "Arkansas",
      "code": "AR",
      "weather": [
          50,
          55,
          63,
          72,
          80,
          87,
          91,
          91,
          85,
          74,
          62,
          52
      ]
  },
  {
      "state": "California",
      "code": "CA",
      "weather": [
          55,
          57,
          62,
          68,
          76,
          85,
          91,
          90,
          85,
          74,
          62,
          54
      ]
  },
  {
      "state": "Colorado",
      "code": "CO",
      "weather": [
          39,
          42,
          51,
          58,
          67,
          78,
          84,
          81,
          74,
          61,
          48,
          39
      ]
  },
  {
      "state": "Connecticut",
      "code": "CT",
      "weather": [
          36,
          39,
          47,
          59,
          70,
          78,
          83,
          81,
          74,
          63,
          51,
          41
      ]
  },
  {
      "state": "Delaware",
      "code": "DE",
      "weather": [
          44,
          47,
          54,
          65,
          74,
          83,
          87,
          85,
          79,
          69,
          57,
          48
      ]
  },
  {
      "state": "Florida",
      "code": "FL",
      "weather": [
          70,
          73,
          77,
          82,
          88,
          90,
          92,
          91,
          89,
          84,
          77,
          72
      ]
  },
  {
      "state": "Georgia",
      "code": "GA",
      "weather": [
          58,
          62,
          69,
          76,
          83,
          89,
          91,
          90,
          85,
          77,
          67,
          60
      ]
  },
  {
      "state": "Hawaii",
      "code": "HI",
      "weather": [
          77,
          77,
          77,
          79,
          80,
          82,
          83,
          84,
          84,
          83,
          80,
          78
      ]
  },
  {
      "state": "Idaho",
      "code": "ID",
      "weather": [
          33,
          37,
          46,
          53,
          63,
          71,
          82,
          81,
          71,
          56,
          42,
          32
      ]
  },
  {
      "state": "Illinois",
      "code": "IL",
      "weather": [
          35,
          40,
          51,
          64,
          74,
          83,
          86,
          84,
          79,
          66,
          51,
          39
      ]
  },
  {
      "state": "Indiana",
      "code": "IN",
      "weather": [
          36,
          40,
          51,
          64,
          74,
          82,
          85,
          84,
          78,
          66,
          52,
          40
      ]
  },
  {
      "state": "Iowa",
      "code": "IA",
      "weather": [
          28,
          33,
          46,
          60,
          71,
          81,
          84,
          82,
          75,
          62,
          46,
          33
      ]
  },
  {
      "state": "Kansas",
      "code": "KS",
      "weather": [
          43,
          47,
          58,
          67,
          76,
          87,
          92,
          90,
          82,
          69,
          56,
          45
      ]
  },
  {
      "state": "Kentucky",
      "code": "KY",
      "weather": [
          44,
          48,
          58,
          69,
          77,
          84,
          87,
          86,
          81,
          70,
          57,
          47
      ]
  },
  {
      "state": "Louisiana",
      "code": "LA",
      "weather": [
          60,
          64,
          71,
          78,
          84,
          90,
          92,
          92,
          88,
          80,
          69,
          62
      ]
  },
  {
      "state": "Maine",
      "code": "ME",
      "weather": [
          25,
          28,
          37,
          50,
          63,
          72,
          77,
          76,
          68,
          55,
          42,
          31
      ]
  },
  {
      "state": "Maryland",
      "code": "MD",
      "weather": [
          43,
          46,
          54,
          66,
          74,
          83,
          87,
          85,
          78,
          68,
          56,
          47
      ]
  },
  {
      "state": "Massachusetts",
      "code": "MA",
      "weather": [
          35,
          37,
          45,
          57,
          68,
          77,
          82,
          80,
          73,
          61,
          50,
          40
      ]
  },
  {
      "state": "Michigan",
      "code": "MI",
      "weather": [
          28,
          31,
          41,
          54,
          67,
          76,
          80,
          78,
          71,
          58,
          44,
          33
      ]
  },
  {
      "state": "Minnesota",
      "code": "MN",
      "weather": [
          19,
          25,
          38,
          53,
          67,
          76,
          80,
          78,
          70,
          55,
          38,
          25
      ]
  },
  {
      "state": "Mississippi",
      "code": "MS",
      "weather": [
          56,
          61,
          68,
          76,
          83,
          89,
          91,
          91,
          87,
          77,
          66,
          58
      ]
  },
  {
      "state": "Missouri",
      "code": "MO",
      "weather": [
          40,
          46,
          56,
          67,
          76,
          84,
          88,
          87,
          80,
          69,
          55,
          44
      ]
  },
  {
      "state": "Montana",
      "code": "MT",
      "weather": [
          31,
          34,
          44,
          53,
          63,
          72,
          82,
          81,
          70,
          55,
          41,
          31
      ]
  },
  {
      "state": "Nebraska",
      "code": "NE",
      "weather": [
          36,
          40,
          51,
          61,
          71,
          82,
          87,
          85,
          78,
          64,
          50,
          38
      ]
  },
  {
      "state": "Nevada",
      "code": "NV",
      "weather": [
          44,
          47,
          55,
          61,
          70,
          81,
          90,
          88,
          79,
          66,
          52,
          43
      ]
  },
  {
      "state": "New Hampshire",
      "code": "NH",
      "weather": [
          29,
          32,
          40,
          54,
          66,
          74,
          79,
          78,
          70,
          57,
          45,
          34
      ]
  },
  {
      "state": "New Jersey",
      "code": "NJ",
      "weather": [
          40,
          43,
          51,
          63,
          72,
          81,
          86,
          84,
          77,
          66,
          55,
          45
      ]
  },
  {
      "state": "New Mexico",
      "code": "NM",
      "weather": [
          50,
          54,
          62,
          69,
          78,
          88,
          89,
          86,
          80,
          71,
          59,
          49
      ]
  },
  {
      "state": "New York",
      "code": "NY",
      "weather": [
          30,
          33,
          41,
          55,
          67,
          75,
          80,
          78,
          71,
          59,
          46,
          35
      ]
  },
  {
      "state": "North Carolina",
      "code": "NC",
      "weather": [
          51,
          55,
          62,
          71,
          78,
          85,
          88,
          86,
          81,
          72,
          62,
          54
      ]
  },
  {
      "state": "North Dakota",
      "code": "ND",
      "weather": [
          20,
          24,
          37,
          53,
          66,
          76,
          82,
          81,
          71,
          55,
          37,
          25
      ]
  },
  {
      "state": "Ohio",
      "code": "OH",
      "weather": [
          36,
          40,
          49,
          63,
          73,
          81,
          84,
          83,
          77,
          65,
          51,
          40
      ]
  },
  {
      "state": "Oklahoma",
      "code": "OK",
      "weather": [
          50,
          55,
          63,
          72,
          80,
          89,
          94,
          93,
          85,
          74,
          62,
          51
      ]
  },
  {
      "state": "Oregon",
      "code": "OR",
      "weather": [
          41,
          44,
          50,
          56,
          64,
          72,
          82,
          82,
          74,
          61,
          47,
          40
      ]
  },
  {
      "state": "Pennsylvania",
      "code": "PA",
      "weather": [
          35,
          38,
          47,
          60,
          71,
          78,
          82,
          81,
          74,
          62,
          50,
          39
      ]
  },
  {
      "state": "Puerto Rico",
      "code": "PR",
      "weather": [
          84,
          84,
          85,
          87,
          87,
          89,
          89,
          89,
          89,
          89,
          86,
          85
      ]
  },
  {
      "state": "Rhode Island",
      "code": "RI",
      "weather": [
          38,
          40,
          47,
          58,
          68,
          76,
          82,
          81,
          74,
          63,
          52,
          43
      ]
  },
  {
      "state": "South Carolina",
      "code": "SC",
      "weather": [
          56,
          60,
          67,
          76,
          83,
          88,
          91,
          90,
          85,
          76,
          66,
          59
      ]
  },
  {
      "state": "South Dakota",
      "code": "SD",
      "weather": [
          29,
          33,
          45,
          57,
          68,
          79,
          86,
          84,
          75,
          60,
          44,
          32
      ]
  },
  {
      "state": "Tennessee",
      "code": "TN",
      "weather": [
          47,
          52,
          61,
          71,
          78,
          85,
          88,
          87,
          82,
          72,
          60,
          50
      ]
  },
  {
      "state": "Texas",
      "code": "TX",
      "weather": [
          60,
          64,
          71,
          79,
          86,
          92,
          95,
          95,
          88,
          80,
          69,
          60
      ]
  },
  {
      "state": "U.S. Virgin Islands",
      "code": "VI",
      "weather": [
          84,
          84,
          85,
          86,
          87,
          89,
          90,
          90,
          89,
          88,
          87,
          85
      ]
  },
  {
      "state": "Utah",
      "code": "UT",
      "weather": [
          38,
          43,
          53,
          60,
          70,
          81,
          88,
          86,
          77,
          63,
          49,
          38
      ]
  },
  {
      "state": "Vermont",
      "code": "VT",
      "weather": [
          27,
          30,
          39,
          52,
          65,
          74,
          78,
          76,
          69,
          56,
          43,
          32
      ]
  },
  {
      "state": "Virginia",
      "code": "VA",
      "weather": [
          46,
          49,
          57,
          68,
          75,
          83,
          86,
          85,
          79,
          69,
          58,
          49
      ]
  },
  {
      "state": "Washington",
      "code": "WA",
      "weather": [
          37,
          42,
          49,
          56,
          64,
          70,
          79,
          79,
          71,
          57,
          44,
          37
      ]
  },
  {
      "state": "Washington D.C",
      "code": "DC",
      "weather": [
          44,
          48,
          57,
          69,
          78,
          86,
          91,
          88,
          82,
          70,
          58,
          50
      ]
  },
  {
      "state": "West Virginia",
      "code": "WV",
      "weather": [
          40,
          44,
          53,
          65,
          73,
          80,
          83,
          82,
          77,
          66,
          54,
          44
      ]
  },
  {
      "state": "Wisconsin",
      "code": "WI",
      "weather": [
          24,
          29,
          41,
          54,
          67,
          76,
          80,
          78,
          71,
          57,
          42,
          29
      ]
  },
  {
      "state": "Wyoming",
      "code": "WY",
      "weather": [
          32,
          35,
          45,
          52,
          62,
          73,
          82,
          80,
          70,
          56,
          42,
          32
      ]
  }
]
