import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {

  constructor(private router: Router, private platform: PlatformService) { }

  canActivate() {
    if(!this.platform.isInBrowser()) return false;
    const admin = localStorage.getItem('admin');
    if (admin === 'true') {
      return true;
    } else {
      this.router.navigate(['/admin'])
      return false;
    }
  }

}
