import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { Game } from '../interfaces/interfaces';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class LandmarksGuard implements CanActivate {

  constructor(private data: DataService, public router: Router) {}

  canActivate() {
      const state: Game = this.data.getStateObj();
      if(state.showShare) {
        return true;
      } else {
        this.router.navigate(['/'])
        return false;
      }
  }

}
