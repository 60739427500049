import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, Observable, tap } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnInit {

  loaded: boolean = false;
  @Input() imgSrc: string = "";

  constructor(private dialog: DialogsService) {}

  ngOnInit(): void {
  }

  imageLoaded() {
    this.loaded = true;
  }

  imageClicked() {
    if(window.innerWidth > 768) {
      this.dialog.updateImage(this.imgSrc);
      this.dialog.openEnlargeImage();
    }
  }
}
