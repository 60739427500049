<div *ngIf="data$ | async as data">
  <div class="wrapper mt-3" *ngIf="state$ | async as state">
    <app-image [imgSrc]="state.imgSrc" class="w-full mb-2"></app-image>
    <div *ngIf="showRound || data.isArchiveGame" class="w-full">
      <app-progress></app-progress>
      <app-guesses></app-guesses>
    </div>
    <div *ngIf="!showRound && !data.isArchiveGame" class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative w-full flex flex-col" role="alert">
      <strong class="font-bold">You've already played this game</strong>
      <span class="block sm:inline">Looks like you have already played this game on another device. Come back tomorrow to play the next game.</span>
    </div>
    <br />
  </div>
</div>
