import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-success',
  templateUrl: './delete-success.component.html',
  styleUrls: ['./delete-success.component.scss']
})
export class DeleteSuccessComponent {

}
